export const API_URL = window.location.port === '8082' ? 'http://127.0.0.1:80/api/' : '/api/';

export const API_ENDPOINTS = {
    GET_GNVOCABULARY: 'mobius/gnvocabulary',
    GET_MOBIUS_PROGRAM: 'mobius/programs',
    GET_MOBIUS_IMAGES: 'gn-ids/images',
    GET_MOBIUS_CATALOGS: 'gn-ids/catalogs',
    CATALOG_PROGRAMS: 'gn-ids/catalogPrograms',
    GET_MOBIUS_PROGRAM_IMAGES: 'gn-ids/programImages',
    GET_MOBIUS_MOCK_ALL_PROGRAMS: 'mobius/catalog-programs',
    GET_GNIDS_CATALOG: 'gn-ids/catalog-programs',
    GET_GNIDS_PROGRAM: 'gn-ids/programs/presentations',
    GET_GNIDS_GNVOCABULARY: 'gn-ids/gnvocabulary',
    GET_GNIDS_PROGRAM_SUMMARIES: 'gn-ids/programSummaries',
    GET_GNIDS_MOVIES: 'gn-ids/movies',
    GET_GNIDS_SHOWS: 'gn-ids/shows',
    GET_BULK_BATCH_STATUS: 'gn-ids/bulk',
    GNIDS_PROGRAMS: 'gn-ids/programs',
    GNIDS_PUBLISH: 'gn-ids/publish',
    GNIDS_BULK: 'gn-ids/bulk',
    ENTITLEMENTS_SOURCES: 'gn-ids-entitlements/sources',
    ENTITLEMENTS_EXPRESSION: 'entitlement-expression'
};

export const API_CURRENT_VERSION = '4';
export const API_NEXT_VERSION = '5';

