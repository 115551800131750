import {
    SET_SELECTED_CATALOG_FROM_DROPDOWN, SET_GNIDS_CATALOG, CLEAR_GNIDS_CATALOG_MODAL_DATA, SHOW_CATALOG_MODAL, MOBIUS_ADD_CATALOGS_SUCCESS, MOBIUS_ADD_CATALOGS_FAIL, GNIDS_UPDATE_REMOVE_CATALOG_PROGRAM_MAPPINGS, GNIDS_UPDATE_REMOVE_CATALOG_PROGRAM_MAPPINGS_FAIL
} from '../actions/ActionTypes';

export const CATALOG_STORE_NAME = 'catalog';

/**
 * Initial State
 */
export const initialState = {
    catalog: null,
    show: false,
    catalogs: [{name: 'All Catalogs', value: ''}],
    catalogProgramMappings: [],
    selectedCatalogFromDropdown: ''
};

/**
 * Reducer
 */
export const GNIDSCatalogReducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_GNIDS_CATALOG:
        return {
            ...state,
            catalog: action.payload
        };
    case SHOW_CATALOG_MODAL:
        return {
            ...state,
            show: action.payload
        }
    case CLEAR_GNIDS_CATALOG_MODAL_DATA:
        return initialState;
    case MOBIUS_ADD_CATALOGS_SUCCESS:
        return {
            ...state,
            catalogs: [...initialState.catalogs, ...action.payload.catalogs.map(catalog => ({name: catalog.name, value: catalog.gnID}))]
        };
    case MOBIUS_ADD_CATALOGS_FAIL:
        return {
            ...state,
            catalogs: initialState.catalogs
        };
    case SET_SELECTED_CATALOG_FROM_DROPDOWN:
        return {
            ...state,
            selectedCatalogFromDropdown: action.payload
        }
    case GNIDS_UPDATE_REMOVE_CATALOG_PROGRAM_MAPPINGS:
        return {
            ...state,
            catalogProgramMappings: action.payload
        };
    case GNIDS_UPDATE_REMOVE_CATALOG_PROGRAM_MAPPINGS_FAIL:
        return {
            ...state,
            catalogProgramMappings: initialState.catalogProgramMappings
        };
    default:
        return state;
    }
};


/**
 * Selectors
 */
export const gnidsCatalog = (state) => state[CATALOG_STORE_NAME]?.catalog;
export const isCatalogModalOpen = (state) => state[CATALOG_STORE_NAME]?.show
export const getGNIDSAllCatalogs = (state) => state[CATALOG_STORE_NAME]?.catalogs;
export const getSelectedCatalogFromDropdown = (state) => state[CATALOG_STORE_NAME]?.selectedCatalogFromDropdown;

/**
 * Default export
 */
export default GNIDSCatalogReducer;