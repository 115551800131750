import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from '../reducers';
import airbrakeMiddleware from 'redux-airbrake';
import EnvironmentService from "./EnvironmentService.js";
import { GNVAUTH_STORE_NAME, gvauthSelToken } from '../reducers/GNVAuthReducer';
import { GNIDS_STATE } from '../constants/LocalStorage';
import axios from 'axios';

export const history = createBrowserHistory();

const configureStore = (preloadedState) => {
    const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: "gnids-state"
    }) : compose;
    const store = createStore(
        createRootReducer(history),
        preloadedState,
        composeEnhancer(
            applyMiddleware(
                thunk,
                routerMiddleware(history),
                airbrakeMiddleware(new EnvironmentService().getAirbrakeClient())))
    );

    // Hot reloading
    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers', () => {
            store.replaceReducer(createRootReducer(history));
        });
    }

    return store;
}

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem(GNIDS_STATE);
        if (serializedState === null) {
            return undefined;
        }
        const prevState = JSON.parse(serializedState);
        return prevState;
    } catch (err) {
        return undefined;
    }
};

const persistedState = loadState();
export const store = configureStore(persistedState);

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(GNIDS_STATE, serializedState);
    } catch (err) {
        gnviewSendLogMessage('Had trouble saving gnids-webclient Redux state')();
    }
};

store.subscribe(() => {
    saveState(store.getState());
});

/** LogService */
export const gnviewSendLogMessage = (message, error = null, param = {}, level = 'ERROR') => {
    return () => {
        if (error) {
            console.error(message, error);
        }
        if (new EnvironmentService().getIsInDevMode() === false) {
            const body = {
                level,
                message,
                "user": store.getState()[GNVAUTH_STORE_NAME].email,
                ...param
            }
            const axiosParams = {
                method: 'post',
                url: '/logs',
                data: body,
                timeout: 1000 * 60,
                headers: {
                    Authorization: `Bearer ${gvauthSelToken(store.getState())}`
                }
            };
            return axios(axiosParams);
        }
        return null;
    }
};