import { GNIDS_UPDATE_REMOVE_CATALOG_PROGRAM_MAPPINGS, GNIDS_UPDATE_REMOVE_CATALOG_PROGRAM_MAPPINGS_FAIL, SET_CURRENT_PROGRAMS, SET_CURRENT_SELECTED_CATALOG } from './ActionTypes';
import { API_ENDPOINTS } from '../config/Api';
import apiService from '../services/ApiService';

export const setCurrentSelectedCatalog = (data) => ({
    type: SET_CURRENT_SELECTED_CATALOG,
    payload: data
});

export const setCurrentPrograms = (data) => ({
    type: SET_CURRENT_PROGRAMS,
    payload: data
});

export const gnidsRemoveProgramsFromCatalogsSuccess = (removedCatalogProgramMappings) => ({
    type: GNIDS_UPDATE_REMOVE_CATALOG_PROGRAM_MAPPINGS,
    payload: { removedCatalogProgramMappings }
});

export const gnidsRemoveProgramsFromCatalogsFail = (data) => ({
    type: GNIDS_UPDATE_REMOVE_CATALOG_PROGRAM_MAPPINGS_FAIL,
    payload: { data }
});

export const gnidsRemoveProgramsFromCatalog = (catalogGnID, presentationGnIDs, sourceID) => {
    return async (dispatch) => {
        const srcID = sourceID[0];
        const body = presentationGnIDs.map(pgnid => {
            return { catalogGnID, presentationGnID: pgnid}
        })
        const headers = { SourceID: srcID };

        return apiService
            .makeApiCall(
                API_ENDPOINTS.CATALOG_PROGRAMS,
                body,
                'patch',
                false,
                headers
            ).then(
                (response) => {
                    dispatch(gnidsRemoveProgramsFromCatalogsSuccess(response));
                    return response;
                },
                (error) => {
                    dispatch(gnidsRemoveProgramsFromCatalogsFail(error));
                    throw error;
                }
            )
    }
};

export const gnidsAddProgramsToCatalogs = (catalogGnID, presentationGnIDs, sourceID) => {
    return async () => {
        const srcID = sourceID[0];
        const body = presentationGnIDs.map(gnid => {
            return {catalogGnID, presentationGnID: gnid}
        })
        const headers = { SourceID: srcID}
        return apiService
            .makeApiCall(
                API_ENDPOINTS.CATALOG_PROGRAMS,
                body,
                'post',
                false,
                headers
            ).then(
                (response) => {
                    return response;
                }
            ).catch(
                (error) => {
                    throw error;
                }
            )
    }
}
