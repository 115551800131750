import { array, boolean, date, number, object, string } from "yup";
import {
    MOBIUS_MIN_CHARACTER_LIMIT,
    MOBIUS_MAX_CHARACTER_LIMIT,
    MOBIUS_MIN_YEAR,
    MOBIUS_MAX_YEAR
} from "./MobiusProgramValidation";
import {MOBIUS_PROGRAM_SUBTYPES_VALUES} from './Mobius';

export const SEASON_SUBTYPE_INITIAL_VALUE = MOBIUS_PROGRAM_SUBTYPES_VALUES.SEASON_OF_A_SERIES;
export const NO_SPACES_REGEX = /^(\S+$)/;

export const MOBIUS_SEASON_FIELDS = {
    DESCRIPTIONS: 'descriptions',
    FINALE_DATE: 'finaleDate',
    NON_SEASON: 'nonSeason',
    SEASON_NUMBER: 'seasonNumber',
    SUBTYPE: 'subType'
};

export const MOBIUS_SEASON_INITIAL_TOUCHED = {
    finaleDate: false,
    nonSeason: false,
    seasonNumber: false,
    subType: false
};

export const MOBIUS_SEASON_INITIAL_VALUES = {
    descriptions: [],
    finaleDate: null,
    nonSeason: false,
    seasonNumber: '',
    subType: SEASON_SUBTYPE_INITIAL_VALUE
};

export const MOBIUS_SEASON_VALIDATION_SCHEMA = object().shape({
    descriptions: array(
        object({
            language: string(),
            length: number(),
            value: string().when('length', (length, schema) => {
                return length ? schema.max(length, "(Maximum of ${max} Characters)") : schema.max(MOBIUS_MAX_CHARACTER_LIMIT, "(Maximum of ${max} Characters)");
            })
        })
    ).nullable(),
    finaleDate: date()
        .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
        .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`)
        .nullable(),
    nonSeason: boolean(),
    seasonNumber: string()
        .when('nonSeason', {
            is: (nonSeason) => nonSeason === false || nonSeason === undefined,
            then: string().required()
                .min(MOBIUS_MIN_CHARACTER_LIMIT, "(Minimum of ${min} Character)")
                .max(MOBIUS_MAX_CHARACTER_LIMIT, "(Maximum of ${max} Characters)"),
            otherwise: string().optional()
        }),
    subType: string().required("(Season subtype is required)")
});
