import React, { useState } from 'react';
import { Form, Popover } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import './GNInput.scss';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { REQUIRED, REQUIRED_FOR_PUBLISH } from '../../../constants/App';


export const GNInput = (props) => {
    const value = props?.value || '';
    const [inputValue, setValue] = useState(value);

    const infoPopover = (
        <Popover>
            <Popover.Content>{props?.infoMessage}</Popover.Content>
        </Popover>
    );

    return (
        <div className={ClassNames('gnInput-main-container', { [props.className]: props.className })}>
            <InputGroup>
                {props?.label && (
                    <Form.Label>
                        {props.label}
                        {props?.infoMessage && (
                            <OverlayTrigger trigger='hover' placement='top' overlay={infoPopover} delay={500}>
                                <FontAwesomeIcon icon='info-circle' />
                            </OverlayTrigger>
                        )}
                        {props?.helpText && <span className="mappingHelpBlock" >
                            {props?.helpText}
                        </span>}
                    </Form.Label>
                )}
                <Form.Control
                    as={props.type}
                    placeholder={props?.placeholder}
                    disabled={props?.disabled || false}
                    value={ props.value }
                    data-lpignore={true}
                    onChange={(e) => {
                        props.handleChange(e.target.value);
                        setValue(e.target.value);
                    }}
                    {...(props?.textAreaRows && { rows: props.textAreaRows })}
                    {...((!props?.validateInput(inputValue) || props?.status) && (!props.hasValue || props.isValueInvalid) && { className: 'invalid' })}
                />
                {props.value?.length > 0 && !props?.disabled && (
                    <FontAwesomeIcon icon="xmark"
                        className={ClassNames({ 'has-label': props?.label })}
                        onClick={(e) => {
                            props.handleChange('', e);
                            setValue('');
                        }}
                    />
                )}
                <div className={ClassNames('validation-text', { invalid: (!props.hasValue || props.isValueInvalid) && (!props?.validateInput(inputValue) || props?.status)})}>
                    <Form.Text className='required-text'>
                        {props?.isRequiredForPublish ? REQUIRED_FOR_PUBLISH : (props?.isRequired && REQUIRED)}
                        {props?.validationText}
                        {(!props?.validationText && props?.status) && <div className='status-text'>{props.status}</div>}
                    </Form.Text>
                    {props.type === 'textarea' && (
                        <Form.Text className='textarea-max'>{`${inputValue.length}/${props.textAreaMax}`}</Form.Text>
                    )}
                </div>
            </InputGroup>
        </div>
    );
};

GNInput.defaultProps = {
    className: '',
    hasValue: false,
    isRequired: false,
    isValueInvalid: false,
    type: 'input'
};

GNInput.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    hasValue: PropTypes.bool,
    infoMessage: PropTypes.string,
    isRequired: PropTypes.bool,
    isRequiredForPublish: PropTypes.bool,
    isValueInvalid: PropTypes.bool,
    label: PropTypes.string,
    helpText: PropTypes.string,
    placeholder: PropTypes.string,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    textAreaMax: PropTypes.number,
    textAreaRows: PropTypes.number,
    type: PropTypes.string,
    validateInput: PropTypes.func.isRequired,
    validationText: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default GNInput;
