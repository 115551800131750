import React from 'react';
import { Button } from 'react-bootstrap';
import lockIcon from '../../../images/403_Icon.png';
import './AccessDenied.scss';
import {
    ACCESS_DENIED_HEADER,
    ACCESS_DENIED_SUBHEADER,
    ACCESS_DENIED_ACCOUNT,
    LEARN_MORE_LINK
} from '../../../constants/AccessDenied';

export function AccessDenied() {
    return (
        <div className="gnview-access-content">
            <div className="access-content-body">
                <img src={lockIcon} alt="Access Denied" className="lock-icon" />
                <div className="access-denied-header">
                    {ACCESS_DENIED_HEADER}
                </div>
                <div className="subheader">{ACCESS_DENIED_SUBHEADER}</div>
                <Button className="upgrade-account-btn">
                    <a href={LEARN_MORE_LINK} target="_blank" rel="noreferrer">
                        {ACCESS_DENIED_ACCOUNT}
                    </a>
                </Button>
            </div>
        </div>
    );
}

export default AccessDenied;
