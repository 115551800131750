import { array, boolean, number, object, string } from "yup";
import { IMAGE_LABELS } from "./Image";
import { MOBIUS_MAX_CHARACTER_LIMIT, MOBIUS_MIN_CHARACTER_LIMIT } from "./MobiusProgramValidation";
import { isValidUrl } from "../utils/MobiusUtils";

export const MOBIUS_IMAGE_TITLE_MAX_LENGTH = 150;
export const MOBIUS_IMAGE_INITIAL_TOUCHED = {
    category: false,
    customAttributes: false,
    dimensions: {
        width: false,
        height: false,
        aspectRatio: false
    },
    fileType: false,
    title: false,
    url: false,
    description: {
        length: false,
        language: false,
        value: false
    },
    gracenoteUsage: false,
    sensitive: false,
    branded: false,
    languages: false,
    markets: false,
    tags: false
};

export const MOBIUS_IMAGE_INITIAL_VALUES = {
    category: '',
    customAttributes: [],
    dimensions: {
        width: '',
        height: '',
        aspectRatio: ''
    },
    fileType: '',
    title: '',
    url: '',
    description: {
        language: '',
        length: 500,
        value: ''
    },
    gracenoteUsage: true,
    sensitive: false,
    branded: false,
    brandDescription: '',
    languages: [],
    markets: [],
    tags: []
};

export const MOBIUS_IMAGE_FIELDS = {
    CATEGORY: 'category',
    CUSTOM_ATTRIBUTES: 'customAttributes',
    CUSTOM_ATTRIBUTES_LABEL: (idx) => `customAttributes[${idx}].label`,
    CUSTOM_ATTRIBUTES_VALUE: (idx) => `customAttributes[${idx}].value`,
    DIMENSIONS: 'dimensions',
    WIDTH: 'width',
    HEIGHT: 'height',
    ASPECT_RATIO: 'aspectRatio',
    DIMENSIONS_HEIGHT: 'dimensions.height',
    DIMENSIONS_WIDTH: 'dimensions.width',
    DIMENSIONS_ASPECT_RATIO: 'dimensions.aspectRatio',
    IMAGE_TYPE: 'fileType',
    TITLE: 'title',
    URL: 'url',
    DESCRIPTION: 'description',
    DESCRIPTION_LANGUAGE: 'description.language',
    DESCRIPTION_LENGTH: 'description.length',
    DESCRIPTION_VALUE: 'description.value',
    GRACENOTE_USAGE: 'gracenoteUsage',
    SENSITIVE: 'sensitive',
    BRANDED: 'branded',
    BRAND_DESCRIPTION: 'brandDescription',
    LANGUAGES: 'languages',
    MARKETS: 'markets',
    TAGS: 'tags'
}


export const MOBIUS_IMAGE_VALIDATION_SCHEMA = object().shape({
    category: string().required(),
    customAttributes: array().of(
        object().shape({
            label: string()
                .min(MOBIUS_MIN_CHARACTER_LIMIT, '(Minimum of ${min} Character)')
                .max(MOBIUS_MAX_CHARACTER_LIMIT, '(Maximum of ${max} Characters)')
                .test(MOBIUS_IMAGE_FIELDS.CUSTOM_ATTRIBUTES_LABEL, IMAGE_LABELS.DUPLICATE_LABELS, (label, context) => {
                    const { createError, path } = context;
                    // if there is only one/zero custom attribute, then it cannot be a duplicate
                    if (context.from[1].value.customAttributes.length <= 1) {
                        return true
                    }
                    // Get all labels in the array
                    const labelsInArray = context.from[1].value.customAttributes.map(obj => obj.label);
                    const labelCount = labelsInArray.filter(l => l === label).length;
                    if (labelCount >= 2) {
                        return createError(IMAGE_LABELS.DUPLICATE_LABELS, path)
                    }
                    return true;
                }),
            value: string()
                .min(MOBIUS_MIN_CHARACTER_LIMIT, '(Minimum of ${min} Character)')
                .max(MOBIUS_MAX_CHARACTER_LIMIT, '(Maximum of ${max} Characters)')
        })
    ),
    dimensions: object().shape({
        width: number().positive(IMAGE_LABELS.DIMENSIONS_INVALID).required(),
        height: number().positive(IMAGE_LABELS.DIMENSIONS_INVALID).required(),
        aspectRatio: string()
            .matches(/^((\d+(\.?\d+)?:\d+)|(\d+\.?\d+))$/, {message: IMAGE_LABELS.ASPECT_RATIO_INVALID, name: "aspect-ratio-invalid"})
            .required()
    }),
    fileType: string().required(),
    title: string().max(MOBIUS_IMAGE_TITLE_MAX_LENGTH, `Must be less than or equal to ${MOBIUS_IMAGE_TITLE_MAX_LENGTH} characters`).required(),
    url: string().test('url', IMAGE_LABELS.URL_INVALID, function(val, {createError, path}) {
        if (!isValidUrl(val)) {
            return createError(IMAGE_LABELS.URL_INVALID, path)
        }

        return true
    }).required(),
    description: object().shape({
        language: string(),
        value: string().max(500),
        length: string()
    }),
    gracenoteUsage: boolean(),
    sensitive: boolean(),
    branded: boolean(),
    brandDescription: string().max(500),
    languages: array()
        .of(string())
        .nullable(),
    markets: array()
        .of(string())
        .nullable(),
    tags: array()
        .of(string())
        .nullable()
});

export const getImageValidationSchema = () => {
    return MOBIUS_IMAGE_VALIDATION_SCHEMA;
}