import {
    GNIDS_BULK_UPLOAD_FAILURE,
    GNIDS_BULK_UPLOAD_SUCCESS,
    GNIDS_CLEAR_PROGRAM_FILES,
    GNIDS_SET_PROGRAM_FILES
} from '../actions/ActionTypes';

export const GNIDS_PROGRAM_FILES_STORE_NAME = 'programfiles';

/**
 * Initial State
 */
export const initialState = {
    files: [],
    bulkUploadSuccess: false,
    bulkUploadFailure: false
};

/**
 * Reducer
 */
export const GNIDSProgramFilesReducer = (state = initialState, action) => {
    switch (action.type) {
    case GNIDS_SET_PROGRAM_FILES:
        return {
            ...state,
            files: action.payload.files
        };
    case GNIDS_CLEAR_PROGRAM_FILES:
        return {...initialState};
    case GNIDS_BULK_UPLOAD_SUCCESS:
        return {
            ...state,
            bulkUploadSuccess: true
        }
    case GNIDS_BULK_UPLOAD_FAILURE:
        return {
            ...state,
            bulkUploadFailure: true
        }
    default:
        return state;
    }
};

/**
 * Selectors
 */

export const gnidsSelProgramFiles = state => state[GNIDS_PROGRAM_FILES_STORE_NAME]?.files;

/**
 * Default export
 */
export default GNIDSProgramFilesReducer;