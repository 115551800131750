import React from 'react'
import './CatalogModalViewOnly.scss'
import PropTypes from 'prop-types'
import ViewOnlyField from '../../components/common/viewOnlyField/ViewOnlyField'
import { CATALOG_LABELS, CATALOG_MODAL_SECTIONS, CATALOG_TOOLTIPS } from '../../constants/Catalog'
import { MOBIUS_VALUES } from '../../constants/Mobius'
import { convertCountryCodes, convertLanguageCodeList } from '../../utils/GeneralUtils'

export const CatalogModalViewOnly = ({catalogData}) => {
    return (
        <div className='catalog-modal-view-main-container'>
            {/* Overview section */}
            <div id="overview" className='catalog-detail-sections'>
                <div className='catalog-overview-section'>
                    <div className="view-section-title">
                        {CATALOG_MODAL_SECTIONS.OVERVIEW}
                    </div>
                    <div className='view-section-body'>
                        <div className='view-section-row name-row'>
                            <ViewOnlyField label={CATALOG_LABELS.NAME} value={catalogData?.name || MOBIUS_VALUES.EMPTY_DATA}/>
                        </div>
                        <div className='view-section-row languages-row'>
                            <ViewOnlyField label={CATALOG_LABELS.PRIMARY_LANGUAGES_USED_IN_THE_CATALOG} value= { catalogData?.languages?.length ? convertLanguageCodeList(catalogData?.languages).join(', ') : MOBIUS_VALUES.EMPTY_DATA}/>
                        </div>
                        <div className='view-section-row markets-row'>
                            <ViewOnlyField label={CATALOG_LABELS.MARKETS} value= { catalogData?.markets?.length ? convertCountryCodes(catalogData?.markets).map(country => country.name).join(', ') : MOBIUS_VALUES.EMPTY_DATA} tooltip={CATALOG_TOOLTIPS.MARKETS}/>
                        </div>
                        <div className='view-section-row tags-row'>
                            <ViewOnlyField label={CATALOG_LABELS.TAGS} value= { catalogData?.tags?.length ? catalogData?.tags?.join(', ') : MOBIUS_VALUES.EMPTY_DATA} tooltip={CATALOG_TOOLTIPS.TAGS}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-divider"/>

        </div>
    )
}

CatalogModalViewOnly.propTypes = {
    catalogData: PropTypes.object
};
