export const CSVDownload = (csv, filename) => {
    // Create a Blob from the CSV data
    const blob = new Blob([csv], { type: 'text/csv' });

    // Create a URL for the Blob
    const blobUrl = URL.createObjectURL(blob);

    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = blobUrl;
    downloadLink.download = filename;


    // Click the link to trigger the download
    downloadLink.click();
}