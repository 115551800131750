import React from 'react';
import { Typeahead } from "react-bootstrap-typeahead";
import PropTypes from 'prop-types';
import './TypeaheadFilter.scss';
import ClassNames from 'classnames';
import { MOBIUS_LABELS } from '../../../constants/Mobius';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { REQUIRED } from '../../../constants/App';
import { REQUIRED_FOR_PUBLISH } from '../../../constants/App';


export const TypeaheadFilter = React.forwardRef((props, ref) => (
    <div className={ClassNames('filter-bar-col gnview-typeahead-filter', { [props.className]: props.className })}>
        <div className={'filter-label'}>
            {props.filterLabel}{props.inherited && <span className={'inherited-text'}> {MOBIUS_LABELS.INHERITED}</span>}
        </div>
        {!props.selected?.length > 0 && <FontAwesomeIcon className={ClassNames({
            'magnifying-glass-with-label': props.filterLabel,
            'magnifying-glass-without-label': !props.filterLabel
        })} icon="magnifying-glass" />}
        <Typeahead
            className={ClassNames('typeahead-main', {
                invalid: !props.hasValue && (!props?.validateTypeahead() || props?.status || props?.errors),
                'is-disabled': props.disabled
            })}
            {...(props.labelKey && { labelKey: props.labelKey })}
            onChange={(item) => props.handleChange(item)}
            onInputChange={(val) => props.onInputChange?.(val)}
            onBlur={(val) => {
                if (props.handleBlur) {
                    props.handleBlur(val);
                }
                if (props.domRef && val.target.value && props.multiple) {
                    props.domRef.clear();
                    props.domRef.focus();
                }
            }}
            options={ props.list }
            placeholder={ props.placeholder }
            clearButton={ props.clearButton }
            multiple={ props.multiple }
            selected={ props.selected }
            disabled={ props.disabled }
            id={'typeahead-' + props.filterLabel}
            ref={ref}
            allowNew={ props.allowNew }
            newSelectionPrefix={ props.addLabelKey }
            inputProps={ {"data-lpignore": "true"} }
            bg=''
        />
        <div className={ClassNames('required-message', {invalid: !props.hasValue && (!props?.validateTypeahead() || props?.status || props?.errors)})}>
            {props?.isRequired && !props?.isRequiredForPublish && REQUIRED}
            {props?.isRequiredForPublish && REQUIRED_FOR_PUBLISH}
            {props?.validationText}
            {(!props?.validationText && props?.status) && props.status}
        </div>
    </div>
));

TypeaheadFilter.defaultProps = {
    allowNew: false,
    clearButton: true,
    disabled: false,
    filterLabel: '',
    hasValue: false,
    inherited: false,
    isRequired: false,
    newSelectionPrefix: 'Add new Item: ',
    validationText: ''
};

TypeaheadFilter.propTypes = {
    addLabelKey: PropTypes.string,
    allowNew: PropTypes.bool,
    className: PropTypes.string,
    clearButton: PropTypes.bool,
    disabled: PropTypes.bool,
    errors: PropTypes.string,
    filterLabel: PropTypes.string.isRequired,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    hasValue: PropTypes.bool,
    inherited: PropTypes.bool,
    isRequired: PropTypes.bool,
    isRequiredForPublish: PropTypes.bool,
    labelKey: PropTypes.string,
    list: PropTypes.array.isRequired,
    multiple: PropTypes.bool,
    onInputChange: PropTypes.func,
    placeholder: PropTypes.string,
    selected: PropTypes.array,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    validateTypeahead: PropTypes.func,
    validationText: PropTypes.string,
    domRef: PropTypes.node
};

TypeaheadFilter.displayName = 'TypeaheadFilter';

export default TypeaheadFilter;