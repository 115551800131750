import React from 'react';
import './ImageModalViewOnly.scss';
import PropTypes from 'prop-types';
import OverviewSection from '../../components/images/overviewSection/OverviewSection';
import DescriptionSection from '../../components/images/descriptionSection/DescriptionSection';
import AdditionalDetailsSection from '../../components/images/additionalDetailsSection/AdditionalDetailsSection';
import CustomAttributesSection from '../../components/images/customAttributesSection/CustomAttributesSection';


export const ImageModalViewOnly = ({image}) => {
    return (
        <React.Fragment>
            <div className='program-modal-view-main-container'>
                {/* Overview section */}
                <div id="overview" className='image-detail-sections'>
                    <OverviewSection imageData={image}/>
                </div>
                <div className="section-divider"/>
                {/* Description section */}
                <div id="description" className='image-detail-sections'>
                    <DescriptionSection imageData={image}/>
                </div>
                <div className="section-divider"/>
                {/* Additional details */}
                <div id="additional-details" className='image-detail-sections'>
                    <AdditionalDetailsSection imageData={image}/>
                </div>
                <div className="section-divider"/>
                {/* Custom Attributes*/}
                <div id="custom-attributes" className='image-detail-sections'>
                    <CustomAttributesSection imageData={image}/>
                </div>
            </div>
        </React.Fragment>
    );
};


ImageModalViewOnly.propTypes = {
    image: PropTypes.object
};


export default ImageModalViewOnly;