import React, { useEffect } from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import GNClientTable from '../../components/common/gnClientTable/GNClientTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { gnidsProgramsSelectedForPublish } from '../../reducers/GNIDSProgramsBulkPublishReducer';
import { gnidsClearProgramsForBulkPublish, gnidsSetProgramsForBulkPublish } from '../../actions/GNIDSProgramsBulkUploadActions';
import './GNIDBulkPublishSidebar.scss';
import GNButton from '../../components/common/gnButton/GNButton';
import { MOBIUS_BUTTON_TEXT, MOBIUS_TOOLTIPS } from '../../constants/Mobius';
import { Badge, Tooltip } from 'react-bootstrap';
import { mapProgramTypeAndSubTypeToGnviewFormat } from '../../utils/ProgramUtils';
import GNBadge from '../../components/common/gnBadge/GNBadge';
import PropTypes from 'prop-types';

export const GNIDBulkPublishSidebar = ({ name, ...props }) => {
    const dispatch = useDispatch();
    const programsSelectedForBulkPublish = useSelector(gnidsProgramsSelectedForPublish)
    const [programsSelected, setProgramsSelected] = useState(programsSelectedForBulkPublish || [])
    const [disableBtn, setDisableBtn] = useState(true);

    useEffect(() => {
        setProgramsSelected(programsSelectedForBulkPublish)
    }, [programsSelectedForBulkPublish, setProgramsSelected])
    useEffect(() => {
        if (programsSelected?.length > 0 && programsSelected?.length <= 1000) {
            setDisableBtn(false);
        } else {
            setDisableBtn(true);
        }
    }, [programsSelected])
    const onRemoveProgramBtnClick = (delRow) => {
        const afterRemove = programsSelected.filter((ele, ind) => {
            return ind !== Number(delRow.id);
        })

        dispatch(gnidsSetProgramsForBulkPublish(afterRemove));
    }

    const onRemoveAllBtnClick = () => {
        dispatch(gnidsClearProgramsForBulkPublish());
    }

    return (
        <>
            <Offcanvas backdrop={false} placement="end" {...props} onHide={props.handleClose} onEntered={() => {
                // Start the timer when the Offcanvas is fully opened
                if (props.autohide) {
                    setTimeout(props.handleClose, 5000)
                }
            }}>
                <Offcanvas.Header closeButton>
                    <FontAwesomeIcon icon="fa-regular fa-rectangle-list" size='2x' style={{color: "#a482db"}} />
                    { programsSelected?.length ? <Badge className="count-on-icon">{programsSelected?.length}</Badge> : <></>}
                    <Offcanvas.Title >{name}</Offcanvas.Title>
                </Offcanvas.Header>
                { programsSelected?.length ?
                    <Offcanvas.Body>
                        <div className='sidebar-content-top-section'>
                            <div className="count-line">
                                <span ><span style={{fontWeight: "bold"}}>{programsSelectedForBulkPublish?.length}</span> Programs Added</span>
                                <span className='remove-all-btn' onClick={onRemoveAllBtnClick}>
                Remove All
                                </span>
                            </div>
                            <span className='info-section'>* Max of 1,000 programs can be bulk published at a time</span>
                        </div>

                        <GNClientTable
                            className='bulk-upload-table'
                            columns={[{
                                "id": "title",
                                "accessor": "title",
                                "Header": "Title",
                                "disableSortBy": true,
                                /* eslint-disable react/prop-types */
                                Cell: ({row}) => (
                                    <span
                                        className='program-title-element'
                                    >
                                        {row.original.title}
                                    </span>
                                )
                            },
                            {
                                "id": "subType",
                                "accessor": "subType",
                                "Header": "SubType",
                                "disableSortBy": true,
                                /* eslint-disable react/prop-types */
                                Cell: ({row}) => {
                                    const { subType: programSubtypes} = mapProgramTypeAndSubTypeToGnviewFormat(row.original.type, row.original.subType);
                                    return (
                                        <GNBadge
                                            viewSelector='programs'
                                            type={row.original.type}
                                            subtype={programSubtypes}
                                            gnview={false}
                                            data-testid='gn-badge'
                                        />
                                    )
                                }
                            },
                            {
                                "id": "delete",
                                "accessor": "delete",
                                "Header": "",
                                "disableSortBy": true,
                                Cell: ({row}) => (

                                    <FontAwesomeIcon icon='trash-alt' className='fa-trash-alt' onClick={() => onRemoveProgramBtnClick(row)}/>
                                )

                            }

                            ]}
                            data={programsSelected ? programsSelected : [] }
                            pagination={false}
                            paginationBottom={false}
                            settings={{pageSize: 1000}}
                            noDataMessage={
                                <p className="no-data-message">{ "No Programs Selected" }</p>
                            }
                        />
                    </Offcanvas.Body> :
                    <Offcanvas.Body>
                        <div className="empty-body-container-div d-flex flex-column min-vh-100 justify-content-center align-items-center">
                            <p>Select programs and click</p>
                            <Button style={{backgroundColor: "#a482db"}}>
                                <FontAwesomeIcon icon='plus' />{'   '}
                                <FontAwesomeIcon icon="fa-regular fa-rectangle-list" />{'   '}
                                {MOBIUS_BUTTON_TEXT.BULK_PUBLISH}
                            </Button>
                            <p>to get started</p>
                        </div>
                    </Offcanvas.Body>
                }
                <div className="offcanvas-footer">
                    <div className="d-flex justify-content-end" style={{margin: "12px"}}>
                        <GNButton
                            trigger={disableBtn ? ['hover'] : null}
                            onClick={() => {}}
                            disabled={Boolean(disableBtn)}
                            tooltip={(disableBtn && (programsSelected?.length > 1000) ? <Tooltip>{MOBIUS_TOOLTIPS.BULK_PUBLISH_MORE_THAN_1000}</Tooltip> : <Tooltip>{MOBIUS_TOOLTIPS.BULK_PUBLISH_ADD_PROGRAMS}</Tooltip>) }
                            buttonClass='bulk-publish-btn'
                        >
                            <div className="icon-text-container">
                                <span className="fa-stack fa-xs">
                                    <FontAwesomeIcon icon="circle" className="fa-stack-2x" />
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-up" className="fa-stack-1x" />
                                </span>
                                <span className='btn-text'>{MOBIUS_BUTTON_TEXT.BULK_PUBLISH}</span>
                            </div>
                        </GNButton>
                    </div>
                </div>
            </Offcanvas>
        </>
    );
}

GNIDBulkPublishSidebar.propTypes = {
    name: PropTypes.string,
    handleClose: PropTypes.func,
    autohide: PropTypes.boolean
};

export default GNIDBulkPublishSidebar;
