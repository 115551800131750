import React, {useState, useEffect} from 'react'
import { useHistory, withRouter } from 'react-router'
import GNDropdown from '../gnDropdown/GNDropdown'
import { ROUTES } from '../../../config/Routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './ViewSwitcher.scss'
import PropTypes from 'prop-types';
import { MOBIUS_DATA_TYPES } from '../../../constants/Mobius';

const viewOptions = {
    programs: {
        name: 'Programs',
        value: 'programs',
        route: ROUTES.GNID_DISTRIBUTION,
        icon: 'fa-tv'
    },
    images: {
        name: 'Images',
        value: 'images',
        route: ROUTES.GNID_IMAGES,
        icon: 'fa-image'
    },
    catalogs: {
        name: 'Catalogs',
        value: 'catalogs',
        route: ROUTES.GNID_CATALOGS,
        icon: 'fa-folder'
    },
    'import status': {
        name: 'Import Status',
        value: 'import status',
        route: ROUTES.GNID_IMPORT_STATUS,
        icon: 'fa-list-check'
    }
}
function ViewSwitcher(props) {
    const history = useHistory()
    const [selectedOption, setSelectedOption] = useState(viewOptions.programs)

    const handleEditView = (view) => {
        setSelectedOption(view)
        const newRoute = viewOptions[view].route
        history.push(newRoute)
    }

    useEffect(() => {
        if (props.value === MOBIUS_DATA_TYPES.PROGRAMS) {
            setSelectedOption(viewOptions.programs)
        } else if (props.value === MOBIUS_DATA_TYPES.IMAGES) {
            setSelectedOption(viewOptions.images)
        } else if (props.value === MOBIUS_DATA_TYPES.CATALOGS) {
            setSelectedOption(viewOptions.catalogs)
        } else if (props.value === MOBIUS_DATA_TYPES.IMPORTS) {
            setSelectedOption(viewOptions['import status'])
        }
    }, [props.value])

    return (
        <div className='view-selector-container'>
            <GNDropdown
                isViewSelector={true}
                options={Object.values(viewOptions)}
                value={selectedOption}
                handleChange={handleEditView}
                renderValue={(valueProp) => (
                    <div className='view-selector-value-container'>
                        <button {...valueProp} className='view-selector-input'>
                            {{...valueProp}.value.length > 1 && <FontAwesomeIcon className='view-selector-icon' icon={viewOptions[{...valueProp}.value.toLowerCase()].icon}/>}
                            {valueProp.value}
                            <FontAwesomeIcon icon='angle-down' className='view-selector-angle-down'/>
                        </button>
                    </div>
                )}
                renderOption={(optionsProp, optionData) => (
                    <div className='view-selector-options-container'>
                        <button className='view-selector-options-button' {...optionsProp}>
                            <FontAwesomeIcon icon={viewOptions[optionsProp.value.toLowerCase()].icon} className='view-selector-icon'/>
                            {optionData.name}
                        </button>
                    </div>
                )}
            />
        </div>
    )
}

ViewSwitcher.defaultProps = {
    value: 'Programs'
}

ViewSwitcher.propTypes = {
    value: PropTypes.string.isRequired
}

export default withRouter(ViewSwitcher)