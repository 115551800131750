import { PropTypes } from "prop-types";
import { Formik } from "formik";
import get from "lodash.get";
import React, { useState } from "react";
import TypeaheadFilter from "../../components/common/typeaheadFilter/TypeaheadFilter";
import GNInput from "../../components/common/gnInput/GNInput";
import {
    MOBIUS_CATALOG_FIELDS,
    MOBIUS_CATALOG_INITIAL_TOUCHED,
    MOBIUS_CATALOG_TITLE_MAX,
    getCatalogValidationSchema
} from "../../constants/MobiusCatalogValidation";
import { useDispatch, useSelector } from "react-redux";
import {
    mobiusVocabSelCountries,
    mobiusVocabSelEntitledLanguages
} from "../../reducers/MobiusVocabReducer";
import { CATALOG_LABELS, CATALOG_MODAL_SECTIONS, CATALOG_TOOLTIPS } from "../../constants/Catalog";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    convertCountryCodesListToNamesList,
    convertLanguageCodeList,
    convertLanguageFromCode
} from "../../utils/GeneralUtils";
import { MOBIUS_BUTTON_TEXT, MOBIUS_NOTIFICATIONS, MOBIUS_TOOLTIPS} from "../../constants/Mobius";
import GNButton from "../../components/common/gnButton/GNButton";
import classNames from "classnames";
import { saveOrRegisterCatalog, setGnidsCatalog } from "../../actions/GNIDSCatalogModalActions";
import { gnviewSendLogMessage } from "../../services/GeneralService";
import GNNotification from "../../components/common/gnNotification/GNNotification";
import { verifyCatalogDataIntegrity } from "../../utils/CatalogUtils";

const CatalogModalEdit = ({ catalog, handleCancel, setRefreshTable, isRegisterMode, handleRegisterSuccess }) => {
    const mobiusEntitledLanguages = useSelector(mobiusVocabSelEntitledLanguages);

    const [saveInProgress, setSaveInProgress] = useState(false)
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState(null);
    const [notificationIsSuccess, setNotificationIsSuccess] = useState(false);
    const dispatch = useDispatch();
    const languages = mobiusEntitledLanguages.map((langCode) => ({
        name: convertLanguageFromCode(langCode),
        value: langCode
    }));

    const [inputTagValue, setInputTagValue] = useState(null);
    const [tagsRef, setTagsRef] = useState(null);
    const [selectedTags, setSelectedTags] = useState(catalog?.tags || []);

    const vocabAllCountries = useSelector(mobiusVocabSelCountries);

    const vocablAllCountriesLabels =
    vocabAllCountries?.map((country) => country.label) || [];

    const handleNotification = (show, message, isSuccess) => {
        setShowNotification(show);
        setNotificationMsg(message);
        setNotificationIsSuccess(isSuccess);
    }

    const onSaveRegisterButtonClick = (values, dirty, isValid, resetForm, setFieldError) => {
        if ((catalog?.gnID || isRegisterMode) && dirty && isValid) {
            setSaveInProgress(true);
            dispatch(saveOrRegisterCatalog({...values, gnID: catalog?.gnID}, isRegisterMode))
                .then((saveOrRegisterCatalogResp) => {
                    handleNotification(true, MOBIUS_NOTIFICATIONS.CATALOG_SUCCESS_SAVED, true);
                    resetForm({values});
                    dispatch(setGnidsCatalog(saveOrRegisterCatalogResp?.result?.data))
                    setRefreshTable(true)
                    if (isRegisterMode) {
                        handleRegisterSuccess()
                    }
                })
                .catch((error) => {
                    if (error?.error === 'conflict') {
                        if (isRegisterMode) {
                            setFieldError(MOBIUS_CATALOG_FIELDS.NAME, MOBIUS_NOTIFICATIONS.CATALOG_ERROR_UNIQUE_NAME)
                        } else {
                            handleNotification(true, MOBIUS_NOTIFICATIONS.CATALOG_ERROR_UNIQUE_NAME, false);
                        }
                    } else if (isRegisterMode) {
                        handleNotification(true, MOBIUS_NOTIFICATIONS.CATALOG_ERROR_REGISTERED, false);
                    } else {
                        handleNotification(true, MOBIUS_NOTIFICATIONS.CATALOG_ERROR_SAVED, false);
                    }
                    dispatch(gnviewSendLogMessage(`mobiusSaveCatalog error: ${error.message}`, error, catalog.gnID));
                })
                .finally(() => {
                    setSaveInProgress(false);
                })
        }
    }


    return (
        <div className="catalog-modal-edit-container">
            <Formik
                initialValues={verifyCatalogDataIntegrity(catalog)}
                initialTouched={MOBIUS_CATALOG_INITIAL_TOUCHED}
                validationSchema={getCatalogValidationSchema()}
            >
                {({
                    dirty,
                    errors,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    resetForm,
                    setFieldTouched,
                    setFieldValue,
                    setFieldError,
                    touched,
                    values
                }) => {
                    const validateForm = (formName) => {
                        return isSubmitting || get(touched, formName)
                            ? !get(errors, formName)
                            : true;
                    };

                    const handleFormChange = (formName, value) => {
                        if (!touched[formName]) {
                            setFieldTouched(formName, true);
                        }
                        setFieldValue(formName, value);
                    };

                    const handleOnBlurTypeaheads = (inputValue, selectedItems, fieldName, setSelectedItems, setInputValue) => {
                        if (inputValue) {
                            const formattedItems = selectedItems === null ? [] : [...selectedItems, inputValue];
                            handleFormChange(fieldName, formattedItems);
                            setSelectedItems(formattedItems);
                            setInputValue('');
                        }
                    }

                    return (
                        <>
                            <Form noValidate onSubmit={handleSubmit}>

                                <div className="edit-mode-container">
                                    <div className="overview-section">

                                        <div className="catalog-modal-tab-header">
                                            {CATALOG_MODAL_SECTIONS.OVERVIEW}
                                        </div>

                                        <div className="catalog-modal-tab-body">
                                            <GNInput
                                                handleChange={(item) =>
                                                    handleFormChange(MOBIUS_CATALOG_FIELDS.NAME, item)
                                                }
                                                isRequired={
                                                    getCatalogValidationSchema()?.fields[
                                                    MOBIUS_CATALOG_FIELDS.NAME
                                                    ]
                                                }
                                                label={CATALOG_LABELS.NAME}
                                                placeholder={CATALOG_LABELS.NAME}
                                                textAreaMax={MOBIUS_CATALOG_TITLE_MAX}
                                                type="textarea"
                                                validateInput={() =>
                                                    validateForm(MOBIUS_CATALOG_FIELDS.NAME)
                                                }
                                                validationText={errors?.name}
                                                value={values?.name}
                                            />

                                            <div className="typeahead-container">
                                                <TypeaheadFilter
                                                    addLabelKey={
                                                        CATALOG_LABELS.PRIMARY_LANGUAGES_USED_IN_THE_CATALOG
                                                    }
                                                    allowNew={false}
                                                    className="primary-languages-typeahead"
                                                    filterLabel={
                                                        CATALOG_LABELS.PRIMARY_LANGUAGES_USED_IN_THE_CATALOG
                                                    }
                                                    handleChange={(items) => {
                                                        const formattedItems = items?.map((item) =>
                                                            item?.label ? item?.label : item
                                                        );
                                                        handleFormChange(
                                                            MOBIUS_CATALOG_FIELDS.LANGUAGES,
                                                            formattedItems
                                                        );
                                                    }}
                                                    isRequired={
                                                        getCatalogValidationSchema()?.fields[
                                                        MOBIUS_CATALOG_FIELDS.LANGUAGES
                                                        ]?.exclusiveTests?.required
                                                    }
                                                    list={convertLanguageCodeList(
                                                        languages.map((language) => language.value)
                                                    )}
                                                    multiple={true}
                                                    placeholder={CATALOG_LABELS.PRIMARY_LANGUAGES}
                                                    selected={convertLanguageCodeList(values.languages)}
                                                    validationText={errors?.languages?.valueOf}
                                                    validateTypeahead={() =>
                                                        validateForm(MOBIUS_CATALOG_FIELDS.LANGUAGES)
                                                    }
                                                />
                                            </div>

                                            <div className="typeahead-container">
                                                <TypeaheadFilter
                                                    addLabelKey={CATALOG_LABELS.ADD_MARKETS}
                                                    allowNew={false}
                                                    className="markets-typeahead"
                                                    filterLabel={
                                                        <div className="typeahead-label-with-tooltip">
                                                            <div className="field-label">
                                                                {CATALOG_LABELS.MARKETS}
                                                            </div>
                                                            <OverlayTrigger
                                                                overlay={
                                                                    <Tooltip>{CATALOG_TOOLTIPS.MARKETS}</Tooltip>
                                                                }
                                                                placement="right"
                                                            >
                                                                <FontAwesomeIcon icon="circle-info" />
                                                            </OverlayTrigger>
                                                        </div>
                                                    }
                                                    handleChange={(items) => {
                                                        const formattedItems = items.map((item) =>
                                                            item?.label ? item.label : item
                                                        );
                                                        handleFormChange(
                                                            MOBIUS_CATALOG_FIELDS.MARKETS,
                                                            formattedItems
                                                        );
                                                    }}
                                                    isRequired={
                                                        getCatalogValidationSchema()?.fields[
                                                        MOBIUS_CATALOG_FIELDS.MARKETS
                                                        ]?.exclusiveTests?.required
                                                    }
                                                    list={vocablAllCountriesLabels}
                                                    multiple={true}
                                                    placeholder={CATALOG_LABELS.MARKETS}
                                                    selected={convertCountryCodesListToNamesList(values.markets)}
                                                    validationText={errors?.markets?.valueOf}
                                                    validateTypeahead={() =>
                                                        validateForm(MOBIUS_CATALOG_FIELDS.LANGUAGES)
                                                    }
                                                />
                                            </div>

                                            <div className="typeahead-container">
                                                <TypeaheadFilter
                                                    addLabelKey={CATALOG_LABELS.ADD_TAGS}
                                                    allowNew={true}
                                                    className="tags-typeahead"
                                                    domRef={tagsRef}
                                                    filterLabel={
                                                        <div className="typeahead-label-with-tooltip">
                                                            <div className="field-label">
                                                                {CATALOG_LABELS.TAGS}
                                                            </div>
                                                            <OverlayTrigger
                                                                overlay={<Tooltip>{CATALOG_TOOLTIPS.TAGS}</Tooltip>}
                                                                placement="right"
                                                            >
                                                                <FontAwesomeIcon icon="circle-info" />
                                                            </OverlayTrigger>
                                                        </div>
                                                    }
                                                    handleChange={(items) => {
                                                        const formattedItems = items.map((item) =>
                                                            item?.label ? item.label : item
                                                        );
                                                        handleFormChange(MOBIUS_CATALOG_FIELDS.TAGS, formattedItems);
                                                        setSelectedTags(formattedItems);
                                                        setInputTagValue('');
                                                    }}
                                                    handleBlur={() => handleOnBlurTypeaheads(inputTagValue, selectedTags, MOBIUS_CATALOG_FIELDS.TAGS, setSelectedTags, setInputTagValue)}
                                                    isRequired={
                                                        getCatalogValidationSchema()?.fields[
                                                            MOBIUS_CATALOG_FIELDS.TAGS
                                                        ]?.exclusiveTests?.required
                                                    }
                                                    list={values?.tags}
                                                    multiple={true}
                                                    onInputChange={(val) => {
                                                        setInputTagValue(val);
                                                    }}
                                                    placeholder={CATALOG_LABELS.TAGS}
                                                    ref={(ref) => {
                                                        setTagsRef(ref);
                                                    }}
                                                    selected={values?.tags}
                                                    validationText={errors?.tags?.valueOf}
                                                    validateTypeahead={() =>
                                                        validateForm(MOBIUS_CATALOG_FIELDS.TAGS)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom-section">
                                    <div className="button-container">
                                        <Button className="cancel-button"
                                            variant="light"
                                            onClick={() => handleCancel(dirty)}
                                        >
                                            {MOBIUS_BUTTON_TEXT.CANCEL}
                                        </Button>
                                        <GNButton
                                            buttonClass={classNames("register-button")}
                                            tooltip={<Tooltip>{MOBIUS_TOOLTIPS.MUST_UPDATE_FIELD}</Tooltip>}
                                            tooltipPlacement='top'
                                            type="submit"
                                            disabled={!isRegisterMode && (!dirty || !isValid || saveInProgress)}
                                            variant="primary"
                                            onClick={() => onSaveRegisterButtonClick(values, dirty, isValid, resetForm, setFieldError)}
                                        >
                                            {isRegisterMode ? MOBIUS_BUTTON_TEXT.REGISTER : MOBIUS_BUTTON_TEXT.SAVE}
                                        </GNButton>
                                    </div>
                                </div>
                            </Form>
                        </>
                    );
                }}
            </Formik>
            {showNotification && <GNNotification handleShow={setShowNotification} message={notificationMsg} milliseconds={5000} show={showNotification} success={notificationIsSuccess} />}
        </div>
    );
};

CatalogModalEdit.propTypes = {
    catalog: PropTypes.object.isRequired,
    handleCancel: PropTypes.func,
    handleNotification: PropTypes.func,
    setRefreshTable: PropTypes.func,
    isRegisterMode: PropTypes.bool,
    handleRegisterSuccess: PropTypes.func
};

export default CatalogModalEdit;
