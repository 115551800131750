import React from 'react';
import './AdditionalDetailsSection.scss';
import PropTypes from 'prop-types';
import { MOBIUS_VALUES } from '../../../constants/Mobius';
import { IMAGE_MODAL_SECTIONS, IMAGE_TOOLTIPS, IMAGE_LABELS } from '../../../constants/Image';
import ImageModalViewOnlyField from '../imageModalViewOnlyField/ImageModalViewOnlyField';
import { convertLanguageCodeList } from '../../../utils/GeneralUtils';
import { convertBooleanToText } from '../../../utils/MobiusUtils';

function AdditionalDetailsSection({imageData}) {
    return (
        <div className='image-additional-details-section'>
            <div className='view-section-title'>
                {IMAGE_MODAL_SECTIONS.ADDITIONAL_DETAILS}
            </div>
            <div className='view-section-body'>
                <div className='view-section-row'>
                    <ImageModalViewOnlyField label={IMAGE_LABELS.GRACENOTE_USAGE} value= {imageData?.gracenoteUsage !== null ? convertBooleanToText(imageData?.gracenoteUsage) : MOBIUS_VALUES.EMPTY_DATA} tooltip={IMAGE_TOOLTIPS.GRACENOTE_USAGE}/>
                    <ImageModalViewOnlyField label={IMAGE_LABELS.SENSITIVE} value= {imageData?.sensitive !== null ? convertBooleanToText(imageData?.sensitive) : MOBIUS_VALUES.EMPTY_DATA} tooltip={IMAGE_TOOLTIPS.SENSITIVE}/>
                </div>
                <div className='view-section-row'>
                    <ImageModalViewOnlyField label={IMAGE_LABELS.BRANDED} value= {imageData?.branded !== null ? convertBooleanToText(imageData?.branded) : MOBIUS_VALUES.EMPTY_DATA} tooltip={IMAGE_TOOLTIPS.BRANDED}/>
                    <ImageModalViewOnlyField label={IMAGE_LABELS.BRAND_DESCRIPTION} value= {imageData?.brandDescription ? imageData?.brandDescription : MOBIUS_VALUES.EMPTY_DATA }/>
                </div>
                <div className='view-section-row'>
                    <ImageModalViewOnlyField label={IMAGE_LABELS.PRIMARY_LANGUAGES_USED_IN_THE_IMAGE} value= { imageData?.languages?.length ? convertLanguageCodeList(imageData?.languages).join(', ') : MOBIUS_VALUES.EMPTY_DATA}/>
                </div>
                <div className='view-section-row'>
                    <ImageModalViewOnlyField label={IMAGE_LABELS.MARKETS} value= { imageData?.markets?.length ? imageData?.markets?.join(', ') : MOBIUS_VALUES.EMPTY_DATA} tooltip={IMAGE_TOOLTIPS.MARKETS}/>
                </div>
                <div className='view-section-row'>
                    <ImageModalViewOnlyField label={IMAGE_LABELS.TAGS} value= { imageData?.tags?.length ? imageData?.tags?.join(', ') : MOBIUS_VALUES.EMPTY_DATA} tooltip={IMAGE_TOOLTIPS.TAGS}/>
                </div>
            </div>
        </div>
    )
}

AdditionalDetailsSection.propTypes = {
    imageData: PropTypes.object
}

export default AdditionalDetailsSection;