import { CLEAR_GNIDS_PROGRAM_IMAGES, SET_GNIDS_PROGRAM_DATA, SET_GNIDS_PROGRAM, CLEAR_GNIDS_PROGRAM_MODAL_DATA, UPDATE_PRESENTATION_ID, SET_GNIDS_PROGRAM_IMAGES, SET_BREADCRUMB_TO_EDIT_MODE } from './ActionTypes';

export const setGnidsProgramRelatedData = (data) => ({
    type: SET_GNIDS_PROGRAM_DATA,
    payload: data
});

export const setGnidsProgram = (data) => ({
    type: SET_GNIDS_PROGRAM,
    payload: data
});

export const setGnidsProgramImages = (data) => ({
    type: SET_GNIDS_PROGRAM_IMAGES,
    payload: data
});

export const clearGnidsProgramImages = () => ({
    type: CLEAR_GNIDS_PROGRAM_IMAGES
});

export const setBreadcrumbToEditMode = (data) => ({
    type: SET_BREADCRUMB_TO_EDIT_MODE,
    payload: data
});

export const clearProgramModalData = () => ({
    type: CLEAR_GNIDS_PROGRAM_MODAL_DATA
});

export const updatePresentationID = (presentationId) => ({
    type: UPDATE_PRESENTATION_ID,
    payload: presentationId
});