import { API_ENDPOINTS } from '../config/Api';
import apiService from '../services/ApiService';
import { convertImageForPost } from '../utils/ImageUtils';
import { SET_GNIDS_IMAGE, CLEAR_GNIDS_IMAGE_MODAL_DATA, SHOW_IMAGE_MODAL } from './ActionTypes';

import {convertCountryNamesListToCodesList} from "../utils/GeneralUtils"

export const setGnidsImage = (imageData) => ({
    type: SET_GNIDS_IMAGE,
    payload: imageData
});

export const showImageModal = (show) => ({
    type: SHOW_IMAGE_MODAL,
    payload: show
});

export const clearImageModalData = () => ({
    type: CLEAR_GNIDS_IMAGE_MODAL_DATA
});


export const saveImage = (sourceId, image, isRegisterMode) => {
    return async () => {
        if (!sourceId?.length || !sourceId) {
            return new Promise((reject) => {
                reject('No sourceId provided');
            });
        }

        const {gnID: imageGnId, markets} = image
        const marketCountryCodes = convertCountryNamesListToCodesList(markets)
        const restImage = {
            ...image,
            markets: marketCountryCodes.length > 0 ? marketCountryCodes : null
        }
        const convertedImage = convertImageForPost(restImage);

        const headers = { SourceID: sourceId?.[0] };
        const endpoint = API_ENDPOINTS.GET_MOBIUS_IMAGES;
        return apiService.makeApiCall(
            isRegisterMode ? endpoint : `${endpoint}/${imageGnId}`,
            convertedImage,
            isRegisterMode ? 'post' : 'put',
            false,
            headers
        );
    };
};
