import React, { useState, useEffect } from 'react';
import { MOBIUS_PROGRAM_FIELDS } from '../../../constants/MobiusProgramValidation';
import GNInput from '../../common/gnInput/GNInput';
import {
    EDIT_DEFAULT_OPTION,
    MOBIUS_LABELS,
    MOBIUS_TOOLTIPS
} from '../../../constants/Mobius';
import './Production.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import ProgramModalAddButton from '../programModalAddButton/ProgramModalAddButton';
import TypeaheadFilter from '../../common/typeaheadFilter/TypeaheadFilter';
import { mobiusVocabSelProductionStatus } from '../../../reducers/MobiusVocabReducer';
import { useSelector } from 'react-redux';
import GNDropdown from '../../common/gnDropdown/GNDropdown';

export const Production = ({ errors, GNVocabCountries, handleFormChange, isMovie, productionCompanies, productionCompaniesIsRequired, productionCountries, productionCountriesIsRequired, productionStatus, productionStatusIsRequired, validateForm, value }) => {
    const [allProductionCompanies, setAllProductionCompanies] = useState([...productionCompanies]);

    const alllProductionStatuses = useSelector(mobiusVocabSelProductionStatus);
    const formattedProductionStatuses = alllProductionStatuses?.map((currentProductionStatus) => ({ name: currentProductionStatus.label, value: currentProductionStatus.value })) || [];

    useEffect(() => {
        setAllProductionCompanies([...productionCompanies]);
    }, [productionCompanies, setAllProductionCompanies]);

    const addNewProductionCompany = () => {
        handleFormChange(MOBIUS_PROGRAM_FIELDS.PRODUCTION_COMPANIES, [...productionCompanies, '']);
    };

    const deleteProductionCompany = (index) => {
        const filteredProductionCompanies = [...productionCompanies].filter((i, idx) => idx !== index);
        handleFormChange(MOBIUS_PROGRAM_FIELDS.PRODUCTION_COMPANIES, filteredProductionCompanies);
    };

    return (
        <div className='production-main-container'>
            <div className='first-row'>
                {isMovie && <div className='production-status-container'>
                    <GNDropdown
                        handleChange={(item) => handleFormChange(MOBIUS_PROGRAM_FIELDS.PRODUCTION_STATUS, item)}
                        isRequired={productionStatusIsRequired}
                        label={MOBIUS_LABELS.PRODUCTION_STATUS}
                        options={[EDIT_DEFAULT_OPTION, ...formattedProductionStatuses]}
                        value={productionStatus}
                    />
                </div>}
                <div className='production-countries-container'>
                    <TypeaheadFilter
                        filterLabel={MOBIUS_LABELS.PRODUCTION_COUNTRIES}
                        handleChange={(countries) => {
                            const formattedCountries = countries.map((country) =>
                                country?.label ? country.label : country
                            );
                            handleFormChange(MOBIUS_PROGRAM_FIELDS.PRODUCTION_COUNTRIES, formattedCountries);
                        }}
                        isRequired={productionCountriesIsRequired}
                        list={GNVocabCountries}
                        multiple={true}
                        placeholder={MOBIUS_LABELS.PRODUCTION_COUNTRY}
                        selected={productionCountries}
                        validationText={errors?.productionCountries || null}
                        validateTypeahead={() => validateForm(MOBIUS_PROGRAM_FIELDS.PRODUCTION_COUNTRIES)}
                        hasValue={value?.length}
                    />
                </div>
            </div>
            <div className='second-row'>
                <div className='production-companies-container'>
                    {allProductionCompanies.map((company, idx) => {
                        const isLast = idx === productionCompanies.length - 1;
                        const newFieldIsFilled = isLast ? company.length > 0 : null;
                        const hasLabel = idx === 0;
                        return (
                            <div className={ClassNames('production-company-input-container', { isLast })} key={idx}>
                                <div className='input-section'>
                                    <GNInput
                                        className='production-company'
                                        handleChange={(item) => handleFormChange(MOBIUS_PROGRAM_FIELDS.PRODUCTION_COMPANIES_VALUE(idx), item)}
                                        isRequired={productionCompaniesIsRequired}
                                        key={`production-company-${idx}`}
                                        label={MOBIUS_LABELS.PRODUCTION_COMPANIES}
                                        placeholder={MOBIUS_LABELS.PRODUCTION_COMPANY}
                                        validateInput={() => validateForm(MOBIUS_PROGRAM_FIELDS.PRODUCTION_COMPANIES_VALUE(idx))}
                                        validationText={errors?.productionCompanies ? errors?.productionCompanies[idx] : null}
                                        value={company} />
                                    {productionCompanies.length > 1 && <OverlayTrigger overlay={<Tooltip id="clear-production-company-tooltip">{MOBIUS_TOOLTIPS.DELETE_PRODUCTION_COMPANY}</Tooltip>} placement="bottom">
                                        <div className={ClassNames('clear-production-company-button', { hasLabel })} onClick={() => deleteProductionCompany(idx)}><FontAwesomeIcon icon='trash-alt' /></div>
                                    </OverlayTrigger>}
                                    {isLast && <ProgramModalAddButton enabled={newFieldIsFilled} hasLabel={hasLabel} onButtonClick={addNewProductionCompany} tooltipPlacement='right' tooltipText={MOBIUS_TOOLTIPS.ADD_PRODUCTION_COMPANY} />}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
};

Production.propTypes = {
    errors: PropTypes.object,
    GNVocabCountries: PropTypes.array.isRequired,
    handleFormChange: PropTypes.func,
    isMovie: PropTypes.bool.isRequired,
    productionCompanies: PropTypes.array.isRequired,
    productionCompaniesIsRequired: PropTypes.bool.isRequired,
    productionCountries: PropTypes.array.isRequired,
    productionCountriesIsRequired: PropTypes.bool.isRequired,
    productionStatus: PropTypes.string.isRequired,
    productionStatusIsRequired: PropTypes.bool,
    validateForm: PropTypes.func,
    value: PropTypes.string
};

export default Production;