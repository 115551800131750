import classNames from "classnames";
import React from "react";
import PropTypes from 'prop-types';

import './StepCircles.scss';

function StepCircles({ step, maxSteps }) {
    return (
        <div className="container">
            {Array.from({length: maxSteps}, (_, i) => i + 1).map((stepIndex) => (
                <div
                    key={stepIndex}
                    className={classNames('step', { 'active': stepIndex === step })}
                ></div>
            ))}
        </div>
    );
}

StepCircles.propTypes = {
    step: PropTypes.number,
    maxSteps: PropTypes.number
}

export default StepCircles;
