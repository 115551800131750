import moment from "moment";
import { convertCodeLanguageList, convertCountryCodes, convertCountryNamesListToCodesList, convertLanguageCodeList } from "./GeneralUtils";
import isEmpty from "lodash.isempty";
import { MOBIUS_CATALOG_INITIAL_VALUES } from "../constants/MobiusCatalogValidation";
import set from "lodash.set";
import get from "lodash.get";

export const transformCatalogsData = (catalog) => ({
    ...catalog,
    name: catalog.name,
    tag: catalog.tags?.slice(0, 10).join(', '),
    market: convertCountryCodes(catalog.markets?.slice(0, 10))?.map(country => country.name).join(', '),
    language: convertLanguageCodeList(catalog.languages?.slice(0, 10)).join(', '),
    gnID: catalog.gnID,
    lastModified: moment(catalog.updated).format('MMM D, YYYY')
})

export const convertCatalogForPost = (
    catalog
) => {
    const {languages, markets} = catalog;
    const convertedLanguageCodes = convertCodeLanguageList(languages);
    const convertedMarkets = convertCountryNamesListToCodesList(markets)
    return {...catalog, languages: convertedLanguageCodes, markets: convertedMarkets}
}


export const verifyCatalogDataIntegrity = (catalog) => {
    if (isEmpty(catalog)) {
        return MOBIUS_CATALOG_INITIAL_VALUES
    }
    for (const [field, val] of Object.entries(MOBIUS_CATALOG_INITIAL_VALUES)) {
        if (isEmpty(catalog[field])) {
            set(catalog, field, val);
        } else {
            set(catalog, field, get(catalog, field) || val);
        }
    }
    return catalog;
}
