import Papa from 'papaparse'

export const parseJSONtoCSV = (fieldToHeaderMapping, dataArr) => {
    const csvTemplate = Papa.unparse({
        fields: Object.values(fieldToHeaderMapping),
        data: dataArr.map(obj => {
            return Object.keys(fieldToHeaderMapping).map(field => obj[field])
        })
    })
    return csvTemplate
}

