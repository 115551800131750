import {
    GNIDS_TOGGLE_SEASON_MODAL,
    GNIDS_SET_SEASON_MODAL_TITLE,
    GNIDS_SET_SEASON,
    GNIDS_CLEAR_SEASON,
    GNIDS_SET_SEASON_GNID,
    GNIDS_SET_SEASON_NUMBER
} from "../actions/ActionTypes";

export const GNIDS_SEASON_MODAL_STORE = 'season';

export const initialState = {
    showSeasonModal: false,
    showRegisterConfirmation: false,
    season: null,
    seasonGnID: null,
    seasonNumber: null,
    // Parent Show program info
    title: '',
    showPresentationGnID: null
};

export const GNIDSSeasonModalReducer = (state = initialState, action) => {
    switch (action.type) {
    case GNIDS_TOGGLE_SEASON_MODAL:
        return {
            ...state,
            showSeasonModal: action.payload.toggle,
            ...(action.payload.showPresentationGnID && {showPresentationGnID: action.payload.showPresentationGnID})
        };
    case GNIDS_SET_SEASON_MODAL_TITLE:
        return {
            ...state,
            title: action.payload.title
        };
    case GNIDS_SET_SEASON_GNID:
        return {
            ...state,
            seasonGnID: action.payload.seasonGnID
        };
    case GNIDS_SET_SEASON_NUMBER:
        return {
            ...state,
            seasonNumber: action.payload.seasonNumber
        };
    case GNIDS_SET_SEASON:
        return {
            ...state,
            season: action.payload.season
        };
    case GNIDS_CLEAR_SEASON:
        return {...initialState};
    default:
        return state;
    }
};

export const gnidsSeasonModalShow = state => state[GNIDS_SEASON_MODAL_STORE]?.showSeasonModal;
export const gnidsSeasonRegisterConfirmationModalShow = state => state[GNIDS_SEASON_MODAL_STORE]?.showRegisterConfirmation;
export const gnidsSeasonModalSeason = state => state[GNIDS_SEASON_MODAL_STORE]?.season;
export const gnidsSeasonModalTitle = state => state[GNIDS_SEASON_MODAL_STORE]?.title;
export const gnidsSeasonShowPresentationGNID = state => state[GNIDS_SEASON_MODAL_STORE]?.showPresentationGnID;
export const gnidsSeasonGNID = state => state[GNIDS_SEASON_MODAL_STORE]?.seasonGnID;
export const gnidsSeasonNumber = state => state[GNIDS_SEASON_MODAL_STORE]?.seasonNumber;

export default GNIDSSeasonModalReducer;