import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const MobiusSelect = ({selectLabel, defaultValue, listOfValues, onChangeHandler, ...props}) => {
    const isValidDefaultValue = listOfValues.some(value => value === defaultValue);
    const initialValue = isValidDefaultValue ? defaultValue : listOfValues[0];
    const [selectedValue, setSelectedValue] = useState(initialValue);

    return (
        <div {...props}>
            <span>{selectLabel} </span>
            <select
                value={selectedValue}
                onChange={(e) => {
                    setSelectedValue(Number(e.target.value))
                    onChangeHandler(Number(e.target.value));
                }}

            >
                {listOfValues.map((noOfRows) => (
                    <option key={noOfRows} value={noOfRows}>
                        {noOfRows}
                    </option>
                ))}
            </select>
        </div>
    )
}

MobiusSelect.propTypes = {
    selectLabel: PropTypes.string,
    defaultValue: PropTypes.number,
    listOfValues: PropTypes.array,
    onChangeHandler: PropTypes.func,
    selected: PropTypes.bool
};

export default MobiusSelect;