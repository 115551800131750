import React, {useCallback, useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { gvauthHasMobiusWriteAccess, gvauthSelMobiusSourceID } from '../reducers/GNVAuthReducer';
import GNButton from '../components/common/gnButton/GNButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MOBIUS_BUTTON_TEXT, MOBIUS_TOOLTIPS, MOBIUS_DATA_TYPES, MOBIUS_PROGRAM_TYPES_VALUES, GET_CATALOGS_PAGE_LIMIT } from '../constants/Mobius';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import ViewSwitcher from '../components/common/viewSwitcher/ViewSwitcher';
import './CatalogListView.scss'
import { mobiusGetAllCatalogs } from '../actions/MobiusActions';
import { CATALOGS_COLUMN_HEADERS } from '../constants/Catalog';
import ErrorBoundary from '../components/common/errorBoundary/ErrorBoundary';
import GNServerTable from '../components/common/gnServerTable/GNServerTable';
import GNNoDataMessage from '../components/common/gnNoDataMessage/GNNoDataMessage';
import { setGnidsCatalog } from '../actions/GNIDSCatalogModalActions';
import CatalogModal from './catalogModal/CatalogModal';
import TablePopover from '../components/common/popover/TablePopover';
import GNNotification from '../components/common/gnNotification/GNNotification';

function CatalogListView() {
    const hasWriteAccess = useSelector(gvauthHasMobiusWriteAccess);
    const sourceId = useSelector(gvauthSelMobiusSourceID);
    const [modalShow, setModalShow] = useState(false);
    const dispatch = useDispatch();
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState(null);
    const [notificationIsSuccess, setNotificationIsSuccess] = useState(false);
    const [shouldResetTable, setShouldResetTable] = useState(false);
    const [isRegisterMode, setIsRegisterMode] = useState(false)

    const fetchData = useCallback(
        // We are still passing the sortBy to action as it has been disabled at the column header settings, we can simply enable it in the header settings we have the BE code is ready for sorting.
        (pageIndex, sortBy) => {
            return mobiusGetAllCatalogs(pageIndex, sourceId, sortBy, GET_CATALOGS_PAGE_LIMIT)
        },
        [sourceId]
    );

    const handleCatalogModal = (catalog) => {
        dispatch(setGnidsCatalog(catalog))
        setModalShow(!modalShow)
    }
    const handleNotification = (show, message, isSuccess) => {
        setShowNotification(show);
        setNotificationMsg(message);
        setNotificationIsSuccess(isSuccess);
    };

    const handleRegisterNewCatalogClick = () => {
        setIsRegisterMode(true);
        setModalShow(true);
    }

    const handleModal = (resetTable) => {
        setShouldResetTable(resetTable)
    }

    useEffect(() => {
        if (shouldResetTable) {
            setShouldResetTable(false)
        }
    }, [setShouldResetTable, shouldResetTable])

    const catalogsColumns = [...CATALOGS_COLUMN_HEADERS];
    const titleIndex = catalogsColumns.findIndex((col) => col.id === 'name');
    if (titleIndex !== -1) {
        catalogsColumns[titleIndex] = {
            ...catalogsColumns[titleIndex],
            /* eslint-disable react/display-name */
            /* eslint-disable react/prop-types */
            // Calling event.stopPropagation() to prevent the tr onClick from handling a row expansion since in this case, we only want the modal to open
            Cell: ({ row }) => (
                <>
                    <OverlayTrigger
                        overlay={<Tooltip id="program-title-tooltip">{row.original.name }</Tooltip>}
                        trigger={(row.original.name.length > 36) ? ['hover'] : null}
                    >
                        <span
                            className='catalog-title'
                            onClick={(event) => {
                                event.stopPropagation();
                                setIsRegisterMode(false);
                                handleCatalogModal(row?.original)
                            }}
                        >
                            {row?.original?.name && row.original.name}
                        </span>
                    </OverlayTrigger>
                </>
            )
            /* eslint-enable react/display-name */
            /* eslint-enable react/prop-types */
        }
    }
    const tablePopoverIndex = catalogsColumns.findIndex((col) => col.id === 'TablePopover');
    if (tablePopoverIndex !== -1) {
        catalogsColumns[tablePopoverIndex] = {
            ...catalogsColumns[tablePopoverIndex],
            /* eslint-disable react/display-name */
            /* eslint-disable react/prop-types */
            Cell: ({ row }) => {
                return hasWriteAccess ? (
                    <TablePopover
                        program={{...row?.original, type: MOBIUS_PROGRAM_TYPES_VALUES.CATALOG}}
                        handleModal={handleModal}
                        gnID={row?.original?.gnID}
                        category={MOBIUS_PROGRAM_TYPES_VALUES.CATALOG}
                        handleNotification={handleNotification}
                        showRegisterOptions={false}
                    />
                ) : null;
            }
            /* eslint-enable react/display-name */
            /* eslint-enable react/prop-types */
        };
    }

    return (
        <ErrorBoundary>
            <div className='catalog-main-container'>
                { modalShow &&
                    <div className='catalog-modal'>
                        <CatalogModal setModalShow={setModalShow} handleNotification={handleNotification} setShouldResetTable={setShouldResetTable} isRegisterMode={isRegisterMode} setIsRegisterMode={setIsRegisterMode}/>
                    </div>
                }
                <div className='catalog-top-section'>
                    <div className='catalog-header-container'>
                        <ViewSwitcher value='Catalogs'/>
                        <GNButton
                            className='register-catalog-button'
                            tooltip={<Tooltip id="register-new-catalog-tooltip">{MOBIUS_TOOLTIPS.REQUIRES_WRITE_ACCESS}</Tooltip>}
                            trigger={!hasWriteAccess ? ['hover'] : null}
                            disabled={!hasWriteAccess}
                            buttonClass='register-button'
                            hasWriteAccess={hasWriteAccess}
                            onClick={handleRegisterNewCatalogClick}
                        >
                            <FontAwesomeIcon icon='plus' />
                            {MOBIUS_BUTTON_TEXT.REGISTER_NEW_CATALOG}
                        </GNButton>
                    </div>
                </div>
                <div className='gnid-distribution-bottom-section'>
                    <ErrorBoundary>
                        {!shouldResetTable && <GNServerTable
                            className='catalog-table'
                            columns={catalogsColumns}
                            fetchData={fetchData}
                            noDataMessage={
                                <GNNoDataMessage programType={MOBIUS_DATA_TYPES.CATALOGS} hasWriteAccess={hasWriteAccess}/>
                            }
                        />}
                    </ErrorBoundary>
                </div>
            </div>
            {showNotification && (
                <GNNotification
                    handleShow={setShowNotification}
                    message={notificationMsg}
                    milliseconds={5000}
                    show={showNotification}
                    success={notificationIsSuccess}
                />
            )}
        </ErrorBoundary>
    )
}

export default CatalogListView