import React, { useEffect } from 'react';
import { ButtonGroup, Button, Form, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import ClassNames from "classnames";
import "./GNClientTable.scss";
import { useTable, useSortBy, usePagination } from "react-table";
import PropTypes from "prop-types";
import { CLIENT_TABLE_PAGE_SIZE } from "../../../constants/App";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { numberWithCommas } from "../../../utils/GeneralUtils";

export const GNClientTable = ({
    checkboxes = false,
    className,
    columns,
    data,
    isLoading = false,
    noDataMessage = "",
    pagination = false,
    paginationBottom = true,
    rowCheckboxHandler,
    settings = { pageSize: CLIENT_TABLE_PAGE_SIZE },
    sorting = true,
    isErrorTable = false
}) => {
    const allColumns = React.useMemo(() => [...columns], [columns]);
    if (checkboxes) {
        allColumns.unshift({
            accessor: "isSelected",
            /* eslint-disable react/display-name */
            Cell: ({ row }) => (
                <Form.Check
                    // For custom checkboxes you need the prop 'custom', label, and a unique id
                    custom
                    label=""
                    id={`${row.original.className}-${row.original?.uniqId}`}
                    className={row.original.className}
                    type="checkbox"
                    defaultChecked={row.values.isSelected}
                    onChange={(e) => {
                        if (rowCheckboxHandler) {
                            rowCheckboxHandler(e, row);
                        }
                    }}
                />
            )
            /* eslint-enable react/display-name */
        });
    }

    const options = {
        columns: allColumns,
        data,
        initialState: { ...settings }
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        nextPage,
        setPageSize,
        previousPage,
        state: { pageIndex, pageSize }
    } = useTable(options, useSortBy, usePagination);

    const currentRowStart = page.length ? pageIndex * pageSize + 1 : 0;
    const currentRowEnd = pageIndex * pageSize + page.length;
    const totalRows = data.length;

    useEffect(() => {
        setPageSize(settings?.pageSize)
    }, [settings?.pageSize, setPageSize])

    return (
        <div className={"gn-client-table-wrapper"} data-testid='gn-client-table'>
            {!paginationBottom && pagination && !isLoading && (
                <div className="pagination-top-container" data-testid='pagination-top-container'>
                    <span className="page-count">
                        {numberWithCommas(currentRowStart)} -{" "}
                        {numberWithCommas(currentRowEnd)} of {numberWithCommas(totalRows)}
                    </span>
                    <ButtonGroup>
                        <Button
                            variant="light"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        >
                            <FontAwesomeIcon icon="angle-left" />
                        </Button>
                        <Button
                            variant="light"
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                        >
                            <FontAwesomeIcon icon="angle-right" />
                        </Button>
                    </ButtonGroup>
                </div>
            )}
            {!isLoading && (
                <Table
                    className={ClassNames("gn-client-table", className)}
                    responsive
                    {...getTableProps()}
                >
                    <thead>
                        {headerGroups.map((headerGroup, i) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                                {headerGroup.headers.map((column, j) => {
                                    if (sorting) {
                                        return (
                                            <th
                                                {...column.getHeaderProps(
                                                    column.getSortByToggleProps()
                                                )}
                                            >
                                                {column.render("Header")}
                                                {column.isSorted && (
                                                    <span>
                            &nbsp;
                                                        <FontAwesomeIcon
                                                            className="sort-icon"
                                                            icon={
                                                                column.isSortedDesc ? "arrow-down" : "arrow-up"
                                                            }
                                                        />
                                                    </span>
                                                )}
                                            </th>
                                        );
                                    }
                                    return (
                                        <th {...column.getHeaderProps()} key={j}>
                                            {column.render("Header")}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={i}>
                                    {row.cells.map((cell, j) => {
                                        return (
                                            <>
                                                <OverlayTrigger overlay={<Tooltip>Download errors to view the full list</Tooltip>} placement="bottom" trigger={isErrorTable && cell.value?.length > 37 ? ['hover'] : null}>
                                                    <td {...cell.getCellProps()} key={j}>
                                                        {isErrorTable && j === 0 ? (
                                                            <span className='error'>
                                                                <FontAwesomeIcon icon="circle-exclamation" />{" "}
                                                                {cell.render("Cell")}
                                                            </span>
                                                        ) : (
                                                            cell.render("Cell")
                                                        )}
                                                    </td>
                                                </OverlayTrigger>
                                            </>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            )}
            {data.length <= 0 && !isLoading && (
                <div className="no-data">{noDataMessage}</div>
            )}
            {paginationBottom && pagination && !isLoading && (
                <div className="pagination-container">
                    <ButtonGroup>
                        <span className="page-count">
                            {numberWithCommas(currentRowStart)} -{" "}
                            {numberWithCommas(currentRowEnd)} of {numberWithCommas(totalRows)}
                        </span>
                        <Button
                            variant="light"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        >
                            <FontAwesomeIcon icon="angle-left" />
                        </Button>
                        <Button
                            variant="light"
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                        >
                            <FontAwesomeIcon icon="angle-right" />
                        </Button>
                    </ButtonGroup>
                </div>
            )}
            {isLoading && <LoadingSpinner />}
        </div>
    );
};

GNClientTable.propTypes = {
    checkboxes: PropTypes.bool,
    className: PropTypes.string,
    columns: PropTypes.array,
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    noDataMessage: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    pagination: PropTypes.bool,
    paginationBottom: PropTypes.bool,
    row: PropTypes.object,
    rowCheckboxHandler: PropTypes.func,
    settings: PropTypes.object,
    sorting: PropTypes.bool,
    isErrorTable: PropTypes.bool
};

export default GNClientTable;
