import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Tooltip, OverlayTrigger } from 'react-bootstrap';
import GNModal from '../../components/common/gnModal/GNModal';
import './ImageModal.scss';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../components/common/loadingSpinner/LoadingSpinner';
import {
    MOBIUS_BUTTON_TEXT,
    MOBIUS_CONFIRMATIONS,
    MOBIUS_LABELS,
    IMAGE_SIDE_MENU,
    MOBIUS_TOOLTIPS,
    MOBIUS_DATA_TYPES,
    IMAGE_SIDE_MENU_RATIOS,
    MOBIUS_NOTIFICATIONS
} from '../../constants/Mobius';
import {
    gvauthHasMobiusWriteAccess, gvauthSelMobiusSourceID
} from '../../reducers/GNVAuthReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GNConfirmationModal from '../../components/common/gnConfirmationModal/GNConfirmationModal';
import ClassNames from 'classnames';
import ScrollSpyMenu from '../../components/common/scrollSpyMenu/ScrollSpyMenu';
import ErrorBoundary from '../../components/common/errorBoundary/ErrorBoundary';
import GNButton from '../../components/common/gnButton/GNButton';
import { getProgramMenuItems, getProgramMenuRatios } from '../../utils/MobiusUtils';
import { gnidsImage } from '../../reducers/GNIDSImageModalReducer';
import { clearImageModalData } from '../../actions/GNIDSImageModalActions';
import ImageModalViewOnly from './ImageModalViewOnly';
import ImageModalEdit from './ImageModalEdit';
import { gnidsClearEpisode, mobiusGetImage } from '../../actions/MobiusActions';
import { REGISTER_NEW_IMAGE } from '../../constants/Image';
import { mobiusRegisterAccessForImage } from '../../reducers/MobiusVocabReducer';
import { convertCountryCodesListToNamesList } from '../../utils/GeneralUtils';
import { clearProgramModalData } from '../../actions/GNIDSProgramModalAction';

export const ImageModal = ({
    isRegisterMode,
    setIsRegisterMode,
    setModalShow,
    setShouldRefreshTable,
    shouldRefreshTable,
    imageModalTitle,
    handleNotification
}) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [showCancelConfirm, setShowCancelConfirm] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const hasAppWriteAccess = useSelector(gvauthHasMobiusWriteAccess);
    const hasImageWriteAccess = useSelector(mobiusRegisterAccessForImage);
    const sourceId = useSelector(gvauthSelMobiusSourceID);
    const imageData = useSelector(gnidsImage);
    const [image, setImage] = useState(isRegisterMode ? {} : imageData);

    const hasWriteAccess = hasAppWriteAccess && hasImageWriteAccess;

    useEffect(() => {
        if (!isRegisterMode && imageData?.gnID) {
            setIsLoading(true)
            dispatch(mobiusGetImage(imageData.gnID, sourceId)).then((data) => {
                setImage({...data, markets: convertCountryCodesListToNamesList(data.markets)});
            }).catch(() => {
                dispatch(clearImageModalData())
                setModalShow(false)
                handleNotification(true, MOBIUS_NOTIFICATIONS.ERROR_COULD_NOT_BE_OPENED, false)
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }, [dispatch, imageData?.gnID, sourceId, isRegisterMode, handleNotification, setModalShow])

    const handleCancel = (dirty, refreshTable) => {
        if (dirty) {
            setShowCancelConfirm(dirty);
        } else {
            handleCancelConfirm()
        }

        if (refreshTable) {
            setShouldRefreshTable((prev) => !prev)
        }
    };

    const handleCancelConfirm = () => {
        dispatch(clearImageModalData());
        dispatch(gnidsClearEpisode());
        dispatch(clearProgramModalData());
        setShowCancelConfirm(false)
        setModalShow(false)
    }

    const handleRegisterSuccess = () => {
        setModalShow(false);
        setIsRegisterMode(false);
        setShouldRefreshTable((prev) => !prev);
        dispatch(clearImageModalData());
        handleNotification(true, MOBIUS_NOTIFICATIONS.IMAGE_SUCCESS_REGISTERED, true);
    }

    const handleEditMode = () => {
        setEditMode((prevEditMode) => !prevEditMode);
    };

    return (
        <ErrorBoundary>
            {/* We're doing a custom css hide here instead of using show because we don't want this to unmount and lose the user's current changes */}
            <GNModal
                show={true}
                onHide={() => handleCancel(isDirty, shouldRefreshTable)}
                className={ClassNames('mobius-program-modal', {
                    hide: showCancelConfirm
                })}
            >
                {isLoading && <LoadingSpinner />}
                {!isLoading && (
                    <div className='program-modal-container' data-testid='program-modal-container'>
                        <Modal.Title>
                            <div className="gnview-header-container">
                                {
                                    imageModalTitle ?
                                        (
                                            <OverlayTrigger
                                                overlay={<Tooltip id="program-title-tooltip">{imageModalTitle }</Tooltip>}
                                                trigger={({imageModalTitle}.length > 36) ? ['hover'] : null}
                                            >
                                                <div className='gnview-header-title'>{imageModalTitle}</div>
                                            </OverlayTrigger>
                                        )
                                        :
                                        (
                                            <OverlayTrigger
                                                overlay={<Tooltip id="program-title-tooltip">{imageData?.title }</Tooltip>}
                                                trigger={(imageData?.title.length > 60) ? ['hover'] : null}
                                                placement='bottom'
                                            >
                                                <div className='gnview-header-title'>{imageData?.title ? imageData?.title : REGISTER_NEW_IMAGE}</div>
                                            </OverlayTrigger>
                                        )}
                                {!editMode && !isRegisterMode && <div className="edit__button">
                                    <GNButton tooltip={<Tooltip id="edit-button-tooltip"> {hasWriteAccess ? MOBIUS_TOOLTIPS.EDIT_BUTTON : MOBIUS_TOOLTIPS.REQUIRES_WRITE_ACCESS}</Tooltip>}
                                        trigger={['hover']}
                                        disabled={!hasWriteAccess}
                                        buttonClass='edit-button'
                                        hasWriteAccess={hasWriteAccess}
                                        onClick={handleEditMode}
                                    >
                                        <FontAwesomeIcon icon="pen" /> {MOBIUS_BUTTON_TEXT.EDIT}
                                    </GNButton>
                                </div>}
                            </div>
                            {!isRegisterMode && (<>
                                <div className='gnview-image-header-labels'>
                                    {(imageData?.category?.length > 0) && (
                                        <span className='category-label with-border'>
                                            {imageData?.category}
                                        </span>
                                    )}
                                    <span className='header-divider'>|</span>
                                    {imageData?.dimensions?.width > 0 && imageData?.dimensions?.height > 0 && (
                                        <span className='width-height-label'>
                                            {`${imageData?.dimensions?.width} x ${imageData?.dimensions?.height}`}
                                        </span>
                                    )}
                                    <span className='header-divider'>|</span>
                                    {imageData?.dimensions?.aspectRatio && (
                                        <span className='aspect-ratio-label'>
                                            {imageData?.dimensions?.aspectRatio}
                                        </span>
                                    )}
                                    <span className='header-divider'>|</span>
                                    {imageData?.fileType?.length > 0 && (
                                        <span className='file-type-label'>
                                            {imageData?.fileType}
                                        </span>
                                    )}
                                </div>
                                <div className='title-sub-section'>
                                    {imageData?.gnID && (
                                        <span className='ids-section'>
                                            <span className='id-text'>
                                                <span className='id-label'>Image GNID</span>
                                                {imageData?.gnID}
                                            </span>
                                        </span>
                                    )}
                                </div>
                            </>)}
                        </Modal.Title>
                        <Modal.Body>
                            <div className='program-modal-side-menu-container'>
                                <ScrollSpyMenu
                                    menuItemIds={getProgramMenuItems(IMAGE_SIDE_MENU, MOBIUS_DATA_TYPES.IMAGES)}
                                    menuLabel={MOBIUS_LABELS.IMAGE_DETAILS}
                                    menuRatios={getProgramMenuRatios(IMAGE_SIDE_MENU_RATIOS, MOBIUS_DATA_TYPES.IMAGES)}
                                    targetContainerId='program-modal-body'
                                    disabled={false}
                                />
                            </div>
                            <div id='program-modal-body'>
                                {(!editMode && !isRegisterMode) && !isLoading && <ImageModalViewOnly image={image} />}
                                {(editMode || isRegisterMode) && !isLoading && <ImageModalEdit image={image} isRegisterMode={isRegisterMode} setIsLoading={setIsLoading} handleCancel={handleCancel} handleRegisterSuccess={handleRegisterSuccess} setIsDirty={setIsDirty} />}
                            </div>
                        </Modal.Body>
                    </div>
                )}
            </GNModal>
            <GNConfirmationModal
                cancelButtonHandler={() => setShowCancelConfirm(false)}
                cancelButtonText={MOBIUS_BUTTON_TEXT.CANCEL}
                message={MOBIUS_CONFIRMATIONS.CANCEL_CONFIRMATION_MESSAGE}
                show={showCancelConfirm}
                submitButtonHandler={handleCancelConfirm}
                submitButtonText={MOBIUS_BUTTON_TEXT.EXIT}
                title={MOBIUS_CONFIRMATIONS.CANCEL_CONFIRMATION_TITLE}
            />
        </ErrorBoundary>
    );
};

ImageModal.propTypes = {
    isRegisterMode: PropTypes.bool,
    setIsRegisterMode: PropTypes.func,
    setModalShow: PropTypes.func.isRequired,
    setShouldRefreshTable: PropTypes.func,
    shouldRefreshTable: PropTypes.bool,
    imageModalTitle: PropTypes.node,
    handleNotification: PropTypes.func
};

export default ImageModal;