import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { PROGRAM_BULK_UPLOAD } from "../../constants/BulkUpload";
import LoadingSpinner from "../../components/common/loadingSpinner/LoadingSpinner";
import "./StepTwo.scss";
import { useDispatch, useSelector } from "react-redux";
import { gnidsSelProgramFiles } from "../../reducers/GNIDSProgramFilesReducer";
import {
    ERROR_FIELD_TO_JSON_HEADER_MAPPING,
    ERROR_MODAL_COLUMNS,
    getRowIDByPath,
    mergeErrors,
    parseFiles
} from "../../utils/BulkUploadUtils";
import GNClientTable from "../../components/common/gnClientTable/GNClientTable";
import { CLIENT_TABLE_LARGE_PAGE_SIZE } from "../../constants/App";
import { gnidsPostBulkUpload, gnidsSetProgramFiles } from "../../actions/GNIDSProgramFilesActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { CSVDownload } from "../../utils/CSVDownloadUtils";
import { parseJSONtoCSV } from "../../utils/JSONtoCSVUtils";
import { MOBIUS_PROGRAM_TYPES_VALUES } from "../../constants/Mobius";
import { convertFlattenedJSONToNested as convertFlattenedJSONShowsToNested } from "../../utils/FlattenedJSONtoNestedShows";
import { convertFlattenedJSONToNested as convertFlattenedJSONMoviesToNested } from "../../utils/FlattenedJSONToNestedMovies";
import { gvauthSelMobiusSourceID } from "../../reducers/GNVAuthReducer";

function StepTwo({ setStep, handlePostBulkUpload }) {
    const files = useSelector(gnidsSelProgramFiles);
    const sourceId = useSelector(gvauthSelMobiusSourceID);

    const dispatch = useDispatch();

    const [isParsing, setIsParsing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [headerErrors, setHeaderErrors] = useState([]);
    const [rowErrors, setRowErrors] = useState([]);
    const [validationErrors, setValidationErrors] = useState([]);

    const handleApiErrors = (reqPayload, errorResp) => {
        // Handling the errors gracefully
        if (!errorResp?.descriptionDetails) {
            return [{
                columnName: errorResp?.description,
                type: errorResp?.error,
                rowID: '-'
            }]
        }

        const apiErrors = [];

        if (errorResp?.descriptionDetails) {
            errorResp.descriptionDetails.forEach((error) => {
                try {
                    if (error?.fields) {
                        Object.keys(error.fields).forEach((key) => {
                            let type = error.fields[key]?.error;
                            const rowID = getRowIDByPath(reqPayload, error.index) || '-';

                            if (((key === 'title.language') && (type === 'language is a required field')) || ((key === 'title.value') && (type === 'value is a required field'))) {
                                type = 'Both title.value and title.language are required when either field is inputted.';
                            }
                            let modifiedKey = key;

                            // Return 1 based index in case of errors due to cast, crew, description, ratings,
                            // production countries, externalIds
                            if (key.startsWith('productionCountries[')) {
                                const match = key.match(/^(productionCountries)\[(\d+)\]$/);
                                const index = parseInt(match[2]) + 1;
                                modifiedKey = `${match[1]}[${index}]`;
                            }
                            if (key.startsWith('cast[') || key.startsWith('crew[')) {
                                const match = key.match(/^(cast|crew)\[(\d+)\]\.([a-zA-Z_][a-zA-Z0-9_]*)\.?([a-zA-Z_][a-zA-Z0-9_]*)?$/);
                                const index = parseInt(match[2]) + 1;
                                modifiedKey = `${match[1]}[${index}].${match[3]}${match[4] ? '.' + match[4] : ''}`;
                            }
                            if (key.startsWith('externalIDs[') || key.startsWith('descriptions[') || key.startsWith('ratings[')) {
                                const match = key.match(/^(externalIDs|descriptions|ratings)\[(\d+)\]\.([a-zA-Z_][a-zA-Z0-9_]*)$/);
                                const index = parseInt(match[2]) + 1;
                                modifiedKey = `${match[1]}[${index}].${match[3]}`;
                            }
                            if (key === 'releaseDate') {
                                // We are prepending the column name with season/episode/version/presentation incase the error is due to releaseDate
                                // as that gives the better information to user
                                // The below regex pattern checks if the error index value ending with season/episode/version/presentation and prepend the matching string
                                const match = error.index?.split('.')?.pop()?.match(/^(Season|Episode|Version|Presentation)/i)
                                if (match) {
                                    const prefix = match[1] + '.';
                                    modifiedKey = prefix + key;
                                    type = prefix + type;
                                }
                            }
                            apiErrors.push({
                                type,
                                columnName: modifiedKey.trim(),
                                rowID
                            });
                        });
                    }
                } catch (exception) {
                    apiErrors.push({
                        type: JSON.stringify(error),
                        columnName: '',
                        rowID: ''
                    })
                }
            });
        }

        return setValidationErrors(apiErrors);
    }

    const handleBulkUpload = useCallback((parsedFiles, file) => {
        setIsLoading(true);
        if (parsedFiles?.[0]?.programType?.toLowerCase() === MOBIUS_PROGRAM_TYPES_VALUES.SHOW) {
            const convertedFiles = convertFlattenedJSONShowsToNested(parsedFiles, file, parsedFiles.length);
            dispatch(gnidsPostBulkUpload(convertedFiles, sourceId, false))
                .then(handlePostBulkUpload)
                .catch(response => handleApiErrors(convertedFiles, response))
                .finally(() => setIsLoading(false));
        } else {
            const convertedFiles = convertFlattenedJSONMoviesToNested(parsedFiles, file, parsedFiles.length);
            dispatch(gnidsPostBulkUpload(convertedFiles, sourceId, true))
                .then(handlePostBulkUpload)
                .catch(response => {
                    handleApiErrors(convertedFiles, response)
                })
                .finally(() => setIsLoading(false));
        }
    }, [dispatch, handlePostBulkUpload, sourceId]);

    useEffect(() => {
        setIsParsing(true);
        parseFiles(
            files,
            (parsedFiles, file) => {
                setIsParsing(false);
                if (!headerErrors?.length && !rowErrors?.length) {
                    handleBulkUpload(parsedFiles, file);
                }
            },
            (errors) => {
                setHeaderErrors(errors);
                setIsParsing(false);
            },
            (errors) => {
                setRowErrors(errors);
                setIsParsing(false);
            }
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleGoBack = () => {
        dispatch(gnidsSetProgramFiles([]));
        setStep(1);
    };

    const getTotalErrors = () => mergeErrors([...headerErrors, ...rowErrors, ...validationErrors]);

    const handleDownloadErrorList = async () => {
        const jsonObj = parseJSONtoCSV(
            ERROR_FIELD_TO_JSON_HEADER_MAPPING,
            getTotalErrors()
        );
        CSVDownload(jsonObj, "Register_program_errors");
    };

    return (
        <div className="step-two" data-testid='step-two'>
            <h4 className="step-title">{PROGRAM_BULK_UPLOAD.STEP2}</h4>
            {isParsing && (
                <div className="instructions">
                    {PROGRAM_BULK_UPLOAD.STEP2_INSTRUCTIONS}
                </div>
            )}
            {(isParsing || isLoading) && (
                <>
                    <LoadingSpinner />
                    <div className="step-close">{PROGRAM_BULK_UPLOAD.STEP2_CLOSE}</div>
                </>
            )}
            {!isParsing && getTotalErrors()?.length ? (
                <>
                    <div className="error-summary">
                        <h4 className="body-title">
                            <FontAwesomeIcon icon="triangle-exclamation" />
                            {`${getTotalErrors()?.length} ${
                                PROGRAM_BULK_UPLOAD.ERROR_HEADER
                            }`}
                        </h4>
                        <div className="body-text">
                            {PROGRAM_BULK_UPLOAD.COULD_NOT_COMPLETE}
                            <b>{PROGRAM_BULK_UPLOAD.FIX_ERRORS}</b> and{" "}
                            <a className="go-back-link" onClick={handleGoBack}>
                                {PROGRAM_BULK_UPLOAD.UPLOAD_AGAIN}
                            </a>
                        </div>
                    </div>
                    <GNClientTable
                        className="error-modal-table"
                        columns={ERROR_MODAL_COLUMNS}
                        data={getTotalErrors()}
                        settings={{ pageSize: CLIENT_TABLE_LARGE_PAGE_SIZE }}
                        isErrorTable={true}
                    />
                    <Button onClick={handleDownloadErrorList} className="download-errors">
                        <FontAwesomeIcon icon="fa-solid fa-download" className="dl-icon" />
                        {PROGRAM_BULK_UPLOAD.DOWNLOAD_ERRORS}
                    </Button>
                </>
            ) : <></>}
        </div>
    );
}

StepTwo.propTypes = {
    setStep: PropTypes.func,
    handlePostBulkUpload: PropTypes.func
};

export default StepTwo;
