import React, { useState, useCallback } from 'react'
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gnidsGetBulkBatch, getStatusByGnID } from '../actions/GNIDSProgramFilesActions';
import ImportBatchStatusExpand from './ImportBatchStatusExpand';
import { getErrorData } from '../utils/ImportBatchStatusUtils';
import { gnviewSendLogMessage } from '../services/GeneralService';
import { IMPORT_STATUS_TABS, IMPORT_STATUS_HELPER_TEXT, REFRESH_BUTTON_TEXT, IMPORT_COLUMN_HEADERS, NO_IMPORT_DATA, DOWNLOAD_ERRORS_BUTTON_TEXT } from '../constants/Imports'; // add IMPORT_COLUMN_HEADERS
import ErrorBoundary from '../components/common/errorBoundary/ErrorBoundary';
import GNRadioGroup from '../components/common/gnRadioGroup/GNRadioGroup';
import GNServerTable from '../components/common/gnServerTable/GNServerTable';
import ViewSwitcher from '../components/common/viewSwitcher/ViewSwitcher';
import { LIST_VIEW_PAGE_LAST_UPDATED } from '../constants/MobiusListView';
import { MOBIUS_MOMENT_DATE_FORMAT } from '../constants/Mobius';
import './ImportStatusView.scss'

function ImportStatusView() {
    const importStatusTabs = IMPORT_STATUS_TABS;
    const importsColumns = [...IMPORT_COLUMN_HEADERS];
    const [moviesTableShow, setMoviesTableShow] = useState(true);
    const [showsTableShow, setShowsTableShow] = useState(false);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [pageLastUpdated, setPageLastUpdated] = useState(moment());
    const dispatch = useDispatch();


    function onTabSelect() {
        setMoviesTableShow(!moviesTableShow)
        setShowsTableShow(!showsTableShow)
    }

    const fetchMovieData = useCallback(
        (pageIndex, sortBy) => {
            setPageLastUpdated(moment());
            return gnidsGetBulkBatch(pageIndex, sortBy, "movies")
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [refreshFlag]
    );

    const fetchShowsData = useCallback(
        (pageIndex, sortBy) => {
            setPageLastUpdated(moment());
            return gnidsGetBulkBatch(pageIndex, sortBy, "shows")
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [refreshFlag]
    );

    const handleRefresh = () => {
        setRefreshFlag(!refreshFlag);
    }

    const renderRowSubComponent = useCallback(
        ({ row }) => (
            <ErrorBoundary>
                <ImportBatchStatusExpand batchGnID={row.original.gnID} batchType={moviesTableShow ? 'movies' : 'shows' } />
            </ErrorBoundary>
        ),
        [moviesTableShow]
    );

    const downloadErrorsCSX = (row) => {
        dispatch(getStatusByGnID(moviesTableShow ? 'movies' : 'shows', row.original.gnID)).then((response) => {
            if (response?.result?.data) {
                if (response?.result?.data.status === 'failed') {
                    const errorData = getErrorData(response.result.data);
                    if (errorData.length > 0) {
                        const csvData = [];
                        csvData.push(Object.keys(errorData[0]));
                        errorData.forEach((errorRow) => {
                            csvData.push(Object.values(errorRow));
                        });
                        const csvRows = csvData.join('\n');
                        const csvFile = new Blob([csvRows], { type: 'text/csv' });
                        const csvUrl = URL.createObjectURL(csvFile);
                        const hiddenElement = document.createElement('a');
                        hiddenElement.href = csvUrl;
                        hiddenElement.target = '_blank';
                        hiddenElement.download = `Register_Program_Errors.csv`;
                        hiddenElement.click();
                    }
                }
            }
        }).catch((error) => {
            dispatch(gnviewSendLogMessage(`getStatusByGnID error: ${error.message}`, error));
        });
    };

    const downloadErrorsIndex = importsColumns.findIndex((col) => col.id === 'download');
    if (downloadErrorsIndex !== -1) {
        importsColumns[downloadErrorsIndex] = {
            ...importsColumns[downloadErrorsIndex],
            /* eslint-disable react/display-name */
            /* eslint-disable react/prop-types */
            Cell: ({ row }) =>
            row?.original?.status === 'failed'
                ? <button className="import-status-download-btn gn_button btn btn-primary" onClick={() => downloadErrorsCSX(row)}><FontAwesomeIcon icon='download' />{ DOWNLOAD_ERRORS_BUTTON_TEXT }</button>
                : null
            /* eslint-enable react/display-name */
            /* eslint-enable react/prop-types */
        };
    }


    return (
        <ErrorBoundary>
            <div className='import-status-main-container'>
                <div className='import-status-top-section'>
                    <span className='gnids-last-updated'>
                        {LIST_VIEW_PAGE_LAST_UPDATED}:{' '}
                        {pageLastUpdated.format(MOBIUS_MOMENT_DATE_FORMAT)}
                    </span>
                    <div className='import-status-header-container'>
                        <ViewSwitcher value="Import Status" />
                    </div>
                    <p>{ IMPORT_STATUS_HELPER_TEXT }</p>
                </div>
                <div className='import-status-bottom-section'>
                    <ErrorBoundary>
                        <GNRadioGroup style="layered" list={importStatusTabs} onSelect={onTabSelect} default={importStatusTabs[0]}/>
                        <button className="import-status-refresh-btn gn_button btn" onClick={handleRefresh} disabled={isLoading}><FontAwesomeIcon icon='refresh' />{ REFRESH_BUTTON_TEXT }</button>
                        { moviesTableShow && <GNServerTable
                            className='imports-table'
                            columns={importsColumns}
                            fetchData={fetchMovieData}
                            noDataMessage={
                                <p className="no-data-message">{ NO_IMPORT_DATA }</p>
                            }
                            renderRowSubComponent={renderRowSubComponent}
                            setIsTableLoading={setIsLoading}
                        />}
                        { showsTableShow && <GNServerTable
                            className='imports-table'
                            columns={importsColumns}
                            fetchData={fetchShowsData}
                            noDataMessage={
                                <p className="no-data-message">{ NO_IMPORT_DATA }</p>
                            }
                            renderRowSubComponent={renderRowSubComponent}
                            setIsTableLoading={setIsLoading}
                        />}

                    </ErrorBoundary>
                </div>
            </div>
        </ErrorBoundary>
    )
}

export default ImportStatusView;