import { MOBIUS_VALUES } from "./Mobius"

// Labels for the separate Image Modal Sections
export const IMAGE_MODAL_SECTIONS = {
    OVERVIEW: 'Overview',
    DESCRIPTION: 'Description',
    ADDITIONAL_DETAILS: 'Additional Details',
    CUSTOM_ATTRIBUTES: 'Custom Attributes'
}

// Tooltips
export const IMAGE_TOOLTIPS = {
    ADD_NEW_CUSTOM_ATTRIBUTES: 'You can add another custom attribute after completing the existing fields',
    BRANDED: 'Image contains a source/channel/network/brand name and/or logo.',
    CLEAR_CUSTOM_ATTRIBUTE: 'Remove Label and Value',
    CUSTOM_ATTRIBUTES: 'Custom key-value pairs for sources to carry proprietary image attributes.',
    DATE_CREATED: 'Date the image was created on.',
    GRACENOTE_USAGE: 'GN may use provided image in its customer-facing datasets upon successful publish request. Available for GN editorial to use unless set to false.',
    MARKETS: 'Applicable markets used in the image.',
    SENSITIVE: 'Image contains sensitive material.',
    TAGS: 'Source provided tags for the image.'
}

// Column header for Image Custom Attributes table
export const MOBIUS_IMAGE_CUSTOM_ATTRIBUTES_HEADERS = [
    { accessor: 'label', Header: 'Label', disableSortBy: true},
    { accessor: 'value', Header: 'Value', disableSortBy: true}
]

export const NO_CUSTOM_ATTRIBUTE_IMAGE_DATA = [
    {
        'label': MOBIUS_VALUES.EMPTY_DATA,
        'value': MOBIUS_VALUES.EMPTY_DATA
    }
]

// Labels for Image Modal
export const IMAGE_LABELS = {
    ADD_CATEGORY: 'Add Category: ',
    ADD_CUSTOM_ATTRIBUTES: 'Add',
    ADD_MARKETS: 'Add Market: ',
    ADD_PRIMARY_LANGUAGE: 'Add Primary Language: ',
    ADD_TAGS: 'Add Tag: ',
    ASPECT_RATIO: 'Aspect Ratio',
    ASPECT_RATIO_INVALID: 'Invalid Aspect Ratio',
    BRAND_DESCRIPTION: 'Brand Description',
    BRANDED: 'Branded',
    CATEGORY: 'Category',
    DESCRIPTION: 'Description',
    DESCRIPTION_LANGUAGE: 'Description Language',
    DIMENSIONS: 'Dimensions',
    DIMENSIONS_INVALID: 'Invalid dimensions',
    DIMENSIONS_HEIGHT: 'Height',
    DIMENSIONS_WIDTH: 'Width',
    GRACENOTE_USAGE: 'Gracenote Usage',
    IMAGE_DETAILS: 'Image Details',
    IMAGE_FILE_TYPE: 'Image File Type',
    IMAGE_LABEL: 'Image Label',
    IMAGE_URL: 'Image URL',
    LABEL: 'Label',
    MARKETS: 'Markets',
    PRIMARY_LANGUAGES_USED_IN_THE_IMAGE: 'Primary Languages Used in the Image',
    PRIMARY_LANGUAGES: 'Primary Languages',
    SENSITIVE: 'Sensitive',
    TAGS: 'Tags',
    TITLE: 'Title',
    URL_INVALID: 'Invalid url',
    DUPLICATE_LABELS: 'Repeated Labels not allowed',
    VALUE: 'Value',
    YES: 'Yes',
    NO: 'No'
}

export const REGISTER_NEW_IMAGE = 'Register New Image';

