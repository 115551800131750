import React from 'react';
import SelectSearch from 'react-select-search';
import PropTypes from 'prop-types';
import './GNDropdown.scss';
import ClassNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import { REQUIRED } from '../../../constants/App';
import { sortOptionsByName } from '../../../utils/MobiusUtils';

export const GNDropdown = (props) => (
    <div className='gndropdown-main-container'>
        <div className='gndropdown-label'>{props?.label}</div>
        <SelectSearch
            className={ ClassNames('select-search', {'invalid': props.status && !props.value})}
            onChange={(item) => props?.handleChange(item)}
            options={props?.isAlphabeticallySorted ? sortOptionsByName(props?.options) : props?.options}
            disabled={props?.disabled}
            emptyMessage={props?.emptyMessage}
            // Initial value for GNDropdown, only will update with onChange
            value={props?.value}
            search={!props.isViewSelector}
            getOptions={(inputValue, options) => {
                if (inputValue) {
                    return options.filter((item) =>
                        typeof item?.name === 'string'
                            ? item.name.toLowerCase().includes(inputValue.toLowerCase())
                            : item.value.toLowerCase().includes(inputValue.toLowerCase())
                    );
                } else {
                    return options;
                }
            }}
            {...(props.renderValue && { renderValue: props.renderValue })}
            {...(props.renderOption && { renderOption: props.renderOption })}
            {...(props.placeholder && { placeholder: props.placeholder })}
        />
        {!props.isViewSelector && <FontAwesomeIcon icon='angle-down' className={ClassNames({ 'has-label': props?.label })} />}
        {(props.isRequired || props.validationText || props.status) && (
            <div className={ClassNames('validation-text', { invalid: !props.validateInput || (props.status && !props.value)})}>
                <Form.Text className='required-text'>
                    {props.isRequired && REQUIRED}
                    {props.validationText}
                    {(props?.status && !props.validationText) && <div className='status-message'>{props.status}</div>}
                </Form.Text>
            </div>
        )}
        {props.idName === 'season_view_mode' &&
            <div className='gndropdown-season-id'>Presentation {props?.value}</div>
        }
    </div>
);

GNDropdown.defaultProps = {
    disabled: false,
    emptyMessage: 'Not Found',
    isRequired: false,
    isAlphabeticallySorted: false,
    isViewSelector: false
};

GNDropdown.propTypes = {
    disabled: PropTypes.bool,
    emptyMessage: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
    isAlphabeticallySorted: PropTypes.bool,
    isViewSelector: PropTypes.bool,
    label: PropTypes.string,
    idName: PropTypes.string,
    // Array of objects that contain these fields: name, value
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    renderOption: PropTypes.func,
    renderValue: PropTypes.func,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    validateInput: PropTypes.bool,
    validationText: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired
};

export default GNDropdown;
