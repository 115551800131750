import get from "lodash.get";
import isEmpty from "lodash.isempty";
import set from "lodash.set";
import moment from "moment";
import { MOBIUS_IMAGE_FIELDS, MOBIUS_IMAGE_INITIAL_VALUES } from "../constants/MobiusImageValidation";
import { convertCodeLanguageList, convertLanguageCodeList, convertCountryCodeToName } from "./GeneralUtils";

export const getImageColor = (type) => {
    return type ? 'color-images' : 'color-default'
}

/**
 * Validate if a given image has all the required fields
 * @param {Object} image - current image data
 * @returns {Object}
 */
export const verifyImageDataIntegrity = (image) => {
    if (isEmpty(image)) {
        return MOBIUS_IMAGE_INITIAL_VALUES
    }

    for (const [field, val] of Object.entries(MOBIUS_IMAGE_INITIAL_VALUES)) {
        switch (field) {
        case MOBIUS_IMAGE_FIELDS.BRANDED:
        case MOBIUS_IMAGE_FIELDS.GRACENOTE_USAGE:
        case MOBIUS_IMAGE_FIELDS.SENSITIVE:
            set(image, field, Boolean(get(image, field)))
            break;
        case MOBIUS_IMAGE_FIELDS.CUSTOM_ATTRIBUTES:
            if ((typeof image[field] === 'object') && !Array.isArray(image[field])) {
                const customAttributes = Object.keys(image[field]).map(key => ({label: key, value: image[field][key]}))
                set(image, field, customAttributes)
            }
            break;
        default:
            if (isEmpty(image[field])) {
                set(image, field, val);
            } else {
                set(image, field, get(image, field) || val);
            }
        }
    }
    return image;
};

export const IMAGE_FILE_TYPES = [ 'BMP', 'PSD', 'JPEG', 'JPG', 'TIFF', 'PNG' ].map(val => ({name: val, value: val}))

export const convertImageForPost = (
    image
) => {
    const {
        category = null,
        customAttributes = null,
        dimensions: {
            width = null,
            height = null,
            aspectRatio = null
        },
        fileType = null,
        title = null,
        url = null,
        description: {
            length,
            language,
            value: descriptionValue
        },
        gracenoteUsage = false,
        sensitive,
        branded,
        brandDescription,
        languages = null,
        markets = null,
        tags = null
    } = image;

    const convertedCustomAttributes = customAttributes?.reduce((a, v) => {
        return ({...a, [v.label]: v.value})
    }, {})

    const convertedLanguageCodes = convertCodeLanguageList(languages);

    return {
        category,
        customAttributes: convertedCustomAttributes,
        dimensions: {
            width: parseInt(width),
            height: parseInt(height),
            aspectRatio
        },
        fileType,
        title,
        url,
        description: descriptionValue ? {
            length,
            value: descriptionValue,
            language: language || 'en'

        } : null,
        gracenoteUsage,
        sensitive,
        branded,
        brandDescription,
        languages: convertedLanguageCodes,
        markets,
        tags
    };
};

export const transformImageData = (image, isLinked = false) => ({
    ...image,
    imageType: image.category,
    tableAspectRatio: image.dimensions?.aspectRatio,
    tableDimensions: `${image.dimensions?.height} x ${image.dimensions?.width}`,
    market: image.markets?.map(market => convertCountryCodeToName(market.toUpperCase())).join(', '),
    link: isLinked,
    language: convertLanguageCodeList(image.languages).join(', '),
    updated: moment(image.updated).format('MMM D, YYYY')
});
