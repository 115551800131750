import {
    GNIDS_UPDATE_EPISODE,
    GNIDS_CLEAR_EPISODE
} from '../actions/ActionTypes';

export const GNIDS_EPISODE_STORE_NAME = 'episode';

/**
 * Initial State
 */
export const initialState = {
    showVersionGnID: null,
    showPresentationGnID: null,
    showTitle: '',
    seasonGnID: null,
    seasonTitle: ''
};

/**
 * Reducer
 */
export const GNIDSEpisodeReducer = (state = initialState, action) => {
    switch (action.type) {
    case GNIDS_UPDATE_EPISODE:
        return {
            ...state,
            showVersionGnID: action.payload.showVersionGnID,
            showPresentationGnID: action.payload.showPresentationGnID,
            showTitle: action.payload.showTitle,
            seasonGnID: action.payload.seasonGnID,
            seasonTitle: action.payload.seasonTitle
        };
    case GNIDS_CLEAR_EPISODE:
        return {...initialState};
    default:
        return state;
    }
};


/**
 * Selectors
 */


export const gnidsSelShowVersionGNID = state => state[GNIDS_EPISODE_STORE_NAME]?.showVersionGnID;
export const gnidsSelShowPresentationGNID = state => state[GNIDS_EPISODE_STORE_NAME]?.showPresentationGnID;
export const gnidsSelShowTitle = state => state[GNIDS_EPISODE_STORE_NAME]?.showTitle;
export const gnidsSelSeasonGNID = state => state[GNIDS_EPISODE_STORE_NAME]?.seasonGnID;
export const gnidsSelSeasonTitle = state => state[GNIDS_EPISODE_STORE_NAME]?.seasonTitle;

/**
 * Default export
 */
export default GNIDSEpisodeReducer;