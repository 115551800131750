import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { gnviewSendLogMessage } from '../services/GeneralService';
import { BATCH_STATUS_SUCCESS_NOTE, BATCH_STATUS_ERRORS_HEADER, BATCH_STATUS_ERRORS_NOTE } from '../constants/Imports'; // add IMPORT_COLUMN_HEADERS
import { getStatusByGnID } from '../actions/GNIDSProgramFilesActions';
import ErrorBoundary from '../components/common/errorBoundary/ErrorBoundary';
import LoadingSpinner from '../components/common/loadingSpinner/LoadingSpinner';
import ImportBatchStatusTable from '../components/imports/ImportBatchStatusTable/ImportBatchStatusTable';
import { getErrorData, getDataFromRoots, getDataFromVersions, getDataFromPresentations, getDataFromSeasons, getDataFromEpisodes } from '../utils/ImportBatchStatusUtils';
import './ImportBatchStatusExpand.scss';


export const ImportBatchStatusExpand = ({ batchGnID, batchType }) => {
    const dispatch = useDispatch();
    const [tableType, setTableType] = useState();
    const batchRows = useMemo(() => [], []);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (batchGnID && batchType && !batchRows.length) {
            dispatch(
                getStatusByGnID(batchType, batchGnID)
            )
                .then((response) => {
                    if (response?.result?.data && loading) {
                        setTableType(response?.result?.data.status);
                        if (response?.result?.data.status === 'succeeded' && loading) {
                            if (response?.result?.data?.response?.roots?.length) {
                                getDataFromRoots(response.result.data.response.roots).map((row) => {
                                    batchRows.push(row);
                                });
                            }
                            if (response?.result?.data?.response?.versions?.length) {
                                getDataFromVersions(response.result.data.response.versions).map((row) => {
                                    batchRows.push(row);
                                });
                            }
                            if (response?.result?.data?.response?.presentations?.length) {
                                getDataFromPresentations(response.result.data.response.presentations).map((row) => {
                                    batchRows.push(row);
                                });
                            }
                            if (response?.result?.data?.response?.seasons?.length) {
                                getDataFromSeasons(response.result.data.response.seasons).map((row) => {
                                    batchRows.push(row);
                                });
                            }
                            if (response?.result?.data?.response?.episodes?.length) {
                                getDataFromEpisodes(response.result.data.response.episodes).map((row) => {
                                    batchRows.push(row);
                                });
                            }
                            setData(batchRows);
                            setLoading(false);
                        } else if (response?.result?.data.status === 'failed') {
                            const errorsList = getErrorData(response.result.data)
                            setData(errorsList);
                            setLoading(false);
                        }
                    }
                })
                .catch((error) => {
                    dispatch(
                        gnviewSendLogMessage(
                            `getStatusByGnID error: ${error.message}`,
                            error
                        )
                    );
                });
        }
    }, [dispatch, batchGnID, batchType, batchRows, loading]);

    return (
        <ErrorBoundary>
            <div className="import-status-table-wrapper">
                { loading ? (
                    <div><LoadingSpinner /></div>
                ) : (
                    <div className="import-status-table-content">
                        {tableType === 'succeeded' && <div className="import-status-table-note">{ BATCH_STATUS_SUCCESS_NOTE }</div>}
                        {tableType === 'failed' && <div className="import-status-table-error"><span><h3><FontAwesomeIcon icon="fa-solid fa-warning color-error" /> {data.length} { BATCH_STATUS_ERRORS_HEADER }</h3><h6>{ BATCH_STATUS_ERRORS_NOTE }</h6></span></div>}
                        <ImportBatchStatusTable batchType={batchType} batchData={data} tableType={tableType}/>
                    </div>
                )}
            </div>
        </ErrorBoundary>
    );
};

ImportBatchStatusExpand.propTypes = {
    batchGnID: PropTypes.object.isRequired,
    batchType: PropTypes.string.isRequired
};

export default ImportBatchStatusExpand;
