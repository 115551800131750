import React from 'react';
import './CustomAttributesSection.scss';
import PropTypes from 'prop-types';
import { IMAGE_MODAL_SECTIONS, IMAGE_TOOLTIPS, MOBIUS_IMAGE_CUSTOM_ATTRIBUTES_HEADERS, NO_CUSTOM_ATTRIBUTE_IMAGE_DATA } from '../../../constants/Image';
import GNClientTable from '../../common/gnClientTable/GNClientTable';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CustomAttributesSection({imageData}) {
    return (
        <div className='image-custom-attributes-section'>
            <div className='view-section-title'>
                {IMAGE_MODAL_SECTIONS.CUSTOM_ATTRIBUTES}
                <OverlayTrigger overlay={<Tooltip>{IMAGE_TOOLTIPS.CUSTOM_ATTRIBUTES}</Tooltip>} placement="right">
                    <FontAwesomeIcon icon='circle-info' />
                </OverlayTrigger>
            </div>
            <div className='view-section-body'>
                <GNClientTable
                    className='custom-attributes-table'
                    columns={MOBIUS_IMAGE_CUSTOM_ATTRIBUTES_HEADERS}
                    data={imageData?.customAttributes && Object.entries(imageData?.customAttributes).length > 0 ? Object.entries(imageData?.customAttributes).map(([currLabel, currValue]) => {
                        const attribute = {
                            'label': currLabel,
                            'value': currValue
                        };
                        return attribute;
                    }) : NO_CUSTOM_ATTRIBUTE_IMAGE_DATA}
                />
            </div>
        </div>

    )
}

CustomAttributesSection.propTypes = {
    imageData: PropTypes.object
}

export default CustomAttributesSection;