
export const IMPORT_STATUS_ICONS = {
    'succeeded': 'check',
    'inProgress': 'spinner',
    'failed': 'exclamation',
    'waiting': 'spinner'
};

export const IMPORT_STATUS_COLORS = {
    'succeeded': 'color-green',
    'failed': 'color-red',
    'inProgress': 'color-black',
    'waiting': 'color-black'
};

