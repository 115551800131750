import { API_ENDPOINTS } from '../config/Api';
import apiService from '../services/ApiService';
import {
    GNIDS_SET_PROGRAM_FILES,
    GNIDS_CLEAR_PROGRAM_FILES,
    GNIDS_BULK_UPLOAD_SUCCESS,
    GNIDS_BULK_UPLOAD_FAILURE
} from './ActionTypes';

export const gnidsSetProgramFiles = (files) => ({
    type: GNIDS_SET_PROGRAM_FILES,
    payload: {
        files
    }
});

export const gnidsClearProgramFiles = () => ({
    type: GNIDS_CLEAR_PROGRAM_FILES
});

export const gnidsPostBulkUploadSuccess = (response) => ({
    type: GNIDS_BULK_UPLOAD_SUCCESS,
    payload: response
});

export const gnidsPostBulkUploadFailure = (errors) => ({
    type: GNIDS_BULK_UPLOAD_FAILURE,
    payload: errors
});

export const gnidsPostBulkUpload = (payload, sourceID, isMovie) => {
    return async (dispatch) => {
        const srcID = sourceID[0];
        const body = payload;
        const headers = { SourceID: srcID };

        return apiService
            .makeApiCall(
                `${API_ENDPOINTS.GNIDS_BULK}/${isMovie ? 'movies' : 'shows'}/batches`,
                body,
                'post',
                false,
                headers
            ).then(
                (response) => {
                    dispatch(gnidsPostBulkUploadSuccess(response));
                    return response;
                },
                (errors) => {
                    dispatch(gnidsPostBulkUploadFailure(errors));
                    throw errors;
                }
            )
    }
};
export const gnidsGetBulkBatch = (pageIndex, sortBy, batchType) => {
    return async () => {
        const params = {
            limit: 20,
            page: pageIndex + 1,
            ...(sortBy?.length > 0 && { sortField: sortBy[0]?.id }),
            ...(sortBy?.length > 0 && { sortDirection: sortBy[0]?.desc ? 'desc' : 'asc' })
        }
        const apiUrl = API_ENDPOINTS.GET_BULK_BATCH_STATUS + '/' + batchType + '/batches';
        const bulkShowsBatchStatus = await apiService.makeApiCall(
            `${apiUrl}`,
            params,
            'get',
            false
        );

        bulkShowsBatchStatus.result.data = bulkShowsBatchStatus?.result?.data?.map((program) => ({
            ...program,
            canExpand: true
        }));
        return bulkShowsBatchStatus
    };
};

export const getStatusByGnID = (batchType, batchGnID) => {
    return async () => {
        const params = {}
        const apiUrl = API_ENDPOINTS.GET_BULK_BATCH_STATUS + '/' + batchType + '/batches/' + batchGnID;
        const bulkBatchStatus = await apiService.makeApiCall(
            `${apiUrl}`,
            params,
            'get',
            false
        );
        return bulkBatchStatus
    }
};