import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import {
    IMAGE_SIDE_MENU
} from '../../constants/Mobius';
import { getImageValidationSchema, MOBIUS_IMAGE_FIELDS } from '../../constants/MobiusImageValidation';
import GNInput from "../common/gnInput/GNInput";
import { IMAGE_LABELS, IMAGE_TOOLTIPS } from '../../constants/Image';
import classNames from 'classnames';

export const ImageModalEditCustomAttributes = ({handleFormChange, values, validateForm, errors, hasWriteAccess}) => {
    const [customAttributes, setCustomAttributes] = useState(values.customAttributes.map((customAttribute, index) => ({...customAttribute, index})));

    if (customAttributes.length === 0) {
        setCustomAttributes([{label: '', value: ''}])
    }

    useEffect(() => {
        setCustomAttributes(values.customAttributes.map((customAttribute, index) => ({...customAttribute, index})));
    }, [values.customAttributes, setCustomAttributes]);

    const handleClearCustomAttribute = (attribute, idx) => {
        const filteredCustomAttributes = [...customAttributes].filter((element) => (element?.index !== idx));
        setCustomAttributes(filteredCustomAttributes)
        handleFormChange(MOBIUS_IMAGE_FIELDS.CUSTOM_ATTRIBUTES, filteredCustomAttributes);
    };

    const onAddCustomAttributes = () => {
        handleFormChange(MOBIUS_IMAGE_FIELDS.CUSTOM_ATTRIBUTES, [...customAttributes, {label: '', value: ''}])
    }

    return (<div className='custom-attributes-section' id={IMAGE_SIDE_MENU.CUSTOM_ATTRIBUTES.id}>
        <div className='custom-attributes-modal-tab-header'>
            {IMAGE_SIDE_MENU.CUSTOM_ATTRIBUTES.label}
            <OverlayTrigger overlay={<Tooltip>{IMAGE_TOOLTIPS.CUSTOM_ATTRIBUTES}</Tooltip>} placement="right">
                <FontAwesomeIcon icon='circle-info' />
            </OverlayTrigger>
        </div>
        <div className='custom-attributes-modal-tab-body'>
            <div className='custom-attributes-container'>
                {customAttributes?.map((attribute, idx) => {
                    const newFieldIsFilled = attribute?.label?.length > 0 && attribute?.value?.length > 0;
                    const isOnlyCustomAttribute = customAttributes?.length === 1;
                    const isLast = idx === customAttributes?.length - 1;
                    return (<div className='custom-attribute-row' key={idx}>
                        <GNInput
                            className='label'
                            handleChange={(item) => handleFormChange(MOBIUS_IMAGE_FIELDS.CUSTOM_ATTRIBUTES_LABEL(idx), item)}
                            isRequired={getImageValidationSchema()?.fields[MOBIUS_IMAGE_FIELDS.CUSTOM_ATTRIBUTES_LABEL(idx)]?.exclusiveTests?.required}
                            label={IMAGE_LABELS.LABEL}
                            placeholder={IMAGE_LABELS.LABEL}
                            validateInput={() => validateForm(MOBIUS_IMAGE_FIELDS.CUSTOM_ATTRIBUTES_LABEL(idx))}
                            validationText={errors?.customAttributes?.[idx]?.label}
                            value={values?.customAttributes?.[idx]?.label} />
                        <GNInput
                            className='value'
                            handleChange={(item) => handleFormChange(MOBIUS_IMAGE_FIELDS.CUSTOM_ATTRIBUTES_VALUE(idx), item)}
                            isRequired={getImageValidationSchema()?.fields[MOBIUS_IMAGE_FIELDS.CUSTOM_ATTRIBUTES_VALUE(idx)]?.exclusiveTests?.required}
                            label={IMAGE_LABELS.VALUE}
                            placeholder={IMAGE_LABELS.VALUE}
                            validateInput={() => validateForm(MOBIUS_IMAGE_FIELDS.CUSTOM_ATTRIBUTES_VALUE(idx))}
                            validationText={errors?.customAttributes?.[idx]?.value}
                            value={values?.customAttributes?.[idx]?.value} />
                        {!isOnlyCustomAttribute && hasWriteAccess && (
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id='clear-custom-attribute-tooltip'>
                                        {IMAGE_TOOLTIPS.CLEAR_CUSTOM_ATTRIBUTE}
                                    </Tooltip>
                                }
                                placement='bottom'
                            >
                                <div
                                    className='clear-custom-attribute-button'
                                    onClick={() => handleClearCustomAttribute(attribute, idx)}
                                >
                                    <FontAwesomeIcon icon='trash-alt' />
                                </div>
                            </OverlayTrigger>
                        )}
                        {isLast && hasWriteAccess && <div className='add-button-main-container'>
                            <OverlayTrigger overlay={<Tooltip id="add-custom-attributes-tooltip">{IMAGE_TOOLTIPS.ADD_NEW_CUSTOM_ATTRIBUTES}</Tooltip>} placement='right'>
                                <div className={classNames('add-button', {enabled: newFieldIsFilled})} onClick={() => {
                                    if (newFieldIsFilled) {
                                        onAddCustomAttributes()
                                    }
                                }}><FontAwesomeIcon icon='plus'/>{IMAGE_LABELS.ADD_CUSTOM_ATTRIBUTES}</div>
                            </OverlayTrigger>
                        </div>}
                    </div>)
                })}
            </div>
        </div>
    </div>)
}

ImageModalEditCustomAttributes.propTypes = {
    handleFormChange: PropTypes.func,
    values: PropTypes.object,
    errors: PropTypes.object,
    validateForm: PropTypes,
    hasWriteAccess: PropTypes.bool
};

export default ImageModalEditCustomAttributes;