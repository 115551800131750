import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import {
    gvauthHasMobiusWriteAccess
} from "../../reducers/GNVAuthReducer";
import { PROGRAM_BULK_UPLOAD } from "../../constants/BulkUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import GNButton from "../../components/common/gnButton/GNButton";
import GNModal from "../../components/common/gnModal/GNModal";
import LoadingSpinner from "../../components/common/loadingSpinner/LoadingSpinner";
import StepCircles from "./StepCircles";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import "./RegisterProgramModal.scss";

export function Link() {
    const openHyperLink = () => {
        window.open(PROGRAM_BULK_UPLOAD.INSTRUCTIONS_URL, "_blank");
    };
    return (
        <OverlayTrigger placement="bottom" overlay={
            <Tooltip>{PROGRAM_BULK_UPLOAD.LINK}</Tooltip>
        }>
            <div
                className={"link"}
                onClick={openHyperLink}
            >
                {PROGRAM_BULK_UPLOAD.LINK}{" "}
                <FontAwesomeIcon icon="arrow-up-right-from-square" />
            </div>
        </OverlayTrigger>
    );
}

function RegisterProgramModal({ show, onClose, openRegisterNewProgramModal, handlePostBulkUpload}) {
    const hasWriteAccess = useSelector(gvauthHasMobiusWriteAccess);
    const [step, setStep] = useState(0);
    const [isLoading] = useState(false);

    return (
        <div data-testid='register-program-modal'>
            <GNModal
                show={show}
                className="bulk-upload-base-modal"
                onHide={onClose}
            >
                {isLoading && <LoadingSpinner />}
                {!isLoading && (
                    <div>
                        <Modal.Title>
                            <div className="modal-title">
                                {step === 0
                                    ? PROGRAM_BULK_UPLOAD.REGISTER
                                    : PROGRAM_BULK_UPLOAD.REGISTER_VIA_CSV}
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip>{PROGRAM_BULK_UPLOAD.TITLE_TOOLTIP}</Tooltip>
                                    }
                                    placement="right"
                                >
                                    <FontAwesomeIcon icon="circle-info" />
                                </OverlayTrigger>
                            </div>
                        </Modal.Title>
                        <Modal.Body>
                            {step === 0 && (
                                <div>
                                    <p className="info-label">{PROGRAM_BULK_UPLOAD.INFO_LABEL}</p>
                                    <div className="select-register-program-mode">
                                        <Button
                                            variant="primary"
                                            onClick={openRegisterNewProgramModal}
                                            className="btn gn_button register-button"
                                            disabled={!hasWriteAccess}
                                        >
                                            {PROGRAM_BULK_UPLOAD.REGISTER_PROGRAM}
                                            <FontAwesomeIcon icon="arrow-right" />
                                        </Button>
                                        <div className="divider"></div>
                                        <div className="register-via-csv-section">
                                            <GNButton

                                                trigger={null}
                                                onClick={() => setStep(1)}
                                                disabled={!hasWriteAccess}
                                                buttonClass="btn register-via-csv-button"
                                                hasWriteAccess={hasWriteAccess}
                                            >
                                                <span>{PROGRAM_BULK_UPLOAD.REGISTER_VIA_CSV}</span>
                                                <FontAwesomeIcon icon="arrow-right" />
                                            </GNButton>
                                            <Link />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {step === 1 && <StepOne Link={Link} handleNext={() => setStep(2)} />}
                            {step === 2 && <StepTwo setStep={setStep} handlePostBulkUpload={handlePostBulkUpload} />}
                        </Modal.Body>
                        {step !== 0 && (
                            <StepCircles step={step} maxSteps={2} />
                        )}
                    </div>
                )}
            </GNModal>
        </div>
    );
}

RegisterProgramModal.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    openRegisterNewProgramModal: PropTypes.func,
    handlePostBulkUpload: PropTypes.func
};

export default RegisterProgramModal;
