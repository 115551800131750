import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import GNModal from '../../components/common/gnModal/GNModal';
import './CatalogModal.scss';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../components/common/loadingSpinner/LoadingSpinner';
import {
    PROGRAM_SIDE_MENU_RATIOS,
    MOBIUS_DATA_TYPES,
    CATALOG_SIDE_MENU,
    MOBIUS_BUTTON_TEXT,
    MOBIUS_NOTIFICATIONS,
    MOBIUS_CONFIRMATIONS
} from '../../constants/Mobius';
import ClassNames from 'classnames';
import ScrollSpyMenu from '../../components/common/scrollSpyMenu/ScrollSpyMenu';
import ErrorBoundary from '../../components/common/errorBoundary/ErrorBoundary';
import { getProgramMenuItems, getProgramMenuRatios } from '../../utils/MobiusUtils';
import { gnidsCatalog } from '../../reducers/GNIDSCatalogReducer';
import { clearCatalogModalData } from '../../actions/GNIDSCatalogModalActions';
import { CATALOG_LABELS, REGISTER_NEW_CATALOG } from '../../constants/Catalog';
import { CatalogModalViewOnly } from './CatalogModalViewOnly';
import { mobiusGetCatalog } from '../../actions/MobiusActions';
import GNButton from '../../components/common/gnButton/GNButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CatalogModalEdit from './CatalogModalEdit';
import GNConfirmationModal from '../../components/common/gnConfirmationModal/GNConfirmationModal';

export const CatalogModal = ({
    setModalShow,
    handleNotification,
    setShouldResetTable,
    isRegisterMode,
    setIsRegisterMode
}) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(!isRegisterMode);
    const catalogData = useSelector(gnidsCatalog)
    const [catalog, setCatalog] = useState(isRegisterMode ? {} : catalogData);
    const [editMode, setEditMode] = useState(false);
    const [showCancelConfirm, setShowCancelConfirm] = useState(false);
    const [refreshTable, setRefreshTable] = useState(false);

    useEffect(() => {
        if (!isRegisterMode && catalogData?.gnID) {
            setIsLoading(true)
            dispatch(mobiusGetCatalog(catalogData.gnID)).then((data) => {
                setCatalog(data);
            }).catch(() => {
                dispatch(clearCatalogModalData())
                setModalShow(false);
                handleNotification(true, MOBIUS_NOTIFICATIONS.ERROR_COULD_NOT_BE_OPENED, false)
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }, [ dispatch, catalogData?.gnID, handleNotification, setModalShow, isRegisterMode])

    const handleCancel = (dirty) => {
        if (dirty) {
            setShowCancelConfirm(dirty);
        } else if (refreshTable) {
            handleCancelConfirm()
            setShouldResetTable(refreshTable)
        } else {
            handleCancelConfirm()
        }
    };


    const handleCancelConfirm = () => {
        dispatch(clearCatalogModalData())
        setShowCancelConfirm(false)
        setModalShow(false)
    }

    const handleEditMode = () => {
        setEditMode((prevEditMode) => !prevEditMode);
    };

    const handleRegisterSuccess = () => {
        setModalShow(false);
        setIsRegisterMode(false);
        setShouldResetTable((prev) => !prev);
        dispatch(clearCatalogModalData());
        handleNotification(true, MOBIUS_NOTIFICATIONS.CATALOG_SUCCESS_REGISTERED, true);
    }

    return (
        <ErrorBoundary>
            {/* We're doing a custom css hide here instead of using show because we don't want this to unmount and lose the user's current changes */}
            <GNModal
                show={true}
                onHide={() => handleCancel()}
                className={ClassNames('mobius-catalog-modal', {
                    hide: showCancelConfirm
                })}
            >
                {isLoading && <LoadingSpinner />}
                {!isLoading && (
                    <div className='catalog-modal-container'>
                        <Modal.Title>
                            <div className="gnview-header-container">
                                <OverlayTrigger
                                    overlay={<Tooltip id="program-title-tooltip">{catalogData?.name }</Tooltip>}
                                    trigger={(catalogData?.name.length > 56) ? ['hover'] : null}
                                    placement='bottom'
                                >
                                    <div className='gnview-header-title'>{(catalogData?.name && !isRegisterMode) ? catalogData?.name : REGISTER_NEW_CATALOG}</div>
                                </OverlayTrigger>
                                {!editMode && !isRegisterMode && <div className="edit__button">
                                    <GNButton
                                        trigger={['']}
                                        buttonClass='edit-button'
                                        onClick={handleEditMode}
                                    >
                                        <FontAwesomeIcon icon="pen" /> {MOBIUS_BUTTON_TEXT.EDIT}
                                    </GNButton>
                                </div>}
                            </div>
                            {!editMode && !isRegisterMode &&
                            <div className='title-sub-section'>
                                {catalog?.gnID && (
                                    <span className='ids-section'>
                                        <span className='id-label'>Catalog GNID</span>
                                        {catalog?.gnID}
                                    </span>
                                )}
                            </div> }
                        </Modal.Title>
                        <Modal.Body>
                            <div className='catalog-modal-side-menu-container'>
                                <ScrollSpyMenu
                                    menuItemIds={getProgramMenuItems(CATALOG_SIDE_MENU, MOBIUS_DATA_TYPES.CATALOGS)}
                                    menuLabel={CATALOG_LABELS.CATALOG_DETAILS}
                                    menuRatios={getProgramMenuRatios(PROGRAM_SIDE_MENU_RATIOS, MOBIUS_DATA_TYPES.CATALOGS)}
                                    targetContainerId='catalog-modal-body'
                                    disabled={false}
                                />
                            </div>
                            <div id='catalog-modal-body'>
                                {!editMode && !isRegisterMode && <CatalogModalViewOnly catalogData={catalog}></CatalogModalViewOnly>}
                                {(editMode || isRegisterMode) && <CatalogModalEdit catalog={catalog} handleCancel={handleCancel} handleNotification={handleNotification} setRefreshTable={setRefreshTable} isRegisterMode={isRegisterMode} handleRegisterSuccess={handleRegisterSuccess}></CatalogModalEdit> }
                            </div>
                        </Modal.Body>
                    </div>
                )}
            </GNModal>

            <GNConfirmationModal
                className='catalog-modal-notification'
                cancelButtonHandler={() => setShowCancelConfirm(false)}
                cancelButtonText={MOBIUS_BUTTON_TEXT.CANCEL}
                message={MOBIUS_CONFIRMATIONS.CANCEL_CONFIRMATION_MESSAGE}
                show={showCancelConfirm}
                submitButtonHandler={handleCancelConfirm}
                submitButtonText={MOBIUS_BUTTON_TEXT.EXIT}
                title={MOBIUS_CONFIRMATIONS.CANCEL_CONFIRMATION_TITLE}
            />
        </ErrorBoundary>
    );
};

CatalogModal.propTypes = {
    setModalShow: PropTypes.func.isRequired,
    handleNotification: PropTypes.func,
    setShouldResetTable: PropTypes.func,
    isRegisterMode: PropTypes.bool,
    setIsRegisterMode: PropTypes.func
};

export default CatalogModal;