import {
    GNIDS_TABLE_POPOVER_TOGGLE,
    GNIDS_TABLE_POPOVER_TOGGLE_ALL,
    SET_CURRENT_SELECTED_CATALOG,
    SET_CURRENT_PROGRAMS
} from '../actions/ActionTypes';

export const GNIDS_TABLE_STORE_NAME = 'tablelistview';

/**
 * Initial State
 */
export const initialState = {
    popovers: {},
    currentPrograms: [],
    currentSelectedCatalog: null
};

/**
 * Reducer
 */
export const GNIDSTableReducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_CURRENT_PROGRAMS:
        return {
            ...state,
            currentPrograms: action.payload
        }
    case SET_CURRENT_SELECTED_CATALOG:
        return {
            ...state,
            currentSelectedCatalog: action.payload
        }
    case GNIDS_TABLE_POPOVER_TOGGLE:
        return {
            ...state,
            popovers: {
                ...state.popovers,
                [action.payload.gnID]: action.payload.data
            }
        };
    case GNIDS_TABLE_POPOVER_TOGGLE_ALL:
        return {
            ...state,
            popovers: Object.fromEntries(Object.keys(state.popovers).map(([key]) => [key, false]))
        };
    default:
        return state;
    }
};

/**
 * Selectors
 */

// Mobius
export const getGNIDTablePopoverByGnId = (gnID) =>
    (state) => state[GNIDS_TABLE_STORE_NAME].popovers[gnID] === undefined ? false : state[GNIDS_TABLE_STORE_NAME].popovers[gnID];
export const gnidsCurrentSelectedCatalog = (state) => state[GNIDS_TABLE_STORE_NAME]?.currentSelectedCatalog;
export const gnidsCurrentPrograms = (state) => state[GNIDS_TABLE_STORE_NAME]?.currentPrograms;

/**
 * Default export
 */
export default GNIDSTableReducer;