import React from 'react';
import GNInput from '../../common/gnInput/GNInput';
import { MOBIUS_PROGRAM_FIELDS } from '../../../constants/MobiusProgramValidation';
import './DurationFields.scss';
import isString from 'lodash.isstring';
import { MOBIUS_LABELS } from '../../../constants/Mobius';
import PropTypes from 'prop-types';

export const DurationFields = ({duration, disabled = false, errors, handleFormChange, isRequired, status, validateForm}) => {
    return (
        <div className='duration-fields-main-container'>
            <div className='duration-header'>{MOBIUS_LABELS.DURATION}</div>
            <div className='duration-inputs'>
                <GNInput
                    className='duration-hours'
                    disabled={disabled}
                    handleChange={(item) => handleFormChange(MOBIUS_PROGRAM_FIELDS.DURATION_HOURS, item)}
                    isRequired={isRequired}
                    isValueInvalid={errors?.duration === MOBIUS_LABELS.DURATION_INVALID_MESSAGE || errors?.duration === MOBIUS_LABELS.REQUIRED_ONCE_PUBLISHED || errors?.duration?.hours === MOBIUS_LABELS.DURATION_VALIDATION_ERROR || errors?.duration === MOBIUS_LABELS.DURATION_REQUIRED_WHEN_PRODUCTION_STATUS_COMPLETED}
                    label={MOBIUS_LABELS.DURATION_HOURS}
                    placeholder={MOBIUS_LABELS.DURATION_HOURS}
                    validateInput={() => isString(errors?.duration) ? validateForm(MOBIUS_PROGRAM_FIELDS.DURATION) : validateForm(MOBIUS_PROGRAM_FIELDS.DURATION_HOURS)}
                    validationText={ isString(errors?.duration) ? errors?.duration : errors?.duration?.hours }
                    value={duration?.hours}
                    hasValue={duration?.hours || duration?.minutes || duration?.seconds}
                    status={isString(status?.duration) && status?.duration} />
                <GNInput
                    className='duration-minutes'
                    disabled={disabled}
                    handleChange={(item) => handleFormChange(MOBIUS_PROGRAM_FIELDS.DURATION_MINUTES, item)}
                    isRequired={isRequired}
                    isValueInvalid={errors?.duration === MOBIUS_LABELS.DURATION_INVALID_MESSAGE || errors?.duration === MOBIUS_LABELS.REQUIRED_ONCE_PUBLISHED || errors?.duration?.minutes === MOBIUS_LABELS.DURATION_VALIDATION_ERROR || errors?.duration === MOBIUS_LABELS.DURATION_REQUIRED_WHEN_PRODUCTION_STATUS_COMPLETED}
                    label={MOBIUS_LABELS.DURATION_MINUTES}
                    placeholder={MOBIUS_LABELS.DURATION_MINUTES}
                    validateInput={() => isString(errors?.duration) ? false : validateForm(MOBIUS_PROGRAM_FIELDS.DURATION_MINUTES)}
                    validationText={errors?.duration?.minutes}
                    value={duration?.minutes}
                    hasValue={duration?.hours || duration?.minutes || duration?.seconds}
                    status={isString(status?.duration) && ' '} />
                <GNInput
                    className='duration-seconds'
                    disabled={disabled}
                    handleChange={(item) => handleFormChange(MOBIUS_PROGRAM_FIELDS.DURATION_SECONDS, item)}
                    isRequired={isRequired}
                    isValueInvalid={errors?.duration === MOBIUS_LABELS.DURATION_INVALID_MESSAGE || errors?.duration === MOBIUS_LABELS.REQUIRED_ONCE_PUBLISHED || errors?.duration?.seconds === MOBIUS_LABELS.DURATION_VALIDATION_ERROR || errors?.duration === MOBIUS_LABELS.DURATION_REQUIRED_WHEN_PRODUCTION_STATUS_COMPLETED}
                    label={MOBIUS_LABELS.DURATION_SECONDS}
                    placeholder={MOBIUS_LABELS.DURATION_SECONDS}
                    validateInput={() => isString(errors?.duration) ? false : validateForm(MOBIUS_PROGRAM_FIELDS.DURATION_SECONDS)}
                    validationText={errors?.duration?.seconds}
                    value={duration?.seconds}
                    hasValue={duration?.hours || duration?.minutes || duration?.seconds}
                    status={isString(status?.duration) && ' '} />
            </div>
        </div>
    )
};

DurationFields.propTypes = {
    duration: PropTypes.object,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    handleFormChange: PropTypes.func,
    isRequired: PropTypes.bool.isRequired,
    status: PropTypes.object,
    validateForm: PropTypes.func
};

export default DurationFields;