import React from 'react';
import './ImageModalViewOnlyField.scss';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ImageModalViewOnlyField({label, value, link, tooltip}) {
    return (
        <div className='modal-field'>
            { label && <div className='label'>
                {label}
                {tooltip &&
                    <OverlayTrigger overlay={<Tooltip>{tooltip}</Tooltip>} placement="right">
                        <FontAwesomeIcon icon='circle-info' />
                    </OverlayTrigger>
                }
            </div> }
            { link ?
                <a className='link-value' href={value} target="_blank" rel="noopener noreferrer">{value}</a>
                :
                <div className='value'>{value}</div>
            }
        </div>
    )
}

ImageModalViewOnlyField.defaultProps = {
    link: false,
    tooltip: null
}

ImageModalViewOnlyField.propTypes = {
    label: PropTypes.string,
    link: PropTypes.bool,
    tooltip: PropTypes.string,
    value: PropTypes.string
}

export default ImageModalViewOnlyField;