import { GNIDS_BULK_PUBLISH_FAILURE, GNIDS_BULK_PUBLISH_SUCCESS, GNIDS_BULK_PUBLISH_CLEAR_PROGRAMS, GNIDS_BULK_PUBLISH_SET_PROGRAMS } from "../actions/ActionTypes";


export const GNIDS_PROGRAMS_BULK_PUBLISH_NAME = 'programsBulkPublish';

/**
 * Initial State
 */
export const initialState = {
    programsToPublish: [],
    bulkPublishSuccess: false,
    bulkPublishFailure: false
};

/**
 * Reducer
 */
export const GNIDSProgramsBulkPublishReducer = (state = initialState, action) => {
    switch (action.type) {
    case GNIDS_BULK_PUBLISH_SET_PROGRAMS:
        return {
            ...state,
            programsToPublish: action.payload
        };
    case GNIDS_BULK_PUBLISH_CLEAR_PROGRAMS:
        return {...initialState};
    case GNIDS_BULK_PUBLISH_SUCCESS:
        return {
            ...state,
            bulkPublishSuccess: true
        }
    case GNIDS_BULK_PUBLISH_FAILURE:
        return {
            ...state,
            bulkPublishFailure: true
        }
    default:
        return state;
    }
};

/**
 * Selectors
 */

export const gnidsProgramsSelectedForPublish = state => state[GNIDS_PROGRAMS_BULK_PUBLISH_NAME]?.programsToPublish;


/**
 * Default export
 */
export default GNIDSProgramsBulkPublishReducer;