/* eslint react/prop-types: 0 */
/* eslint react/display-name: 0 */
import React from 'react';
import moment from 'moment-timezone';
import { ROUTES } from '../config/Routes';
import MobiusStatusIcon from '../components/mobius/mobiusStatusIcon/MobiusStatusIcon';
import {
    MOBIUS_IMAGES_ASPECT_RATIO_LABELS,
    MOBIUS_PROGRAM_SUBTYPES_LABELS,
    MOBIUS_PROGRAM_TYPES_VALUES
} from './Mobius';
import GNViewButton from '../components/common/gnviewButton/GNViewButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const PROGRAM_LIST_VIEW_COLUMN_HEADERS = [
    {
        id: 'checkBox',
        accessor: 'checkBox',
        disableSortBy: true
    },
    {
        id: 'publishStatus',
        accessor: 'publishStatus',
        disableSortBy: true,
        Header: 'Status',
        Cell: ({ row }) =>
            row?.original?.publishStatus ? (
                <MobiusStatusIcon status={row.original.publishStatus} circle={true} />
            ) : null
    },
    {
        id: 'title',
        accessor: 'title',
        Header: 'Title'
    },
    {
        id: 'subType',
        accessor: 'subType',
        Header: 'Subtype'
    },
    {
        id: 'versionLabel',
        accessor: 'versionLabel',
        Header: 'Version Label',
        disableSortBy: true,
        Cell: ({ row }) => row?.original?.versionLabels?.join(', ') || null
    },
    {
        id: 'presentationLabel',
        accessor: 'presentationLabels',
        Header: 'Presentation Label',
        disableSortBy: true,
        Cell: ({ row }) =>
            Array.isArray(row?.original?.presentationLabels)
                ? row?.original?.presentationLabels?.join(', ') || ''
                : row?.original?.presentationLabels || ''
    },
    {
        id: 'genre',
        accessor: 'genre',
        Header: 'Genres',
        disableSortBy: true,
        Cell: ({ row }) => row?.original?.genres?.join(', ') || null
    },
    {
        id: 'releaseDate',
        accessor: 'releaseDate',
        Header: 'Release Date',
        Cell: ({ row }) =>
            row?.original?.releaseDate
                ? moment(row.original.releaseDate).format('MMM D, YYYY')
                : null
    },
    {
        id: 'externalId',
        accessor: 'externalId',
        Header: 'Primary External ID',
        disableSortBy: true,
        Cell: ({ row }) =>
            row?.original?.externalIDs?.map((id, index) =>
                id?.isPrimary ? (
                    <div className='external-ids' key={index}>
                        {id.id}
                    </div>
                ) : null
            ) || null
    },
    {
        id: 'tms_id',
        accessor: 'tms_id',
        Header: 'GNView Page',
        disableSortBy: true,
        Cell: ({ row }) => {
            if (row?.original?.tmsID) {
                const url = `${ROUTES.PROGRAM_DETAILS}/${row.original.tmsID}`;
                return (
                    <span>
                        <GNViewButton link={url} />
                    </span>
                );
            }
            return null;
        }
    },
    {
        id: 'updated',
        accessor: 'latestUpdate',
        Header: 'Last Modified',
        Cell: ({ value }) => moment(value).format('MMM D, YYYY') || null
    },
    {
        id: 'TablePopover',
        Header: '',
        disableSortBy: true
    },
    {
        id: 'expander',
        Header: '',
        disableSortBy: true,
        Cell: ({ row }) =>
            row?.original?.subType === 'series' ? (
                <span
                    {...row.getToggleRowExpandedProps()}
                    className='expand-collapse-icon-container'
                >
                    {row.isExpanded ? (
                        <FontAwesomeIcon icon="angle-up" className="expand-collapse-icon" />
                    ) : (
                        <FontAwesomeIcon icon="angle-down" className="expand-collapse-icon" />
                    )}
                </span>
            ) : null
    }
];

export const PROGRAM_LIST_VIEW_EPISODE_COLUMN_HEADERS = [
    {
        disableSortBy: true,
        id: 'publishingStatus',
        accessor: 'publishingStatus',
        Header: 'Status',
        Cell: ({ row }) =>
            row?.original?.publishingStatus ? (
                <MobiusStatusIcon status={row.original.publishingStatus} circle={true} />
            ) : null
    },
    {
        disableSortBy: true,
        accessor: 'episodeNumber',
        Header: 'Episode #'
    },
    { disableSortBy: true, id: 'title', accessor: 'title', Header: 'Episode Title' },
    {
        disableSortBy: true,
        id: 'description',
        accessor: 'descriptions',
        Header: 'Description',
        Cell: ({ row }) => {
            return row?.original?.descriptions?.[0]?.value || '';
        }
    },
    {
        disableSortBy: true,
        id: 'releaseDate',
        accessor: 'releaseDate',
        Header: 'Release Date',
        Cell: ({ row }) =>
            row?.values?.releaseDate ? moment(row.values.releaseDate).format('MMM D, YYYY') : null
    },
    {
        disableSortBy: true,
        id: 'tms_id',
        accessor: 'tms_id',
        Header: 'GNView Page',
        Cell: ({ row }) => {
            if (row?.original?.tmsID) {
                const url = `${ROUTES.PROGRAM_DETAILS}/${row.original.tmsID}`;
                return (
                    <span>
                        <GNViewButton link={url} />
                    </span>
                );
            }
            return null;
        }
    },
    {
        disableSortBy: true,
        id: 'last_modified_date',
        accessor: 'last_modified_date',
        Header: 'Last Modified Date',
        Cell: ({ row }) => {
            if (row?.original?.latestUpdate) {
                return moment(row.original.latestUpdate).format('MMM D, YYYY');
            } else {
                return null;
            }
        }
    },
    {
        disableSortBy: true,
        id: 'TablePopover',
        Header: ''
    }
];

export const PROGRAM_VIEW_MODAL_EPISODE_COLUMN_HEADERS = [
    {
        disableSortBy: true,
        accessor: 'episodeNumber',
        Header: 'Episode #'
    },
    { disableSortBy: true, id: 'title', accessor: 'title', Header: 'Episode Title' }
];

export const ERROR_MODAL_PROGRAM_COLUMNS = [
    {
        disableSortBy: true,
        id: 'errorTitle',
        accessor: 'programTitle',
        Header: 'Program Title'
    },
    {
        disableSortBy: true,
        accessor: 'presentationGnID',
        Header: 'ID'
    }
]

export const ERROR_MODAL_CATALOG_COLUMNS = [
    {
        disableSortBy: true,
        id: 'errorTitle',
        accessor: 'catalogTitle',
        Header: 'Catalog Title'
    },
    {
        disableSortBy: true,
        accessor: 'catalogGnID',
        Header: 'ID'
    }
]

export const PROGRAM_LIST_VIEW_TABLE_INITIAL_SETTINGS = {
    pageSize: 20,
    sortBy: [
        {
            id: 'updated',
            desc: true
        }
    ]
};

export const LIST_VIEW_PAGE_LAST_UPDATED = 'Page Last Updated';

export const MOBIUS_PROGRAM_FILTER_TYPES = {
    SUBTYPE: 'subtype',
    STATUS: 'status'
};

export const MOBIUS_IMAGES_FILTER_TYPES = {
    ASPECT_RATIO: 'aspectRatio',
    CATEGORY: 'category'
};

export const PROGRAM_LIST_VIEW_FILTERS = [
    {
        category: MOBIUS_PROGRAM_FILTER_TYPES.SUBTYPE,
        type: MOBIUS_PROGRAM_TYPES_VALUES.MOVIE,
        subtype: MOBIUS_PROGRAM_SUBTYPES_LABELS.FEATURE_FILM
    },
    {
        category: MOBIUS_PROGRAM_FILTER_TYPES.SUBTYPE,
        type: MOBIUS_PROGRAM_TYPES_VALUES.MOVIE,
        subtype: MOBIUS_PROGRAM_SUBTYPES_LABELS.SHORT_FILM
    },
    {
        category: MOBIUS_PROGRAM_FILTER_TYPES.SUBTYPE,
        type: MOBIUS_PROGRAM_TYPES_VALUES.MOVIE,
        subtype: MOBIUS_PROGRAM_SUBTYPES_LABELS.TRAILER
    },
    {
        category: MOBIUS_PROGRAM_FILTER_TYPES.SUBTYPE,
        type: MOBIUS_PROGRAM_TYPES_VALUES.MOVIE,
        subtype: MOBIUS_PROGRAM_SUBTYPES_LABELS.TV_MOVIE
    },
    {
        category: MOBIUS_PROGRAM_FILTER_TYPES.SUBTYPE,
        type: MOBIUS_PROGRAM_TYPES_VALUES.SHOW,
        subtype: MOBIUS_PROGRAM_SUBTYPES_LABELS.SERIES
    },
    {
        category: MOBIUS_PROGRAM_FILTER_TYPES.SUBTYPE,
        type: MOBIUS_PROGRAM_TYPES_VALUES.SHOW,
        subtype: MOBIUS_PROGRAM_SUBTYPES_LABELS.COMPILATION
    },
    {
        category: MOBIUS_PROGRAM_FILTER_TYPES.SUBTYPE,
        type: MOBIUS_PROGRAM_TYPES_VALUES.SHOW,
        subtype: MOBIUS_PROGRAM_SUBTYPES_LABELS.DERIVATIVE
    },
    {
        category: MOBIUS_PROGRAM_FILTER_TYPES.SUBTYPE,
        type: MOBIUS_PROGRAM_TYPES_VALUES.SHOW,
        subtype: MOBIUS_PROGRAM_SUBTYPES_LABELS.HIGHLIGHTS
    },
    {
        category: MOBIUS_PROGRAM_FILTER_TYPES.SUBTYPE,
        type: MOBIUS_PROGRAM_TYPES_VALUES.SHOW,
        subtype: MOBIUS_PROGRAM_SUBTYPES_LABELS.MUSIC_VIDEO
    },
    {
        category: MOBIUS_PROGRAM_FILTER_TYPES.SUBTYPE,
        type: MOBIUS_PROGRAM_TYPES_VALUES.SHOW,
        subtype: MOBIUS_PROGRAM_SUBTYPES_LABELS.PAID_PROGRAM
    },
    {
        category: MOBIUS_PROGRAM_FILTER_TYPES.SUBTYPE,
        type: MOBIUS_PROGRAM_TYPES_VALUES.SHOW,
        subtype: MOBIUS_PROGRAM_SUBTYPES_LABELS.MINISERIES
    },
    {
        category: MOBIUS_PROGRAM_FILTER_TYPES.SUBTYPE,
        type: MOBIUS_PROGRAM_TYPES_VALUES.SHOW,
        subtype: MOBIUS_PROGRAM_SUBTYPES_LABELS.SPECIAL
    }
];

export const generateImagesListViewFilters = (imageCatagories = []) => [
    ...Object.keys(MOBIUS_IMAGES_ASPECT_RATIO_LABELS).map(key => ({
        aspectRatio: MOBIUS_IMAGES_ASPECT_RATIO_LABELS[key]
    })),
    ...imageCatagories.map(category => ({
        category
    }))
]

export const IMAGE_COLUMN_HEADERS = [
    {
        id: 'title',
        accessor: 'title',
        Header: 'Title'
    },
    {
        id: 'imageType',
        accessor: 'imageType',
        disableSortBy: true,
        Header: 'Image Type'
    },
    {
        id: 'tableAspectRatio',
        accessor: 'tableAspectRatio',
        disableSortBy: true,
        Header: 'Aspect Ratio'
    },
    {
        id: 'tableDimensions',
        accessor: 'tableDimensions',
        disableSortBy: true,
        Header: 'Dimensions'
    },
    {
        id: 'market',
        accessor: 'market',
        disableSortBy: true,
        Header: 'Market'
    },
    {
        id: 'language',
        accessor: 'language',
        disableSortBy: true,
        Header: 'Language'
    },
    {
        id: 'gnID',
        accessor: 'gnID',
        Header: 'GNID'
    },
    {
        id: 'updated',
        accessor: 'updated',
        Header: 'Last Modified'
    },
    {
        id: 'TablePopover',
        Header: '',
        disableSortBy: true
    }
]
