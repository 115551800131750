export function convertExternalIDs(externalIDs) {
    for (let i = 0; i < externalIDs.length; i++) {
        if (Object.prototype.hasOwnProperty.call(externalIDs[i], "isPrimary")) {
            externalIDs[i].isPrimary = JSON.parse(externalIDs[i].isPrimary.toLowerCase())
        }
        if (Object.prototype.hasOwnProperty.call(externalIDs[i], "isProvider")) {
            externalIDs[i].isProvider = JSON.parse(externalIDs[i].isProvider.toLowerCase())
        }
    }
    return externalIDs;
}

export function convertCast(cast) {
    for (let i = 0; i < cast.length; i++) {
        if (Object.prototype.hasOwnProperty.call(cast[i], "order")) {
            cast[i].order = parseInt(cast[i].order)
        }
    }
    return cast;
}

export function convertCrew(crew) {
    for (let i = 0; i < crew.length; i++) {
        if (Object.prototype.hasOwnProperty.call(crew[i], "order")) {
            crew[i].order = parseInt(crew[i].order)
        }
    }
    return crew;
}

export function convertDescriptions(descriptions) {
    for (let i = 0; i < descriptions.length; i++) {
        if (Object.prototype.hasOwnProperty.call(descriptions[i], "length")) {
            descriptions[i].length = parseInt(descriptions[i].length)
        }
    }
    return descriptions;
}

export function convertRatings (ratings) {
    for (let i = 0; i < ratings.length; i++) {
        if (Object.prototype.hasOwnProperty.call(ratings[i], 'notYetRated')) {
            ratings[i].notYetRated = JSON.parse(ratings[i].notYetRated.toLowerCase())
        }
        if (Object.prototype.hasOwnProperty.call(ratings[i], 'ratingExempt')) {
            ratings[i].ratingExempt = JSON.parse(ratings[i].ratingExempt.toLowerCase())
        }
    }
    return ratings;
}