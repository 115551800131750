import {
    GNIDS_TOGGLE_SEASON_MODAL,
    GNIDS_SET_SEASON_MODAL_TITLE,
    GNIDS_SET_SEASON,
    GNIDS_CLEAR_SEASON,
    GNIDS_SET_SEASON_GNID,
    GNIDS_SET_SEASON_NUMBER
} from './ActionTypes';
import { API_ENDPOINTS } from '../config/Api';
import apiService from '../services/ApiService';
import { convertSeasonForPost } from '../utils/SeasonUtils';

export const gnidsSeasonModalToggle = (toggle, showPresentationGnID) => ({
    type: GNIDS_TOGGLE_SEASON_MODAL,
    payload: {
        toggle,
        showPresentationGnID
    }
});

export const gnidsSetSeasonModalTitle = (title) => ({
    type: GNIDS_SET_SEASON_MODAL_TITLE,
    payload: {title}
});

export const gnidsSetSeasonGNID = (seasonGnID) => ({
    type: GNIDS_SET_SEASON_GNID,
    payload: {seasonGnID}
});

export const gnidsSetSeasonNumber = (seasonNumber) => ({
    type: GNIDS_SET_SEASON_NUMBER,
    payload: {seasonNumber}
})

export const gnidsSetSeason = (season) => ({
    type: GNIDS_SET_SEASON,
    payload: {season}
});

export const gnidsClearSeason = () => ({
    type: GNIDS_CLEAR_SEASON
});

export const gnidsRegisterNewSeason = (sourceId, showPresentationGnID, season) => {
    return () => {
        if (!sourceId?.length || !sourceId) {
            return new Promise((reject) => {
                reject('No sourceId provided');
            });
        }
        const headers = { SourceID: sourceId?.[0] };
        const convertedSeason = convertSeasonForPost(season);
        return apiService.makeApiCall(
            `${API_ENDPOINTS.GET_GNIDS_SHOWS}/versions/presentations/${showPresentationGnID}/seasons`,
            convertedSeason,
            'post',
            false,
            headers
        );
    };
};