import React from 'react';
import './DescriptionSection.scss';
import PropTypes from 'prop-types';
import { MOBIUS_VALUES } from '../../../constants/Mobius';
import { IMAGE_MODAL_SECTIONS, IMAGE_LABELS } from '../../../constants/Image';
import ImageModalViewOnlyField from '../imageModalViewOnlyField/ImageModalViewOnlyField';
import { convertLanguageFromCode } from '../../../utils/GeneralUtils';

function DescriptionSection({imageData}) {
    return (
        <div className='image-description-section'>
            <div className='view-section-title'>
                {IMAGE_MODAL_SECTIONS.DESCRIPTION}
            </div>
            <div className='view-section-body'>
                <div className='view-section-row'>
                    <ImageModalViewOnlyField label={IMAGE_LABELS.DESCRIPTION_LANGUAGE} value= {imageData?.description?.language ? convertLanguageFromCode(imageData?.description?.language) : MOBIUS_VALUES.EMPTY_DATA}/>
                </div>
                <div className='view-section-row'>
                    <ImageModalViewOnlyField label={IMAGE_LABELS.DESCRIPTION} value= {imageData?.description?.value ? imageData?.description?.value : MOBIUS_VALUES.EMPTY_DATA}/>
                </div>
            </div>
        </div>

    )
}

DescriptionSection.propTypes = {
    imageData: PropTypes.object
}

export default DescriptionSection;