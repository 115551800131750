import React, {useState, useCallback, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import GNServerTable from '../components/common/gnServerTable/GNServerTable'
import {
    IMAGE_COLUMN_HEADERS,
    LIST_VIEW_PAGE_LAST_UPDATED,
    generateImagesListViewFilters
} from '../constants/MobiusListView';
import { gvauthHasMobiusWriteAccess, gvauthSelMobiusSourceID } from '../reducers/GNVAuthReducer';
import {mobiusRegisterAccessForImage, mobiusVocabSelImageCategories} from '../reducers/MobiusVocabReducer';
import GNButton from '../components/common/gnButton/GNButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MOBIUS_BUTTON_TEXT, MOBIUS_LABELS, MOBIUS_MOMENT_DATE_FORMAT, MOBIUS_PROGRAM_TYPES_VALUES, MOBIUS_TOOLTIPS } from '../constants/Mobius';
import { Tooltip, OverlayTrigger} from 'react-bootstrap';
import ViewSwitcher from '../components/common/viewSwitcher/ViewSwitcher';
import './ImagesListView.scss'
import moment from 'moment-timezone';
import { ImageModal } from './imageModal/ImageModal';
import './ImagesListView.scss'
import ErrorBoundary from '../components/common/errorBoundary/ErrorBoundary';
import TablePopover from '../components/common/popover/TablePopover';
import GNNoDataMessage from '../components/common/gnNoDataMessage/GNNoDataMessage';
import { mobiusGetAllImages } from '../actions/MobiusActions';
import { setGnidsImage } from '../actions/GNIDSImageModalActions';
import GNBadge from '../components/common/gnBadge/GNBadge';
import GNNotification from '../components/common/gnNotification/GNNotification';


function ImagesListView() {
    const dispatch = useDispatch()
    const hasAppWriteAccess = useSelector(gvauthHasMobiusWriteAccess);
    const vocabAllImageCategories = useSelector(mobiusVocabSelImageCategories)
    const hasImageWriteAccess = useSelector(mobiusRegisterAccessForImage);
    const sourceId = useSelector(gvauthSelMobiusSourceID);
    const [pageLastUpdated, setPageLastUpdated] = useState(moment());
    const [modalShow, setModalShow] = useState(false);
    const [isRegisterMode, setIsRegisterMode] = useState(false)
    const [shouldRefreshTable, setShouldRefreshTable] = useState(false)
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState(null);
    const [notificationIsSuccess, setNotificationIsSuccess] = useState(false);
    const [filters, setFilters] = useState(generateImagesListViewFilters(vocabAllImageCategories) || [])

    const hasWriteAccess = hasAppWriteAccess && hasImageWriteAccess;

    useEffect(() => {
        setPageLastUpdated(moment())
    }, [])

    const fetchData = useCallback(
        (pageIndex, sortBy) => {
            setPageLastUpdated(moment());
            return mobiusGetAllImages(pageIndex, sourceId, filters, sortBy)
        },
        [sourceId, filters]
    );

    const handleImageModal = (program) => {
        dispatch(setGnidsImage(program))
        setModalShow(!modalShow)
    }

    const handleModal = (resetTable) => {
        setShouldRefreshTable(resetTable)
    }

    useEffect(() => {
        if (shouldRefreshTable) {
            setShouldRefreshTable(false)
        }
    }, [shouldRefreshTable])

    const handleNotification = (show, message, isSuccess) => {
        setShowNotification(show);
        setNotificationMsg(message);
        setNotificationIsSuccess(isSuccess);
    };

    const imagesColumns = [...IMAGE_COLUMN_HEADERS];
    const titleIndex = imagesColumns.findIndex((col) => col.id === 'title');
    if (titleIndex !== -1) {
        imagesColumns[titleIndex] = {
            ...imagesColumns[titleIndex],
            /* eslint-disable react/display-name */
            /* eslint-disable react/prop-types */
            // Calling event.stopPropagation() to prevent the tr onClick from handling a row expansion since in this case, we only want the modal to open
            Cell: ({ row }) => (
                <OverlayTrigger
                    overlay={<Tooltip id="program-title-tooltip">{row?.original?.title}</Tooltip>}
                    trigger={(row?.original?.title.length > 36) ? ['hover'] : null}
                >
                    <span
                        className='image-title'
                        onClick={(event) => {
                            event.stopPropagation();
                            setIsRegisterMode(false);
                            handleImageModal(row?.original)
                        }}
                    >
                        {row?.original?.title && row.original.title}
                    </span>
                </OverlayTrigger>
            )
            /* eslint-enable react/display-name */
            /* eslint-enable react/prop-types */
        }
    }
    const tablePopoverIndex = imagesColumns.findIndex((col) => col.id === 'TablePopover');
    if (tablePopoverIndex !== -1) {
        imagesColumns[tablePopoverIndex] = {
            ...imagesColumns[tablePopoverIndex],
            /* eslint-disable react/display-name */
            /* eslint-disable react/prop-types */
            Cell: ({ row }) => {
                return hasWriteAccess ? (
                    <TablePopover
                        program={{...row?.original, type: 'image'}}
                        handleModal={handleModal}
                        gnID={row?.original?.gnID}
                        category={MOBIUS_PROGRAM_TYPES_VALUES.IMAGE}
                        handleNotification={handleNotification}
                        showRegisterOptions={false}
                    />
                ) : null;
            }
            /* eslint-enable react/display-name */
            /* eslint-enable react/prop-types */
        };
    }
    const showClearAllFilters = () => {
        return filters?.filter((f) => f.selected).length > 0;
    };

    const clearAllFilters = () => {
        const filtersCleared = [...filters].map((f) => ({ ...f, selected: false }));
        setFilters(filtersCleared);
    };

    const handleFilterClick = (filter) => {
        const filterIndex = filter.aspectRatio ? filters?.findIndex(f => (f.aspectRatio === filter.aspectRatio)) : filters?.findIndex(f => (f.category === filter.category))
        if (filterIndex !== -1) {
            setShouldRefreshTable(true);
            const filtersCopy = [...filters];
            filtersCopy[filterIndex].selected = !filtersCopy[filterIndex].selected;
            setFilters(filtersCopy);
        }
    };

    const handleRegisterNewImageClick = () => {
        setIsRegisterMode(true);
        setModalShow(true);
    }

    return (
        <ErrorBoundary>
            <div className='images-main-container'>
                { modalShow &&
                    <div className='image-modal'>
                        <ImageModal setModalShow={setModalShow} shouldRefreshTable={shouldRefreshTable} setShouldRefreshTable={setShouldRefreshTable} isRegisterMode={isRegisterMode} setIsRegisterMode={setIsRegisterMode} handleNotification={handleNotification} />
                    </div>
                }
                <div className='image-top-section'>
                    <span className='gnids-last-updated'>
                        {LIST_VIEW_PAGE_LAST_UPDATED}:{' '}
                        {pageLastUpdated.format(MOBIUS_MOMENT_DATE_FORMAT)}
                    </span>
                    <div className='images-header-container'>
                        <ViewSwitcher value='Images'/>
                        <GNButton
                            className='register-image-button'
                            tooltip={<Tooltip id="register-new-image-tooltip">{MOBIUS_TOOLTIPS.REQUIRES_WRITE_ACCESS}</Tooltip>}
                            trigger={!hasWriteAccess ? ['hover'] : null}
                            disabled={!hasWriteAccess}
                            buttonClass='register-button'
                            hasWriteAccess={hasWriteAccess}
                            onClick={handleRegisterNewImageClick}
                        >
                            <FontAwesomeIcon icon='plus' />
                            {MOBIUS_BUTTON_TEXT.REGISTER_NEW_IMAGE}
                        </GNButton>
                    </div>
                    <div className='gnid-images-filters'>
                        <span className='filters-label'>Filters:</span>
                        <div>
                            {filters?.map((filter, idx) => {
                                return (
                                    <GNBadge
                                        viewSelector='images'
                                        type={filter.selected ? (filter.aspectRatio || filter.category) : null}
                                        value={filter.aspectRatio || filter.category}
                                        onClick={() => handleFilterClick(filter)}
                                        key={idx}
                                    />
                                );
                            })}
                            {showClearAllFilters() && (
                                <span className='clear-all-filters' onClick={clearAllFilters}>
                                    {MOBIUS_BUTTON_TEXT.CLEAR_ALL}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className='gnid-distribution-bottom-section'>
                    <ErrorBoundary>
                        {!shouldRefreshTable && <GNServerTable
                            className='catalog-table'
                            columns={imagesColumns}
                            fetchData={fetchData}
                            noDataMessage={
                                <GNNoDataMessage programType={MOBIUS_LABELS.IMAGES} hasWriteAccess={hasWriteAccess}/>
                            }
                        />}
                    </ErrorBoundary>
                </div>
            </div>
            {showNotification && <GNNotification handleShow={setShowNotification} message={notificationMsg} milliseconds={5000} show={showNotification} success={notificationIsSuccess} />}
        </ErrorBoundary>
    )
}

export default ImagesListView