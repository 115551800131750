
import { array, object, string } from "yup";

export const MOBIUS_CATALOG_TITLE_MAX = 120;

export const MOBIUS_CATALOG_INITIAL_TOUCHED = {
    name: false,
    languages: false,
    markets: false,
    tags: false
};

export const MOBIUS_CATALOG_INITIAL_VALUES = {
    name: '',
    languages: [],
    markets: [],
    tags: []
};


export const MOBIUS_CATALOG_FIELDS = {
    NAME: 'name',
    LANGUAGES: 'languages',
    MARKETS: 'markets',
    TAGS: 'tags'
}

export const MOBIUS_CATALOG_VALIDATION_SCHEMA = object().shape({
    name: string().max(MOBIUS_CATALOG_TITLE_MAX)
        .required(),
    languages: array()
        .of(string())
        .nullable(),
    markets: array()
        .of(string())
        .nullable(),
    tags: array()
        .of(string())
        .nullable()
});

export const getCatalogValidationSchema = () => {
    return MOBIUS_CATALOG_VALIDATION_SCHEMA;
}