import { API_ENDPOINTS } from '../config/Api';
import apiService from '../services/ApiService';
import { convertCatalogForPost } from '../utils/CatalogUtils';
import { SET_SELECTED_CATALOG_FROM_DROPDOWN, SET_GNIDS_CATALOG, CLEAR_GNIDS_CATALOG_MODAL_DATA, SHOW_CATALOG_MODAL } from './ActionTypes';

export const setGnidsCatalog = (catalogData) => ({
    type: SET_GNIDS_CATALOG,
    payload: catalogData
});

export const showCatalogModal = (show) => ({
    type: SHOW_CATALOG_MODAL,
    payload: show
});

export const clearCatalogModalData = () => ({
    type: CLEAR_GNIDS_CATALOG_MODAL_DATA
});

// This is setting the catalogGNID for the Catalog Dropdown in GNIDDistribution
export const setSelectedCatalogFromDropdown = (catalogGNID) => ({
    type: SET_SELECTED_CATALOG_FROM_DROPDOWN,
    payload: catalogGNID
})

export const saveOrRegisterCatalog = (catalog, isRegister) => {
    return async () => {
        const {gnID: catalogGnId} = catalog
        const convertedCatalog = convertCatalogForPost(catalog);
        const endpoint = API_ENDPOINTS.GET_MOBIUS_CATALOGS;
        return apiService.makeApiCall(
            isRegister ? endpoint : `${endpoint}/${catalogGnId}`,
            convertedCatalog,
            isRegister ? 'post' : 'put',
            false
        );
    };
};
