import React, { useEffect, useState } from 'react';
import { Button, Form, Tooltip } from 'react-bootstrap';
import { Formik } from 'formik';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
    MOBIUS_BUTTON_TEXT,
    MOBIUS_NOTIFICATIONS,
    MOBIUS_TOOLTIPS
} from '../../constants/Mobius';
import GNButton from '../../components/common/gnButton/GNButton';
import { getImageValidationSchema, MOBIUS_IMAGE_INITIAL_TOUCHED } from '../../constants/MobiusImageValidation';
import { verifyImageDataIntegrity } from '../../utils/ImageUtils';
import { convertLanguageFromCode } from '../../utils/GeneralUtils';
import { mobiusVocabSelCountries, mobiusVocabSelEntitledLanguages, mobiusVocabSelImageCategories } from '../../reducers/MobiusVocabReducer';
import { mobiusGetGNVocabulary } from '../../actions/MobiusActions';
import { gvauthHasMobiusWriteAccess, gvauthSelMobiusSourceID } from '../../reducers/GNVAuthReducer';
import ImageModalEditOverview from '../../components/images/ImageModalEditOverview';
import ImageModalEditDescription from '../../components/images/ImageModalEditDescription';
import ImageModalEditAdditionalDetails from '../../components/images/ImageModalEditAdditionalDetails';
import ImageModalEditCustomAttributes from '../../components/images/ImageModalEditCustomAttributes';
import { saveImage, setGnidsImage } from '../../actions/GNIDSImageModalActions';
import { gnviewSendLogMessage } from '../../services/GeneralService';
import GNNotification from '../../components/common/gnNotification/GNNotification';

export const ImageModalEdit = ({
    setIsLoading,
    setIsDirty,
    handleCancel,
    handleRegisterSuccess,
    image,
    isRegisterMode
}) => {
    const vocabAllImageCategories = useSelector(mobiusVocabSelImageCategories)
    const sourceId = useSelector(gvauthSelMobiusSourceID);
    const mobiusEntitledLanguages = useSelector(mobiusVocabSelEntitledLanguages);
    const vocabAllCountries = useSelector(mobiusVocabSelCountries);
    const hasWriteAccess = useSelector(gvauthHasMobiusWriteAccess);

    const [saveInProgress, setSaveInProgress] = useState(false)
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState(null);
    const [notificationIsSuccess, setNotificationIsSuccess] = useState(false);
    const [refreshTable, setRefreshTable] = useState(false);


    const vocablAllCountriesLabels = vocabAllCountries?.map((country) => country.label) || [];

    const languages = mobiusEntitledLanguages.map((langCode) => ({
        name: convertLanguageFromCode(langCode),
        value: langCode
    }));

    const dispatch = useDispatch()

    useEffect(() => {
        if (!vocabAllImageCategories.length) {
            setIsLoading(true);
            dispatch(mobiusGetGNVocabulary(sourceId)).then(() => {
                setIsLoading(false);
            });
        }
    }, [vocabAllImageCategories.length, dispatch, sourceId, setIsLoading])

    const canSaveImage = (isValid, isDirty, gnID) => {
        return (gnID || isRegisterMode) && isDirty && isValid
    }

    const handleNotification = (show, message, isSuccess) => {
        setShowNotification(show);
        setNotificationMsg(message);
        setNotificationIsSuccess(isSuccess);
    }

    const onSaveRegisterButtonClick = (values, dirty, isValid, resetForm) => {
        // Existing Image - Save
        if (canSaveImage(isValid, dirty, image.gnID)) {
            setSaveInProgress(true);
            dispatch(saveImage(sourceId, {...values, gnID: image.gnID}, isRegisterMode)).then((saveImgResp) => {
                handleNotification(true, MOBIUS_NOTIFICATIONS.IMAGE_SUCCESS_SAVED, true);
                setIsDirty(false);
                resetForm({values});
                dispatch(setGnidsImage(saveImgResp?.result?.data))
                setRefreshTable(true)
                if (isRegisterMode) {
                    handleRegisterSuccess()
                }
            }).catch((error) => {
                dispatch(gnviewSendLogMessage(`mobiusSaveImage error: ${error.message}`, error, image.gnID));
                handleNotification(true, MOBIUS_NOTIFICATIONS.IMAGE_ERROR_SAVED, false);
            }).finally(() => {
                setSaveInProgress(false);
            })
        }
    }

    return (
        <div className="program-modal-edit-container">
            <Formik
                initialValues={verifyImageDataIntegrity(image)}
                initialTouched={MOBIUS_IMAGE_INITIAL_TOUCHED}
                validationSchema={getImageValidationSchema()}
            >
                {({
                    dirty,
                    errors,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    resetForm,
                    setFieldTouched,
                    setFieldValue,
                    touched,
                    values
                }) => {
                    // Return errors only if we are submitting the form or the field has been touched
                    const validateForm = (formName) => {
                        return (isSubmitting || get(touched, formName)) ? !get(errors, formName) : true;
                    }

                    const handleFormChange = (formName, value) => {
                        if (!touched[formName]) {
                            setFieldTouched(formName, true);
                        }
                        setFieldValue(formName, value);
                    };

                    return (
                        <Form noValidate onSubmit={handleSubmit}>
                            <div className='edit-mode-container'>
                                <ImageModalEditOverview handleFormChange={handleFormChange} values={values} errors={errors} validateForm={validateForm} vocabAllImageCategories={vocabAllImageCategories} />
                                <div className="section-divider"/>
                                <ImageModalEditDescription handleFormChange={handleFormChange} values={values} validateForm={validateForm} allLanguages={languages} />
                                <div className="section-divider"/>
                                <ImageModalEditAdditionalDetails handleFormChange={handleFormChange} values={values} validateForm={validateForm} allLanguages={languages} countries={vocablAllCountriesLabels} />
                                <div className="section-divider"/>
                                <ImageModalEditCustomAttributes handleFormChange={handleFormChange} values={values} validateForm={validateForm} hasWriteAccess={hasWriteAccess} errors = {errors} />
                            </div>
                            <div className='bottom-section'>
                                <div className='button-container'>
                                    <Button
                                        className='cancel-button'
                                        variant='light'
                                        onClick={() =>
                                            handleCancel(dirty, refreshTable)
                                        }
                                    >
                                        {MOBIUS_BUTTON_TEXT.CANCEL}
                                    </Button>
                                    <GNButton
                                        buttonClass={ClassNames('register-button')}
                                        tooltip={<Tooltip>{MOBIUS_TOOLTIPS.MUST_UPDATE_FIELD}</Tooltip>}
                                        tooltipPlacement='top'
                                        type='submit'
                                        disabled={!isRegisterMode && (!dirty || !isValid || saveInProgress)}
                                        variant='primary'
                                        onClick={() => onSaveRegisterButtonClick(values, dirty, isValid, resetForm)}>
                                        {isRegisterMode ? MOBIUS_BUTTON_TEXT.REGISTER : MOBIUS_BUTTON_TEXT.SAVE}
                                    </GNButton>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
            {showNotification && <GNNotification handleShow={setShowNotification} message={notificationMsg} milliseconds={5000} show={showNotification} success={notificationIsSuccess} />}
        </div>
    )
};

ImageModalEdit.propTypes = {
    setIsLoading: PropTypes.func,
    setIsDirty: PropTypes.func,
    image: PropTypes.object,
    isRegisterMode: PropTypes.bool,
    handleCancel: PropTypes.func,
    handleRegisterSuccess: PropTypes.func
};

export default ImageModalEdit;