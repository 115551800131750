export const CATALOGS_COLUMN_HEADERS = [
    {
        id: 'name',
        accessor: 'name',
        Header: 'Name',
        disableSortBy: true
    },
    {
        id: 'tags',
        accessor: 'tag',
        Header: 'Tags',
        disableSortBy: true
    },
    {
        id: 'markets',
        accessor: 'market',
        Header: 'Market',
        disableSortBy: true
    },
    {
        id: 'languages',
        accessor: 'language',
        Header: 'Languages',
        disableSortBy: true
    },
    {
        id: 'gnid',
        accessor: 'gnID',
        Header: 'Catalog ID',
        disableSortBy: true
    },
    {
        id: 'programs',
        accessor: 'presentationCount',
        Header: 'Programs',
        disableSortBy: true
    },
    {
        id: 'lastModified',
        accessor: 'lastModified',
        Header: 'Last Modified',
        disableSortBy: true
    },
    {
        id: 'TablePopover',
        Header: '',
        disableSortBy: true
    }
]

// Labels for Catalog Modal
export const CATALOG_LABELS = {
    CATALOG_DETAILS: 'CATALOG DETAILS',
    NAME: 'Name',
    MARKETS: 'Markets',
    PRIMARY_LANGUAGES_USED_IN_THE_CATALOG: 'Primary Languages Used in the Catalog',
    PRIMARY_LANGUAGES: 'Primary Languages',
    TAGS: 'Tags',
    ADD_MARKETS: 'Add Market:',
    ADD_TAGS: 'Add Tag: '
}

// Labels for the separate Catalog Modal Sections
export const CATALOG_MODAL_SECTIONS = {
    OVERVIEW: 'Overview'
}

// Tooltips
export const CATALOG_TOOLTIPS = {
    MARKETS: 'Applicable markets used in the catalog.',
    TAGS: 'Source provided tags for the catalog.'
}

export const REGISTER_NEW_CATALOG = 'Register New Catalog';

