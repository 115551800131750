/* eslint react/prop-types: 0 */
/* eslint react/display-name: 0 */
import React from 'react';
import localTimeConverter from '../utils/DateUtils';
import {ROUTES} from '../config/Routes';
import {Link} from "react-router-dom";
import {convertLanguageFromCode} from '../utils/GeneralUtils';
import { MOBIUS_CATALOG_PROGRAM_ERROR_MODAL } from './Mobius';

export const PROGRAM_DETAILS = 'Program Details';
export const PROGRAM_TITLE = 'Program Title';
export const PROGRAM_TMS_ID = 'TMS ID';
export const PROGRAM_SEARCH_TYPES_LABEL = 'Find a Program by';
export const PROGRAM_SEARCH_FILTER_LABEL = 'Filter by Program Type:';
export const PROGRAM_SEARCH_TYPES = [ PROGRAM_TITLE, PROGRAM_TMS_ID ];
export const PROGRAM_FILTER_TYPES = [
    { name: 'Film', progTypes: ['Feature Film', 'Short Film'] },
    { name: 'Series', progTypes: ['Series', 'Miniseries'] },
    { name: 'Paid Programming', progTypes: ['Paid Programming'] },
    { name: 'Special', progTypes: ['Special'] },
    { name: 'Sports', progTypes: ['Sports event', 'Sports non-event'] },
    { name: 'TV Movie', progTypes: ['TV Movie'] }
];
export const PROGRAM_LANGUAGE = 'Program Language';
export const RECENT_SEARCHES = 'Recently Viewed Programs';
export const NO_SEASON = 'No Season';
export const ADVANCED_SEARCH = 'Advanced Search';

export const TAB_NAMES = {
    OVERVIEW: 'Overview',
    SEASONS_AND_EPISODES: 'Seasons & Episodes',
    CAST_AND_CREW: 'Cast & Crew',
    IMAGES: 'Images',
    OTHER_VERSIONS: 'Other Versions',
    RELATED_PROGRAMS: 'Related Programs',
    ALTERNATE_EPISODES: 'Alternate Episodes',
    SUMMARY: 'Summary',
    TITLES: 'Titles',
    DESCRIPTIONS: 'Descriptions',
    RELEASES: 'Releases',
    RATINGS: 'Ratings',
    AWARDS: 'Awards',
    TEAMS: 'Teams',
    VIDEO_DESCRIPTORS: 'Video Descriptors',
    XML: 'XML'
};

export const PROGRAM_TYPES = {
    FEATURE_FILM: 'Feature Film',
    SERIES: 'Series',
    SHORT_FILM: 'Short Film',
    MINISERIES: 'Miniseries',
    PAID_PROGRAMMING: 'Paid Programming',
    SPECIAL: 'Special',
    SPORTS_EVENT: 'Sports event',
    SPORTS_NON_EVENT: 'Sports non-event',
    TV_MOVIE: 'TV Movie',
    THEATRE_EVENT: 'Theatre Event',
    MUSIC_VIDEO: 'Music Video',
    PREVIEW: 'Preview'
};

export const PROGRAM_SUBTYPES = {
    FEATURE_FILM: 'Feature Film',
    SERIES: 'Series',
    SHORT_FILM: 'Short Film',
    MINISERIES: 'Miniseries',
    EPISODE: 'Episode',
    PAID_PROGRAMMING: 'Paid Program',
    SPECIAL: 'Special',
    SPORT_EVENT: 'Sport Event',
    TEAM_EVENT: 'Team Event',
    TV_MOVIE: 'TV Movie',
    THEATRE_EVENT: 'Theatre Event',
    MUSIC_VIDEO: 'Music Video',
    TRAILER: 'Trailer',
    PREVIEW: 'Preview',
    COMPILATION: 'Compilation',
    SPORT: 'Sport',
    SPORT_RELATED_EPISODE: 'Sport-Related Episode'
};

export const EPISODIC_PROGRAM_SUBTYPES = [PROGRAM_SUBTYPES.EPISODE, PROGRAM_SUBTYPES.TEAM_EVENT, PROGRAM_SUBTYPES.PREVIEW, PROGRAM_SUBTYPES.SPORT_EVENT, PROGRAM_SUBTYPES.TEAM_EVENT, PROGRAM_SUBTYPES.SPORT_RELATED_EPISODE];

export const PROGRAM_TYPES_WITH_SUBTYPE = {
    [PROGRAM_TYPES.FEATURE_FILM]: [
        PROGRAM_SUBTYPES.FEATURE_FILM
    ],
    [PROGRAM_TYPES.MINISERIES]: [
        PROGRAM_SUBTYPES.EPISODE,
        PROGRAM_SUBTYPES.MINISERIES
    ],
    [PROGRAM_TYPES.MUSIC_VIDEO]: [
        PROGRAM_SUBTYPES.MUSIC_VIDEO
    ],
    [PROGRAM_TYPES.PAID_PROGRAMMING]: [
        PROGRAM_SUBTYPES.PAID_PROGRAMMING
    ],
    [PROGRAM_TYPES.PREVIEW]: [
        PROGRAM_SUBTYPES.PREVIEW
    ],
    [PROGRAM_TYPES.SERIES]: [
        PROGRAM_SUBTYPES.COMPILATION,
        PROGRAM_SUBTYPES.EPISODE,
        PROGRAM_SUBTYPES.MINISERIES,
        PROGRAM_SUBTYPES.PAID_PROGRAMMING,
        PROGRAM_SUBTYPES.PREVIEW,
        PROGRAM_SUBTYPES.SERIES
    ],
    [PROGRAM_TYPES.SHORT_FILM]: [
        PROGRAM_SUBTYPES.SHORT_FILM,
        PROGRAM_SUBTYPES.TRAILER
    ],
    [PROGRAM_TYPES.SPECIAL]: [
        PROGRAM_SUBTYPES.COMPILATION,
        PROGRAM_SUBTYPES.EPISODE,
        PROGRAM_SUBTYPES.PREVIEW,
        PROGRAM_SUBTYPES.SPECIAL
    ],
    [PROGRAM_TYPES.SPORTS_EVENT]: [
        PROGRAM_SUBTYPES.PREVIEW,
        PROGRAM_SUBTYPES.SPORT,
        PROGRAM_SUBTYPES.SPORT_EVENT,
        PROGRAM_SUBTYPES.TEAM_EVENT
    ],
    [PROGRAM_TYPES.SPORTS_NON_EVENT]: [
        PROGRAM_SUBTYPES.EPISODE,
        PROGRAM_SUBTYPES.SPORT_RELATED_EPISODE
    ],
    [PROGRAM_TYPES.THEATRE_EVENT]: [
        PROGRAM_SUBTYPES.THEATRE_EVENT
    ],
    [PROGRAM_TYPES.TV_MOVIE]: [
        PROGRAM_SUBTYPES.TV_MOVIE
    ]
};

// GNVIEW internal variable on program types (gn_progtype)
export const PROGRAM_GNPROGTYPES = {
    MOVIE: 'movie',
    SERIES: 'series',
    TRIMMED_SERIES: 'trimmedSeries',
    EPISODE: 'episode',
    SPORTS: 'sports',
    SPORTS_SUMMARY: 'sportsSummary',
    PREVIEW: 'preview'
};


export const SPORTS_TEAMS_COLUMN_HEADERS = [
    { accessor: 'brand_id', Header: 'Team Brand ID'},
    { accessor: 'name', Header: 'Team Name'},
    { accessor: 'venue', Header: 'Venue'}
];

export const PROGRAM_TITLE_COLUMN_HEADERS = [
    { accessor: 'text', Header: 'Title'},
    { accessor: 'language', Header: 'Title Language', Cell: ({row}) => {
        return convertLanguageFromCode(row.values.language);
    }},
    { accessor: 'type', Header: 'Type'},
    { accessor: 'sub_type', Header: 'Subtype'},
    { accessor: 'size', Header: 'Max Size'}
];

export const PROGRAM_DESC_COLUMN_HEADERS = [
    { accessor: 'text', Header: 'Description', maxWidth: 60},
    { accessor: 'language', Header: 'Description Language', Cell: ({row}) => {
        return convertLanguageFromCode(row.values.language);
    }},
    { accessor: 'type', Header: 'Type'},
    { accessor: 'size', Header: 'Max Size'}
];

export const PROGRAM_SEASON_EPISODE_COLUMN_HEADERS = {
    SERIES_WITH_LINK: [
        { accessor: 'tms_id', Header: 'TMS ID', Cell: ({row}) => {
            const url = `${ROUTES.PROGRAM_DETAILS}/${row.values.tms_id}`;
            return <span><Link to={url}>{row.values.tms_id}</Link></span>
        }},
        { accessor: 'title', Header: 'Episode Title', Cell: ({row}) => row?.original?.episode_info?.title || null},
        { accessor: 'original_air_date', Header: 'Original Air Date', Cell: ({row}) => localTimeConverter.convertToLocalTime(row.values.original_air_date) },
        { accessor: 'episode_number', Header: 'Episode Number', Cell: ({row}) => row?.original?.episode_info?.number || null},
        { accessor: 'industry_episode', Header: 'Industry Episode Number', Cell: ({row}) => row?.original?.episode_info?.syn_num || null}
    ],
    SERIES_NO_LINK: [
        { accessor: 'tms_id', Header: 'TMS ID' },
        { accessor: 'title', Header: 'Episode Title', Cell: ({row}) => row?.original?.episode_info?.title || null},
        { accessor: 'original_air_date', Header: 'Original Air Date', Cell: ({row}) => localTimeConverter.convertToLocalTime(row.values.original_air_date) },
        { accessor: 'episode_number', Header: 'Episode Number', Cell: ({row}) => row?.original?.episode_info?.number || null},
        { accessor: 'industry_episode', Header: 'Industry Episode Number', Cell: ({row}) => row?.original?.episode_info?.syn_num || null}
    ],
    SPORTS_EVENT_WITH_LINK: [
        { accessor: 'tms_id', Header: 'TMS ID', Cell: ({row}) => {
            const url = `${ROUTES.PROGRAM_DETAILS}/${row.values.tms_id}`;
            return <span><Link to={url}>{row.values.tms_id}</Link></span>
        }},
        { accessor: 'title', Header: 'Episode Title', Cell: ({row}) => row?.original?.sports_info?.title || null},
        { accessor: 'original_air_date', Header: 'Original Air Date', Cell: ({row}) => localTimeConverter.convertToLocalTime(row.values.original_air_date) },
        { accessor: 'episode_number', Header: 'Episode Number', Cell: ({row}) => row?.original?.sports_info?.number || null},
        { accessor: 'industry_episode', Header: 'Industry Episode Number', Cell: ({row}) => row?.original?.sports_info?.syn_num || null}
    ],
    SPORTS_EVENT_NO_LINK: [
        { accessor: 'tms_id', Header: 'TMS ID' },
        { accessor: 'title', Header: 'Episode Title', Cell: ({row}) => row?.original?.sports_info?.title || null},
        { accessor: 'original_air_date', Header: 'Original Air Date', Cell: ({row}) => localTimeConverter.convertToLocalTime(row.values.original_air_date) },
        { accessor: 'episode_number', Header: 'Episode Number', Cell: ({row}) => row?.original?.sports_info?.number || null},
        { accessor: 'industry_episode', Header: 'Industry Episode Number', Cell: ({row}) => row?.original?.sports_info?.syn_num || null}
    ]
};

export const PROGRAM_OTHER_VERSIONS_COLUMN_HEADERS = [
    { accessor: 'tmsid', Header: 'TMS ID', Cell: ({row}) => {
        const url = `${ROUTES.PROGRAM_DETAILS}/${row.values.tmsid}`;
        return <span><Link to={url}>{row.values.tmsid}</Link></span>
    }},
    { accessor: 'title', Header: 'Title'},
    { accessor: 'subtype', Header: 'Subtype' },
    { accessor: 'title_language', Header: 'Title Language', Cell: ({row}) => {
        return convertLanguageFromCode(row.values.title_language);
    }},
    { accessor: 'language', Header: 'Description Language', Cell: ({row}) => {
        return convertLanguageFromCode(row.values.language);
    }}
];

export const PROGRAM_RATINGS_COLUMN_HEADERS = [
    { accessor: 'code', Header: 'Rating'},
    { accessor: 'description', Header: 'Description'},
    { accessor: 'ratings_body', Header: 'Rating Body'},
    { accessor: 'warning', Header: 'Warning'}
];

export const PROGRAM_AWARDS_COLUMN_HEADERS = [
    { accessor: 'year', Header: 'Award Year'},
    { accessor: 'name', Header: 'Award Name'},
    { accessor: 'category', Header: 'Award Category'},
    { accessor: 'recipient', Header: 'Recipient'},
    { accessor: 'won', Header: 'Result', Cell: ({row}) => {
        return row.values.won ? 'Won' : 'Nominated'
    }}
];


export const PROGRAM_ALTERNATE_EPISODES_COLUMN_HEADERS = [
    { accessor: 'season', Header: 'Alternate Season Number' },
    { accessor: 'episode', Header: 'Episode Number' },
    { accessor: 'station_name', Header: 'Station Name', Cell: ({row}) => (
        <span><Link to={`${ROUTES.STATION_DETAILS}/${row.values.station_id}`}>{row.values.station_name}</Link></span>
    )},
    { accessor: 'station_call_sign', Header: 'Station Call Letters' },
    { accessor: 'station_id', Header: 'Prog Serv ID' },
    { accessor: 'countrywide', Header: 'Country Wide', Cell: ({row}) => (
        row.values.countrywide ? 'Yes' : 'No'
    )}
];

// Program colors are determined by program type
export const PROGRAM_COLORS = {
    // GNVIEW: ON data
    gnview: {
        'Feature Film': 'color-movie',
        'Short Film': 'color-movie',
        'Series': 'color-series',
        'Miniseries': 'color-series',
        'Paid Programming': 'color-paid',
        'Special': 'color-special',
        'Sports event': 'color-sports',
        'Sports non-event': 'color-sports',
        'TV Movie': 'color-tv-movie',
        'Theatre Event': 'color-other',
        'Music Video': 'color-other',
        'Preview': 'color-other'
    },
    // Mobius: NEd data
    gnIds: {
        'episode': 'color-series',
        'movie': 'color-movie',
        'show': 'color-series',
        'sport': 'color-sports',
        'teamEvent': 'color-sports',
        'theatreEvent': 'color-other',
        'nonTeamEvent': 'color-sports',
        'season': 'color-series'
    }
};

export const CAST_AND_CREW_SUBTABS = { cast: 'Cast', crew: 'Crew' };

export const IMAGES_SUBTABS = { programImages: 'Program Images', seasonSpecificImages: 'Season Specific Images' };

export const PROGRAM_POPULARITY_DEFINITION = "Gracenote’s US Video Popularity Score lets customers customize the search and discovery experience for users by appropriately prioritizing programs in search results and discovery paths. Additionally, customers can use it for identifying new titles to license for their catalog and prioritizing content for editorial curation.";

export const UnentitledMessageSection = () =>
    <div className="unentitled-message">
        <span className="header">Access Denied</span><br/>You are not entitled to view Gracenote metadata details for this Program. This program is outside the scope of your entitlement access. If you have any questions or concerns, contact your account manager.
    </div>;

export const SAVE_PROGRAM_DROPDOWN_VIEW_TYPES = {
    SAVED_TO_LISTS: 'Saved to Lists',
    CREATE_NEW_LIST: 'Create a New List'
};

export const NEW_LIST_NAME = 'New List Name';
export const FAVORITES_LIST_LABEL = 'Favorites';
export const PROGRAM_LIST_LAST_UPDATED = 'Last Updated';

export const NAME = 'name';
export const ALL_CATALOGS = 'All Catalogs';
export const SEARCH_CATALOGS = 'Search Catalog...';
export const ADD = 'Add'

// shows the potential error radio buttons that can appear when adding programs to a catalog (used for radio buttons)
export const CATALOG_PROGRAM_ERRORS = [
    {
        name: MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.CATALOG_NOT_FOUND,
        value: MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.CATALOG_NOT_FOUND_ERROR
    },
    {
        name: MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.DUPLICATE_PROGRAMS,
        value: MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.DUPLICATE_MAPPING_ERROR
    },
    {
        name: MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.PROGRAM_NOT_FOUND,
        value: MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.PRESENTATION_NOT_FOUND_ERROR
    }
]

// used to determine which fields in the /catalogProgram error response to map to which headers in a generated JSON object
export const ERROR_FIELD_TO_JSON_HEADER_MAPPING = {
    "type": "Error type",
    "presentationGnID": "Presentation GNID",
    "catalogGnID": "Catalog GNID"
};

export const CATALOG_PROGRAM_ERRORS_CSV_NAME = 'catalog_program_errors';
