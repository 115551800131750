export const PROGRAM_BULK_UPLOAD = {
    BROWSE_BUTTON_TEXT: 'Browse',
    COULD_NOT_COMPLETE: 'We could not complete the import request. ',
    ERROR_HEADER: 'Errors Found',
    FILE_UPLOAD_TEXT: 'Drag and drop a CSV file, or ',
    FILE_UPLOAD_LIMIT_TEXT: '5 MB max/1000 rows',
    FIX_ERRORS: 'Please fix these errors',
    TITLE_TOOLTIP: 'This is your source data and is step one in getting your data into the Gracenote certified database. This data is NOT sent to our editorial team until you request publish.',
    INFO_LABEL: 'There are two options to upload programs. Please select one!',
    REGISTER: 'Register New Programs',
    REGISTER_PROGRAM: 'Register a Program via Web Form',
    REGISTER_VIA_CSV: 'Register Programs via CSV File',
    COMING_SOON: 'Coming soon!',
    LINK: 'View instructions and templates.',
    INSTRUCTIONS_URL: 'http://www.gracenote.com/documentation/gn-ids-web-app/html/Content/web-app/bulk-program-registration.htm',
    REPLACE_FILE_TEXT: 'Replace file',
    STEP1: 'Step 1. Upload CSV',
    STEP2: 'Step 2. Registration',
    STEP1_INSTRUCTIONS: 'We recommend downloading the entire workbook to get the instructions paired with both movie and show templates. However, you can also download the movie or show template directly. Fill the template manually and be sure to export it as a CSV, or use the template as a guide on how to structure your CSV, which can be produced through automated means.',
    STEP2_INSTRUCTIONS: 'Checking file to start registering programs.',
    STEP2_CLOSE: 'This window will close shortly.',
    DOWNLOAD_ERRORS: 'Download Errors',
    SUCCESS_FIRST_LINE: 'Currently importing and registering programs via CSV.',
    SUCCESS_SECOND_LINE: 'You can leave this page and track progress through the ',
    IMPORT_STATUS: 'Import Status',
    UPLOAD_AGAIN: 'upload again.'
}

export const MAX_FILE_SIZE = 5 * 1024 * 1024;