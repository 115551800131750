import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { gvauthSelToken } from '../../../reducers/GNVAuthReducer';
import { ROUTES } from '../../../config/Routes';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';

const PrivateRoute = ({ token, component: InnerComponent, ...rest }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (token !== undefined) {
            setIsLoading(false);
        }
    }, [token]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const isLoggedIn = !isEmpty(token);

    return (
        <Route
            {...rest}
            render={(props) =>
                isLoggedIn ? (
                    <InnerComponent {...props} />
                ) : (
                    <Redirect to={`${ROUTES.LANDING}?redirectPath=${props.location.pathname}`} />
                )
            }
        />
    );
};

PrivateRoute.propTypes = {
    token: PropTypes.string,
    component: PropTypes.elementType.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    }).isRequired
}

const mapStateToProps = (state) => {
    return {
        token: gvauthSelToken(state)
    };
}

export default withRouter(connect(mapStateToProps)(PrivateRoute));