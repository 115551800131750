import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import "./GNDropdownWithButton.scss";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gnidsCurrentSelectedCatalog } from "../../../reducers/GNIDSTableReducer";
import { setCurrentSelectedCatalog } from "../../../actions/GNIDSCatalogProgramMappingActions";

const GNDropdownWithButton = ({buttonText, searchPlaceHolder, buttonIcon, actionButtonLabel, actionButtonHandler, options, dropdownDisabled, tooltip}) => {
    const dispatch = useDispatch();
    const currentSelectedCatalog = useSelector(gnidsCurrentSelectedCatalog);
    const [isOpen, setIsOpen] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [selectedItem, setSelectedItem] = useState(currentSelectedCatalog);
    const [isAddButtonEnabled, setIsAddButtonEnabled] = useState(selectedItem?.name !== undefined || false);

    useEffect(() => {
        function handleGlobalClick(event) {
            if (
                isOpen &&
            !event.target.closest('.dropdown-container') &&
            !event.target.closest('.dropdown-content')
            ) {
                setIsOpen(false);
            }
        }

        document.addEventListener('mousedown', handleGlobalClick);

        return () => {
            document.removeEventListener('mousedown', handleGlobalClick);
        };
    }, [isOpen]);

    const handleSearchInputChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleItemClick = (item) => {
        setSelectedItem(item);
        dispatch(setCurrentSelectedCatalog(item))
        setIsAddButtonEnabled(true);
    };

    const handleAddButtonClick = () => {
        setIsOpen(!isOpen);
        actionButtonHandler(selectedItem?.value);
        setSelectedItem("");
        dispatch(setCurrentSelectedCatalog(null))
        setIsAddButtonEnabled(false);
    };

    const handleToggleButton = () => {
        setIsOpen(!isOpen);
        setSearchText("");
    };

    const handleDropdownBlur = (event) => {
        if (!event.relatedTarget || !event.relatedTarget.closest('.dropdown-content')) {
            setIsOpen(false);
        }
    };

    const addButton = () => {
        return (
            <div>
                <button
                    className={`add-button ${isAddButtonEnabled ? "enabled" : "disabled"}`}
                    disabled={!isAddButtonEnabled}
                    onClick={(event) => {
                        handleAddButtonClick()
                        event.stopPropagation();
                    }}
                >
                    {actionButtonLabel}
                </button>
            </div>
        )
    }

    return (
        <div className="dropdown-container">
            <Button
                className={`toggle-button ${dropdownDisabled && "disabled"}`}
                buttonClass='show-dropdown-button'
                onClick={handleToggleButton}
                disabled={dropdownDisabled}
            >
                <FontAwesomeIcon icon={buttonIcon} className='icon-class' />
                {buttonText}
            </Button>
            {isOpen && (
                <div className="dropdown-content" tabIndex="0" onBlur={handleDropdownBlur}>
                    <div className="search-container">
                        <input
                            type="text"
                            value={searchText}
                            placeholder={searchPlaceHolder}
                            onChange={handleSearchInputChange}
                            className="search-input"
                        />
                        <i className="fa-solid fa-magnifying-glass search-icon"></i>
                    </div>
                    <ul className="item-list">
                        {options
                            .filter((item) =>
                                item.name?.toLowerCase().includes(searchText?.toLowerCase())
                            )
                            .map((item, index) => (
                                <li
                                    key={index}
                                    onClick={() => handleItemClick(item)}
                                    className={`list-item ${
                                        selectedItem?.name === item?.name ? "selected" : ""
                                    }`}
                                >
                                    <span>{item.name}{" "}</span>
                                    {selectedItem?.name === item?.name && <i className="fas fa-check"></i>}
                                </li>
                            ))}
                    </ul>
                    <div className="divider"></div>
                    <div className="add-button-container">
                        { isAddButtonEnabled ? (
                            addButton()
                        ) : (
                            <OverlayTrigger overlay={<Tooltip>{tooltip}</Tooltip>} placement="right">
                                {addButton()}
                            </OverlayTrigger>
                        )
                        }
                    </div>
                </div>
            )}
        </div>
    );
};


GNDropdownWithButton.propTypes = {
    buttonText: PropTypes.string.isRequired,
    searchPlaceHolder: PropTypes.string.isRequired,
    buttonIcon: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired, // Array of objects that contain these fields: label, value
    actionButtonLabel: PropTypes.string.isRequired,
    actionButtonHandler: PropTypes.func.isRequired,
    dropdownDisabled: PropTypes.bool.isRequired,
    tooltip: PropTypes.string
};

export default GNDropdownWithButton;
