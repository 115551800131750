import { array, boolean, date, number, object, setLocale, string } from 'yup';
import {
    MOBIUS_PROGRAM_TYPES_VALUES,
    MOBIUS_PROGRAM_DEFAULT_SUBTYPE,
    CAST_DEFAULT_VALUE,
    CREW_DEFAULT_VALUE,
    MOBIUS_LABELS,
    MAX_DURATION,
    MOBIUS_REQUIRED_MESSAGE
} from './Mobius';
import uniqId from 'uniqid';

export const COLOR_INITIAL_VALUE = 'color';

export const EXTERNAL_ID_INITIAL_VALUE = {
    label: '',
    id: '',
    isPrimary: false,
    isProvider: false
};

export const RATINGS_INITIAL_VALUE = {
    rating: '',
    ratingBody: '',
    advisories: [],
    ratingExempt: false,
    notYetRated: false
};

export const IMAGES_INITIAL_VALUE = {
    label: '',
    url: ''
};

/**
 * A Color initial value depends on the program type
 * @param {string} programType - movie, show, episode
 * @returns {string} - when the program type is movie, returns color. Otherwise, return ''
 */
export const COLOR_INITIAL_VALUE_BY_TYPE = (programType) =>
    programType === MOBIUS_PROGRAM_TYPES_VALUES.MOVIE ? COLOR_INITIAL_VALUE : COLOR_INITIAL_VALUE;

export const CAST_INITIAL_VALUE = (castType = CAST_DEFAULT_VALUE, order = 1) => ({
    order,
    name: {
        first: '',
        last: ''
    },
    character: '',
    role: castType
});

export const CREW_INITIAL_VALUE = (crewType = CREW_DEFAULT_VALUE, order = 1) => ({
    order,
    name: {
        first: '',
        last: ''
    },
    type: crewType
});

export const MOBIUS_PROGRAM_TITLE_MAX = 120;
export const MOBIUS_MIN_CHARACTER_LIMIT = 1;
export const MOBIUS_MAX_CHARACTER_LIMIT = 500;
export const MOBIUS_MIN_YEAR = 1600;
export const MOBIUS_MAX_YEAR = 2999;

export const MOBIUS_DESCRIPTION_LENGTHS = {
    '500': 500,
    '250': 250,
    '100': 100,
    '60': 60,
    '40': 40
};

export const MOBIUS_PROGRAM_FIELDS = {
    CAST: "cast",
    ROLE: "role",
    CAST_CHARACTER_NAME: (idx) => `cast[${idx}].character`,
    CAST_NAME: (idx) => `cast[${idx}].name`,
    CAST_FIRST_NAME: (idx) => `cast[${idx}].name.first`,
    CAST_LAST_NAME: (idx) => `cast[${idx}].name.last`,
    CAST_TYPE: (idx) => `cast[${idx}].role`,
    COLOR: "color",
    CREW: "crew",
    CREW_NAME: (idx) => `crew[${idx}].name`,
    CREW_FIRST_NAME: (idx) => `crew[${idx}].name.first`,
    CREW_LAST_NAME: (idx) => `crew[${idx}].name.last`,
    CREW_TYPE: (idx) => `crew[${idx}].type`,
    CREW_LABEL: "type",
    DESCRIPTIONS: "descriptions",
    DESCRIPTIONS_LANGUAGE_CODE: (idx) => `descriptions[${idx}].language`,
    DESCRIPTIONS_LENGTH: (idx) => `descriptions[${idx}].length`,
    DESCRIPTIONS_TEXT: (idx) => `descriptions[${idx}].value`,
    DURATION: "versions.duration",
    DURATION_HOURS: "versions.duration.hours",
    DURATION_MINUTES: "versions.duration.minutes",
    DURATION_SECONDS: "versions.duration.seconds",
    EXTERNAL_IDS: "externalIDs",
    EXTERNAL_IDS_LABEL: (idx) => `externalIDs[${idx}].label`,
    EXTERNAL_IDS_VALUE: (idx) => `externalIDs[${idx}].id`,
    EXTERNAL_IDS_PRIMARY: (idx) => `externalIDs[${idx}].isPrimary`,
    EXTERNAL_IDS_PROVIDER: (idx) => `externalIDs[${idx}].isProvider`,
    EPISODE_NUMBER: 'episodeNumber',
    FINALE_DATE: "finaleDate",
    GENRES: "genres",
    IMAGES: "imagery",
    IMAGES_LABEL: (idx) => `imagery[${idx}].label`,
    IMAGE_URL: (idx) => `imagery[${idx}].url`,
    INDUSTRY_NETWORK_NUMBER: 'industryNetworkNumber',
    INDUSTRY_SYNDICATED_NUMBER: 'industryNetworkSyndicated',
    LANGUAGE: 'language',
    MAPPINGS: 'internalAttributes',
    MAPPINGS_ID: 'internalAttributes.selfMappingID',
    ORIGINAL_SOURCE: "originalSource",
    PART_NUMBERS: "partNumbers",
    PRESENTATION_LABEL: "presentationLabels",
    PRESENTATION_RELEASE_DATE: "releaseDate",
    PRESENTATION_RELEASE_YEAR: "releaseYear",
    PRODUCTION_COMPANIES: "productionCompanies",
    PRODUCTION_COMPANIES_VALUE: (idx) => `productionCompanies[${idx}]`,
    PRODUCTION_COUNTRIES: "productionCountries",
    PRODUCTION_COUNTRIES_VALUE: (idx) => `productionCountries[${idx}].value`,
    PRODUCTION_STATUS: "productionStatus",
    RATINGS: "ratings",
    RATINGS_VALUE: (idx) => `ratings[${idx}].rating`,
    RATINGS_BODY: (idx) => `ratings[${idx}].ratingBody`,
    RATINGS_ADVISORIES: (idx) => `ratings[${idx}].advisories`,
    RATINGS_NOT_YET_RATED: (idx) => `ratings[${idx}].notYetRated`,
    RATINGS_RATING_EXEMPT: (idx) => `ratings[${idx}].ratingExempt`,
    SEASON_EPISODE_NUMBER: "seasonEpisodeNumber",
    SEASON_NUMBER: "seasonNumber",
    SUBTYPE: "subType",
    TARGET_AUDIENCE: 'targetAudience',
    TITLE: "title",
    TITLE_VALUE: "title.value",
    TITLE_LANGUAGE_CODE: "title.language",
    TYPE: "type",
    VALUE: 'value',
    VERSIONS: "versions",
    VERSIONS_COLOR: "versions.color",
    VERSION_LABELS: "versions.versionLabels",
    VERSION_RELEASE_DATE: "versions.releaseDate",
    VERSION_RELEASE_YEAR: "versions.releaseYear"
};

export const MOBIUS_INITIAL_DESCRIPTIONS = Object.values(MOBIUS_DESCRIPTION_LENGTHS).reverse().map((length) => ({
    language: "",
    length,
    value: ""
}));

export const MOBIUS_PROGRAM_INITIAL_VALUES = {
    cast: [{...CAST_INITIAL_VALUE(CAST_DEFAULT_VALUE), uniqKey: uniqId()}],
    crew: [{...CREW_INITIAL_VALUE(CREW_DEFAULT_VALUE), uniqKey: uniqId()}],
    descriptions: MOBIUS_INITIAL_DESCRIPTIONS,
    episodeNumber: '',
    externalIDs: [{...EXTERNAL_ID_INITIAL_VALUE, uniqKey: uniqId()}],
    finaleDate: "",
    genres: [],
    industryNetworkNumber: '',
    industryNetworkSyndicated: '',
    originalSource: "",
    partNumbers: "",
    presentationLabels: [],
    productionCompanies: [''],
    productionCountries: [],
    productionStatus: '',
    ratings: [{...RATINGS_INITIAL_VALUE, uniqKey: uniqId()}],
    releaseDate: "",
    releaseYear: "",
    subType: MOBIUS_PROGRAM_DEFAULT_SUBTYPE[MOBIUS_PROGRAM_TYPES_VALUES.MOVIE],
    targetAudience: '',
    title: {
        value: "",
        language: ""
    },
    type: MOBIUS_PROGRAM_TYPES_VALUES.MOVIE,
    versions: {
        color: COLOR_INITIAL_VALUE,
        duration: {
            hours: "",
            minutes: "",
            seconds: ""
        },
        releaseDate: "",
        releaseYear: "",
        versionLabels: []
    },
    internalAttributes: {
        selfMappingID: '',
        selfMappingType: 'tmsID'
    }
};

export const MOBIUS_PROGRAM_INITIAL_TOUCHED = {
    cast: false,
    crew: false,
    descriptions: false,
    episodeNumber: false,
    externalIDs: false,
    finaleDate: false,
    genres: false,
    industryNetworkNumber: false,
    industryNetworkSyndicated: false,
    originalSource: false,
    partNumbers: false,
    presentationLabels: false,
    productionCompanies: false,
    productionCountries: false,
    productionStatus: false,
    ratings: false,
    releaseDate: false,
    releaseYear: false,
    subType: false,
    targetAudience: false,
    title: {
        value: false,
        language: false
    },
    type: false,
    versions: {
        color: false,
        duration: {
            hours: false,
            minutes: false,
            seconds: false
        },
        releaseDate: false,
        releaseYear: false,
        versionLabels: false
    }
};



setLocale({
    array: {
        min: ' ',
        required: ' '
    },
    string: {
        required: ' '
    },
    mixed: {
        required: ' '
    }
})
const detectPartialFieldsTest = (testName, sectionName, fieldsNeeded, message) => ({
    name: testName,
    message,
    test: (currentFieldValue, context) => {
        const { from: sections, createError, path, options } = context;
        const { index } = options;
        const sectionWithAllFields = sections.find(formSection => formSection.value[sectionName])
        const section = sectionWithAllFields?.value[sectionName] || [];
        if (!Array.isArray(fieldsNeeded)) {
            const fieldNeededValue = section.length > 0 && section[index][fieldsNeeded];
            if ((fieldNeededValue && fieldNeededValue.length > 0 && !currentFieldValue)) {
                return createError(message, path);
            }
        } else {
            const fieldNeededValues = fieldsNeeded.map((field) => {
                if (field.includes('.')) {
                    const fieldProperties = field.split('.');
                    return section.length > 0 && section[index][fieldProperties[0]][fieldProperties[1]];
                }
                return section.length > 0 && section[index][field];
            });
            if (!fieldNeededValues.every(field => field === undefined) && fieldNeededValues.some(field => field === undefined) && !currentFieldValue) {
                return createError(message, path);
            }
        }

        return true;
    },
    exclusive: true
});

export const MOBIUS_PROGRAM_REGISTER_VALIDATION_SCHEMA = object({
    cast: array().of(
        object().shape({
            name: object().shape({
                first: string().test(detectPartialFieldsTest('validationOnRole', MOBIUS_PROGRAM_FIELDS.CAST, [MOBIUS_PROGRAM_FIELDS.ROLE, 'name.first', 'name.last', 'character'], MOBIUS_REQUIRED_MESSAGE.REQUIRED)),
                last: string().max(MOBIUS_MAX_CHARACTER_LIMIT, "(Maximum of ${max} Characters)").nullable()
            }),
            character: string().max(MOBIUS_MAX_CHARACTER_LIMIT, "(Maximum of ${max} Characters)").nullable(),
            role: string().when(['name', 'character'], {
                is: (name, character) => {
                    return name?.first?.length > 0 || name?.last?.length > 0 || character?.length > 0;
                },
                then: string().required(MOBIUS_REQUIRED_MESSAGE.REQUIRED),
                otherwise: string().nullable()
            }, [['character', 'role', 'name']])
        }, [['character', 'role', 'name']])
    ),
    crew: array().of(
        object().shape({
            name: object().shape({
                first: string().test(detectPartialFieldsTest('validationOnType', MOBIUS_PROGRAM_FIELDS.CREW, [MOBIUS_PROGRAM_FIELDS.CREW_LABEL, 'name.first', 'name.last'], MOBIUS_REQUIRED_MESSAGE.REQUIRED)),
                last: string().min(1, "(Minimum of ${min} Character)").max(MOBIUS_MAX_CHARACTER_LIMIT, "(Maximum of ${max} Characters)")
            }),
            type: string().when('name', {
                is: (name) => {
                    return name?.first?.length > 0 || name?.last?.length > 0
                },
                then: string().required(MOBIUS_REQUIRED_MESSAGE.REQUIRED),
                otherwise: string().nullable()
            }, [['name', 'type']])
        }, [['name', 'type']])
    ),
    descriptions: array(
        object({
            language: string(),
            length: number(),
            value: string().when('length', (length, schema) => {
                return length ? schema.max(length, "(Maximum of ${max} Characters)") : schema.max(MOBIUS_MAX_CHARACTER_LIMIT, "(Maximum of ${max} Characters)");
            })
        })
    ).nullable(),
    externalIDs: array().of(
        object().shape({
            label: string()
                .min(MOBIUS_MIN_CHARACTER_LIMIT, '(Minimum of ${min} Character)')
                .max(MOBIUS_MAX_CHARACTER_LIMIT, '(Maximum of ${max} Characters)'),
            id: string()
                .min(MOBIUS_MIN_CHARACTER_LIMIT, '(Minimum of ${min} Character)')
                .max(MOBIUS_MAX_CHARACTER_LIMIT, '(Maximum of ${max} Characters)')
        })
    ),
    genres: array()
        .of(string()),
    presentationLabels: array().of(string()),
    originalSource: string()
        .max(MOBIUS_MAX_CHARACTER_LIMIT, "(Maximum of ${max} Characters)"),
    productionCompanies: array().of(string()).nullable(),
    productionCountries: array().of(string()).nullable(),
    ratings: array().of(
        object().shape({
            rating: string()
                .max(MOBIUS_MAX_CHARACTER_LIMIT, "(Maximum of ${max} Characters)"),
            ratingBody: string()
                .max(MOBIUS_MAX_CHARACTER_LIMIT, "(Maximum of ${max} Characters)"),
            advisories: array().nullable(),
            ratingExempt: boolean(),
            notYetRated: boolean()
        })
    ),
    releaseDate: date()
        .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
        .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`)
        .nullable(),
    subType: string().required(),
    targetAudience: string().nullable(),
    title: object({
        value: string()
            .min(MOBIUS_MIN_CHARACTER_LIMIT, "(Minimum of ${min} Character)")
            .max(MOBIUS_PROGRAM_TITLE_MAX, "(Maximum ${max} Characters)")
            .required(),
        language: string().required()
    }),
    type: string().required(),
    versions: object({
        duration: object().shape({
            hours: string()
                .matches(/^[0-9]\d*$/, {message: MOBIUS_LABELS.DURATION_VALIDATION_ERROR, name: "duration-hours-invalid"})
                .nullable(),
            minutes: string()
                .matches(/^[0-9]\d*$/, {message: MOBIUS_LABELS.DURATION_VALIDATION_ERROR, name: "duration-minutes-invalid"})
                .nullable(),
            seconds: string()
                .matches(/^[0-9]\d*$/, {message: MOBIUS_LABELS.DURATION_VALIDATION_ERROR, name: "duration-seconds-invalid"})
                .nullable()
        }).test('duration', 'Duration is too long', function({hours = 0, minutes = 0, seconds = 0}, {createError, path}) {
            const duration = (hours * 60 * 60 + minutes * 60 + seconds) * 1000;
            if (duration > MAX_DURATION) {
                return createError(MOBIUS_LABELS.DURATION_INVALID_MESSAGE, path)
            }
            return true
        }).nullable(),
        releaseDate: date()
            .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
            .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`)
            .nullable()
    }),
    internalAttributes: object().shape({
        id: string(),
        type: string().default('tmsID')
    })
});

export const MOBIUS_PRESENTATION_LABELS_SCHEMA = array().of(string());
export const MOBIUS_VERSION_LABELS_SCHEMA = array().of(string());

export const MOBIUS_MOVIES_REGISTER_VALIDATION_SCHEMA =
    MOBIUS_PROGRAM_REGISTER_VALIDATION_SCHEMA.clone().concat(
        object().shape({
            productionStatus: string().nullable(),
            [MOBIUS_PROGRAM_FIELDS.PRESENTATION_LABEL]: MOBIUS_PRESENTATION_LABELS_SCHEMA,
            releaseDate: date().when('releaseYear', {
                is: (releaseYear) => !releaseYear,
                then: date()
                    .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
                    .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`),
                otherwise: date()
                    .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
                    .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`)
                    .nullable()
            }),
            releaseYear: number().when('releaseDate', {
                is: (releaseDate) =>
                    !(releaseDate instanceof Date) || releaseDate === undefined,
                then: number()
                    .integer('Numerical whole digits only')
                    .typeError('Must be a valid Number')
                    .min(MOBIUS_MIN_YEAR, '(Minimum Year of ${min})')
                    .max(MOBIUS_MAX_YEAR, '(Maximum Year of ${max})'),
                otherwise: number()
                    .integer('Numerical whole digits only')
                    .typeError('Must be a valid Number')
                    .min(MOBIUS_MIN_YEAR, '(Minimum Year of ${min})')
                    .max(MOBIUS_MAX_YEAR, '(Maximum Year of ${max})')
                    .nullable()
            }),
            versions: object().shape({
                color: string(),
                releaseDate: date().when('releaseYear', {
                    is: (releaseYear) => !releaseYear,
                    then: date()
                        .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
                        .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`),
                    otherwise: date()
                        .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
                        .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`)
                        .nullable()
                }),
                duration: object().shape({
                    hours: string()
                        .matches(/^[0-9]\d*$/, {message: MOBIUS_LABELS.DURATION_VALIDATION_ERROR, name: "duration-hours-invalid"}),
                    minutes: string()
                        .matches(/^[0-9]\d*$/, {message: MOBIUS_LABELS.DURATION_VALIDATION_ERROR, name: "duration-minutes-invalid"}),
                    seconds: string()
                        .matches(/^[0-9]\d*$/, {message: MOBIUS_LABELS.DURATION_VALIDATION_ERROR, name: "duration-seconds-invalid"})
                }).test('duration', 'Duration is too long', function({hours = 0, minutes = 0, seconds = 0}, {createError, path}) {
                    const duration = (hours * 60 * 60 + minutes * 60 + seconds) * 1000;
                    if (duration > MAX_DURATION) {
                        return createError(MOBIUS_LABELS.DURATION_INVALID_MESSAGE, path)
                    }
                    return true
                }).test('duration', 'Required when Production Status is completed', function({hours = 0, minutes = 0, seconds = 0}, {createError, path}) {
                    // Access the outside productionStatus field
                    /* eslint-disable babel/no-invalid-this */
                    const productionStatus = this.from?.[2]?.value?.productionStatus
                    /* eslint-enable babel/no-invalid-this */

                    // Check if productionStatus is 'completed' and set additional validation rules
                    if (productionStatus === 'completed' && (!Number(hours) && !Number(minutes) && !Number(seconds))) {
                        return createError(MOBIUS_LABELS.REQUIRED_TO_REGISTER_THIS_PROGRAM, path)
                    }
                    return true
                }).nullable(),
                releaseYear: number().when('releaseDate', {
                    is: (releaseDate) =>
                        !(releaseDate instanceof Date) || releaseDate === undefined,
                    then: number()
                        .integer('Numerical whole digits only')
                        .typeError('Must be a valid Number')
                        .min(MOBIUS_MIN_YEAR, '(Minimum Year of ${min})')
                        .max(MOBIUS_MAX_YEAR, '(Maximum Year of ${max})'),
                    otherwise: number()
                        .integer('Numerical whole digits only')
                        .typeError('Must be a valid Number')
                        .min(MOBIUS_MIN_YEAR, '(Minimum Year of ${min})')
                        .max(MOBIUS_MAX_YEAR, '(Maximum Year of ${max})')
                        .nullable()
                }),
                versionLabels: MOBIUS_VERSION_LABELS_SCHEMA
            }, [['releaseDate', 'releaseYear']])
        }, [['releaseDate', 'releaseYear']])
    );

export const MOBIUS_SHOWS_REGISTER_VALIDATION_SCHEMA =
    MOBIUS_PROGRAM_REGISTER_VALIDATION_SCHEMA.clone().concat(
        object({
            finaleDate: date()
                .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
                .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`)
                .nullable(),
            [MOBIUS_PROGRAM_FIELDS.PRESENTATION_LABEL]: MOBIUS_PRESENTATION_LABELS_SCHEMA,
            releaseDate: date()
                .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
                .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`),
            versions: object().shape({
                color: string().nullable(),
                releaseDate: date()
                    .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
                    .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`),
                versionLabels: MOBIUS_VERSION_LABELS_SCHEMA
            })
        })
    );
export const MOBIUS_EPISODE_REGISTER_VALIDATION_SCHEMA =
    MOBIUS_PROGRAM_REGISTER_VALIDATION_SCHEMA.clone().concat(
        object({
            episodeNumber: string(),
            industryNetworkNumber: string()
                .min(MOBIUS_MIN_CHARACTER_LIMIT, "(Minimum of ${min} Character)")
                .max(MOBIUS_MAX_CHARACTER_LIMIT, "(Maximum of ${max} Characters)"),
            industryNetworkSyndicated: string()
                .min(MOBIUS_MIN_CHARACTER_LIMIT, "(Minimum of ${min} Character)")
                .max(MOBIUS_MAX_CHARACTER_LIMIT, "(Maximum of ${max} Characters)"),
            partNumbers: string()
                .min(MOBIUS_MIN_CHARACTER_LIMIT, '(Minimum of ${min} Character)')
                .max(MOBIUS_MAX_CHARACTER_LIMIT, '(Maximum of ${max} Characters)')
                .nullable(),
            releaseDate: date()
                .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
                .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`),
            versions: object().shape({
                color: string().nullable()
            })
        })
    );

export const MOBIUS_PROGRAM_PUBLISHED_VALIDATION_SCHEMA = object({
    cast: array().of(
        object().shape({
            name: object().shape({
                first: string().test(detectPartialFieldsTest('validationOnRole', MOBIUS_PROGRAM_FIELDS.CAST, [MOBIUS_PROGRAM_FIELDS.ROLE, 'name.first', 'name.last', 'character'], MOBIUS_REQUIRED_MESSAGE.REQUIRED)),
                last: string().max(MOBIUS_MAX_CHARACTER_LIMIT, "(Maximum of ${max} Characters)").nullable()
            }),
            character: string().max(MOBIUS_MAX_CHARACTER_LIMIT, "(Maximum of ${max} Characters)").nullable(),
            role: string().when(['name', 'character'], {
                is: (name, character) => {
                    return name?.first?.length > 0 || name?.last?.length > 0 || character?.length > 0;
                },
                then: string().required(MOBIUS_REQUIRED_MESSAGE.REQUIRED),
                otherwise: string().nullable()
            }, [['character', 'role', 'name']])
        }, [['character', 'role', 'name']])
    ),
    crew: array().of(
        object().shape({
            name: object().shape({
                first: string().test(detectPartialFieldsTest('validationOnType', MOBIUS_PROGRAM_FIELDS.CREW, [MOBIUS_PROGRAM_FIELDS.CREW_LABEL, 'name.first', 'name.last'], MOBIUS_REQUIRED_MESSAGE.REQUIRED)),
                last: string().min(1, "(Minimum of ${min} Character)").max(MOBIUS_MAX_CHARACTER_LIMIT, "(Maximum of ${max} Characters)")
            }),
            type: string().when('name', {
                is: (name) => {
                    return name?.first?.length > 0 || name?.last?.length > 0
                },
                then: string().required(MOBIUS_REQUIRED_MESSAGE.REQUIRED),
                otherwise: string().nullable()
            }, [['name', 'type']])
        }, [['name', 'type']])
    ),
    descriptions: array().of(
        object().shape({
            language: string(),
            length: number(),
            value: string().when('length', (length, schema) => {
                return length ? schema.max(length, "(Maximum of ${max} Characters)") : schema.max(MOBIUS_MAX_CHARACTER_LIMIT, "(Maximum of ${max} Characters)");
            })
        })),
    externalIDs: array().of(
        object().shape({
            label: string()
                .min(MOBIUS_MIN_CHARACTER_LIMIT, '(Minimum of ${min} Character)')
                .max(MOBIUS_MAX_CHARACTER_LIMIT, '(Maximum of ${max} Characters)'),
            id: string()
                .min(MOBIUS_MIN_CHARACTER_LIMIT, '(Minimum of ${min} Character)')
                .max(MOBIUS_MAX_CHARACTER_LIMIT, '(Maximum of ${max} Characters)')
        })
    ),
    genres: array()
        .of(string())
        .min(MOBIUS_MIN_CHARACTER_LIMIT),
    presentationLabels: array().of(string()),
    originalSource: string()
        .max(MOBIUS_MAX_CHARACTER_LIMIT, "(Maximum of ${max} Characters)"),
    productionCompanies: array().of(string()).nullable(),
    productionCountries: array().of(string()).nullable(),
    ratings: array().of(
        object().shape({
            rating: string()
                .max(MOBIUS_MAX_CHARACTER_LIMIT, "(Maximum of ${max} Characters)"),
            ratingBody: string()
                .max(MOBIUS_MAX_CHARACTER_LIMIT, "(Maximum of ${max} Characters)"),
            advisories: array().nullable(),
            ratingExempt: boolean(),
            notYetRated: boolean()
        })),
    releaseDate: date()
        .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
        .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`)
        .nullable(),
    subType: string().required(),
    targetAudience: string().nullable(),
    title: object({
        value: string()
            .min(MOBIUS_MIN_CHARACTER_LIMIT, "(Minimum of ${min} Character)")
            .max(MOBIUS_PROGRAM_TITLE_MAX, "(Maximum ${max} Characters)")
            .required(),
        language: string().required()
    }),
    type: string().required(),
    versions: object({
        color: string(),
        duration: object().shape({
            hours: string()
                .matches(/^[0-9]\d*$/, {message: MOBIUS_LABELS.DURATION_VALIDATION_ERROR, name: "duration-hours-invalid"})
                .nullable(),
            minutes: string()
                .matches(/^[0-9]\d*$/, {message: MOBIUS_LABELS.DURATION_VALIDATION_ERROR, name: "duration-minutes-invalid"})
                .nullable(),
            seconds: string()
                .matches(/^[0-9]\d*$/, {message: MOBIUS_LABELS.DURATION_VALIDATION_ERROR, name: "duration-seconds-invalid"})
                .nullable()
        }).test('duration', 'Duration is too long', function({hours = 0, minutes = 0, seconds = 0}, {createError, path}) {
            const duration = (hours * 60 * 60 + minutes * 60 + seconds) * 1000;
            if (duration > MAX_DURATION) {
                return createError(MOBIUS_LABELS.DURATION_INVALID_MESSAGE, path)
            }
            return true
        }).nullable(),
        releaseDate: date()
            .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
            .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`)
            .nullable()
    }),
    internalAttributes: object().shape({
        id: string(),
        type: string().default('tmsID')
    })
});

export const MOBIUS_MOVIES_PUBLISHED_VALIDATION_SCHEMA =
        MOBIUS_PROGRAM_PUBLISHED_VALIDATION_SCHEMA.clone().concat(
            object().shape({
                productionStatus: string().nullable(),
                productionCountries: array().of(string()),
                [MOBIUS_PROGRAM_FIELDS.PRESENTATION_LABEL]: MOBIUS_PRESENTATION_LABELS_SCHEMA,
                releaseDate: date().when('releaseYear', {
                    is: (releaseYear) => !releaseYear,
                    then: date()
                        .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
                        .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`)
                        .required(),
                    otherwise: date()
                        .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
                        .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`)
                        .nullable()
                }),
                releaseYear: number().when('releaseDate', {
                    is: (releaseDate) =>
                        !(releaseDate instanceof Date) || releaseDate === undefined,
                    then: number()
                        .integer('Numerical whole digits only')
                        .typeError('Must be a valid Number')
                        .min(MOBIUS_MIN_YEAR, '(Minimum Year of ${min})')
                        .max(MOBIUS_MAX_YEAR, '(Maximum Year of ${max})')
                        .required(),
                    otherwise: number()
                        .integer('Numerical whole digits only')
                        .typeError('Must be a valid Number')
                        .min(MOBIUS_MIN_YEAR, '(Minimum Year of ${min})')
                        .max(MOBIUS_MAX_YEAR, '(Maximum Year of ${max})')
                        .nullable()
                }),
                versions: object().shape({
                    duration: object().shape({
                        hours: string()
                            .matches(/^[0-9]\d*$/, {message: MOBIUS_LABELS.DURATION_VALIDATION_ERROR, name: "duration-hours-invalid"})
                            .nullable(),
                        minutes: string()
                            .matches(/^[0-9]\d*$/, {message: MOBIUS_LABELS.DURATION_VALIDATION_ERROR, name: "duration-minutes-invalid"})
                            .nullable(),
                        seconds: string()
                            .matches(/^[0-9]\d*$/, {message: MOBIUS_LABELS.DURATION_VALIDATION_ERROR, name: "duration-seconds-invalid"})
                            .nullable()
                    }).test('duration', 'Duration is too long', function({hours = 0, minutes = 0, seconds = 0}, {createError, path}) {
                        const duration = (hours * 60 * 60 + minutes * 60 + seconds) * 1000;
                        if (duration > MAX_DURATION) {
                            return createError(MOBIUS_LABELS.DURATION_INVALID_MESSAGE, path)
                        }
                        return true
                    }).test('duration', 'Required when Production Status is completed', function({hours = 0, minutes = 0, seconds = 0}, {createError, path}) {
                        // Access the outside productionStatus field
                        /* eslint-disable babel/no-invalid-this */
                        const productionStatus = this.from?.[2]?.value?.productionStatus
                        /* eslint-enable babel/no-invalid-this */

                        // Check if productionStatus is 'completed' and set additional validation rules
                        if (productionStatus === 'completed' && (!Number(hours) && !Number(minutes) && !Number(seconds))) {
                            return createError(MOBIUS_LABELS.REQUIRED_TO_REGISTER_THIS_PROGRAM, path)
                        }
                        return true
                    }),
                    releaseDate: date().when('releaseYear', {
                        is: (releaseYear) => !releaseYear,
                        then: date()
                            .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
                            .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`)
                            .required(),
                        otherwise: date()
                            .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
                            .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`)
                            .nullable()
                    }),
                    releaseYear: number().when('releaseDate', {
                        is: (releaseDate) =>
                            !(releaseDate instanceof Date) || releaseDate === undefined,
                        then: number()
                            .integer('Numerical whole digits only')
                            .typeError('Must be a valid Number')
                            .min(MOBIUS_MIN_YEAR, '(Minimum Year of ${min})')
                            .max(MOBIUS_MAX_YEAR, '(Maximum Year of ${max})')
                            .required(),
                        otherwise: number()
                            .integer('Numerical whole digits only')
                            .typeError('Must be a valid Number')
                            .min(MOBIUS_MIN_YEAR, '(Minimum Year of ${min})')
                            .max(MOBIUS_MAX_YEAR, '(Maximum Year of ${max})')
                            .nullable()
                    }),
                    versionLabels: MOBIUS_VERSION_LABELS_SCHEMA
                }, [['releaseDate', 'releaseYear']])
            }, [['releaseDate', 'releaseYear']])
        );

export const MOBIUS_SHOWS_SERIES_PUBLISHED_VALIDATION_SCHEMA =
        MOBIUS_PROGRAM_PUBLISHED_VALIDATION_SCHEMA.clone().concat(
            object({
                finaleDate: date()
                    .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
                    .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`)
                    .nullable(),
                [MOBIUS_PROGRAM_FIELDS.PRESENTATION_LABEL]: MOBIUS_PRESENTATION_LABELS_SCHEMA,
                releaseDate: date()
                    .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
                    .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`)
                    .required(),
                versions: object().shape({
                    color: string(),
                    releaseDate: date()
                        .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
                        .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`)
                        .required(),
                    versionLabels: MOBIUS_VERSION_LABELS_SCHEMA
                })
            })
        );
export const MOBIUS_SHOWS_NON_SERIES_PUBLISHED_VALIDATION_SCHEMA =
        MOBIUS_PROGRAM_PUBLISHED_VALIDATION_SCHEMA.clone().concat(
            object({
                finaleDate: date()
                    .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
                    .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`)
                    .nullable(),
                [MOBIUS_PROGRAM_FIELDS.PRESENTATION_LABEL]: MOBIUS_PRESENTATION_LABELS_SCHEMA,
                releaseDate: date()
                    .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
                    .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`)
                    .required(),
                versions: object().shape({
                    color: string(),
                    duration: object().shape({
                        hours: string()
                            .matches(/^[0-9]\d*$/, {message: MOBIUS_LABELS.DURATION_VALIDATION_ERROR, name: "duration-hours-invalid"})
                            .nullable(),
                        minutes: string()
                            .matches(/^[0-9]\d*$/, {message: MOBIUS_LABELS.DURATION_VALIDATION_ERROR, name: "duration-minutes-invalid"})
                            .nullable(),
                        seconds: string()
                            .matches(/^[0-9]\d*$/, {message: MOBIUS_LABELS.DURATION_VALIDATION_ERROR, name: "duration-seconds-invalid"})
                            .nullable()
                    }).test('duration', function({hours = 0, minutes = 0, seconds = 0}, {createError, path}) {
                        const duration = (hours * 60 * 60 + minutes * 60 + seconds) * 1000;
                        if (duration > MAX_DURATION) {
                            return createError(MOBIUS_LABELS.DURATION_INVALID_MESSAGE, path)
                        }
                        return true
                    }),
                    releaseDate: date()
                        .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
                        .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`)
                        .required(),
                    versionLabels: MOBIUS_VERSION_LABELS_SCHEMA
                })
            })
        );
export const MOBIUS_EPISODE_PUBLISHED_VALIDATION_SCHEMA =
        MOBIUS_PROGRAM_PUBLISHED_VALIDATION_SCHEMA.clone().concat(
            object({
                episodeNumber: string(),
                industryNetworkNumber: string()
                    .min(MOBIUS_MIN_CHARACTER_LIMIT, "(Minimum of ${min} Character)")
                    .max(MOBIUS_MAX_CHARACTER_LIMIT, "(Maximum of ${max} Characters)"),
                industryNetworkSyndicated: string()
                    .min(MOBIUS_MIN_CHARACTER_LIMIT, "(Minimum of ${min} Character)")
                    .max(MOBIUS_MAX_CHARACTER_LIMIT, "(Maximum of ${max} Characters)"),
                partNumbers: string()
                    .min(MOBIUS_MIN_CHARACTER_LIMIT, '(Minimum of ${min} Character)')
                    .max(MOBIUS_MAX_CHARACTER_LIMIT, '(Maximum of ${max} Characters)')
                    .nullable(),
                releaseDate: date()
                    .min(new Date(`${MOBIUS_MIN_YEAR}-01-01`), `(Date must be later than year ${MOBIUS_MIN_YEAR})`)
                    .max(new Date(`${MOBIUS_MAX_YEAR}-01-01`), `(Date must be earlier than year ${MOBIUS_MAX_YEAR})`)
                    .required(MOBIUS_REQUIRED_MESSAGE.REQUIRED),
                versions: object().shape({
                    color: string(),
                    duration: object().shape({
                        hours: string()
                            .matches(/^[0-9]\d*$/, {message: MOBIUS_LABELS.DURATION_VALIDATION_ERROR, name: "duration-hours-invalid"})
                            .nullable(),
                        minutes: string()
                            .matches(/^[0-9]\d*$/, {message: MOBIUS_LABELS.DURATION_VALIDATION_ERROR, name: "duration-minutes-invalid"})
                            .nullable(),
                        seconds: string()
                            .matches(/^[0-9]\d*$/, {message: MOBIUS_LABELS.DURATION_VALIDATION_ERROR, name: "duration-seconds-invalid"})
                            .nullable()
                    }).test('duration', function({hours = 0, minutes = 0, seconds = 0}, {createError, path}) {
                        const duration = (hours * 60 * 60 + minutes * 60 + seconds) * 1000;
                        if (duration > MAX_DURATION) {
                            return createError(MOBIUS_LABELS.DURATION_INVALID_MESSAGE, path)
                        }
                        return true
                    })
                })
            })
        );
