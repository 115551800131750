import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import GNInput from '../../common/gnInput/GNInput';
import GNDropdown from '../../common/gnDropdown/GNDropdown';
import { MOBIUS_PROGRAM_FIELDS, CAST_INITIAL_VALUE, CREW_INITIAL_VALUE } from '../../../constants/MobiusProgramValidation';
import { mobiusVocabSelCastType, mobiusVocabSelCrewTypes, mobiusVocabSelGnvocab } from '../../../reducers/MobiusVocabReducer';
import './CastAndCrewFields.scss';
import ProgramModalAddButton from '../programModalAddButton/ProgramModalAddButton';
import uniqId from 'uniqid';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClassNames from 'classnames';
import {
    CAST_DEFAULT_VALUE,
    CREW_DEFAULT_VALUE,
    EDIT_DEFAULT_OPTION,
    MOBIUS_LABELS,
    MOBIUS_TOOLTIPS,
    MOBIUS_VOCABULARIES_LIST
} from '../../../constants/Mobius';
import PropTypes from 'prop-types';
import { convertVocab } from '../../../utils/MobiusUtils';

export const CastAndCrewFields = ({errors, handleFormChange, isCast, isRequired, members, validateForm}) => {
    const [allMembers, setAllMembers] = useState([...members]);
    const allMobiusVocabularies = useSelector(mobiusVocabSelGnvocab);
    const allCastTypes = useSelector(mobiusVocabSelCastType);
    const allCrewTypes = useSelector(mobiusVocabSelCrewTypes);
    const formattedCastTypes = [EDIT_DEFAULT_OPTION, ...allCastTypes?.map((type) => ({name: type.label, value: type.label}))] || [];
    const formattedCrewTypes = [EDIT_DEFAULT_OPTION, ...allCrewTypes?.map((type) => ({name: type.label, value: type.label}))] || [];

    const addNewMemberField = () =>
        isCast
            ? handleFormChange(MOBIUS_PROGRAM_FIELDS.CAST, [
                ...members,
                { ...CAST_INITIAL_VALUE(CAST_DEFAULT_VALUE, members.length + 1), uniqKey: uniqId() }
            ])
            : handleFormChange(MOBIUS_PROGRAM_FIELDS.CREW, [
                ...members,
                { ...CREW_INITIAL_VALUE(CREW_DEFAULT_VALUE, members.length + 1), uniqKey: uniqId() }
            ]);

    const deleteMemberField = (index) => {
        const filteredMembers = [...members].filter((i, idx) => idx !== index);
        return isCast ? handleFormChange(MOBIUS_PROGRAM_FIELDS.CAST, filteredMembers) : handleFormChange(MOBIUS_PROGRAM_FIELDS.CREW, filteredMembers);
    };

    const moveMemberUp = (index) => {
        const membersCopy = [...members];
        [membersCopy[index].billing_order, membersCopy[index - 1].billing_order] = [membersCopy[index - 1].billing_order, membersCopy[index].billing_order];
        [membersCopy[index], membersCopy[index - 1]] = [membersCopy[index - 1], membersCopy[index]];
        return isCast ? handleFormChange(MOBIUS_PROGRAM_FIELDS.CAST, membersCopy) : handleFormChange(MOBIUS_PROGRAM_FIELDS.CREW, membersCopy);
    };

    const moveMemberDown = (index) => {
        const membersCopy = [...members];
        [membersCopy[index].billing_order, membersCopy[index + 1].billing_order] = [membersCopy[index + 1].billing_order, membersCopy[index].billing_order];
        [membersCopy[index], membersCopy[index + 1]] = [membersCopy[index + 1], membersCopy[index]];
        return isCast ? handleFormChange(MOBIUS_PROGRAM_FIELDS.CAST, membersCopy) : handleFormChange(MOBIUS_PROGRAM_FIELDS.CREW, membersCopy);
    };

    const isAddNewEnabled = () => {
        return allMembers.every((member) => {
            if (isCast) {
                return member?.role?.length > 0 && member?.name?.first?.length > 0;
            } else {
                return member?.type?.length > 0 && member?.name?.first?.length > 0;
            }
        });
    };
    if (members.length === 0) {
        addNewMemberField()
    }

    useEffect(() => {
        setAllMembers([...members]);
    }, [members, setAllMembers]);

    return (
        <div className='cast-and-crew-fields-main-container'>
            {allMembers.map((member, idx) => {
                const firstNameLabel = isCast ? MOBIUS_LABELS.CAST_FIRST_NAME : MOBIUS_LABELS.CREW_FIRST_NAME;
                const lastNameLabel = isCast ? MOBIUS_LABELS.CAST_LAST_NAME : MOBIUS_LABELS.CREW_LAST_NAME;
                const typeLabel = isCast ? MOBIUS_LABELS.CAST_ROLE : MOBIUS_LABELS.CREW_TYPE;
                const isFirst = idx === 0;
                const isLast = idx === allMembers.length - 1;
                return (
                    <div className='cast-crew-fields' key={member.uniqKey}>
                        {allMembers.length > 1 && <OverlayTrigger overlay={<Tooltip id="reorder-member-field-tooltip">{isCast ? MOBIUS_TOOLTIPS.REORDER_CAST_MEMBER : MOBIUS_TOOLTIPS.REORDER_CREW_MEMBER}</Tooltip>} placement='left'>
                            <div className={ClassNames('ordering-section', {hasLabel: isFirst})}>
                                <FontAwesomeIcon className={ClassNames('arrow-up', {disabled: isFirst})} icon='angle-up' onClick={isFirst ? null : () => moveMemberUp(idx)}/>
                                <FontAwesomeIcon className={ClassNames('arrow-down', {disabled: isLast})} icon='angle-down' onClick={isLast ? null : () => moveMemberDown(idx)}/>
                            </div>
                        </OverlayTrigger>}
                        <GNDropdown
                            label={isFirst ? typeLabel : null}
                            options={isCast ? formattedCastTypes : formattedCrewTypes }
                            value={
                                isCast
                                    ? convertVocab(
                                        allMobiusVocabularies,
                                        member?.role,
                                        MOBIUS_VOCABULARIES_LIST.CAST_TYPE
                                    ) || CAST_DEFAULT_VALUE
                                    : convertVocab(
                                        allMobiusVocabularies,
                                        member?.type,
                                        MOBIUS_VOCABULARIES_LIST.CREW_TYPE
                                    ) || CREW_DEFAULT_VALUE
                            }
                            handleChange={(item) => {
                                return isCast ? handleFormChange(MOBIUS_PROGRAM_FIELDS.CAST_TYPE(idx), item) : handleFormChange(MOBIUS_PROGRAM_FIELDS.CREW_TYPE(idx), item);
                            }}
                            validateInput={() => isCast ? validateForm(MOBIUS_PROGRAM_FIELDS.CAST_TYPE(idx)) : validateForm(MOBIUS_PROGRAM_FIELDS.CREW_TYPE(idx))}
                            validationText={Array.isArray(errors) ? errors[idx]?.role || errors[idx]?.type : errors}
                        />
                        <GNInput
                            className='cast-crew-name'
                            handleChange={(item) => isCast ? handleFormChange(MOBIUS_PROGRAM_FIELDS.CAST_FIRST_NAME(idx), item) : handleFormChange(MOBIUS_PROGRAM_FIELDS.CREW_FIRST_NAME(idx), item)}
                            isRequired={isRequired}
                            label={isFirst ? firstNameLabel : null}
                            placeholder={firstNameLabel}
                            validateInput={() => isCast ? validateForm(MOBIUS_PROGRAM_FIELDS.CAST_FIRST_NAME(idx)) : validateForm(MOBIUS_PROGRAM_FIELDS.CREW_FIRST_NAME(idx))}
                            validationText={Array.isArray(errors) ? errors[idx]?.name?.first : errors}
                            value={member.name.first} />
                        <GNInput
                            className='cast-crew-name'
                            handleChange={(item) => isCast ? handleFormChange(MOBIUS_PROGRAM_FIELDS.CAST_LAST_NAME(idx), item) : handleFormChange(MOBIUS_PROGRAM_FIELDS.CREW_LAST_NAME(idx), item)}
                            isRequired={isCast ? false : isRequired}
                            label={isFirst ? lastNameLabel : null}
                            placeholder={lastNameLabel}
                            validateInput={() => isCast ? validateForm(MOBIUS_PROGRAM_FIELDS.CAST_LAST_NAME(idx)) : validateForm(MOBIUS_PROGRAM_FIELDS.CREW_LAST_NAME(idx))}
                            validationText={Array.isArray(errors) ? errors[idx]?.name?.last : errors}
                            value={member.name.last} />
                        {isCast && <GNInput
                            className='cast-character-name'
                            handleChange={(item) => handleFormChange(MOBIUS_PROGRAM_FIELDS.CAST_CHARACTER_NAME(idx), item)}
                            isRequired={isRequired}
                            label={isFirst ? MOBIUS_LABELS.CAST_CHARACTER_NAME : null}
                            placeholder={MOBIUS_LABELS.CAST_CHARACTER_NAME}
                            validateInput={() => validateForm(MOBIUS_PROGRAM_FIELDS.CAST_CHARACTER_NAME(idx))}
                            validationText={Array.isArray(errors) ? errors[idx]?.character : errors}
                            value={member.character} />}
                        {allMembers.length > 1 && <OverlayTrigger overlay={<Tooltip id="delete-member-field-tooltip">{isCast ? MOBIUS_TOOLTIPS.DELETE_CAST_MEMBER : MOBIUS_TOOLTIPS.DELETE_CREW_MEMBER}</Tooltip>} placement="bottom">
                            <div className={ClassNames('delete-member-field-btn', {hasLabel: isFirst})} onClick={() => deleteMemberField(idx)}><FontAwesomeIcon icon='trash-alt' /></div>
                        </OverlayTrigger>}
                        {isLast && <ProgramModalAddButton enabled={isAddNewEnabled()} hasLabel={isFirst} onButtonClick={addNewMemberField} tooltipPlacement='right' tooltipText={isCast ? MOBIUS_TOOLTIPS.ADD_NEW_CAST_MEMBER : MOBIUS_TOOLTIPS.ADD_NEW_CREW_MEMBER} />}
                    </div>
                )
            })}
        </div>
    )
};

CastAndCrewFields.defaultProps = {
    isCast: true
};

CastAndCrewFields.propTypes = {
    errors: PropTypes.object,
    handleFormChange: PropTypes.func,
    isCast: PropTypes.bool.isRequired,
    isRequired: PropTypes.bool.isRequired,
    members: PropTypes.array.isRequired,
    validateForm: PropTypes.func
};

export default CastAndCrewFields;