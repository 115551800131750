import {
    CLEAR_GNIDS_PROGRAM_IMAGES,
    SET_GNIDS_PROGRAM_DATA,
    SET_GNIDS_PROGRAM,
    SET_GNIDS_PROGRAM_IMAGES,
    CLEAR_GNIDS_PROGRAM_MODAL_DATA,
    UPDATE_PRESENTATION_ID,
    SET_BREADCRUMB_TO_EDIT_MODE
} from '../actions/ActionTypes';
import { MOBIUS_PROGRAM_TYPES_VALUES } from '../constants/Mobius';

export const PROGRAM_MODAL_STORE_NAME = 'program';


/**
 * Initial State
 */
export const initialState = {
    presentationGnId: null,
    versionGnId: null,
    programType: null,
    showPresentationGnID: null,
    seasonNumber: null,
    program: null,
    images: null,
    breadcrumbToEditMode: null
};

/**
 * Reducer
 */


export const GNIDSProgramModalReducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_GNIDS_PROGRAM_DATA: {
        const { program, seasonNumber, seriesGnId = null } = action.payload;
        return {
            ...state,
            programType: program?.type,
            showPresentationGnID: seriesGnId,
            presentationGnId: program ? presentationId(program) : null,
            versionGnId: program?.type === MOBIUS_PROGRAM_TYPES_VALUES.EPISODE ? program?.showVersionGnID : program?.versionGnID,
            seasonNumber
        };
    }
    case SET_GNIDS_PROGRAM:
        return {
            ...state,
            program: action.payload
        };
    case UPDATE_PRESENTATION_ID:
        return {
            ...state,
            presentationGnId: action.payload
        }
    case SET_GNIDS_PROGRAM_IMAGES:
        return {
            ...state,
            images: action.payload
        }
    case CLEAR_GNIDS_PROGRAM_IMAGES:
        return {
            ...state,
            images: initialState.images
        }
    case SET_BREADCRUMB_TO_EDIT_MODE:
        return {
            ...state,
            breadcrumbToEditMode: action.payload
        }
    case CLEAR_GNIDS_PROGRAM_MODAL_DATA:
        return initialState;
    default:
        return state;
    }
};


/**
 * Function
 */
const presentationId = (program) => {
    switch (program.type) {
    case MOBIUS_PROGRAM_TYPES_VALUES.MOVIE: {
        return program?.presentationGnID || program?.gnID;
    }
    case MOBIUS_PROGRAM_TYPES_VALUES.SHOW: {
        return program?.showPresentationGnID || program?.gnID || program?.presentationGnID;
    }
    case MOBIUS_PROGRAM_TYPES_VALUES.EPISODE: {
        return program?.gnID || program?.presentationGnID;
    }
    default:
        return program?.gnID;
    }
};

/**
 * Selectors
 */
export const gnidsProgram = (state) => state[PROGRAM_MODAL_STORE_NAME]?.program;
export const gnidsSelectProgramType = (state) => state[PROGRAM_MODAL_STORE_NAME]?.programType;
export const gnidsSelectShowVersionGNID = state => state[PROGRAM_MODAL_STORE_NAME]?.versionGnId;
export const gnidsSelectSeasonNumber = state => state[PROGRAM_MODAL_STORE_NAME]?.seasonNumber;
export const gnidsSelectPresentationGnId = state => state[PROGRAM_MODAL_STORE_NAME]?.presentationGnId;
export const gnidsProgramModalProgram = state => state[PROGRAM_MODAL_STORE_NAME]?.program;
export const gnidsProgramImages = (state) => state[PROGRAM_MODAL_STORE_NAME]?.images;
export const gnidsSelectBreadcrumbToEditMode = (state) => state[PROGRAM_MODAL_STORE_NAME]?.breadcrumbToEditMode;

/**
 * Default export
 */
export default GNIDSProgramModalReducer;