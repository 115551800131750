/* eslint react/prop-types: 0 */
/* eslint react/display-name: 0 */
import React from 'react';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImportStatusIcon from '../components/imports/importStatusIcon/ImportStatusIcon.jsx';
import GNBadge from '../components/common/gnBadge/GNBadge.jsx';
import ClassNames from 'classnames';
import { MOBIUS_SEASON_TYPES_VALUES } from './Mobius.js';
import { convertKBtoMBAndAddUnit, determineBulkSeriesType } from '../utils/ImportBatchStatusUtils.js';

export const IMPORT_STATUS_TABS = ['Movies', 'Shows'];
export const IMPORT_COLUMN_HEADERS = [
    {
        id: 'status',
        accessor: 'status',
        Header: 'Status',
        disableSortBy: false,
        Cell: ({ row }) =>
            row?.original?.status ? <ImportStatusIcon status={row.original.status} /> : null
    },
    {
        id: 'context.fileName',
        accessor: 'context.fileName',
        Header: 'File Name',
        disableSortBy: true
    },
    {
        id: 'context.rowCount',
        accessor: 'context.rowCount',
        Header: 'Number of Rows',
        disableSortBy: true
    },
    {
        id: 'context.fileSize',
        accessor: 'context.fileSize',
        Header: 'File Size',
        disableSortBy: true,
        Cell: ({ row }) => row?.original?.context?.fileSize ? convertKBtoMBAndAddUnit(row?.original?.context?.fileSize) : null
    },
    {
        id: 'gnID',
        accessor: 'gnID',
        Header: 'Batch GnID',
        disableSortBy: true
    },
    {
        id: 'created',
        accessor: 'created',
        Header: 'Created',
        disableSortBy: false,
        Cell: ({ row }) =>
            row?.original?.created
                ? moment(row.original.created).format('MMM D, YYYY')
                : null
    },
    {
        id: 'updated',
        accessor: 'updated',
        Header: 'Last Updated',
        disableSortBy: false,
        Cell: ({ row }) =>
            row?.original?.updated
                ? moment(row.original.updated).format('MMM D, YYYY')
                : null
    },
    {
        id: 'download',
        Header: '',
        disableSortBy: true
    },
    {
        id: 'expander',
        Header: '',
        disableSortBy: true,
        Cell: ({ row }) =>
            row?.original?.status === 'succeeded' || row?.original?.status === 'failed' ? (
                <span
                    {...row.getToggleRowExpandedProps()}
                    className='expand-collapse-icon-container'
                >
                    {row.isExpanded ? (
                        <FontAwesomeIcon icon="angle-up" className="expand-collapse-icon" />
                    ) : (
                        <FontAwesomeIcon icon="angle-down" className="expand-collapse-icon" />
                    )}
                </span>
            ) : null
    }
]

export const BATCH_STATUS_SUCCESS_NOTE = "This list displays program data as of the time of upload. Please note that program data may have been updated or removed since then.";
export const BATCH_STATUS_ERRORS_HEADER = "Registration Errors Found.";
export const BATCH_STATUS_ERRORS_NOTE = "Please correct the errors below and re-upload the file.";

export const BATCH_STATUS_SUCCESS_COLUMN_HEADERS_MOVIES = (handleProgramModal) => [
    {
        id: 'title',
        accessor: 'title',
        Header: 'Import Title',
        disableSortBy: true,
        Cell: ({ row }) =>
            <span className={'import-status-program-title'} onClick={() => handleProgramModal(row.original)}>{row.original.title}</span>
    },
    {
        id: 'language',
        accessor: 'language',
        Header: 'Language',
        disableSortBy: true
    },
    {
        id: 'rootGnID',
        accessor: 'rootGnID',
        Header: 'Root ID',
        disableSortBy: true
    },
    {
        id: 'versionGnID',
        accessor: 'versionGnID',
        Header: 'Version ID',
        disableSortBy: true
    },
    {
        id: 'presentationGnID',
        accessor: 'presentationGnID',
        Header: 'Presentation ID',
        disableSortBy: true
    }
]

export const BATCH_STATUS_SUCCESS_COLUMN_HEADERS_SHOWS = (handleProgramModal) => [
    {
        id: 'title',
        accessor: 'title',
        Header: 'Import Title',
        disableSortBy: true,
        Cell: ({ row }) =>
            <span className={ClassNames('import-status-program-title', { 'season-type-title': row?.original?.subType === MOBIUS_SEASON_TYPES_VALUES.SEASON_OF_A_SERIES || row?.original?.subType === MOBIUS_SEASON_TYPES_VALUES.DERIVATIVE })} onClick={() => {
                if (row?.original?.subType !== MOBIUS_SEASON_TYPES_VALUES.SEASON_OF_A_SERIES && row?.original?.subType !== MOBIUS_SEASON_TYPES_VALUES.DERIVATIVE) {
                    handleProgramModal(row.original);
                }
            }}>{row.original.title}</span>
    },
    {
        id: 'subtype',
        accessor: 'subType',
        Header: 'Subtype',
        disableSortBy: true,
        Cell: ({ row }) =>
            <GNBadge
                viewSelector='programs'
                subtype={row?.original?.subType}
                gnview={false}
                color={ `color-${determineBulkSeriesType(row?.original?.type)}` } />
    },
    {
        id: 'language',
        accessor: 'language',
        Header: 'Language',
        disableSortBy: true
    },
    {
        id: 'rootGnID',
        accessor: 'rootGnID',
        Header: 'Root ID',
        disableSortBy: true
    },
    {
        id: 'versionGnID',
        accessor: 'versionGnID',
        Header: 'Version ID',
        disableSortBy: true
    },
    {
        id: 'presentationID',
        accessor: 'presentationGnID',
        Header: 'Presentation ID',
        disableSortBy: true
    }
]

export const BATCH_STATUS_FAILURES_COLUMN_HEADERS = [
    {
        id: 'error',
        className: 'error',
        accessor: 'type',
        Header: 'Error',
        disableSortBy: true,
        Cell: ({ value }) =>
            <p className="error">{value}</p>
    },
    {
        id: 'row',
        className: 'row',
        accessor: 'row',
        Header: 'Row',
        disableSortBy: true
    },
    {
        id: 'import_title',
        className: 'import-title',
        accessor: 'title',
        Header: 'Import Title',
        disableSortBy: true
    },
    {
        id: 'parent_gnid',
        className: 'parent-gnid',
        accessor: 'parentGNID',
        Header: 'Parent GNID',
        disableSortBy: true
    }
];

export const IMPORT_STATUS_HELPER_TEXT = "View your CSV registration import status here. Click on completed programs to view registered programs. Click on rows to see registered programs or errors that occured that stopped program registration. Press the Refresh button to see the most up todate status of imports.";
export const REFRESH_BUTTON_TEXT = 'Refresh';
export const NO_IMPORT_DATA = 'No Data'
export const BATCH_ROW_LENGTH = 20;
export const DOWNLOAD_ERRORS_BUTTON_TEXT = 'Download Errors';