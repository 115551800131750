import React, { useState } from 'react';
import './ImagesFields.scss';
import GNInput from '../../common/gnInput/GNInput';
import {
    MOBIUS_PROGRAM_FIELDS
} from '../../../constants/MobiusProgramValidation';
import ProgramModalAddButton from '../programModalAddButton/ProgramModalAddButton';
import {
    MOBIUS_IMAGE_ERRORS,
    MOBIUS_LABELS,
    MOBIUS_TOOLTIPS
} from '../../../constants/Mobius';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { gvauthSelMobiusSourceID } from '../../../reducers/GNVAuthReducer';
import { mobiusGetImage } from '../../../actions/MobiusActions';
import ClassNames from 'classnames';
import { transformImageData } from '../../../utils/ImageUtils';

export const ImagesFields = ({ images, setImages, deletedImages, setDeletedImages, isRequired, validateForm, setImagesTouched }) => {
    const dispatch = useDispatch();
    const [addImageGNID, setAddImageGNID] = useState('');
    const [imageError, setImageError] = useState('');
    const sourceId = useSelector(gvauthSelMobiusSourceID);

    const fetchImage = () => {
        dispatch(mobiusGetImage(
            addImageGNID,
            sourceId
        )).then((res) => {
            const transformedImageResponse = transformImageData(res)
            setImages(data => [transformedImageResponse, ...data])
            setImagesTouched(true)
        }).catch(() => {
            setImageError(MOBIUS_IMAGE_ERRORS.IMAGE_NOT_FOUND)
        })
    }

    const addNewImageField = () => {
        setImageError('');
        const isImageDuplicate = images.some(image => image.gnID === addImageGNID)
        // checks whether the new gnID is already in the images list
        if (!isImageDuplicate) {
            // checks whether the new gnID is already in the deleted imagegnid list before fetching image data
            const imageExistsInDeleteList = deletedImages.filter(image => image.gnID === addImageGNID)
            if (imageExistsInDeleteList?.length) {
                setImages(data => [imageExistsInDeleteList[0], ...data])
                setDeletedImages(data => data.filter(item => item.gnID !== addImageGNID))
            } else {
                fetchImage();
            }
        } else {
            setImageError(MOBIUS_IMAGE_ERRORS.DUPLICATE_IMAGE);
        }
        setAddImageGNID('')
    }

    return (
        <div className='images-fields-main-container'>
            <div className='images-input-fields-container'>
                <GNInput
                    handleChange={(val) => setAddImageGNID(val)}
                    isRequired={isRequired}
                    label={MOBIUS_LABELS.IMAGE_ID}
                    placeholder={MOBIUS_LABELS.IMAGE_ID}
                    value={addImageGNID}
                    validateInput={() => validateForm(MOBIUS_PROGRAM_FIELDS.IMAGES)}
                    isValueInvalid={imageError?.length > 0}
                    validationText={imageError}
                    status={imageError?.length > 0}
                />
                <div className={ClassNames('add-image-button', {'has-error': imageError?.length > 0})}>
                    <ProgramModalAddButton
                        enabled={addImageGNID?.length > 0}
                        hasLabel={true}
                        onButtonClick={addNewImageField}
                        tooltipPlacement='right'
                        tooltipText={MOBIUS_TOOLTIPS.ADD_IMAGE_FIELD}
                    />
                </div>
            </div>
        </div>
    )
};

ImagesFields.propTypes = {
    deletedImages: PropTypes.array,
    setDeletedImages: PropTypes.func,
    images: PropTypes.array.isRequired,
    isRequired: PropTypes.bool.isRequired,
    setImages: PropTypes.func,
    setImagesTouched: PropTypes.func,
    validateForm: PropTypes.func
};

export default ImagesFields;