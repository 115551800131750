import React, { useState } from 'react';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Formik } from 'formik';
import get from 'lodash.get';
import {
    MOBIUS_PROGRAM_FIELDS
} from '../../constants/MobiusProgramValidation';
import {
    MOBIUS_BUTTON_TEXT,
    MOBIUS_LABELS,
    PROGRAM_SIDE_MENU,
    MOBIUS_TOOLTIPS,
    MOBIUS_VOCABULARIES_LIST,
    MOBIUS_PROGRAM_TYPES_VALUES,
    MOBIUS_NOTIFICATIONS,
    MOBIUS_CONFIRMATIONS
} from '../../constants/Mobius';
import GNInput from '../../components/common/gnInput/GNInput';
import GNDropdown from '../../components/common/gnDropdown/GNDropdown';
import PropTypes from 'prop-types';
import DescriptionFields from '../../components/mobius/descriptionFields/DescriptionFields';
import {
    getDefaultLanguage,
    getProgramSubTypeVocab,
    verifySeasonDataIntegrity
} from '../../utils/MobiusUtils';
import GNDatePickerWithYear from '../../components/common/gnDatePickerWithYear/GNDatePickerWithYear';
import moment from 'moment';
import {
    MOBIUS_SEASON_INITIAL_TOUCHED,
    MOBIUS_SEASON_VALIDATION_SCHEMA,
    MOBIUS_SEASON_FIELDS
} from '../../constants/MobiusSeasonValidation';
import { useDispatch, useSelector } from 'react-redux';
import { mobiusVocabSelEntitledLanguages, mobiusVocabSelGnvocab } from '../../reducers/MobiusVocabReducer';
import { convertLanguageFromCode } from '../../utils/GeneralUtils';
import { gnidsSeasonModalToggle, gnidsSetSeason, gnidsClearSeason, gnidsRegisterNewSeason } from '../../actions/GNIDSSeasonModalActions';
import { gvauthSelMobiusSourceID } from '../../reducers/GNVAuthReducer';
import { gnidsSeasonModalSeason, gnidsSeasonShowPresentationGNID } from '../../reducers/GNIDSSeasonModalReducer';
import { mobiusSaveSeason } from '../../actions/MobiusActions';
import { gnviewSendLogMessage } from '../../services/GeneralService';
import GNNotification from '../../components/common/gnNotification/GNNotification';
import GNConfirmationModal from '../../components/common/gnConfirmationModal/GNConfirmationModal';
import GNButton from '../../components/common/gnButton/GNButton';

export const SeasonModalEdit = ({ handleCancel, seasonGnID, setIsDirty, setRefreshSeason, showCancelConfirmation, setShowCancelConfirmation, handleNotification, setRefreshRowSubComponent, setShowErrNotification }) => {
    const dispatch = useDispatch();
    const mobiusEntitledLanguages = useSelector(mobiusVocabSelEntitledLanguages);
    const allMobiusVocabularies = useSelector(mobiusVocabSelGnvocab);
    const showPresentationGnID = useSelector(gnidsSeasonShowPresentationGNID);
    const season = useSelector(gnidsSeasonModalSeason);
    const [showFieldsInRedNotification, setShowFieldsInRedNotification] = useState(false);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState(null);
    const [notificationIsSuccess, setNotificationIsSuccess] = useState(false);
    const sourceId = useSelector(gvauthSelMobiusSourceID);

    const getAllLanguages = () => mobiusEntitledLanguages.map((langCode) => ({
        name: convertLanguageFromCode(langCode),
        value: langCode
    }));
    const allLanguages = getAllLanguages();
    const defaultLanguage = getDefaultLanguage(allLanguages);
    const isRegisterSeason = !(season?.gnID || seasonGnID)

    const canRegisterSeason = (dirty, isValid) => dirty && isValid;

    const onSaveRegisterButtonClick = (seasonToRegisterOrEdit, dirty, isValid) => {
        const setNotification = (show, message, isSuccess) => {
            setShowNotification(show);
            setNotificationMsg(message);
            setNotificationIsSuccess(isSuccess);
        }
        if (!isRegisterSeason) { // save an existing season
            setSaveInProgress(true);
            dispatch(mobiusSaveSeason(sourceId, seasonToRegisterOrEdit)).then(() => {
                setNotification(true, MOBIUS_NOTIFICATIONS.SEASON_SUCCESS_SAVED, true);
                setSaveInProgress(false);
                setRefreshSeason(true);
                setIsDirty(false);
            }).catch((error) => {
                dispatch(gnviewSendLogMessage(`mobiusSaveSeason error: ${error.message}`, error, season.gnID));
                setNotification(true, MOBIUS_NOTIFICATIONS.SEASON_ERROR_SAVED, false);
                setSaveInProgress(false);
            });
        } else if (canRegisterSeason(dirty, isValid)) {
            dispatch(gnidsSetSeason(seasonToRegisterOrEdit));
            setShowFieldsInRedNotification(false);
            handleRegisterConfirm(seasonToRegisterOrEdit)
        } else {
            setShowFieldsInRedNotification(true);
        }
    }

    const handleRegisterConfirm = (registerSeason) => {
        dispatch(gnidsRegisterNewSeason(sourceId, showPresentationGnID, registerSeason))
            .then(() => {
                dispatch(gnidsSeasonModalToggle(false));
                handleNotification(true, MOBIUS_NOTIFICATIONS.SEASON_SUCCESS_REGISTERED, true);
                setRefreshRowSubComponent(true);
                dispatch(gnidsClearSeason());
            })
            .catch((error) => {
                dispatch(gnviewSendLogMessage(`gnidsRegisterNewSeason error: `, error));
                dispatch(gnidsSeasonModalToggle(true));
                setShowErrNotification(true);
            });
    };

    const handleConfirmationButtonSubmit = () => {
        setShowCancelConfirmation(false);
        dispatch(gnidsSeasonModalToggle(false));
        dispatch(gnidsClearSeason());
    }

    const handleConfirmationButtonCancel = () => {
        setShowCancelConfirmation(false);
    }

    return (
        <div className="season-modal-edit-container">
            <Formik
                enableReinitialize={true}
                initialValues={verifySeasonDataIntegrity(season, defaultLanguage)}
                initialTouched={MOBIUS_SEASON_INITIAL_TOUCHED}
                validationSchema={MOBIUS_SEASON_VALIDATION_SCHEMA}
                onSubmit={(values, {resetForm}) => {
                    onSaveRegisterButtonClick(values)
                    resetForm({ values })
                }}>
                {({
                    dirty,
                    errors,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    setFieldTouched,
                    setFieldValue,
                    touched,
                    values
                }) => {
                    setIsDirty(!notificationIsSuccess ? dirty : false)
                    // Return errors only if we are submitting the form or the field has been touched
                    const validateForm = (formName) => {
                        return (isSubmitting || get(touched, formName)) ? !get(errors, formName) : true;
                    }
                    const handleFormChange = (formName, value) => {
                        if (!touched[formName]) {
                            setFieldTouched(formName, true);
                        }
                        setFieldValue(formName, value);
                    };

                    const isSeasonNumberRequired = () => values?.nonSeason === false;

                    return (
                        <Form noValidate onSubmit={handleSubmit}>
                            <div className='edit-mode-container'>
                                <div className='overview-section' id={PROGRAM_SIDE_MENU.OVERVIEW.id}>
                                    <div className='season-modal-tab-header'>
                                        {MOBIUS_LABELS.OVERVIEW}
                                    </div>
                                    <div className='overview-fields'>
                                        <div className='season-number'>
                                            <GNInput
                                                className="season-number-input"
                                                handleChange={(item) => {
                                                    if (item) {
                                                        handleFormChange(MOBIUS_SEASON_FIELDS.NON_SEASON, false);
                                                    }
                                                    handleFormChange(MOBIUS_SEASON_FIELDS.SEASON_NUMBER, item);
                                                    validateForm(MOBIUS_SEASON_FIELDS.SEASON_NUMBER);
                                                }}
                                                isRequired={isSeasonNumberRequired()}
                                                label={MOBIUS_LABELS.SEASON_NUMBER}
                                                placeholder={MOBIUS_LABELS.SEASON_NUMBER}
                                                validateInput={() => validateForm(MOBIUS_SEASON_FIELDS.SEASON_NUMBER)}
                                                validationText={errors?.[MOBIUS_SEASON_FIELDS.SEASON_NUMBER]}
                                                value={values?.[MOBIUS_SEASON_FIELDS.SEASON_NUMBER]}
                                            />
                                            <Form.Check
                                                checked={values?.[MOBIUS_SEASON_FIELDS.NON_SEASON]}
                                                id="custom-radio-non-season"
                                                label={MOBIUS_LABELS.NON_SEASON}
                                                onClick={() => {
                                                    handleFormChange(MOBIUS_SEASON_FIELDS.SEASON_NUMBER, '');
                                                    handleFormChange(MOBIUS_SEASON_FIELDS.NON_SEASON, !values?.[MOBIUS_SEASON_FIELDS.NON_SEASON]);
                                                }}
                                                type='radio'
                                                validateInput={() => validateForm(MOBIUS_SEASON_FIELDS.NON_SEASON)}
                                                value={values?.[MOBIUS_SEASON_FIELDS.NON_SEASON]}
                                            />
                                        </div>
                                        <OverlayTrigger
                                            trigger={!isRegisterSeason ? 'focus' : null}
                                            overlay={
                                                <Tooltip>{MOBIUS_TOOLTIPS.SUBTYPE_CHANGE}</Tooltip>
                                            }
                                            delay={500}
                                            placement='top'
                                        >
                                            <GNDropdown
                                                handleChange={(item) => {
                                                    handleFormChange(MOBIUS_SEASON_FIELDS.SUBTYPE, item || "");
                                                }}
                                                isRequired={MOBIUS_SEASON_VALIDATION_SCHEMA?.fields[MOBIUS_SEASON_FIELDS.SUBTYPE]?.exclusiveTests?.required}
                                                label={MOBIUS_LABELS.SEASON_SUBTYPE}
                                                options={getProgramSubTypeVocab(
                                                    allMobiusVocabularies[MOBIUS_VOCABULARIES_LIST.PROGRAM_TYPE],
                                                    MOBIUS_PROGRAM_TYPES_VALUES?.SEASON
                                                )?.map((subtype) => ({
                                                    name: subtype.label,
                                                    value: subtype.value
                                                })) || []}
                                                validateInput={() => validateForm(MOBIUS_SEASON_FIELDS.SUBTYPE)}
                                                value={values?.subType}
                                                disabled={!isRegisterSeason}
                                            />
                                        </OverlayTrigger>
                                        <GNDatePickerWithYear
                                            handleChange={(item) =>
                                                handleFormChange(
                                                    MOBIUS_SEASON_FIELDS.FINALE_DATE,
                                                    !item ? null : moment(item).format('YYYY-MM-DD')
                                                )
                                            }
                                            isRequired={MOBIUS_SEASON_VALIDATION_SCHEMA?.fields[MOBIUS_SEASON_FIELDS.FINALE_DATE]?.exclusiveTests?.required}
                                            label={MOBIUS_LABELS.FINALE_DATE}
                                            placeholder={MOBIUS_LABELS.FINALE_DATE}
                                            strictParsing={true}
                                            validateYear={() => validateForm(MOBIUS_SEASON_FIELDS.FINALE_DATE)}
                                            validationText={errors?.finaleDate}
                                            value={values?.finaleDate ? new Date(moment(values?.finaleDate).format()) : null} />
                                    </div>
                                </div>
                                <div className='season-modal-section' id={PROGRAM_SIDE_MENU.DESCRIPTIONS.id}>
                                    <div className='season-modal-tab-header'>
                                        {MOBIUS_LABELS.DESCRIPTIONS}
                                    </div>
                                    <div className='season-modal-tab-body'>
                                        <DescriptionFields
                                            allLanguages={allLanguages}
                                            defaultLanguage={defaultLanguage}
                                            descriptions={get(values, MOBIUS_PROGRAM_FIELDS.DESCRIPTIONS)}
                                            errors={errors[MOBIUS_PROGRAM_FIELDS.DESCRIPTIONS]}
                                            handleFormChange={handleFormChange}
                                            isRequired={MOBIUS_SEASON_VALIDATION_SCHEMA?.fields[MOBIUS_SEASON_FIELDS.DESCRIPTIONS]?.exclusiveTests?.required}
                                            validateForm={validateForm} />
                                    </div>
                                </div>
                            </div>
                            <div className='bottom-section'>
                                {(isRegisterSeason && canRegisterSeason(dirty, isValid)) && (
                                    <div className='register-requirements-msg'>
                                        {MOBIUS_LABELS.REGISTER_REQUIREMENTS_COMPLETED}
                                    </div>
                                )}
                                {showFieldsInRedNotification && !canRegisterSeason(dirty, isValid) && (
                                    <div className='revise-fields-red-msg'>
                                        {MOBIUS_LABELS.PLEASE_REVISE_FIELDS_IN_RED}
                                    </div>
                                )}
                                <div className='button-container'>
                                    <Button
                                        className='cancel-button'
                                        variant='light'
                                        onClick={() =>
                                            handleCancel()
                                        }
                                    >
                                        {MOBIUS_BUTTON_TEXT.CANCEL}
                                    </Button>
                                    <GNButton
                                        buttonClass='register-button'
                                        disabled={!isRegisterSeason && !(dirty && isValid)}
                                        type='submit'
                                        tooltip={<Tooltip>{MOBIUS_TOOLTIPS.MUST_UPDATE_FIELD}</Tooltip>}
                                        tooltipPlacement='top'
                                        variant='primary'
                                        onClick={() => onSaveRegisterButtonClick(values, dirty, isValid)}>
                                        {!isRegisterSeason ? MOBIUS_BUTTON_TEXT.SAVE
                                            : MOBIUS_BUTTON_TEXT.REGISTER}
                                    </GNButton>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
            <GNConfirmationModal
                cancelButtonHandler={handleConfirmationButtonCancel}
                cancelButtonText={MOBIUS_BUTTON_TEXT.CANCEL}
                message={MOBIUS_CONFIRMATIONS.CANCEL_CONFIRMATION_MESSAGE}
                show={showCancelConfirmation}
                submitButtonHandler={handleConfirmationButtonSubmit}
                submitButtonText={MOBIUS_BUTTON_TEXT.EXIT}
                title={MOBIUS_CONFIRMATIONS.CANCEL_CONFIRMATION_TITLE}
            />
            {showNotification && <GNNotification handleShow={setShowNotification} message={notificationMsg} milliseconds={5000} show={!saveInProgress && showNotification} success={notificationIsSuccess} />}
        </div>
    )
};

SeasonModalEdit.propTypes = {
    handleCancel: PropTypes.func,
    seasonGnID: PropTypes.string,
    setIsDirty: PropTypes.func,
    setRefreshSeason: PropTypes.func,
    showCancelConfirmation: PropTypes.bool,
    setShowCancelConfirmation: PropTypes.func,
    handleNotification: PropTypes.func,
    setRefreshRowSubComponent: PropTypes.func,
    setShowErrNotification: PropTypes.func
};

export default SeasonModalEdit;