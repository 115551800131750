import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'react-bootstrap';
import "./GNNotification.scss";
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

export const GNNotification = ({className, handleShow, message, milliseconds, show, success, isLoading}) => {
    useEffect(() => {
        if (milliseconds && show) {
            setTimeout(() => {
                handleShow(false);
            }, milliseconds);
        }
    }, [handleShow, milliseconds, show]);

    return (
        <Alert show={show} variant="dark" className={ClassNames("gnview-notification", {[className]: className})}>
            <div className="success-error-icon-container">
                {success && <FontAwesomeIcon icon="check" className="success-icon"/>}
                {!success && !isLoading && <FontAwesomeIcon icon="exclamation-triangle" className="error-icon"/>}
                {!success && isLoading && <FontAwesomeIcon icon="spinner" className='fa-spin-pulse spinner-icon' />}
                <div className="message">
                    {message}
                </div>
            </div>
            <FontAwesomeIcon icon="times" className="close-icon" onClick={() => handleShow(false)}/>
        </Alert>
    )
}

GNNotification.defaultProps = {
    success: true,
    isLoading: false
};

GNNotification.propTypes = {
    className: PropTypes.string,
    handleShow: PropTypes.func,
    message: PropTypes.string,
    milliseconds: PropTypes.number,
    show: PropTypes.bool,
    success: PropTypes.bool,
    isLoading: PropTypes.bool
};

export default GNNotification;