export const MENU_CONTENTS = [
    {
        icon: 'book',
        title: 'User Guide',
        url: 'https://www.gracenote.com/documentation/gn-ids-web-app/html/Content/web-app/Overview.htm#Top',
        newTab: true
    },
    {
        icon: 'bell',
        title: "What's New!"
    }
];