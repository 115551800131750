import React from 'react'
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import './GNHeaderCheckbox.scss';

export const GNHeaderCheckbox = ({isIndeterminate, handleClick, label}) => {
    const setCheckboxRef = checkbox => {
        if (checkbox) {
            checkbox.indeterminate = isIndeterminate;
        }
    };

    return (
        <div>
            <Form className="table-header-checkbox-container">
                <Form.Check
                    className="table-header-checkbox"
                    type="checkbox"
                    ref={setCheckboxRef}
                    onClick={handleClick}
                />
                <Form.Label className="table-header-checkbox-label">
                    {label ? label : ""}
                </Form.Label>
            </Form>
        </div>
    );
}

GNHeaderCheckbox.defaultProps = {
    isIndeterminate: false,
    label: ''
};

GNHeaderCheckbox.propTypes = {
    handleClick: PropTypes.func,
    isIndeterminate: PropTypes.bool,
    label: PropTypes.string || PropTypes.bool
};

export default GNHeaderCheckbox;
