import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import {
    IMAGE_SIDE_MENU
} from '../../constants/Mobius';
import { getImageValidationSchema, MOBIUS_IMAGE_FIELDS } from '../../constants/MobiusImageValidation';
import GNInput from '../common/gnInput/GNInput';
import { IMAGE_LABELS, IMAGE_TOOLTIPS } from '../../constants/Image';
import ImageModalEditAdditionalDetailsBooleanField from './ImageModalEditAdditionalDetailsBooleanField';
import TypeaheadFilter from '../common/typeaheadFilter/TypeaheadFilter';
import { convertLanguageCodeList } from '../../utils/GeneralUtils';

export const ImageModalEditAdditionalDetails = ({handleFormChange, values, validateForm, errors, allLanguages, countries}) => {
    const [inputMarketValue, setInputMarketValue] = useState(null);
    const [inputTagValue, setInputTagValue] = useState(null);
    const [selectedMarkets, setSelectedMarkets] = useState(values.markets || []);
    const [selectedTags, setSelectedTags] = useState(values.tags || []);

    const [marketsRef, setMarketsRef] = useState(null);
    const [tagsRef, setTagsRef] = useState(null);

    const handleOnBlurTypeaheads = (inputValue, selectedItems, fieldName, setSelectedItems, setInputValue) => {
        if (inputValue) {
            const formattedItems = selectedItems === null ? [] : [...selectedItems, inputValue];
            handleFormChange(fieldName, formattedItems);
            setSelectedItems(formattedItems);
            setInputValue('');
        }
    }

    return (<div className='additional-details-section' id={IMAGE_SIDE_MENU.ADDITIONAL_DETAILS.id}>
        <div className='additional-details-modal-tab-header'>
            {IMAGE_SIDE_MENU.ADDITIONAL_DETAILS.label}
        </div>
        <div className='additional-details-modal-tab-body'>
            <div className='additional-details-radio-section'>
                <ImageModalEditAdditionalDetailsBooleanField label={IMAGE_LABELS.GRACENOTE_USAGE} tooltip={IMAGE_TOOLTIPS.GRACENOTE_USAGE} value={values?.gracenoteUsage} field={MOBIUS_IMAGE_FIELDS.GRACENOTE_USAGE} handleFormChange={handleFormChange} validateForm={validateForm} errors={errors} />
                <ImageModalEditAdditionalDetailsBooleanField label={IMAGE_LABELS.SENSITIVE} tooltip={IMAGE_TOOLTIPS.SENSITIVE} value={values?.sensitive} field={MOBIUS_IMAGE_FIELDS.SENSITIVE} handleFormChange={handleFormChange} validateForm={validateForm} errors={errors} />
                <div className='brand-section'>
                    <ImageModalEditAdditionalDetailsBooleanField label={IMAGE_LABELS.BRANDED} tooltip={IMAGE_TOOLTIPS.BRANDED} value={values?.branded} field={MOBIUS_IMAGE_FIELDS.BRANDED} handleFormChange={handleFormChange} validateForm={validateForm} errors={errors} />
                    <div className='brand-description'>
                        <div className='description-message'>
                            {IMAGE_LABELS.BRAND_DESCRIPTION}
                        </div>
                        <div className="description-field-wrapper">
                            <GNInput
                                handleChange={(item) => handleFormChange(MOBIUS_IMAGE_FIELDS.BRAND_DESCRIPTION, item)}
                                isRequired={getImageValidationSchema()?.fields?.[MOBIUS_IMAGE_FIELDS.BRAND_DESCRIPTION]?.exclusiveTests?.required}
                                placeholder="Brand Description"
                                className='image-brand-description'
                                textAreaMax={500}
                                textAreaRows={3}
                                type="textarea"
                                validationText={errors?.brandDescription?.valueOf}
                                validateInput={() => validateForm(MOBIUS_IMAGE_FIELDS.BRAND_DESCRIPTION)}
                                value={values?.brandDescription}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='primary-languages-typeahead-container'>
                <TypeaheadFilter
                    addLabelKey={IMAGE_LABELS.ADD_PRIMARY_LANGUAGE}
                    className="primary-languages-typeahead"
                    filterLabel={IMAGE_LABELS.PRIMARY_LANGUAGES_USED_IN_THE_IMAGE}
                    handleChange={(items) => {
                        const formattedItems = items?.map((item) =>
                            item?.label ? item?.label : item
                        );
                        handleFormChange(MOBIUS_IMAGE_FIELDS.LANGUAGES, formattedItems);
                    }}
                    isRequired={getImageValidationSchema()?.fields[MOBIUS_IMAGE_FIELDS.LANGUAGES]?.exclusiveTests?.required}
                    list={convertLanguageCodeList(allLanguages.map(language => language.value))}
                    multiple={true}
                    placeholder={IMAGE_LABELS.PRIMARY_LANGUAGES}
                    selected={convertLanguageCodeList(values.languages)}
                    validationText={errors?.languages?.valueOf}
                    validateTypeahead={() => validateForm(MOBIUS_IMAGE_FIELDS.LANGUAGES)}
                />
            </div>
            <div className='markets-typeahead-container'>
                <TypeaheadFilter
                    addLabelKey={IMAGE_LABELS.ADD_MARKETS}
                    ref={(ref) => {
                        setMarketsRef(ref);
                    }}
                    allowNew={true}
                    className="markets-typeahead"
                    domRef={marketsRef}
                    filterLabel={<div className='typeahead-label-with-tooltip'>
                        <div className='field-label'>{IMAGE_LABELS.MARKETS}</div>
                        <OverlayTrigger overlay={<Tooltip>{IMAGE_TOOLTIPS.MARKETS}</Tooltip>} placement="right">
                            <FontAwesomeIcon icon='circle-info' />
                        </OverlayTrigger>
                    </div>}
                    onInputChange={(val) => {
                        setInputMarketValue(val);
                    }}
                    handleChange={(items) => {
                        const formattedItems = items.map((item) =>
                            item?.label ? item.label : item
                        );
                        handleFormChange(MOBIUS_IMAGE_FIELDS.MARKETS, formattedItems);
                        setSelectedMarkets(formattedItems);
                        setInputMarketValue('');
                    }}
                    handleBlur={() => handleOnBlurTypeaheads(inputMarketValue, selectedMarkets, MOBIUS_IMAGE_FIELDS.MARKETS, setSelectedMarkets, setInputMarketValue)}
                    isRequired={getImageValidationSchema()?.fields[MOBIUS_IMAGE_FIELDS.MARKETS]?.exclusiveTests?.required}
                    list={countries}
                    multiple={true}
                    placeholder={IMAGE_LABELS.MARKETS}
                    selected={values.markets}
                    validationText={errors?.markets?.valueOf}
                    validateTypeahead={() => validateForm(MOBIUS_IMAGE_FIELDS.MARKETS)}
                />
            </div>
            <div className='tags-typeahead-container'>
                <TypeaheadFilter
                    addLabelKey={IMAGE_LABELS.ADD_TAGS}
                    allowNew={true}
                    className="tags-typeahead"
                    domRef={tagsRef}
                    filterLabel={<div className='typeahead-label-with-tooltip'>
                        <div className='field-label'>{IMAGE_LABELS.TAGS}</div>
                        <OverlayTrigger overlay={<Tooltip>{IMAGE_TOOLTIPS.TAGS}</Tooltip>} placement="right">
                            <FontAwesomeIcon icon='circle-info' />
                        </OverlayTrigger>
                    </div>}
                    handleBlur={() => handleOnBlurTypeaheads(inputTagValue, selectedTags, MOBIUS_IMAGE_FIELDS.TAGS, setSelectedTags, setInputTagValue)}
                    handleChange={(items) => {
                        const formattedItems = items.map((item) =>
                            item?.label ? item.label : item
                        );
                        handleFormChange(MOBIUS_IMAGE_FIELDS.TAGS, formattedItems);
                        setSelectedTags(formattedItems);
                        setInputTagValue('');
                    }}
                    isRequired={getImageValidationSchema()?.fields[MOBIUS_IMAGE_FIELDS.TAGS]?.exclusiveTests?.required}
                    list={values?.tags}
                    multiple={true}
                    onInputChange={(val) => {
                        setInputTagValue(val);
                    }}
                    placeholder={IMAGE_LABELS.TAGS}
                    ref={(ref) => {
                        setTagsRef(ref);
                    }}
                    selected={selectedTags}
                    validationText={errors?.tags?.valueOf}
                    validateTypeahead={() => validateForm(MOBIUS_IMAGE_FIELDS.TAGS)}
                />
            </div>
        </div>
    </div>)
}

ImageModalEditAdditionalDetails.propTypes = {
    handleFormChange: PropTypes.func,
    values: PropTypes.object,
    errors: PropTypes.object,
    validateForm: PropTypes.func,
    allLanguages: PropTypes.array,
    countries: PropTypes.array
};

export default ImageModalEditAdditionalDetails;