import React, { useState } from 'react'
import PropTypes from 'prop-types';
import './ImportBatchStatusTable.scss';
import { NO_IMPORT_DATA, BATCH_STATUS_FAILURES_COLUMN_HEADERS, BATCH_STATUS_SUCCESS_COLUMN_HEADERS_MOVIES, BATCH_STATUS_SUCCESS_COLUMN_HEADERS_SHOWS } from '../../../constants/Imports';
import GNClientTable from '../../common/gnClientTable/GNClientTable';
import ErrorBoundary from '../../common/errorBoundary/ErrorBoundary';
import { useDispatch } from 'react-redux';
import ProgramModal from '../../../views/programModal/ProgramModal';
import { gnidsUpdateEpisode } from '../../../actions/MobiusActions';
import { setGnidsProgramRelatedData, clearProgramModalData } from '../../../actions/GNIDSProgramModalAction';
import { MOBIUS_PROGRAM_TYPES_VALUES } from '../../../constants/Mobius';
import GNNotification from '../../common/gnNotification/GNNotification';

export const ImportBatchStatusTable = ({batchType, batchData, tableType}) => {
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState(null);
    const [notificationIsSuccess, setNotificationIsSuccess] = useState(false);

    const handleNotification = (show, message, isSuccess) => {
        setShowNotification(show);
        setNotificationMsg(message);
        setNotificationIsSuccess(isSuccess);
    };

    const handleProgramModal = (program, keepOpen) => {
        if (!keepOpen) {
            setModalShow((prevModalShow) => !prevModalShow);
        }
        dispatch(clearProgramModalData());
        if (program?.subType === MOBIUS_PROGRAM_TYPES_VALUES.EPISODE) {
            dispatch(gnidsUpdateEpisode(null, program.presentationGnID));
            dispatch(setGnidsProgramRelatedData({program}));
        } else {
            dispatch(setGnidsProgramRelatedData({program}));
        }
    };

    const displayColumns = [];
    if (tableType === 'succeeded') {
        if (batchType === "movies") {
            const movieColumns = BATCH_STATUS_SUCCESS_COLUMN_HEADERS_MOVIES(handleProgramModal);
            displayColumns.push(...movieColumns);
        } else {
            const showColumns = BATCH_STATUS_SUCCESS_COLUMN_HEADERS_SHOWS(handleProgramModal);
            displayColumns.push(...showColumns);
        }
    } else if (tableType === 'failed') {
        displayColumns.push(...BATCH_STATUS_FAILURES_COLUMN_HEADERS);
    }
    return (
        <div>
            <ErrorBoundary>
                <GNClientTable
                    className='import-status-table'
                    columns={displayColumns}
                    data={batchData}
                    pagination={true}
                    paginationBottom={false}
                    noDataMessage={
                        <p className="no-data-message">{ NO_IMPORT_DATA }</p>
                    }
                />
                {modalShow && <ProgramModal
                    handleModal={handleProgramModal}
                    importStatusFlag={true}
                    handleNotification={handleNotification}
                />}
                {showNotification && (
                    <GNNotification
                        handleShow={setShowNotification}
                        message={notificationMsg}
                        milliseconds={10000}
                        show={showNotification}
                        success={notificationIsSuccess}
                    />
                )}
            </ErrorBoundary>
        </div>
    );
}

ImportBatchStatusTable.defaultProps = {
    batchType: "shows",
    batchData: [],
    tableType: "success"
};

ImportBatchStatusTable.propTypes = {
    batchType: PropTypes.string,
    batchData: PropTypes.array,
    tableType: PropTypes.string
};

export default ImportBatchStatusTable;