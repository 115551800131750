import React from 'react';
import PropTypes from 'prop-types';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ClassNames from 'classnames';
import { getStatusColor, getStatusIcon, convertVocab } from '../../../utils/MobiusUtils';
import './MobiusStatusIcon.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { mobiusVocabSelGnvocab } from '../../../reducers/MobiusVocabReducer';
import { MOBIUS_VOCABULARIES_LIST } from '../../../constants/Mobius';


export const MobiusStatusIcon = ({circle, onClick, status, selected}) => {
    const icon = getStatusIcon(status) || 'square';
    const color = getStatusColor(status) || 'color-default';
    const vocab = useSelector(mobiusVocabSelGnvocab);
    const fullStatusText = convertVocab(vocab, status, MOBIUS_VOCABULARIES_LIST.PUBLISH_STATUS, true);

    return (
        <Badge className={ClassNames(`mobius-badge ${color}`, {circle, 'rectangle': !circle, 'not-selected': !selected, 'mobius-cursor-default': !onClick})} {... (onClick && {onClick})} bg=''>
            {icon && <OverlayTrigger overlay={<Tooltip id="mobius-badge-tooltip">{fullStatusText}</Tooltip>} placement="bottom" trigger={circle ? ['hover', 'focus'] : null}>
                <span className="status-icon-container">
                    <span className="fa-stack">
                        <FontAwesomeIcon className={`status-circle ${color} fa-stack-2x`} icon="circle" />
                        <FontAwesomeIcon className={`status-icon ${color} fa-stack-1x`} icon={icon} />
                    </span>
                </span>
            </OverlayTrigger>}
            { !circle && <span className="badge-text">{fullStatusText}</span> }
        </Badge>
    );
}

MobiusStatusIcon.defaultProps = {
    circle: false,
    selected: false
};

MobiusStatusIcon.propTypes = {
    circle: PropTypes.bool,
    onClick: PropTypes.func,
    status: PropTypes.string,
    selected: PropTypes.bool
};

export default MobiusStatusIcon;