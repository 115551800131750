/* eslint react/prop-types: 0 */
/* eslint react/display-name: 0 */

import React from 'react';
import { OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import { convertLanguageFromCode } from '../utils/GeneralUtils';
import { Badge } from 'react-bootstrap';
import GNViewButton from '../components/common/gnviewButton/GNViewButton';
import { ROUTES } from '../config/Routes';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const LAST_UPDATED = 'Last Updated';
export const NEW_SELECTION = 'New selection: ';

export const MOBIUS_MOMENT_DATE_FORMAT = 'MMM D, YYYY h:mma';

export const TABLE_POPOVER_BUTTONS = {
    DELETE: 'Delete',
    REGISTER_PRESENTATION: 'Register a Presentation',
    REGISTER_VERSION: 'Register a Version'
};

export const MOBIUS_DATA_TYPES = {
    PROGRAMS: 'Programs',
    IMAGES: 'Images',
    CATALOGS: 'Catalogs',
    IMPORTS: 'Import Status'
}

export const MOBIUS_LABELS = {
    ADD_GENRES: 'Add new Genres: ',
    ADD_VERSION_LABELS: 'New Version Labels: ',
    CAST_CHARACTER_NAME: 'Character Name',
    CAST: 'Cast',
    CAST_FIRST_NAME: 'Cast First Name',
    CAST_LAST_NAME: 'Cast Last Name',
    CAST_ROLE: 'Cast Role',
    COLOR: 'Color',
    CREATE_NEW_VERSION_PRESENTATION: 'Create New Version/Presentation',
    CREW: 'Crew',
    CREW_FIRST_NAME: 'Crew First Name',
    CREW_LAST_NAME: 'Crew Last Name',
    CREW_TYPE: 'Crew Type',
    DESCRIPTION_LANGUAGE: 'Description Language',
    DESCRIPTIONS: 'Descriptions',
    DESCRIPTION_REQUIRED_MESSAGE: '* Minimum of one description length required for Publish',
    DURATION: 'Duration',
    DURATION_HOURS: 'Hours',
    DURATION_MINUTES: 'Minutes',
    DURATION_SECONDS: 'Seconds',
    DURATION_INVALID_MESSAGE: 'Duration is too long',
    DURATION_VALIDATION_ERROR: 'Numerical whole digits only',
    DURATION_REQUIRED_WHEN_PRODUCTION_STATUS_COMPLETED: 'Required when Production Status is completed',
    EPISODE_NUMBER: 'Episode Number',
    EPISODE_TITLE: 'Episode Title',
    EPISODE_TITLE_LANGUAGE: 'Episode Title Language',
    EXTERNAL_ID_LABEL: 'External ID Label',
    EXTERNAL_ID: 'External ID',
    EXTERNAL_ID_PRIMARY: 'Primary',
    EXTERNAL_ID_PROVIDER: 'Provider',
    EXTERNAL_IDS: 'External IDs',
    FINALE_DATE: 'Finale Date',
    GENRES: 'Genres',
    GNID_DISTRIBUTION_TITLE: 'Gracenote ID Distribution System',
    IMAGES: 'Images',
    IMAGE_ID: 'Image ID',
    INDUSTRY: 'Industry',
    INDUSTRY_NUMBER: 'Industry Number',
    INDUSTRY_NETWORK_NUMBER: 'Industry Network Number',
    INDUSTRY_NUMBER_TYPE: 'Industry Number Type',
    INDUSTRY_SYNDICATED_NUMBER: 'Industry Syndicated Number',
    INHERITED: '[inherited]',
    LEARN_MORE: 'Learn More',
    MAPPINGS: 'Self Mapping',
    NO_RELATED_PROGRAMS: 'There are no related versions or presentations for this program yet.',
    NO_EPISODES_ENTERED: 'No episode data entered.',
    NO_IMAGES_FOUND: 'No images found.',
    NO_CATALOGS_FOUND: 'No catalogs found.',
    NO_PROGRAMS_FOUND: 'No programs found.',
    NO_SEASON_AND_EPISODES_ENTERED: 'No season and episode data entered.',
    NO_RELATED_PROGRAMS_DESCRIPTION: 'Click the Register Version or Register Presentation button below to create related types.',
    NO_DATA_ENTERED: 'No data entered.',
    NO_SEASON_NUMBER: 'No Season Number',
    NON_SEASON: 'Non Season',
    ORIGINAL_SOURCE: 'Original Source',
    OVERVIEW: 'Overview',
    PART_NUMBER: 'Part Number',
    PLEASE_REVISE_FIELDS_IN_RED: 'Please revise fields in red',
    PRESENTATION_RELEASE_DATE: 'Presentation Release Date',
    PRESENTATION_RELEASE_YEAR: 'Presentation Release Year',
    PRESENTATION_LABELS: 'Presentation Labels',
    PRODUCTION: 'Production',
    PRODUCTION_COMPANIES: 'Production Companies',
    PRODUCTION_COMPANY: 'Production Company',
    PRODUCTION_COUNTRIES: 'Production Countries',
    PRODUCTION_COUNTRY: 'Production Country',
    PRODUCTION_STATUS: 'Production Status',
    PROGRAM_DETAILS: 'Program Details',
    PROGRAM_TYPE: 'Program Type',
    PROGRAM_SUBTYPE: 'Program Subtype',
    REGISTER_NEW_EPISODE: 'Register New Episode',
    REGISTER_NEW_PROGRAM: 'Register New Program',
    REGISTER_NEW_PRESENTATION: 'Register New Presentation',
    REGISTER_NEW_SEASON: 'Register Season',
    REGISTER_NEW_VERSION: 'Register New Version',
    RATINGS_ADVISORIES: 'Advisories',
    RATINGS_BODY: 'Rating Body',
    RATINGS: 'Ratings',
    RATINGS_NOT_YET_RATED: 'Not Yet Rated',
    RATINGS_RATING_EXEMPT: 'Rating Exempt',
    RATINGS_NOT_RATED_RADIO: 'Not Rated',
    RATINGS_RATED_RADIO: 'Rated',
    RATINGS_VALUE: 'Rating',
    RELEASE_DETAILS: 'Release Details',
    RELATED_PROGRAMS: 'Related Programs',
    RELATED_VERSIONS_AND_PRESENTATIONS: 'Related Versions & Presentations',
    REQUIRED_FOR_PUBLISH: '*Required for Publish',
    REQUIRED_ONCE_PUBLISHED: "*Required once program has been publish",
    REQUIRED_TO_REGISTER_THIS_PROGRAM: '*Required to register this program.',
    REGISTER_REQUIREMENTS_COMPLETED: 'Register Requirements Completed',
    REGISTER_AND_PUBLISH_REQUIREMENTS_COMPLETED: 'Register and Publish requirements completed',
    SEASON_EPISODE_NUMBER: 'Episode Number',
    SEASON_DESCRIPTION: 'Season Descriptions',
    SEASON_NUMBER: 'Season Number',
    SEASONS_AND_EPISODES: 'Seasons and Episodes',
    SEASON_SUBTYPE: 'Season Subtype',
    SEASONS_DROPDOWN_LABEL: 'Season #',
    TARGET_AUDIENCE: 'Target Audience',
    TITLE: 'Title',
    TITLE_LANGUAGE: 'Title Language',
    VERSION_RELEASE_DATE: 'Version Release Date',
    DATE_PLACEHOLDER: 'MM/DD/YYYY',
    VERSION_RELEASE_YEAR: 'Version Release Year',
    VERSION_LABELS: 'Version Labels',
    ONE_OF_TWO_REQUIRED: 'One of these two fields is required.'
};

export const MOBIUS_VALUES = {
    EMPTY_DATA: '-'
};

// Tooltips
export const MOBIUS_TOOLTIPS = {
    // Edit Button
    EDIT_BUTTON: 'Click here to make changes to this program.',
    EDIT_HEADER_INFO: 'SOME SORTA INFO HERE',
    // Register Button
    MUST_UPDATE_FIELD: 'At least one field needs to be updated',
    // Cast
    ADD_NEW_CAST_MEMBER: 'You can add another cast member after completing the existing fields',
    ADD_NEW_CREW_MEMBER: 'You can add another crew member after completing the existing fields',
    CAST_INFO: 'Cast details in Billing Order. Use the arrows to move rows.',
    CREW_INFO: 'Crew details in Billing Order. Use the arrows to move rows.',
    DELETE_CAST_MEMBER: 'Remove cast member',
    DELETE_CREW_MEMBER: 'Remove crew member',
    DELETE_SEASON: 'Delete Season',
    REORDER_CAST_MEMBER: 'Reorder cast members by clicking on the up or down arrows',
    REORDER_CREW_MEMBER: 'Reorder crew members by clicking on the up or down arrows',
    // Description
    DESCRIPTION_INFO: 'Provide at least one program description. Multi-length descriptions are supported and are encouraged to provide for various client needs.',
    // External Ids
    ADD_EXTERNAL_ID: 'You can add another ID after completing the existing ID fields',
    CLEAR_EXTERNAL_ID: 'Remove Label and ID',
    EXTERNAL_ID_INFO: 'List of IDs associated with the program. You must indicate a unique Primary ID. Provider ID (optional) indicates an ID from the original source of the content.',
    // Image
    ADD_IMAGE_FIELD: 'Click to load image details and add another Image ID',
    DELETE_IMAGE_FIELD: 'Remove Image',
    IMAGE_INFO: 'Provide full image links for program urls. Use the label to identify the image type and other details (e.g. banner, backdrop, Cast photo). Provide as many images as needed.',
    IMAGE_SECTION: 'Only registered images can be linked to programs.',
    IMAGE_LINKED: 'Image linked to program',
    // Industry fields
    INDUSTRY_INFO: 'Network and Syndicated Episode Numbers.',
    // Overview
    OVERVIEW_INFO: 'All the fields in the overview section are required for program registration.',
    // Production
    ADD_PRODUCTION_COMPANY: 'You can add another Production Company after completing the existing fields',
    ADD_PRODUCTION_COUNTRY: 'You can add another Production Country after completing the existing fields',
    DELETE_PRODUCTION_COMPANY: 'Remove Production Company',
    DELETE_PRODUCTION_COUNTRY: 'Remove Production Country',
    PRODUCTION_INFO: 'Provide any and all production companies associated with the program.',
    // Rating
    ADD_RATING: 'Must enter at least one entry',
    CLEAR_RATING: 'Remove Rating',
    RATING_INFO: 'Official ratings details for the program.',
    RATING_DISABLED: 'Select Rating Body to continue.',
    // Release Details
    RELEASE_INFO: 'Provide as much release information as possible, including original dates, technical details, etc.',
    REQUIRES_WRITE_ACCESS: "Requires Write Access",
    // Edit Season
    SUBTYPE_CHANGE: 'Subtype cannot be changed after it has been registered.',
    // Add Program to Catalogs
    MUST_SELECT_A_CATALOG: 'Must select at least one catalog.',
    // Mappings
    MAPPINGS_INFO: 'Optionally, enter a TMS ID to associate to the program when published. Gracenote may replace this ID if there is a better match',
    // BulkPublish
    BULK_PUBLISH_ADD_PROGRAMS: 'Add Programs',
    BULK_PUBLISH_MORE_THAN_1000: 'You have exceeded the max number of programs. Reduce to 1,000 programs or less to continue.'
};

// Program Status notifications
export const MOBIUS_NOTIFICATIONS = {
    ERROR_COULD_NOT_BE_OPENED: 'We cannot open the link. Please try again in a few minutes.',
    IMAGE_SUCCESS_SAVED: 'Image successfully saved!',
    IMAGE_SUCCESS_REGISTERED: 'Image successfully registered!',
    IMAGE_ERROR_ALREADY_MAPPED: 'Image already linked to this program.',
    IMAGE_ERROR_DELETE: "Sorry, there was an error deleting this image. Please try again in a few minutes.",
    IMAGE_ERROR_NOT_FOUND: 'Image no longer available.',
    IMAGE_ERROR_SAVED: 'We could not save your changes. Please try again in a few minutes.',
    IMAGE_SUCCESS_DELETE: "Your image record has been successfully deleted.",
    SEASONS_ERROR_DELETE: "Sorry, there was an error deleting this Season. Please try again in a few minutes.",
    SEASONS_ERROR_GET: "There was an error getting the seasons for this program. Please try again in a few minutes.",
    SEASONS_ERROR_REGISTERED: "There was an error registering the Season. Please try again in a few minutes.",
    PUBLISH_EXCEPTION: "We could not complete the latest publish request. Please review the following before requesting publish again.",
    PROGRAM_ERROR_DELETE: "Sorry, there was an error deleting this program. Please try again in a few minutes.",
    CATALOG_ERROR_DELETE: "Sorry, there was an error deleting this catalog. Please try again in a few minutes.",
    CATALOG_SUCCESS_SAVED: 'Catalog successfully saved!',
    CATALOG_SUCCESS_REGISTERED: 'Catalog successfully registered!',
    CATALOG_ERROR_SAVED: 'We could not save your changes. Please try again in a few minutes.',
    CATALOG_ERROR_REGISTERED: "There was an error registering the catalog. Please try again in a few minutes.",
    CATALOG_ERROR_UNIQUE_NAME: 'Catalog name must be unique.',
    PROGRAM_ERROR_PUBLISHED: "We could not process your publish request. Please try again in a few minutes.",
    PROGRAM_ERROR_REGISTERED: "There was an error registering the program. Please try again in a few minutes.",
    PROGRAM_ERROR_SAVED: "We could not save your changes. Please try again in a few minutes.",
    PROGRAM_SUCCESS_DELETE: "Your program record has been successfully deleted.",
    PROGRAM_DELETED: 'This program has been deleted',
    CATALOG_SUCCESS_DELETE: "Your catalog record has been successfully deleted.",
    PROGRAM_SUCCESS_PUBLISHED: "Your publish request has been received.",
    PROGRAM_SUCCESS_REGISTERED: "Program successfully registered!",
    PROGRAM_SUCCESS_SAVED: "Program saved!",
    SEASON_ERROR_SAVED: "We could not save your changes. Please try again in a few minutes.",
    SEASON_SUCCESS_REGISTERED: "Season successfully registered!",
    SEASON_SUCCESS_SAVED: "Season successfully saved!",
    CATALOG_PROGRAM_MAPPING_REMOVED_SUCCESS: "Successfully removed from the catalog!",
    CATALOG_PROGRAM_MAPPING_ADD_SUCCESS: "Successfully added program(s) to the catalog!",
    PROGRAM_ERROR_ADDING_TO_BULK_PUBLISH: "This program has already been added to the Bulk Publish List."
};

// Exact error descriptions from apis
export const API_ERROR_MESSAGES = {
    API_IMAGE_NOT_FOUND: 'image not found',
    API_IMAGE_ALREADY_MAPPED: 'image already mapped to specified presentation'
}

// Program Modal Image Error Messages
export const MOBIUS_IMAGE_ERRORS = {
    DUPLICATE_IMAGE: '* Duplicate Image',
    IMAGE_NOT_FOUND: '* Image not found'
}

// Confirmation modals
export const MOBIUS_CONFIRMATIONS = {
    CANCEL_CONFIRMATION_MESSAGE: "Unsaved changes will be lost.",
    CANCEL_CONFIRMATION_TITLE: "Are you sure you want to exit?",
    DELETE_PROGRAM_CONFIRMATION_MESSAGE: "Deleting this Program Record cannot be undone.",
    DELETE_CATALOG_CONFIRMATION_MESSAGE: "Deleting this Catalog Record cannot be undone.",
    DELETE_SEASON_CONFIRMATION_MESSAGE: "This action cannot be undone and will delete all the episodes within this season.",
    DELETE_PROGRAM_CONFIRMATION_TITLE: "Are you sure you want to delete this Program Record?",
    DELETE_CATALOG_CONFIRMATION_TITLE: "Are you sure you want to delete this Catalog Record?",
    DELETE_IMAGE_CONFIRMATION_MESSAGE: "Deleting this Image Record cannot be undone.",
    DELETE_IMAGE_CONFIRMATION_TITLE: "Are you sure you want to delete this Image Record?",
    DELETE_SEASON_CONFIRMATION_TITLE: "Are you sure you want to delete this season?",
    ERROR_ADDING_TO_CATALOG: "Error Adding to Catalog",
    ERROR_REMOVING_FROM_CATALOG: "Error Removing from Catalog",
    REGISTER_CONFIRMATION_MESSAGE: "Reminder: Program type and subtype cannot be changed after registration.",
    REGISTER_SEASON_CONFIRMATION_MESSAGE: "Reminder: Season subtype cannot be changed after registration.",
    REGISTER_CONFIRMATION_TITLE: "Proceed with registration?",
    REQUEST_PUBLISH_CONFIRMATION_MESSAGE: "Your program will be submitted to Gracenote for review. Reminder: Upon successful publishing, your program becomes available for delivery.",
    REQUEST_PUBLISH_CONFIRMATION_TITLE: "Proceed with publishing?",
    REMOVE_PROGRAMS_FROM_CATALOG_TITLE: "Remove from Catalog?",
    REMOVE_PROGRAMS_FROM_CATALOG_MESSAGE: "Selected programs will be removed from the current catalog list."
};

// Button text
export const MOBIUS_BUTTON_TEXT = {
    BULK_PUBLISH: "Bulk Publish",
    CANCEL: "Cancel",
    CLEAR_ALL: "Clear All",
    DELETE: "Delete",
    DELETE_SEASON: "Delete Season",
    DOWNLOAD_ERROR_LIST: "Download Error List",
    EDIT: "Edit",
    EDIT_SEASON: "Edit Season",
    EXIT: "Exit",
    REGISTER: "Register",
    PUBLISH: "Publish",
    REGISTER_NEW_EPISODE: "Register New Episode",
    REGISTER_NEW_IMAGE: "Register New Image",
    REGISTER_NEW_CATALOG: "Register New Catalog",
    REGISTER_NEW_PRESENTATION: "Register New Presentation",
    REGISTER_NEW_PROGRAM: "Register New Program",
    REGISTER_NEW_SEASON: "Register New Season",
    REMOVE_FROM_CATALOG: "Remove from this Catalog",
    REMOVE_PROGRAM_FROM_CATALOG: "Remove From Catalog",
    REQUEST_PUBLISH: "Request Publish",
    SAVE: "Save",
    SEARCH: "Search",
    SEARCH_FOR_TMS: 'Search for TMS ID'
};

// A list of GET /gnvocabulary
export const MOBIUS_VOCABULARIES_LIST = {
    AIRING_TYPE: 'airingType',
    CAST_TYPE: 'castType',
    COLOR: 'color',
    COUNTRIES: 'countries',
    CREW_TYPE: 'crewType',
    DESCRIPTION_LENGTH: 'descriptionLength',
    ENTITLEMENTS: 'entitlements',
    GENRE: 'genre',
    LANGUAGES: 'languages',
    PRODUCTION_STATUS: 'productionStatus',
    PROGRAM_TYPE: 'programType',
    PROGRAM_SUBTYPE: 'programSubType',
    PUBLISH_STATUS: 'publishStatus',
    RATING_BODY: 'ratingBody',
    TARGET_AUDIENCE: 'targetAudience',
    VERSION_LABELS: 'versionLabels'
};

export const MOBIUS_PROGRAM_TYPES_VALUES = {
    EPISODE: 'episode',
    MOVIE: 'movie',
    SHOW: 'show',
    SPORT: 'sport',
    TEAM_EVENT: 'teamEvent',
    THEATRE_EVENT: 'theatreEvent',
    NON_TEAM_EVENT: 'nonTeamEvent',
    SEASON: 'season',
    PROGRAM: 'program',
    CATALOG: 'catalog',
    IMAGE: 'image'
};

export const MOBIUS_SEASON_TYPES_VALUES = {
    SEASON_OF_A_SERIES: 'seasonOfASeries',
    DERIVATIVE: 'derivative'
}


export const MOBIUS_PROGRAM_TYPES_LABELS = {
    EPISODE: 'Episode',
    MOVIE: 'Movie',
    SHOW: 'Show',
    SPORT: 'Sport',
    TEAM_EVENT: 'Team Event',
    THEATRE_EVENT: 'Theatre Event',
    NON_TEAM_EVENT: 'Non-Team Event',
    SEASON: 'Season'
};

export const MOBIUS_PROGRAM_TYPES_CONVERTER = {
    // VALUES -> LABELS
    [MOBIUS_PROGRAM_TYPES_VALUES.EPISODE]: MOBIUS_PROGRAM_TYPES_LABELS.EPISODE,
    [MOBIUS_PROGRAM_TYPES_VALUES.MOVIE]: MOBIUS_PROGRAM_TYPES_LABELS.MOVIE,
    [MOBIUS_PROGRAM_TYPES_VALUES.SHOW]: MOBIUS_PROGRAM_TYPES_LABELS.SHOW,
    [MOBIUS_PROGRAM_TYPES_VALUES.SPORT]: MOBIUS_PROGRAM_TYPES_LABELS.SPORT,
    [MOBIUS_PROGRAM_TYPES_VALUES.TEAM_EVENT]: MOBIUS_PROGRAM_TYPES_LABELS.TEAM_EVENT,
    [MOBIUS_PROGRAM_TYPES_VALUES.THEATRE_EVENT]: MOBIUS_PROGRAM_TYPES_LABELS.THEATRE_EVENT,
    [MOBIUS_PROGRAM_TYPES_VALUES.NON_TEAM_EVENT]: MOBIUS_PROGRAM_TYPES_LABELS.NON_TEAM_EVENT,
    [MOBIUS_PROGRAM_TYPES_VALUES.SEASON]: MOBIUS_PROGRAM_TYPES_LABELS.SEASON,
    // LABELS -> VALUES
    [MOBIUS_PROGRAM_TYPES_LABELS.EPISODE]: MOBIUS_PROGRAM_TYPES_VALUES.EPISODE,
    [MOBIUS_PROGRAM_TYPES_LABELS.MOVIE]: MOBIUS_PROGRAM_TYPES_VALUES.MOVIE,
    [MOBIUS_PROGRAM_TYPES_LABELS.SHOW]: MOBIUS_PROGRAM_TYPES_VALUES.SHOW,
    [MOBIUS_PROGRAM_TYPES_LABELS.SPORT]: MOBIUS_PROGRAM_TYPES_VALUES.SPORT,
    [MOBIUS_PROGRAM_TYPES_LABELS.TEAM_EVENT]: MOBIUS_PROGRAM_TYPES_VALUES.TEAM_EVENT,
    [MOBIUS_PROGRAM_TYPES_LABELS.THEATRE_EVENT]: MOBIUS_PROGRAM_TYPES_VALUES.THEATRE_EVENT,
    [MOBIUS_PROGRAM_TYPES_LABELS.NON_TEAM_EVENT]: MOBIUS_PROGRAM_TYPES_VALUES.NON_TEAM_EVENT,
    [MOBIUS_PROGRAM_TYPES_LABELS.SEASON]: MOBIUS_PROGRAM_TYPES_VALUES.SEASON
};

export const MOBIUS_PROGRAM_SUBTYPES_VALUES = {
    COMPILATION: 'compilation',
    DERIVATIVE: 'derivative',
    HIGHLIGHTS: 'highlights',
    MINISERIES: 'miniseries',
    MUSIC_VIDEO: 'musicVideo',
    PAID_PROGRAM: 'paidProgram',
    SERIES: 'series',
    SPECIAL: 'special',
    EPISODE: 'episode',
    FEATURE_FILM: 'featureFilm',
    SHORT_FILM: 'shortFilm',
    TRAILER: 'trailer',
    TV_MOVIE: 'tvMovie',
    SEASON_OF_A_SERIES: 'seasonOfASeries',
    SPORT: 'sport',
    TEAM_EVENT: 'teamEvent',
    SPORT_EVENT: 'sportEvent',
    SPORT_RELATED_EPISODE: 'sportRelatedEpisode',
    THEATRE_EVENT: 'theatreEvent'
};

export const MOBIUS_PROGRAM_SUBTYPES_LABELS = {
    COMPILATION: 'Compilation',
    DERIVATIVE: 'Derivative',
    HIGHLIGHTS: 'Highlights',
    MINISERIES: 'Miniseries',
    MUSIC_VIDEO: 'Music Video',
    PAID_PROGRAM: 'Paid Program',
    SERIES: 'Series',
    SPECIAL: 'Special',
    EPISODE: 'Episode',
    FEATURE_FILM: 'Feature Film',
    SHORT_FILM: 'Short Film',
    TRAILER: 'Trailer',
    TV_MOVIE: 'TV Movie',
    SEASON_OF_A_SERIES: 'Season of a Series',
    SPORT: 'Sport',
    TEAM_EVENT: 'Team Event',
    SPORT_EVENT: 'Sport Event',
    SPORT_RELATED_EPISODE: 'Sport-Related Episode',
    THEATRE_EVENT: 'Theatre Event'
};

export const MOBIUS_PROGRAM_SUBTYPES_LOWERCASE_MAPPINGS = {
    'compilation': MOBIUS_PROGRAM_SUBTYPES_VALUES.COMPILATION,
    'derivative': MOBIUS_PROGRAM_SUBTYPES_VALUES.DERIVATIVE,
    'highlights': MOBIUS_PROGRAM_SUBTYPES_VALUES.HIGHLIGHTS,
    'miniseries': MOBIUS_PROGRAM_SUBTYPES_VALUES.MINISERIES,
    'musicvideo': MOBIUS_PROGRAM_SUBTYPES_VALUES.MUSIC_VIDEO,
    'paidprogram': MOBIUS_PROGRAM_SUBTYPES_VALUES.PAID_PROGRAM,
    'series': MOBIUS_PROGRAM_SUBTYPES_VALUES.SERIES,
    'special': MOBIUS_PROGRAM_SUBTYPES_VALUES.SPECIAL,
    'episode': MOBIUS_PROGRAM_SUBTYPES_VALUES.EPISODE,
    'featurefilm': MOBIUS_PROGRAM_SUBTYPES_VALUES.FEATURE_FILM,
    'shortfilm': MOBIUS_PROGRAM_SUBTYPES_VALUES.SHORT_FILM,
    'trailer': MOBIUS_PROGRAM_SUBTYPES_VALUES.TRAILER,
    'tvmovie': MOBIUS_PROGRAM_SUBTYPES_VALUES.TV_MOVIE,
    'seasonofaseries': MOBIUS_PROGRAM_SUBTYPES_VALUES.SEASON_OF_A_SERIES,
    'sport': MOBIUS_PROGRAM_SUBTYPES_VALUES.SPORT,
    'teamevent': MOBIUS_PROGRAM_SUBTYPES_VALUES.TEAM_EVENT,
    'sportevent': MOBIUS_PROGRAM_SUBTYPES_VALUES.SPORT_EVENT,
    'sportrelatedepisode': MOBIUS_PROGRAM_SUBTYPES_VALUES.SPORT_RELATED_EPISODE,
    'theatreevent': MOBIUS_PROGRAM_SUBTYPES_VALUES.THEATRE_EVENT
}

export const MOBIUS_IMAGES_ASPECT_RATIO_LABELS = {
    2_3: '2:3',
    3_4: '3:4',
    4_3: '4:3',
    16_9: '16:9',
    2_1: '2:1',
    9_5: '9:5'
};

export const REGISTER_MOBIUS_PROGRAM_TYPES = {
    [MOBIUS_PROGRAM_TYPES_LABELS.MOVIE]: 'movie',
    [MOBIUS_PROGRAM_TYPES_LABELS.SHOW]: 'show'
};

export const REGISTER_MOBIUS_PROGRAM_SUBTYPES = {
    [MOBIUS_PROGRAM_SUBTYPES_LABELS.FEATURE_FILM]: 'featureFilm',
    [MOBIUS_PROGRAM_SUBTYPES_LABELS.SERIES]: 'series',
    [MOBIUS_PROGRAM_SUBTYPES_LABELS.EPISODE]: 'episode',
    [MOBIUS_PROGRAM_SUBTYPES_LABELS.SHORT_FILM]: 'shortFilm'
};

// Based on Type selected, this is the default subtype that should be selected
export const MOBIUS_PROGRAM_DEFAULT_SUBTYPE = {
    [MOBIUS_PROGRAM_TYPES_VALUES.EPISODE]: MOBIUS_PROGRAM_SUBTYPES_VALUES.EPISODE,
    [MOBIUS_PROGRAM_TYPES_VALUES.MOVIE]: MOBIUS_PROGRAM_SUBTYPES_VALUES.FEATURE_FILM,
    [MOBIUS_PROGRAM_TYPES_VALUES.SHOW]: MOBIUS_PROGRAM_SUBTYPES_VALUES.SERIES
};

export const MOBIUS_STATUS = {
    REGISTERED: "registered",
    REQUESTED: "requested",
    PUBLISHING: "publishing",
    PUBLISHED: "published",
    PUBLISH_EXCEPTION: "publishException"
};

export const MOBIUS_STATUS_COLORS = {
    [MOBIUS_STATUS.REGISTERED]: 'color-blue',
    [MOBIUS_STATUS.REQUESTED]: 'color-purple',
    [MOBIUS_STATUS.PUBLISHING]: 'color-yellow',
    [MOBIUS_STATUS.PUBLISHED]: 'color-green',
    [MOBIUS_STATUS.PUBLISH_EXCEPTION]: 'color-red'
};

export const MOBIUS_STATUS_ICONS = {
    [MOBIUS_STATUS.REGISTERED]: 'list',
    [MOBIUS_STATUS.REQUESTED]: 'eye',
    [MOBIUS_STATUS.PUBLISHING]: 'ellipsis',
    [MOBIUS_STATUS.PUBLISHED]: 'check',
    [MOBIUS_STATUS.PUBLISH_EXCEPTION]: 'exclamation'
};

// VIEW-ONLY Constants
export const MOBIUS_EXTERNAL_IDS_COLUMN_HEADERS = [
    { accessor: 'label', Header: 'ID Name', Cell: ({ value }) => {
        return <span>{value || MOBIUS_VALUES.EMPTY_DATA}</span>
    }},
    { accessor: 'id', Header: 'ID', Cell: ({ value }) => {
        return <span>{value || MOBIUS_VALUES.EMPTY_DATA}</span>
    }},
    { accessor: 'details', Header: '', Cell: ({row}) => {
        // For custom checkboxes you need the prop 'custom', label, and a unique id
        const isPrimary = row.values.details.isPrimary ? <Form.Check custom inline id='custom-radio' type='radio' label='Primary' disabled={true} checked={true}/> : null;
        const isProvider = row.values.details.isProvider ? <Form.Check custom inline id='custom-radio' type='radio' label='Provider' disabled={true} checked={true}/> : null;
        return <div className='primary-provider-container'>{isPrimary}{isProvider}</div>;
    }}
];

export const MOBIUS_RATINGS_COLUMN_HEADERS = [
    { accessor: 'ratingBody', Header: 'Rating Body' },
    {
        accessor: 'details', Header: 'Rating', Cell: ({ row }) => {
            let rating = row.values.details.rating;
            if (row.values.details.notYetRated) {
                rating = MOBIUS_LABELS.RATINGS_NOT_YET_RATED;
            } else if (row.values.details.ratingExempt) {
                rating = MOBIUS_LABELS.RATINGS_RATING_EXEMPT;
            }
            return <div>{rating}</div>
        }
    },
    {
        accessor: 'advisories', Header: 'Advisories', Cell: ({ row }) => {
            const advisories = Array.isArray(row.values.advisories) && row.values.advisories.length ? row.values.advisories.join(', ') : '-'
            return <div>{advisories}</div>
        }
    }
];

export const MOBIUS_DESCRIPTIONS_COLUMN_HEADERS = [
    { accessor: 'length', Header: 'Descriptions', Cell: ({row}) => (
        <OverlayTrigger overlay={<Tooltip>Maximum Length</Tooltip>} placement='bottom-start'><div>{row.values.length}</div></OverlayTrigger>
    )},
    { accessor: 'value', Header: '' }
];

export const MOBIUS_CAST_COLUMN_HEADERS = [
    { accessor: 'name', Header: 'Cast Member Name', Cell: ({ value }) => {
        const firstName = value.first;
        const lastName = value?.last || null;
        return <div className='cast-name'>{firstName} {lastName}</div>
    }},
    { accessor: 'role', Header: 'Cast Role' },
    { accessor: 'character', Header: 'Character Name' }
];

export const MOBIUS_CREW_COLUMN_HEADERS = [
    { accessor: 'name', Header: 'Crew Member Name', Cell: ({ value }) => {
        const firstName = value.first;
        const lastName = value.last;
        return <div className='cast-name'>{firstName} {lastName}</div>
    }},
    { accessor: 'type', Header: 'Crew Type' }
];

export const MOBIUS_PROGRAM_READ_MODAL_IMAGE_HEADERS = [
    { accessor: 'gnID', Header: 'Image ID' },
    { accessor: 'title', Header: 'Title', id: 'title' },
    { accessor: 'category', Header: 'Category' },
    {
        id: 'tableDimensions',
        accessor: 'tableDimensions',
        Header: 'Dimensions'
    },
    { accessor: 'market', Header: 'Market' }
]

export const MOBIUS_PROGRAM_EDIT_MODAL_IMAGE_HEADERS = [
    {
        id: 'gnID',
        accessor: 'gnID',
        Header: 'Image ID'
    },
    {
        id: 'link',
        accessor: 'link',
        Header: '',
        Cell: ({ row }) => {
            if (row?.original?.link) {
                return (
                    <OverlayTrigger
                        overlay={
                            <Tooltip id='delete-image-tooltip'>{MOBIUS_TOOLTIPS.IMAGE_LINKED}</Tooltip>
                        }
                        placement='right'
                    >
                        <FontAwesomeIcon icon='fa-link' className='fa-link-alt'/>
                    </OverlayTrigger>
                )
            }
            return null;
        }
    },
    {
        id: 'title',
        accessor: 'title',
        Header: 'Title'
    },
    {
        id: 'category',
        accessor: 'category',
        Header: 'Category'
    },
    {
        id: 'tableDimensions',
        accessor: 'tableDimensions',
        Header: 'Dimensions'
    },
    {
        id: 'market',
        accessor: 'market',
        Header: 'Market'
    },
    {
        id: 'deleteButton',
        accessor: 'deleteButton',
        Header: ''
    }
]

export const MOBIUS_RELATED_PROGRAMS_COLUMN_HEADERS = (currentPresentationGnId, handlePresentationChange) => [
    { disableSortBy: true, accessor: 'title', Header: 'Title', Cell: ({row}) => {
        if (currentPresentationGnId === row?.original?.gnID) {
            return (
                <span className='related-programs-title-column current'>
                    <OverlayTrigger overlay={<Tooltip>This program is currently being viewed.</Tooltip>} placement='bottom'>
                        <span className='selected-title'>{row?.original?.title?.value}</span>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip>You are viewing this program right now.</Tooltip>} placement='bottom'>
                        <Badge className='primary'>Current Program</Badge>
                    </OverlayTrigger>
                </span>
            )
        } else {
            return <OverlayTrigger overlay={<Tooltip>View Program</Tooltip>} placement='bottom'>
                <span className='related-programs-title-column'>
                    <span className='title' onClick={() => handlePresentationChange(row?.original)}>{row?.original?.title?.value}</span>
                </span>
            </OverlayTrigger>
        }
    }},
    { disableSortBy: true, accessor: 'description', Header: 'Description Language', Cell: ({row}) => {
        return convertLanguageFromCode(row?.original?.descriptions?.[0]?.language);
    }},
    { disableSortBy: true, accessor: 'versionLabels', Header: 'Version Labels', Cell: ({ row }) => {
        return row?.original?.versionLabels?.join(', ') || '';
    }},
    { disableSortBy: true, accessor: 'presentationLabels', Header: 'Presentation Labels', Cell: ({row}) => {
        return row?.original?.presentationLabels?.join(', ') || '';
    }},
    { disableSortBy: true, accessor: 'releaseDate', Header: 'Release Date', Cell: ({row}) => {
        return row?.original?.releaseDate ? moment(row?.original?.releaseDate)?.format('MMM DD, YYYY') : null;
    }},
    { disableSortBy: true, accessor: 'tms_id', Header: '', Cell: ({row}) => {
        return <div className='gnview-btn-container'>
            {row?.original?.tmsID && <GNViewButton link={`${ROUTES.PROGRAM_DETAILS}/${row?.original?.tmsID}`} />}
        </div>
    }}
];

export const MOBIUS_REGISTER_PROGRAM_TYPE_OPTIONS = [
    { name: MOBIUS_PROGRAM_TYPES_LABELS.MOVIE, value: MOBIUS_PROGRAM_TYPES_LABELS.MOVIE },
    { name: MOBIUS_PROGRAM_TYPES_LABELS.SHOW, value: MOBIUS_PROGRAM_TYPES_LABELS.SHOW }
];

// Based on length, we're showing longer or shorter text areas
export const MOBIUS_DESCRIPTION_TEXT_AREA_ROWS = {
    '500': 5,
    '250': 4,
    '100': 3,
    '60': 2,
    '40': 2
};

export const PROGRAM_SIDE_MENU = {
    PUBLISH_EXCEPTION: {
        id: "publish-exception",
        label: "Publish Exception"
    },
    OVERVIEW: {
        id: "overview",
        label: "Overview"
    },
    SEASONS_EPISODES: {
        id: 'seasons-and-episodes',
        label: 'Seasons and Episodes'
    },
    RELEASE_DETAILS: {
        id: "release-details",
        label: "Release Details"
    },
    EXTERNAL_IDS: {
        id: "external-ids",
        label: "External IDs"
    },
    MAPPINGS: {
        id: 'mappings',
        label: 'Self Mapping'
    },
    RATINGS: {
        id: "ratings",
        label: "Ratings"
    },
    DESCRIPTIONS: {
        id: "descriptions",
        label: "Descriptions"
    },
    PRODUCTION: {
        id: "production",
        label: "Production"
    },
    CAST: {
        id: "cast",
        label: "Cast"
    },
    CREW: {
        id: "crew",
        label: "Crew"
    },
    IMAGES: {
        id: 'images',
        label: 'Images'
    }
};

export const IMAGE_SIDE_MENU = {
    OVERVIEW: {
        id: "overview",
        label: "Overview"
    },
    DESCRIPTION: {
        id: "description",
        label: "Description"
    },
    ADDITIONAL_DETAILS: {
        id: "additional-details",
        label: "Additional Details"
    },
    CUSTOM_ATTRIBUTES: {
        id: "custom-attributes",
        label: "Custom Attributes"
    }
}

export const CATALOG_SIDE_MENU = {
    OVERVIEW: {
        id: "overview",
        label: "Overview"
    }
}

// Intersection Ratios for the different components that appear in the programModal, used by ScrollSpymenu
export const PROGRAM_SIDE_MENU_RATIOS = [
    0.7,
    0.5,
    0.5,
    0.1,
    0.4,
    0.6,
    0.8,
    0.3,
    0.3,
    0.3,
    0.3,
    0.3
]

export const IMAGE_SIDE_MENU_RATIOS = [
    0.3,
    0.3,
    0.3,
    0.3
]

export const CAST_DEFAULT_VALUE = '';

export const CREW_DEFAULT_VALUE = '';

export const EDIT_DEFAULT_OPTION = { name: 'None', value: '' };

export const PUBLISH_PROGRAM_PRE_REQS_FIELDS = [
    {field: "descriptions", error: "at least one description must be provided before publishing"},
    {field: "ratings", error: "at least one rating must be provided before publishing."},
    {field: "originalSource", error: "originalSource must be provided before publishing."}

]
export const PUBLISH_MOVIE_PRE_REQS_FIELDS = [
    ...PUBLISH_PROGRAM_PRE_REQS_FIELDS,
    {field: "productionCountries", error: "at least one rating must be provided before publishing."},
    {field: "duration", error: "duration must be specified before publishing.", level: "version"}
];

export const PUBLISH_SHOW_AND_EPISODE_PRE_REQS_FIELDS = [
    ...PUBLISH_PROGRAM_PRE_REQS_FIELDS,
    {field: "color", error: "color must be provided before publishing."},
    {field: "duration", error: "duration must be specified before publishing.", level: "version"}
];

export const PUBLISH_SHOW_MINISERIES_PRE_REQS_FIELDS = [
    ...PUBLISH_PROGRAM_PRE_REQS_FIELDS,
    {field: "color", error: "color must be provided before publishing."}
];

export const MAX_DURATION = Math.pow(2, 63) - 1;

export const MOBIUS_BOOLEAN_TEXT = {
    TRUE: 'Yes',
    FALSE: 'No'
}

export const MOBIUS_PROGRAM_PUBLISHING_STATUS = {
    REGISTERED: 'registered',
    REQUESTED: 'requested',
    PUBLISHED: 'published',
    PUBLISHING: 'publishing'
}

export const MOBIUS_REQUIRED_MESSAGE = {
    REQUIRED: '*Required'
}

export const GET_CATALOGS_PAGE_LIMIT = 20;

export const MOBIUS_CATALOG_PROGRAM_ERROR_MODAL = {
    CATALOG_NOT_FOUND: 'Catalog Not Found',
    CATALOG_NOT_FOUND_SUMMARY: 'We could not add the program(s) to the catalog as the following catalog does not exist in the system.',
    CATALOG_NOT_FOUND_ERROR: 'catalog_not_found',
    DUPLICATE_PROGRAMS: 'Duplicate Program(s)',
    DUPLICATE_PROGRAMS_SUMMARY: 'We could not add the program(s) to the catalog as the following program(s) are already in the selected catalog, so they cannot be added again.',
    DUPLICATE_MAPPING_ERROR: 'duplicate_mapping',
    PROGRAM_NOT_FOUND: 'Program(s) Not Found',
    PROGRAM_NOT_FOUND_SUMMARY: 'We could not add the program(s) to the catalog as the following program(s) do not exist in the system.',
    PRESENTATION_NOT_FOUND_ERROR: 'presentation_not_found',
    MAPPING_NOT_FOUND: 'Mapping(s) Not Found',
    MAPPING_NOT_FOUND_SUMMARY: 'We could not remove the program(s) from the catalog because the mapping(s) do not exist in the system.',
    MAPPING_NOT_FOUND_ERROR: 'mapping_not_found'
}