import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const FormButtonIcon = ({ publishingStatus, disableRequestPublishButton }) => (
    <span className="status-icon-container">
        <span className="fa-stack custom-size">
            <FontAwesomeIcon className="status-circle color-purple fa-stack-2x" icon="circle" />
            {publishingStatus !== "published" && (
                <FontAwesomeIcon
                    className={classNames('status-icon', 'fa-stack-1x', {
                        'color-gray': disableRequestPublishButton,
                        'color-purple': !disableRequestPublishButton
                    })}
                    icon="arrow-up"
                />
            )}
            {publishingStatus === "published" && (
                <FontAwesomeIcon className="status-icon color-purple fa-stack-1x" icon="arrow-rotate-right" />
            )}
        </span>
    </span>
);


FormButtonIcon.propTypes = {
    publishingStatus: PropTypes.string.isRequired,
    disableRequestPublishButton: PropTypes.bool.isRequired
};

FormButtonIcon.defaultProps = {
    disableRequestPublishButton: false
};

export default FormButtonIcon;