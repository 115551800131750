import {
    MOBIUS_ADD_GNVOCABULARY_IN_PROGRESS,
    MOBIUS_ADD_GNVOCABULARY_SUCCESS,
    MOBIUS_ADD_GNVOCABULARY_FAIL,
    MOBIUS_ADD_ENTITLEMENTS,
    MOBIUS_ADD_ENTITLEMENTS_FAIL
} from '../actions/ActionTypes';
import { MOBIUS_ACCESS_TYPES_IMAGES } from '../constants/App';

export const MOBIUS_VOCAB_STORE = 'mobiusVocab';

/**
 * Initial State
 */
export const initialState = {
    gnvocabulary: {},
    entitlements: {},
    error: null
};

/**
 * Reducer
 */
export const MobiusVocabReducer = (state = initialState, action) => {
    switch (action.type) {
    case MOBIUS_ADD_GNVOCABULARY_IN_PROGRESS: {
        return { ...state, gnvocabulary: {}, error: null };
    }
    case MOBIUS_ADD_GNVOCABULARY_SUCCESS: {
        return { ...state, gnvocabulary: action.payload.gnvocabulary, error: null };
    }
    case MOBIUS_ADD_GNVOCABULARY_FAIL: {
        return { ...state, gnvocabulary: {}, error: action.payload.data };
    }
    case MOBIUS_ADD_ENTITLEMENTS: {
        return {
            ...state,
            entitlements: action.payload,
            error: null
        };
    }
    case MOBIUS_ADD_ENTITLEMENTS_FAIL: {
        return { ...state, error: action.payload.data };
    }
    default:
        return state;
    }
};

/**
 * Selectors
 */
export const mobiusVocabSelAiringType = (state) =>
    state[MOBIUS_VOCAB_STORE].gnvocabulary?.airingType;
export const mobiusVocabSelCastType = (state) => state[MOBIUS_VOCAB_STORE].gnvocabulary?.castType;
export const mobiusVocabSelColor = (state) => state[MOBIUS_VOCAB_STORE].gnvocabulary?.color;
export const mobiusVocabSelCountries = (state) => state[MOBIUS_VOCAB_STORE].gnvocabulary?.countries;
export const mobiusVocabSelCrewTypes = (state) => state[MOBIUS_VOCAB_STORE].gnvocabulary?.crewType;
export const mobiusVocabSelDescriptionLength = (state) =>
    state[MOBIUS_VOCAB_STORE].gnvocabulary?.descriptionLength;
export const mobiusVocabSelEntitledLanguages = (state) =>
    state[MOBIUS_VOCAB_STORE]?.entitlements?.programs?.include?.languages || [];

/* TODO Commenting it temporarily until the required changes are deployed on higher environments, so returning true always
export const mobiusVocabHasTMSEntitlements = (state) =>
    !state[MOBIUS_VOCAB_STORE]?.entitlements?.programs?.include?.mappings?.includes('tmsID'); */
export const mobiusVocabHasTMSEntitlements = () => true;

export const mobiusVocabSelGenre = (state) => state[MOBIUS_VOCAB_STORE].gnvocabulary?.genre;
export const mobiusVocabSelImageCategories = (state) => state[MOBIUS_VOCAB_STORE].gnvocabulary?.imageCategories;
export const mobiusVocabSelGnvocab = (state) => state[MOBIUS_VOCAB_STORE]?.gnvocabulary;
export const mobiusVocabSelLanguage = (state) => state[MOBIUS_VOCAB_STORE].gnvocabulary?.languages;
export const mobiusVocabSelProgramType = (state) => state[MOBIUS_VOCAB_STORE].gnvocabulary?.programType;
export const mobiusVocabSelPublishStatus = (state) => state[MOBIUS_VOCAB_STORE].gnvocabulary?.publishStatus;
export const mobiusVocabSelProductionStatus = (state) => state[MOBIUS_VOCAB_STORE].gnvocabulary?.productionStatus;
export const mobiusVocabSelRatingBody = (state) => state[MOBIUS_VOCAB_STORE].gnvocabulary?.ratingBody;
export const mobiusVocabSelTargetAudience = (state) => state[MOBIUS_VOCAB_STORE].gnvocabulary?.targetAudience;
export const mobiusVocabSelVersionLabels = (state) => state[MOBIUS_VOCAB_STORE].gnvocabulary?.versionLabels;
export const mobiusRegisterAccessForImage = state => state[MOBIUS_VOCAB_STORE].entitlements?.images?.include?.actions?.includes(MOBIUS_ACCESS_TYPES_IMAGES.REGISTER);

/**
 * Default export
 */
export default MobiusVocabReducer;
