import countries from "i18n-iso-countries";
import { LANGUAGES } from '../constants/Languages';

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

// Replace null item in provided array with replacement appended at end
export const replaceNull = (arr, replacement) => {
    if (arr?.length > 0 && arr.includes(null)) {
        const idx = arr.indexOf(null);
        arr.splice(idx, 1);
        if (replacement) {
            arr.push(replacement);
        }
    }
};

// Finds objects in array using provided prop and category value for comparison, compares with replacement if prop is null
export const getObjsFind = (arr, prop, category, replacement) => {
    return arr?.length > 0 ? arr.find(obj => {
        if (category === replacement) {
            return obj[prop] === null;
        } else {
            return obj[prop] === category;
        }
    }) : {};
};

// Filters objects in array using provided prop and category value for comparison, compares with replacement if prop is null
export const getObjsFilter = (arr, prop, category, replacement) => {
    return arr?.length > 0 ? arr.filter(obj => {
        if (category === replacement) {
            return obj[prop] === null;
        } else {
            return obj[prop] === category;
        }
    }) : [];
};

// Returns first element of provided array if non-empty, null or provided default if provided argument is non-array or empty array
export const getFirstElement = (arr, def = null) => arr?.length > 0 ? arr[0] : def;

// Converts an array of country codes ['USA'] to an array with code and name [{code: 'USA', name: 'United States of America'}]
export const convertCountryCodes = (countryCodes) => {
    const countryNameAndCodes = countryCodes?.reduce((result, code) => {
        // Only add the country if we can convert the country code to name
        if (countries.getName(code, 'en')) {
            result.push({ code, name: countries.getName(code, 'en') });
        }
        return result;
    }, []);

    // Sort alphabetically a-z
    return countryNameAndCodes?.sort((a, b) => a.name > b.name ? 1 : -1);
};

export const convertCountryCodeToName = (countryCode) => {
    countryCode = countryCode === "GS" ? "South Georgia and The South Sandwich Isl" : countryCode;
    return countries.getName(countryCode, 'en') || countryCode
}
export const convertCountryCodesListToNamesList = (countryCodesLists) => {
    return countryCodesLists?.length > 0 ? countryCodesLists.map(countryCode => convertCountryCodeToName(countryCode)) : []
};

export const convertCountryNameToCode = (countryName) => {
    countryName = countryName === "Côte d'Ivoire" ? "Cote D'Ivoire" : countryName;
    // South Georgia and The South Sandwich Isl is not in the alpha codes
    countryName = countryName === "South Georgia and The South Sandwich Isl" ? "GS" : countryName;
    return countries.getAlpha2Code(countryName, 'en') || countryName
}
export const convertCountryNamesListToCodesList = (countryNamesList) => {
    return countryNamesList?.length > 0 ? countryNamesList.map(countryName => convertCountryNameToCode(countryName)) : []
};

export const formatExportFileName = (response) => (
    response.headers['content-disposition']?.split("filename=")[1].slice(1, -1)
)

export const downloadExportFile = (data, dataType, fileName) => {
    // For IE 10+ and Edge.
    if (window.navigator.msSaveBlob) {
        const blob = new Blob([data], { type: dataType });
        window.navigator.msSaveBlob(blob, fileName);
    } else {
        const downloadLink = document.createElement('a');
        downloadLink.download = fileName;
        // For newer browsers
        if (window.URL.createObjectURL) {
            const blob = new Blob([data], { type: dataType });
            downloadLink.href = window.URL.createObjectURL(blob);
        } else {
            // For older browsers
            downloadLink.href = `data:${dataType}, ${encodeURIComponent(data)}`;
        }
        // Force click event to trigger download
        if (document.createEvent) {
            const event = document.createEvent('MouseEvents');
            event.initEvent('click', true, true);
            downloadLink.dispatchEvent(event);
        } else {
            downloadLink.click();
        }
    }
};

export const numberWithCommas = (num) => {
    return !isNaN(num) ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0';
};

export const convertLanguageFromCode = (code) => {
    return LANGUAGES[code] || code || '';
};

export const convertLanguageCodeList = languageCodes => languageCodes?.length > 0 ? languageCodes.map(code => convertLanguageFromCode(code)) : [];

export const convertCodeFromLanguage = (language) => {
    return Object.keys(LANGUAGES).find(key => LANGUAGES[key] === language) || language;
}

export const convertCodeLanguageList = languageList => languageList?.length > 0 ? languageList.map(language => convertCodeFromLanguage(language)) : [];

export const handleEventPropagation = (event) => {
    event.stopPropagation();
    event.preventDefault();
}

export const convertStringtoArray = (data) => {
    if (data) {
        if (typeof (data) === 'string') {
            return [data]
        }
        return data
    }
    return []
}