import React from 'react';
import Badge from 'react-bootstrap/Badge';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import './GNBadge.scss';
import { getProgramColor } from '../../../utils/ProgramUtils';
import { getImageColor } from '../../../utils/ImageUtils';

export const GNBadge = (props) => {
    const color = props.viewSelector === 'programs' ? getProgramColor(props.type, props.gnview) || props.color || 'color-default' : getImageColor(props.type)

    return (
        <Badge className={ClassNames('gn_badge', `${color}`, `${props.onClick ? 'cursor-pointer' : 'not-allowed'}`)} onClick={props.onClick ? props.onClick : null} bg=''>
            { props.viewSelector === 'programs' && props.subtype && <div className='badge-text'>{props.subtype}</div> }
            { props.viewSelector === 'programs' && !props.subtype && <div className='badge-text'>SUBTYPE</div> }
            { props.viewSelector === 'images' && <div className='badge-text'>{props.value}</div> }
        </Badge>
    );
}

GNBadge.defaultProps = {
    gnview: true,
    viewSelector: 'programs'
};

GNBadge.propTypes = {
    color: PropTypes.string,
    gnview: PropTypes.bool,
    onClick: PropTypes.func,
    subtype: PropTypes.string,
    type: PropTypes.string,
    viewSelector: PropTypes.oneOf(['programs', 'images']),
    value: PropTypes.string
};

export default GNBadge;