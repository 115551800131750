import React from 'react'
import { Form} from 'react-bootstrap';
import PropTypes from 'prop-types';
import './GNRowCheckbox.scss';


export const GNRowCheckbox = ({addSelectedProgram, checked, removeSelectedProgram, row}) => {
    return (
        <Form className="row-checkbox-container">
            <Form.Check
                className="row-checkbox"
                type='checkbox'
                checked={checked}
                onChange={() => {
                    if (checked) {
                        removeSelectedProgram(row)
                    } else {
                        addSelectedProgram(row)
                    }
                }}
                onClick={(event) => {
                    event.stopPropagation();
                }}/>
        </Form>
    )
}

GNRowCheckbox.propTypes = {
    addSelectedProgram: PropTypes.func,
    checked: PropTypes.bool,
    removeSelectedProgram: PropTypes.func,
    row: PropTypes.object
}

export default GNRowCheckbox
