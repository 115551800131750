import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ProgramModalAddButton.scss';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';

export const ProgramModalAddButton = ({enabled, hasLabel, onButtonClick, tooltipPlacement, tooltipText}) => {
    return (
        <div className='add-button-main-container'>
            <OverlayTrigger overlay={<Tooltip id="add-ext-id-tooltip">{tooltipText}</Tooltip>} placement={tooltipPlacement}>
                <div className={ClassNames('add-button', {enabled, hasLabel})} onClick={() => {
                    if (enabled) {
                        onButtonClick()
                    }
                }}><FontAwesomeIcon icon='plus'/>ADD</div>
            </OverlayTrigger>
        </div>
    )
};

ProgramModalAddButton.defaultProps = {
    hasLabel: false,
    tooltipPlacement: 'right',
    tooltipText: ''
};

ProgramModalAddButton.propTypes = {
    enabled: PropTypes.bool,
    hasLabel: PropTypes.bool,
    onButtonClick: PropTypes.func.isRequired,
    tooltipPlacement: PropTypes.string,
    tooltipText: PropTypes.string
};

export default ProgramModalAddButton;