export const ROUTES = {
    AUTH_FORBIDDEN: '/auth-forbidden',
    GNID_DISTRIBUTION: '/gnid-distribution',
    GNID_IMAGES: '/gnid-distribution/images',
    GNID_IMPORT_STATUS: '/gnid-distribution/import-status',
    GNID_CATALOGS: '/gnid-distribution/catalogs',
    PROGRAM_DETAILS: '/programs',
    ACCESS_DENIED: '/gnid-distribution-access-denied',
    LANDING: '/'
};
