import { GNIDS_BULK_PUBLISH_CLEAR_PROGRAMS, GNIDS_BULK_PUBLISH_SET_PROGRAMS } from "./ActionTypes";

export const gnidsSetProgramsForBulkPublish = (programsToPublish) => ({
    type: GNIDS_BULK_PUBLISH_SET_PROGRAMS,
    payload: programsToPublish

});

export const gnidsClearProgramsForBulkPublish = () => ({
    type: GNIDS_BULK_PUBLISH_CLEAR_PROGRAMS

});