import React from 'react';
import './OverviewSection.scss';
import PropTypes from 'prop-types';
import { MOBIUS_VALUES } from '../../../constants/Mobius';
import { IMAGE_MODAL_SECTIONS, IMAGE_LABELS } from '../../../constants/Image';
import ImageModalViewOnlyField from '../imageModalViewOnlyField/ImageModalViewOnlyField';

function OverviewSection({imageData}) {
    return (
        <div className='image-overview-section'>
            <div className="view-section-title">
                {IMAGE_MODAL_SECTIONS.OVERVIEW}
            </div>
            <div className='view-section-body'>
                <div className='view-section-row'>
                    <ImageModalViewOnlyField label={IMAGE_LABELS.CATEGORY} value={imageData?.category ? imageData?.category : MOBIUS_VALUES.EMPTY_DATA}/>
                    <ImageModalViewOnlyField label={IMAGE_LABELS.DIMENSIONS} value={imageData?.dimensions?.width && imageData?.dimensions?.height ? `${imageData?.dimensions?.width} x ${imageData?.dimensions?.height}` : MOBIUS_VALUES.EMPTY_DATA}/>
                    <ImageModalViewOnlyField label={IMAGE_LABELS.ASPECT_RATIO} value={imageData?.dimensions?.aspectRatio ? imageData?.dimensions?.aspectRatio : MOBIUS_VALUES.EMPTY_DATA}/>
                    <ImageModalViewOnlyField label={IMAGE_LABELS.IMAGE_FILE_TYPE} value={imageData?.fileType ? imageData?.fileType : MOBIUS_VALUES.EMPTY_DATA}/>
                </div>
                <div className='view-section-row'>
                    <ImageModalViewOnlyField label={IMAGE_LABELS.TITLE} value={imageData?.title ? imageData?.title : MOBIUS_VALUES.EMPTY_DATA}/>
                </div>
                <div className='view-section-row'>
                    <ImageModalViewOnlyField label={IMAGE_LABELS.IMAGE_URL} value={imageData?.url ? imageData?.url : MOBIUS_VALUES.EMPTY_DATA} link={imageData?.url?.length > 0}/>
                </div>
            </div>
        </div>
    )
}

OverviewSection.propTypes = {
    imageData: PropTypes.object
}

export default OverviewSection;