import React from 'react';
import { PROGRAM_SIDE_MENU, MOBIUS_NOTIFICATIONS, MOBIUS_STATUS } from '../../../constants/Mobius';
import './PublishException.scss';
import PropTypes from 'prop-types';
import MobiusStatusIcon from '../mobiusStatusIcon/MobiusStatusIcon';

export const PublishException = ({ publishExceptions }) => {
    return (
        <div className='publish-exception-container' id={PROGRAM_SIDE_MENU.PUBLISH_EXCEPTION.id}>
            <div className='publish-exception-header'>
                <MobiusStatusIcon status={MOBIUS_STATUS.PUBLISH_EXCEPTION} circle={true} />
                <span className={'publish-exception-label'}>{PROGRAM_SIDE_MENU.PUBLISH_EXCEPTION.label}</span>
            </div>
            <div className='exceptions-list-container'>
                <span className='exceptions-message'>{MOBIUS_NOTIFICATIONS.PUBLISH_EXCEPTION}</span>
                <ul className='exceptions-list'>
                    {
                        Object.entries(publishExceptions).map(([reason, details], idx) =>
                            <li key={idx}>
                                <span className='exceptions-reason'>{reason}: </span><span className='exceptions-details'>{details.join(", ")}</span>
                            </li>
                        )
                    }
                </ul>
            </div>
        </div>
    )
};

PublishException.propTypes = {
    publishExceptions: PropTypes.object
};

export default PublishException;