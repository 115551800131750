import React from 'react';
import './GNNoDataMessage.scss';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'react-bootstrap'
import { MOBIUS_BUTTON_TEXT, MOBIUS_DATA_TYPES, MOBIUS_LABELS, MOBIUS_TOOLTIPS } from '../../../constants/Mobius';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GNButton from '../gnButton/GNButton';

export const GNNoDataMessage = ({programType, modal = false, hasWriteAccess = false, handleClick}) => {
    const renderReadAccessNoDataMessage = (type) => {
        switch (type) {
        case 'season':
            return modal ? MOBIUS_LABELS.NO_SEASON_AND_EPISODES_ENTERED : (
                <div className='message-with-button'>
                    Click
                    <GNButton
                        buttonClass='register-button'
                        tooltip={<Tooltip id="request-publish-button-tooltip">{MOBIUS_TOOLTIPS.REQUIRES_WRITE_ACCESS}</Tooltip>}
                        disabled
                    >
                        <FontAwesomeIcon className='fa-plus' icon='plus' />
                        {MOBIUS_BUTTON_TEXT.REGISTER_NEW_SEASON}
                    </GNButton>
                    to get started. You can add episodes after the season has been created.
                </div>
            )
        case 'episode':
            return MOBIUS_LABELS.NO_EPISODES_ENTERED
        case MOBIUS_LABELS.IMAGES:
            return MOBIUS_LABELS.NO_IMAGES_FOUND
        case MOBIUS_DATA_TYPES.CATALOGS:
            return MOBIUS_LABELS.NO_CATALOGS_FOUND
        default:
            return MOBIUS_LABELS.NO_PROGRAMS_FOUND
        }
    }
    const renderWriteAccessNoDataMessage = (type) => {
        switch (type) {
        case 'season':
            return (
                <div className='message-with-button'>
                    Click
                    <Button
                        className='register-button'
                        onClick={handleClick}
                    >
                        <FontAwesomeIcon className='fa-plus' icon='plus' />
                        {MOBIUS_BUTTON_TEXT.REGISTER_NEW_SEASON}
                    </Button>
                    to get started. You can add episodes after the season has been created.
                </div>
            )
        case 'episode':
            return (
                <div className='message-with-button'>
                    Click
                    <Button
                        className='register-button'
                        onClick={handleClick}
                    >
                        <FontAwesomeIcon className='fa-plus' icon='plus' />
                        {MOBIUS_BUTTON_TEXT.REGISTER_NEW_EPISODE}
                    </Button>
                    to add episodes to the currently selected season.
                </div>
            )
        case MOBIUS_LABELS.IMAGES:
            return MOBIUS_LABELS.NO_IMAGES_FOUND
        case MOBIUS_DATA_TYPES.CATALOGS:
            return MOBIUS_LABELS.NO_CATALOGS_FOUND
        default:
            return (
                <div>
                    No programs found.<br/>Click &quot;<strong>Register New Program</strong>&quot; above to get started.
                </div>
            )
        }
    }
    return (
        <div className='no-data-message-container'>
            {hasWriteAccess ? (
                <div className='no-data-write'>
                    {renderWriteAccessNoDataMessage(programType)}
                </div>
            ) : (
                <div className={modal ? 'no-data-read-left ' : 'no-data-read-center'}>
                    {renderReadAccessNoDataMessage(programType)}
                </div>
            )}
        </div>
    )
}

GNNoDataMessage.propTypes = {
    // programType: determines what type of message to return
    programType: PropTypes.string.isRequired,
    // modal: determines whether to style message for a modal view (left) or in list view (center)
    modal: PropTypes.bool,
    // hasWriteAccess: determines what type of message to return
    hasWriteAccess: PropTypes.bool,
    // handleClick: function that is used by the button in no data messages for Write users
    handleClick: PropTypes.func
};

export default GNNoDataMessage;