import React from "react";
import PropTypes from 'prop-types';
import FileUpload from "../../components/common/fileUpload/FileUpload";
import { PROGRAM_BULK_UPLOAD, MAX_FILE_SIZE } from "../../constants/BulkUpload";

import './StepOne.scss';

function StepOne({ handleNext, Link }) {
    return (
        <div className="step-one" data-testid='step-one'>
            <div className="instructions">
                <h4 className="step-title">{PROGRAM_BULK_UPLOAD.STEP1}</h4>
                <Link />
                <div className="instruction-details">{PROGRAM_BULK_UPLOAD.STEP1_INSTRUCTIONS}</div>
            </div>
            <FileUpload
                acceptTypes=".csv"
                maxSize={MAX_FILE_SIZE}
                maxTotalFiles={1}
                handleNext={handleNext}
            />
        </div>
    );
}

StepOne.propTypes = {
    handleNext: PropTypes.func,
    Link: PropTypes.node
}

export default StepOne;
