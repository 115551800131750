import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./GNViewButton.scss";
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

export const GNViewButton = ({link}) => {
    return (
        <Link to={link} target="_blank" rel="noreferrer" className="gnview-button" data-testid="gnview-button">
            <OverlayTrigger overlay={<Tooltip>Gracenote View <br />Program Details Page</Tooltip>} placement="bottom">
                <div className="gnview-button-inner">
                    <span className="button-text"><span className="bold">GN</span>VIEW</span>
                    <FontAwesomeIcon className="link-icon" icon="external-link-alt" />
                </div>
            </OverlayTrigger>
        </Link>
    )
}

GNViewButton.propTypes = {
    link: PropTypes.string
};

export default GNViewButton;