import React, { useState, useEffect } from 'react';
import { Button, Modal, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import "./GNErrorModal.scss";
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GNClientTable from '../gnClientTable/GNClientTable';
import { CLIENT_TABLE_LARGE_PAGE_SIZE } from '../../../constants/App';
import { ERROR_MODAL_CATALOG_COLUMNS, ERROR_MODAL_PROGRAM_COLUMNS } from '../../../constants/MobiusListView';
import { MOBIUS_CATALOG_PROGRAM_ERROR_MODAL } from '../../../constants/Mobius';
import { findMatchingObjects, findTitleByCatalogGnID, findTitleByPresentationGnID } from '../../../utils/ProgramUtils';
import { CATALOG_PROGRAM_ERRORS } from '../../../constants/Program';

export const GNErrorModal = ({isLoading, isAddError, modalTitle, error, submitButtonHandler, submitButtonText, cancelButtonHandler, show, allPrograms, allCatalogs, allExpandedEpisodes}) => {
    const [allAddErrors, setAllAddErrors] = useState([]);
    const [errorTypeSelected, setErrorTypeSelected] = useState('');
    const [duplicatePrograms, setDuplicatePrograms] = useState([]);
    const [presentationNotFound, setPresentationNotFound] = useState([]);
    const [catalogNotFound, setCatalogNotFound] = useState([]);
    const [mappingNotFound, setMappingNotFound] = useState([]);

    useEffect(() => {
        setAllAddErrors(filterCatalogProgramErrors(error))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {
        if (isAddError) {
            setErrorTypeSelected(allAddErrors[0]?.value || [])
        }
    }, [allAddErrors, isAddError])

    // reorganize the errors from the /catalogProgram api error response so they are grouped by error type
    const filterCatalogProgramErrors = (errors) => {
        const groupedErrorList = errors.descriptionDetails.reduce((acc, item) => {
            const type = item.type;
            const programTitle = findTitleByPresentationGnID(allPrograms, allExpandedEpisodes, item?.presentationGnID)
            const catalogTitle = findTitleByCatalogGnID(allCatalogs, item?.catalogGnID)

            if (!acc[type]) {
                acc[type] = [];
            }

            acc[type].push({type: item.type, programTitle, catalogTitle, ...item});
            return acc;
        }, {});
        setErrors(groupedErrorList)
        return isAddError ? findMatchingObjects(groupedErrorList, CATALOG_PROGRAM_ERRORS) : [];
    };

    const setErrors = (errors) => {
        if (isAddError) {
            setDuplicatePrograms(errors[MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.DUPLICATE_MAPPING_ERROR] || []);
            setPresentationNotFound(errors[MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.PRESENTATION_NOT_FOUND_ERROR] || []);
            setCatalogNotFound(errors[MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.CATALOG_NOT_FOUND_ERROR] || []);
        } else {
            setMappingNotFound(errors[MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.MAPPING_NOT_FOUND_ERROR] || []);
        }
    }

    const handleErrorButtonChange = (val) => {
        setErrorTypeSelected(val)
    }

    const handleTableSelect = () => {
        if (errorTypeSelected === MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.CATALOG_NOT_FOUND_ERROR) {
            return errorTable(MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.CATALOG_NOT_FOUND_SUMMARY, ERROR_MODAL_CATALOG_COLUMNS, [catalogNotFound[0]]);
        } else if (errorTypeSelected === MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.DUPLICATE_MAPPING_ERROR) {
            return errorTable(MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.DUPLICATE_PROGRAMS_SUMMARY, ERROR_MODAL_PROGRAM_COLUMNS, duplicatePrograms);
        }
        return errorTable(MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.PROGRAM_NOT_FOUND_SUMMARY, ERROR_MODAL_PROGRAM_COLUMNS, presentationNotFound);
    }

    const errorTable = (summary, tableColumn, tableData) => {
        return (
            <div>
                <div className='body-text'>
                    {summary}
                </div>
                <GNClientTable
                    className='error-modal-table'
                    columns={tableColumn}
                    data={tableData}
                    settings={{pageSize: CLIENT_TABLE_LARGE_PAGE_SIZE}}
                />
            </div>
        )
    }

    return (
        <Modal show={show} centered dialog className="gnview-error-confirmation-modal" onHide={cancelButtonHandler}>
            {isLoading && <LoadingSpinner />}
            {!isLoading && <>
                <Modal.Header closeButton>
                    <div className="header-text">
                        <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" size='xl' className="error-icon"/>
                        <Modal.Title>
                            {modalTitle}
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {(duplicatePrograms?.length > 0 && presentationNotFound?.length < 1 && catalogNotFound?.length < 1 && isAddError && allAddErrors.length === 1) &&
                    <>
                        <div className='body-title'>
                            {`${duplicatePrograms?.length > 1 ? duplicatePrograms?.length : ''} ${MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.DUPLICATE_PROGRAMS}`}
                        </div>
                        {errorTable(MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.DUPLICATE_PROGRAMS_SUMMARY, ERROR_MODAL_PROGRAM_COLUMNS, duplicatePrograms)}
                    </>
                    }
                    {(duplicatePrograms?.length < 1 && presentationNotFound?.length > 0 && catalogNotFound?.length < 1 && isAddError && allAddErrors.length === 1) &&
                    <>
                        <div className='body-title'>
                            {`${presentationNotFound?.length > 1 ? presentationNotFound?.length : ''} ${MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.PROGRAM_NOT_FOUND}`}
                        </div>
                        {errorTable(MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.PROGRAM_NOT_FOUND_SUMMARY, ERROR_MODAL_PROGRAM_COLUMNS, presentationNotFound)}
                    </>
                    }
                    {(duplicatePrograms?.length < 1 && presentationNotFound?.length < 1 && catalogNotFound?.length > 0 && isAddError && allAddErrors.length === 1) &&
                    <>
                        <div className='body-title'>
                            {`${catalogNotFound?.length > 1 ? catalogNotFound?.length : ''} ${MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.CATALOG_NOT_FOUND}`}
                        </div>
                        {errorTable(MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.CATALOG_NOT_FOUND_SUMMARY, ERROR_MODAL_CATALOG_COLUMNS, [catalogNotFound[0]])}
                    </>
                    }
                    {(mappingNotFound?.length > 0 && !isAddError) &&
                    <>
                        <div className='body-title'>
                            {`${mappingNotFound?.length > 1 ? mappingNotFound?.length : ''} ${MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.MAPPING_NOT_FOUND}`}
                        </div>
                        {errorTable(MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.MAPPING_NOT_FOUND_SUMMARY, ERROR_MODAL_PROGRAM_COLUMNS, mappingNotFound)}
                    </>
                    }
                    {(allAddErrors?.length > 1 && isAddError) &&
                       <>
                           <ToggleButtonGroup className='error-type-switcher-container' type="radio" value={errorTypeSelected} onChange={handleErrorButtonChange} defaultValue={MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.CATALOG_NOT_FOUND_ERROR} name="error-type-switcher-container">
                               {
                                   allAddErrors.map((radio, idx) => (
                                       <ToggleButton className='error-type-button' id={`tbg-btn-${idx}`} value={radio.value} key={idx}>
                                           {(radio?.numOfErrors > 1 && radio?.name !== MOBIUS_CATALOG_PROGRAM_ERROR_MODAL.CATALOG_NOT_FOUND) && <div className='num_of_errors-icon'>{radio?.numOfErrors}</div>}
                                           {radio.name}
                                       </ToggleButton>
                                   ))
                               }
                           </ToggleButtonGroup>
                           {handleTableSelect()}
                       </>
                    }
                </Modal.Body>
                <Modal.Footer className="error-modal-footer">
                    <Button onClick={submitButtonHandler}>
                        <FontAwesomeIcon icon="fa-solid fa-download" className="dl-icon"/>
                        {submitButtonText}
                    </Button>
                </Modal.Footer>
            </>}
        </Modal>
    );
}

GNErrorModal.propTypes = {
    cancelButtonHandler: PropTypes.func,
    isAddError: PropTypes.bool,
    isLoading: PropTypes.bool,
    error: PropTypes.string,
    show: PropTypes.bool.isRequired,
    submitButtonHandler: PropTypes.func,
    submitButtonText: PropTypes.string,
    modalTitle: PropTypes.string,
    allPrograms: PropTypes.array,
    allCatalogs: PropTypes.array,
    allExpandedEpisodes: PropTypes.array
};

export default GNErrorModal;