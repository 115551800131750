import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, useLocation, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import GNIDDistribution from './views/GNIDDistribution';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { config } from '@fortawesome/fontawesome-svg-core';
import { GNVIEW_AUTH } from './constants/LocalStorage';
import { gnviewAuthUpdate } from './actions/GNViewAuthActions';
import { ROUTES } from './config/Routes';
import AccessDenied from './components/common/accessDenied/AccessDenied';
import { createBrowserHistory } from 'history';
import ErrorBoundary from './components/common/errorBoundary/ErrorBoundary';
import { hasMobiusEntitlements } from './utils/MobiusUtils';
import ImagesListView from './views/ImagesListView';
import CatalogListView from './views/CatalogListView';
import ImportStatusView from './views/ImportStatusView';
import PrivateRoute from './components/common/privateRoute/privateRoute'; // Correct import

// https://stackoverflow.com/questions/48358529/failed-to-execute-removechild-on-node-with-fontawesome-in-react
config.autoReplaceSvg = 'nest';
library.add(fas);
const history = createBrowserHistory();

const App = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        const serializedAuthState = localStorage.getItem(GNVIEW_AUTH);
        const authState = JSON.parse(serializedAuthState);
        if (authState) {
            dispatch(gnviewAuthUpdate(authState));
            const hasGNIDSAccess = hasMobiusEntitlements(authState);
            if (!hasGNIDSAccess) {
                history.replace(ROUTES.ACCESS_DENIED);
            }
        } else {
            history.push(`${ROUTES.LANDING}?redirectPath=${location.pathname}`);
        }
    }, [dispatch, location.pathname]);

    useEffect(() => {
        history.replace(`${window.location.pathname}${window.location.search}`);
    }, []);

    return (
        <ErrorBoundary>
            <Switch>
                <PrivateRoute
                    exact
                    path={ROUTES.GNID_DISTRIBUTION}
                    component={GNIDDistribution}
                />
                <PrivateRoute
                    path={ROUTES.GNID_IMAGES}
                    component={ImagesListView}
                />
                <PrivateRoute
                    path={ROUTES.GNID_CATALOGS}
                    component={CatalogListView}
                />
                <Route path={ROUTES.ACCESS_DENIED} component={AccessDenied} />
                <PrivateRoute
                    path={ROUTES.GNID_IMPORT_STATUS}
                    component={ImportStatusView}
                />
            </Switch>
        </ErrorBoundary>
    );
};

App.propTypes = {
    gnviewAuthUpdate: PropTypes.func.isRequired,
    history: PropTypes.object,
    location: PropTypes.object
}

export default withRouter(App)