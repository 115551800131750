import { convertLanguageFromCode } from './GeneralUtils';

const batchRows = [];

export function getErrorData(data) {
    const errorRows = [];
    for (const error of data.errors) {
        const path = error.path.split('[');
        const parentPath = path[0];
        const index = path[1].split(']')[0];
        const childPath = parentPath.substring(0, parentPath.length - 1);
        let title = "Referenced Row not found.";
        let row = "-";
        let parentGnID = '-';
        if (data.payload[parentPath] && data.payload[parentPath][index] && data.payload[parentPath][index][childPath]) {
            title = data.payload[parentPath][index][childPath].title?.value;
            row = data.payload[parentPath][index].context?.rowID;
            switch (parentPath) {
            case 'versions':
                parentGnID = data.payload[parentPath][index].rootGnID;
                break;
            case 'presentations':
                parentGnID = data.payload[parentPath][index].versionGnID;
                break;
            case 'seasons':
                parentGnID = data.payload[parentPath][index].presentationGnID;
                break;
            case 'episodes':
                parentGnID = data.payload[parentPath][index].seasonGnID;
                break;
            default:
                parentGnID = '-';
                break;
            }
        }
        errorRows.push(
            {
                "type": error.type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
                row,
                title,
                "parentGNID": parentGnID
            }
        );
    }
    return errorRows;
}

function getPresentationData(presentations) {
    presentations.map((presentation) => {
        batchRows.push({
            "title": presentation.presentation.title.value,
            "type": presentation.presentation.type,
            "subType": presentation.presentation.subType,
            "language": convertLanguageFromCode(presentation.presentation.title.language),
            "rootGnID": presentation.presentation.rootGnID,
            "versionGnID": presentation.presentation.versionGnID,
            "presentationGnID": presentation.presentation.gnID
        });
        if (presentation.seasons?.length) {
            getSeasonData(presentation.seasons);
        }
        if (presentation.episodes?.length) {
            getSeasonData(presentation.episodes);
        }
    });
}

function getSeasonData(seasons) {
    seasons.map((season) => {
        let seasonTitle = 'No Season';
        if (season?.season?.title?.value?.length > 0) {
            seasonTitle = season.season.title.value;
        } else if (season?.season?.seasonNumber) {
            seasonTitle = `${season.season.seasonNumber}`;
        }
        batchRows.push({
            "title": seasonTitle,
            "type": season.season.type,
            "subType": season.season.subType,
            "language": season?.season?.title?.language ? convertLanguageFromCode(season.season.title.language) : '',
            "rootGnID": '-',
            "versionGnID": '-',
            "presentationGnID": season.season.gnID
        });
        if (season.episodes?.length) {
            getEpisodeData(season.episodes)
        }
    });
}

function getEpisodeData(episodes) {
    episodes.map((episode) => {
        batchRows.push({
            "title": episode.episode.title.value,
            "type": episode.episode.type,
            "subType": episode.episode.subType,
            "language": convertLanguageFromCode(episode.episode.title.language),
            "rootGnID": '-',
            "versionGnID": '-',
            "presentationGnID": episode.episode.gnID
        });
    });
}

export function getDataFromRoots(roots) {
    batchRows.length = 0;
    for (const root of roots) {
        for (const version of root.versions) {
            if (version.presentations?.length) {
                getPresentationData(version.presentations);
            }
        }
    }
    return batchRows;
}

export function getDataFromVersions(versions) {
    batchRows.length = 0;
    for (const version of versions) {
        if (version.presentations?.length) {
            getPresentationData(version.presentations);
        }
    }
    return batchRows;
}

export function getDataFromPresentations(presentations) {
    batchRows.length = 0;
    getPresentationData(presentations);
    return batchRows;
}

export function getDataFromSeasons(seasons) {
    batchRows.length = 0;
    getSeasonData(seasons);
    return batchRows;
}

export function getDataFromEpisodes(episodes) {
    batchRows.length = 0;
    getEpisodeData(episodes);
    return batchRows;
}

export const determineBulkSeriesType = (type) => {
    if (type === 'show') {
        return 'series'
    }
    return type
}

export function convertKBtoMBAndAddUnit(bytes) {
    if (!bytes) {
        return '0 MB';
    }
    const kilobytes = bytes / 1000;
    const megabytes = kilobytes / 1000;

    return `${megabytes.toFixed(2)} MB`;
}