import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MOBIUS_PROGRAM_FIELDS, RATINGS_INITIAL_VALUE } from '../../../constants/MobiusProgramValidation';
import ProgramModalAddButton from '../programModalAddButton/ProgramModalAddButton';
import uniqId from 'uniqid';
import { MOBIUS_LABELS, MOBIUS_TOOLTIPS, NEW_SELECTION } from '../../../constants/Mobius';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './RatingsFields.scss';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import TypeaheadFilter from '../../common/typeaheadFilter/TypeaheadFilter';
import { ConditionalWrapper } from '../../common/conditionalWrapper/ConditionalWrapper';
import { convertStringtoArray } from '../../../utils/GeneralUtils';
import { mobiusVocabSelRatingBody } from '../../../reducers/MobiusVocabReducer';
import isString from 'lodash.isstring';

export const RatingsFields = ({
    errors,
    handleFormChange,
    ratings,
    validateForm,
    values
}) => {
    const GNVocabRatingBody = useSelector(mobiusVocabSelRatingBody)
    const [allRatingBodies, setAllRatingBodies] = useState([...ratings]);
    const [ratingsBody, setRatingsBody] = useState({});
    const [advisoriesValue, setAdvisoriesValue] = useState({});
    const [ratingsValue, setRatingsValue] = useState({});
    const [selectedRatingsBody, setSelectedRatingsBody] = useState({});
    const [selectedRatings, setSelectedRatings] = useState({});
    const [selectedAdvisories, setSelectedAdvisories] = useState({});
    const [ratingsBodyRef, setRatingsBodyRef] = useState({});
    const [ratingsRef, setRatingsRef] = useState({});
    const [advisoryRef, setAdvisoryRef] = useState({});

    const bodyToOptionsMap = {}
    GNVocabRatingBody.forEach(body => {
        bodyToOptionsMap[body.value] = body.rating
    })

    useEffect(() => {
        setAllRatingBodies([...ratings]);
    }, [ratings, setAllRatingBodies]);

    const addNewRatingField = () => {
        handleFormChange(MOBIUS_PROGRAM_FIELDS.RATINGS, [...ratings, { ...RATINGS_INITIAL_VALUE, uniqKey: uniqId() }]);
    };

    const deleteRatingField = (index) => {
        const filteredRatings = [...ratings].filter((i, idx) => idx !== index);
        handleFormChange(MOBIUS_PROGRAM_FIELDS.RATINGS, filteredRatings);
    };

    const formatRatingBody = (body) => {
        // checking to see if a ratingBody is a value or label in the GNVocabRatingBody
        if (body) {
            for (let i = 0; i < GNVocabRatingBody.length; i++) {
                if (body.toLowerCase() === GNVocabRatingBody[i].label.toLowerCase() || body === GNVocabRatingBody[i].value) {
                    return `${GNVocabRatingBody[i].label} (${GNVocabRatingBody[i].value})`
                }
            }
        }
        return body
    }

    const checkRatingValue = (ratingValue) => {
        if (ratingValue?.length > 0) {
            handleFormChange(ratingValue, '')
        }
    }
    const checkNotYetRated = (ratingNotYetRated) => {
        if (ratingNotYetRated) {
            handleFormChange(ratingNotYetRated, !ratingNotYetRated)
        }
    }
    const checkRatingExempt = (ratingExempt) => {
        if (ratingExempt) {
            handleFormChange(ratingExempt, !ratingExempt)
        }
    }
    const checkRatingAdvisories = (ratingAdvisories) => {
        if (ratingAdvisories?.length > 0) {
            handleFormChange(ratingAdvisories, [])
        }
    }

    const clearRatingsFields = (type, ratingValue, ratingNotYetRated, ratingExempt, ratingAdvisories = []) => {
        switch (type) {
        case MOBIUS_LABELS.RATINGS:
            checkNotYetRated(ratingNotYetRated)
            checkRatingExempt(ratingExempt)
            break;
        case MOBIUS_LABELS.RATINGS_RATING_EXEMPT:
            checkRatingValue(ratingValue)
            checkNotYetRated(ratingNotYetRated)
            break;
        case MOBIUS_LABELS.RATINGS_NOT_YET_RATED:
            checkRatingValue(ratingValue)
            checkRatingExempt(ratingExempt)
            break;
        default:
            checkRatingValue(ratingValue)
            checkNotYetRated(ratingNotYetRated)
            checkRatingExempt(ratingExempt)
            checkRatingAdvisories(ratingAdvisories)
        }
    }

    const handleCurrentInput = (val, idx, formState, setFormState) => {
        formState[idx] = val;
        setFormState(formState);
    }

    const handleRatingsBodyChange = (currentRatingBody, newRatingBodies, idx) => {
        const newRatingBody = filterTypeaheadData(newRatingBodies[0])
        if (newRatingBody) {
            ratingsBody[idx] = ''
            setRatingsBody(ratingsBody)
        }
        handleFormChange(currentRatingBody, newRatingBody);
        handleCurrentInput(newRatingBody, idx, selectedRatingsBody, setSelectedRatingsBody)
    }

    const handleRatingsChange = (currentRatingValue, newRatingValues, idx) => {
        const newRatingValue = filterTypeaheadData(newRatingValues[0])
        if (newRatingValue) {
            ratingsValue[idx] = ''
            setRatingsValue(ratingsValue)
        }
        handleFormChange(currentRatingValue, newRatingValue)
        handleCurrentInput(newRatingValue, idx, selectedRatings, setSelectedRatings)
    }

    const handleAdvisoriesChange = (currentRatingAdvisories, newRatingAdvisories, idx) => {
        const newRatingAdvisory = filterTypeaheadData(newRatingAdvisories)
        handleFormChange(currentRatingAdvisories, newRatingAdvisory);
        handleCurrentInput(newRatingAdvisories, idx, selectedAdvisories, setSelectedAdvisories)
    }

    const handleRatingsBodyOnBlur = (currentRatingBodies, idx) => {
        if (ratingsBody[idx]) {
            ratingsBodyRef[idx].clear();

            const newRatingsBody = {
                customOption: true,
                label: ratingsBody[idx],
                id: `new-id-${Math.floor(Math.random() * 10) + 10}`
            };
            selectedRatingsBody[idx] = selectedRatingsBody[idx] || [];
            selectedRatingsBody[idx].push(newRatingsBody);
            handleRatingsBodyChange(currentRatingBodies, selectedRatingsBody[idx], idx);
            handleCurrentInput('', idx, ratingsBody, setRatingsBody);
        }
    }

    const handleRatingsOnBlur = (currentsRatings, idx) => {
        if (ratingsValue[idx]) {
            ratingsRef[idx].clear();

            const newRating = {
                customOption: true,
                label: ratingsValue[idx],
                id: `new-id-${Math.floor(Math.random() * 10) + 10}`
            };
            selectedRatings[idx] = selectedRatings[idx] || [];
            selectedRatings[idx].push(newRating);
            handleRatingsChange(currentsRatings, selectedRatings[idx], idx);
            handleCurrentInput('', idx, ratingsValue, setRatingsValue);
        }
    }

    const handleAdvisoriesOnBlur = (currentRatingAdvisories, idx) => {
        if (advisoriesValue[idx]) {
            advisoryRef[idx].clear();

            const newAdvisory = {
                customOption: true,
                label: advisoriesValue[idx],
                id: `new-id-${Math.floor(Math.random() * 10) + 10}`
            };
            selectedAdvisories[idx] = selectedAdvisories[idx] || [];
            selectedAdvisories[idx].push(newAdvisory);
            handleAdvisoriesChange(currentRatingAdvisories, selectedAdvisories[idx], idx);
            handleCurrentInput('', idx, advisoriesValue, setAdvisoriesValue);
        }
    }

    return (
        <div className='ratings-fields-main-container'>
            {
                allRatingBodies?.map((rating, idx) => {
                    const isLast = idx === allRatingBodies.length - 1;
                    const newFieldIsFilled = isLast
                        ? rating?.ratingBody && (rating?.rating || rating?.ratingExempt || rating?.notYetRated)
                        : null;
                    const currentRatingBody = MOBIUS_PROGRAM_FIELDS.RATINGS_BODY(idx)
                    const currentRatingValue = MOBIUS_PROGRAM_FIELDS.RATINGS_VALUE(idx)
                    const currentRatingNotYetRated = MOBIUS_PROGRAM_FIELDS.RATINGS_NOT_YET_RATED(idx)
                    const currentRatingExempt = MOBIUS_PROGRAM_FIELDS.RATINGS_RATING_EXEMPT(idx)
                    const currentRatingAdvisories = MOBIUS_PROGRAM_FIELDS.RATINGS_ADVISORIES(idx)
                    return (
                        <div className='rating-input-container' key={rating.uniqKey}>
                            <div className='ratings-body-typeahead'>
                                <TypeaheadFilter
                                    ref={(ref) => {
                                        ratingsBodyRef[idx] = ref;
                                        setRatingsBodyRef(() => ratingsBodyRef);
                                    }}
                                    className='rating-input-typeahead'
                                    allowNew
                                    clearButton={!ratingsBody[idx]}
                                    domRef={ratingsBodyRef[idx]}
                                    errors={isString(errors?.ratings) && errors.ratings}
                                    filterLabel={MOBIUS_LABELS.RATINGS_BODY}
                                    onInputChange={(val) => {
                                        handleCurrentInput(val, idx, ratingsBody, setRatingsBody);
                                    }}
                                    handleChange={(newRatingBodies) => {
                                        handleRatingsBodyChange(currentRatingBody, newRatingBodies, idx);
                                        if (newRatingBodies?.length === 0) {
                                            clearRatingsFields(MOBIUS_LABELS.RATINGS_BODY, currentRatingValue, currentRatingNotYetRated, currentRatingExempt, currentRatingAdvisories)
                                        }
                                        selectedRatings[idx] = '';
                                        setSelectedRatings(selectedRatings);
                                        selectedAdvisories[idx] = '';
                                        setSelectedAdvisories(selectedAdvisories);
                                    }}
                                    handleBlur={() => {
                                        handleRatingsBodyOnBlur(currentRatingBody, idx);
                                        clearRatingsFields(MOBIUS_LABELS.RATINGS_BODY, currentRatingValue, currentRatingNotYetRated, currentRatingExempt, currentRatingAdvisories)
                                    }}
                                    list={GNVocabRatingBody}
                                    multiple={false}
                                    placeholder={MOBIUS_LABELS.RATINGS_BODY}
                                    selected={convertStringtoArray(formatRatingBody(ratingsBody[idx] || rating.ratingBody))}
                                    validateTypeahead={() => isString(errors?.ratings) ? !validateForm(MOBIUS_PROGRAM_FIELDS.RATINGS) : validateForm(currentRatingBody)}
                                    hasValue={Boolean(values?.ratings[idx]?.ratingBody)}
                                    validationText={isString(errors?.ratings) ? errors?.ratings : errors?.ratings?.ratingBody}
                                    addLabelKey={!rating?.ratingBody ? NEW_SELECTION : null}
                                />
                            </div>
                            <div className='ratings-options-typeahead'>
                                {MOBIUS_LABELS.RATINGS_VALUE}
                                <ConditionalWrapper
                                    condition={!rating?.ratingBody}
                                    wrapper={children => (
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip className='rating-tooltip'>{MOBIUS_TOOLTIPS.RATING_DISABLED}</Tooltip>
                                            }
                                            placement='top'
                                        >
                                            {children}
                                        </OverlayTrigger>
                                    )}
                                >
                                    <div className='ratings-value-typeahead'>
                                        <TypeaheadFilter
                                            ref={(ref) => {
                                                ratingsRef[idx] = ref;
                                                setRatingsRef(() => ratingsRef);
                                            }}
                                            className='rating-input-typeahead'
                                            clearButton={!ratingsValue[idx]}
                                            disabled={!rating?.ratingBody}
                                            domRef={ratingsRef[idx]}
                                            allowNew
                                            onInputChange={(val) => {
                                                handleCurrentInput(val, idx, ratingsValue, setRatingsValue);
                                            }}
                                            handleChange={(newRatingValues) => {
                                                handleRatingsChange(currentRatingValue, newRatingValues, idx);
                                                clearRatingsFields(MOBIUS_LABELS.RATINGS, currentRatingValue, currentRatingNotYetRated, currentRatingExempt)
                                            }}
                                            handleBlur={() => {
                                                handleRatingsOnBlur(currentRatingValue, idx);
                                                clearRatingsFields(MOBIUS_LABELS.RATINGS, currentRatingValue, currentRatingNotYetRated, currentRatingExempt)
                                            }}
                                            list={bodyToOptionsMap[rating.ratingBody] || []}
                                            multiple={false}
                                            placeholder={MOBIUS_LABELS.RATINGS_VALUE}
                                            selected={convertStringtoArray(ratingsValue[idx] || rating?.rating)}
                                            validateTypeahead={() => validateForm(currentRatingValue)}
                                            validationText={errors?.ratings?.rating}
                                            addLabelKey={!rating?.rating ? NEW_SELECTION : null}
                                        />
                                        <div className='rating-radio-container'>
                                            <div className='rating-radio-box'>
                                                <Form.Check
                                                    custom
                                                    inline
                                                    id={`custom-radio-not-yet-rated-${idx}`}
                                                    checked={rating.notYetRated}
                                                    type='radio'
                                                    disabled={!rating?.ratingBody}
                                                    value={rating?.notYetRated}
                                                    onClick={() => {
                                                        handleFormChange(currentRatingNotYetRated, !rating.notYetRated)
                                                        clearRatingsFields(MOBIUS_LABELS.RATINGS_NOT_YET_RATED, currentRatingValue, currentRatingNotYetRated, currentRatingExempt)
                                                        ratingsValue[idx] = ''
                                                        setRatingsValue(ratingsValue)
                                                        selectedRatings[idx] = undefined;
                                                        setSelectedRatings(selectedRatings);
                                                    }}
                                                />
                                                <div className='rating-radio-label'>{MOBIUS_LABELS.RATINGS_NOT_YET_RATED}</div>
                                            </div>
                                            <div className='rating-radio-box'>
                                                <Form.Check
                                                    custom
                                                    inline
                                                    id={`custom-radio-rating-exempt-${idx}`}
                                                    checked={rating.ratingExempt}
                                                    type='radio'
                                                    disabled={!rating?.ratingBody}
                                                    value={rating?.ratingExempt}
                                                    onClick={() => {
                                                        handleFormChange(currentRatingExempt, !rating.ratingExempt)
                                                        clearRatingsFields(MOBIUS_LABELS.RATINGS_RATING_EXEMPT, currentRatingValue, currentRatingNotYetRated, currentRatingExempt)
                                                        ratingsValue[idx] = ''
                                                        setRatingsValue(ratingsValue)
                                                        selectedRatings[idx] = undefined;
                                                        setSelectedRatings(selectedRatings);
                                                    }}
                                                />
                                                <div className='rating-radio-label'>{MOBIUS_LABELS.RATINGS_RATING_EXEMPT}</div>
                                            </div>
                                        </div>
                                    </div>
                                </ConditionalWrapper>
                            </div>
                            <div className='ratings-advisory-typeahead'>
                                {MOBIUS_LABELS.RATINGS_ADVISORIES}
                                <ConditionalWrapper
                                    condition={!rating?.ratingBody}
                                    wrapper={children => (
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip className='rating-tooltip'>{MOBIUS_TOOLTIPS.RATING_DISABLED}</Tooltip>
                                            }
                                            placement='top'
                                        >
                                            {children}
                                        </OverlayTrigger>
                                    )}
                                >
                                    <div>
                                        <TypeaheadFilter
                                            className='rating-input-typeahead'
                                            disabled={!rating?.ratingBody}
                                            allowNew
                                            domRef={advisoryRef[idx]}
                                            onInputChange={(val) => {
                                                handleCurrentInput(val, idx, advisoriesValue, setAdvisoriesValue);
                                            }}
                                            handleChange={(newRatingAdvisories) => {
                                                handleAdvisoriesChange(currentRatingAdvisories, newRatingAdvisories, idx);
                                                handleCurrentInput('', idx, advisoriesValue, setAdvisoriesValue);
                                            }}
                                            handleBlur={() => {
                                                handleAdvisoriesOnBlur(currentRatingAdvisories, idx)
                                            }}
                                            list={[]}
                                            multiple
                                            placeholder={MOBIUS_LABELS.RATINGS_ADVISORIES}
                                            ref={(ref) => {
                                                advisoryRef[idx] = ref;
                                                setAdvisoryRef(advisoryRef);
                                            }}
                                            selected={rating?.advisories}
                                            validateTypeahead={() => validateForm(currentRatingAdvisories)}
                                            validationText={errors?.ratings?.advisories || null}
                                        />
                                    </div>
                                </ConditionalWrapper>
                            </div>
                            <div className='vertical-line'></div>
                            {allRatingBodies.length > 1 && (
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip id='delete-rating-tooltip'>{MOBIUS_TOOLTIPS.CLEAR_RATING}</Tooltip>
                                    }
                                    placement='top'
                                >
                                    <div className='delete-rating' onClick={() => deleteRatingField(idx)}>
                                        <FontAwesomeIcon icon='trash-alt' className='fa-trash-alt'/>
                                    </div>
                                </OverlayTrigger>
                            )}
                            {isLast && (
                                <div className='add-rating'>
                                    <ProgramModalAddButton
                                        enabled={newFieldIsFilled}
                                        onButtonClick={addNewRatingField}
                                        tooltipPlacement='right'
                                        tooltipText={MOBIUS_TOOLTIPS.ADD_RATING}
                                    />
                                </div>
                            )}
                        </div>
                    );
                })}
        </div>
    );
};

const filterTypeaheadData = (ratingData) => {
    if (ratingData?.value) {
        return ratingData.value
    } else if (ratingData?.label) {
        return ratingData.label
    }
    return ratingData
}

RatingsFields.propTypes = {
    errors: PropTypes.object,
    handleFormChange: PropTypes.func,
    ratings: PropTypes.array.isRequired,
    validateForm: PropTypes.func,
    values: PropTypes.object
};

export default RatingsFields;
