import React from 'react';
import GNInput from '../../common/gnInput/GNInput';
import { MOBIUS_LABELS } from '../../../constants/Mobius';
import { MOBIUS_PROGRAM_FIELDS } from '../../../constants/MobiusProgramValidation';
import './IndustryFields.scss';
import PropTypes from 'prop-types';

export const IndustryFields = ({ errors, handleFormChange, industryNetworkNumber, industryNetworkSyndicated, isRequired, validateForm }) => {
    return (
        <div className='industry-fields-container'>
            <GNInput
                className='industry-type'
                handleChange={(item) => handleFormChange(MOBIUS_PROGRAM_FIELDS.INDUSTRY_NETWORK_NUMBER, item)}
                isRequired={isRequired}
                label={MOBIUS_LABELS.INDUSTRY_NETWORK_NUMBER}
                placeholder={MOBIUS_LABELS.INDUSTRY_NETWORK_NUMBER}
                validateInput={() => validateForm(MOBIUS_PROGRAM_FIELDS.INDUSTRY_NETWORK_NUMBER)}
                validationText={errors?.industryNetworkNumber}
                value={industryNetworkNumber} />
            <GNInput
                className='industry-number'
                handleChange={(item) => handleFormChange(MOBIUS_PROGRAM_FIELDS.INDUSTRY_SYNDICATED_NUMBER, item)}
                isRequired={isRequired}
                label={MOBIUS_LABELS.INDUSTRY_SYNDICATED_NUMBER}
                placeholder={MOBIUS_LABELS.INDUSTRY_SYNDICATED_NUMBER}
                validateInput={() => validateForm(MOBIUS_PROGRAM_FIELDS.INDUSTRY_SYNDICATED_NUMBER)}
                validationText={errors?.industryNetworkSyndicated}
                value={industryNetworkSyndicated} />
        </div>
    )
};

IndustryFields.propTypes = {
    errors: PropTypes.object,
    handleFormChange: PropTypes.func,
    industryNetworkNumber: PropTypes.string,
    industryNetworkSyndicated: PropTypes.string,
    isRequired: PropTypes.bool.isRequired,
    validateForm: PropTypes.func
};

export default IndustryFields;