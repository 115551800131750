import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { ButtonGroup, Button, Table } from 'react-bootstrap';
import ClassNames from 'classnames';
import "./GNServerTable.scss";
import { useTable, useSortBy, usePagination, useExpanded } from 'react-table';
import PropTypes from 'prop-types';
import { numberWithCommas } from '../../../utils/GeneralUtils';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gnidsSetSeasonNumber } from '../../../actions/GNIDSSeasonModalActions';
import { setCurrentPrograms } from '../../../actions/GNIDSCatalogProgramMappingActions';
import { mobiusGetEpisodesByPresentationId } from '../../../actions/MobiusActions';
import { MOBIUS_PROGRAM_TYPES_VALUES } from '../../../constants/Mobius';
import { gvauthSelMobiusSourceID } from '../../../reducers/GNVAuthReducer';

export const GNServerTable = ({ className, columns, fetchData, noDataMessage, paginationBottom, refreshRowSubComponent, renderRowSubComponent, showTableHeaderWithNoData = true, settings, onPageUpdate, selectedIds = [], setIsTableLoading, selectedCatalog }) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    // Note: if using sticky column, this would only work for one column (ideally the first column), default is pinned = true
    // If you want to use sticky column add `pinnable: true` to the column you want pinned
    const [isPinned, setIsPinned] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const stickyCol = columns.filter((c) => c.pinnable)?.[0];
    // This needs to be a ref since we don't want fetchData to have startAfter as a dependency for the useEffect and get called an extra time after we set it
    const startAfter = useRef({});
    const options = {
        columns: useMemo(() => [...columns], [columns]),
        data: useMemo(() => [...data], [data]),
        initialState: {
            ...settings
        },
        // ** Sorting settings ** //
        // react-table won't handle sorting, we'll need to handle sorting with 'fetchData' and update 'data' passed in
        manualSortBy: true,
        // By default, react-table has an unsorted "state", removing this so one of the columns is always sorted
        disableSortRemove: false,
        // Disable multi-column sort, only one column can we sorted at a time
        disableMultiSort: true,
        // Prevent resetting sort once data changes
        // autoResetSortBy: false,
        // ** Pagination settings ** //
        // react-table won't automatically handle pagination
        manualPagination: true,
        // prevent going to page 1 once data changes
        autoResetPage: false,
        // Required if doing manual pagination, needed for canNextPage
        pageCount: Math.ceil(totalRows / (settings?.pageSize || 20))
    };
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        rows,
        state: { pageIndex, pageSize, sortBy },
        toggleRowExpanded
    } = useTable(options, useSortBy, useExpanded, usePagination);
    const sourceId = useSelector(gvauthSelMobiusSourceID);

    const fetchAndUpdateData = useCallback(async () => {
        setIsLoading(true);
        setIsTableLoading(true);
        try {
            const response = await dispatch(fetchData(pageIndex, sortBy, startAfter.current));
            if (response?.result?.data) {
                const initialData = response.result.data;
                dispatch(setCurrentPrograms(initialData));
                setData(initialData);
                setIsLoading(false);
                setIsTableLoading(false);
                const updatedData = await Promise.all(
                    initialData.map(async (row) => {
                        if (row?.type === MOBIUS_PROGRAM_TYPES_VALUES.SHOW) {
                            try {
                                const resp = await mobiusGetEpisodesByPresentationId(row?.presentationGnID, sourceId, selectedCatalog);
                                const episodesResp = resp?.result
                                const publishingStatusSet = new Set();
                                const episodesOfTheSeries =
                                episodesResp?.data.map((episode) => {
                                    publishingStatusSet.add(episode["publishingStatus"])
                                    return {"gnID": episode.gnID, "title": episode.title.value, "type": episode.type, "subType": episode.subType, "episodeNumber": episode.episodeNumber, "publishingStatus": episode.publishingStatus, "seasonNumber": episode.seasonNumber, "showPresentationGnID": episode.showPresentationGnID, "seasonGnID": episode.seasonGnID}
                                })
                                return { ...row, episodesCount: episodesResp?.meta?.count, episodesPublishingStatus: Array.from(publishingStatusSet), episodes: [...episodesOfTheSeries]};
                            } catch (error) {
                                return row;
                            }
                        }
                        return row;
                    })
                );
                dispatch(setCurrentPrograms(updatedData));
                setData(updatedData);
            }

            if (response?.result?.meta?.total) {
                setTotalRows(parseInt(response.result.meta.total));
            }

            if (response?.headers?.start_after) {
                startAfter.current = { ...startAfter.current, [pageIndex + 1]: parseInt(response.headers.start_after) };
            }
        } catch (error) {
            dispatch(gnviewSendLogMessage(`GNServerTable fetchData error: ${error.message}`, error));
        } finally {
            setIsLoading(false);
            setIsTableLoading(false);
        }
    }, [dispatch, fetchData, pageIndex, sortBy, setIsTableLoading, sourceId, selectedCatalog]);

    useEffect(() => {
        fetchAndUpdateData();
    }, [fetchAndUpdateData]);

    const currentRowStart = page.length ? (pageIndex * pageSize) + 1 : 0;
    const currentRowEnd = Math.min(((pageIndex * pageSize) + page.length), totalRows);
    const showTable = showTableHeaderWithNoData === true || data.length > 0;

    return (
        <div className={'gn-server-table-wrapper'}>
            {!paginationBottom && isLoading && <LoadingSpinner />}
            {!paginationBottom && <div className="pagination-top-container" data-test-id='pagination-top-container'>
                {!isLoading && <div className="pagination-buttons">
                    <span className="page-count">{numberWithCommas(currentRowStart)} - {numberWithCommas(currentRowEnd)} of {numberWithCommas(totalRows)}</span>
                    <ButtonGroup>
                        <Button variant="light" onClick={() => previousPage()} disabled={!canPreviousPage} data-testid='previous-page'>
                            <FontAwesomeIcon icon="angle-left" />
                        </Button>
                        <Button variant="light" onClick={() => nextPage()} disabled={!canNextPage} data-testid='next-page'>
                            <FontAwesomeIcon icon="angle-right" />
                        </Button>
                    </ButtonGroup>
                </div>}
            </div>}
            {showTable && !isLoading && <Table className={ClassNames('gn-server-table', className)} {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup, i) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                            {headerGroup.headers.map((column, j) => {
                                return (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} key={j} {...stickyCol && { className: ClassNames({ 'is-pinned': isPinned && column.pinnable }) }}>
                                        {column.pinnable &&
                                            <span>&nbsp;<FontAwesomeIcon className='lock-icon' icon={isPinned ? 'lock' : 'lock-open'} onClick={(e) => {
                                                e.stopPropagation();
                                                setIsPinned(prevIsPinned => !prevIsPinned)
                                            }} /></span>}
                                        {column.render('Header')}
                                        {column.isSorted && column.canSort && <span>&nbsp;<FontAwesomeIcon className='sort-icon' icon={column.isSortedDesc ? 'arrow-down' : 'arrow-up'} /></span>}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        const currentRowId = row?.original?.presentationGnID;
                        return (
                            <React.Fragment key={i}>
                                <tr
                                    {...row.getRowProps()}
                                    {... (row.original?.canExpand && row.getToggleRowExpandedProps({
                                        onClick: () => {
                                            const expandedRow = rows.find(r => r.isExpanded);
                                            if (expandedRow && (expandedRow.id !== row.id)) {
                                                toggleRowExpanded(expandedRow.id, false);
                                                toggleRowExpanded(row.id, true);
                                                dispatch(gnidsSetSeasonNumber(null))
                                            } else if (expandedRow && (expandedRow.id === row.id)) {
                                                toggleRowExpanded(row.id, false);
                                            } else {
                                                toggleRowExpanded(row.id, true);
                                                dispatch(gnidsSetSeasonNumber(null))
                                            }
                                        }
                                    }))}
                                    className={ClassNames({ 'is-expanded': row.isExpanded, 'is-checked': selectedIds?.includes(currentRowId) })}
                                >
                                    {row.cells.map((cell, j) => {
                                        return (
                                            <td {...cell.getCellProps()} key={j} {...stickyCol && { className: ClassNames({ 'is-pinned': isPinned && cell?.column?.id === stickyCol?.id }) }}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                                {!refreshRowSubComponent && row.isExpanded && <tr className="row-expansion" data-testid='row-expansion'>
                                    <td colSpan={columns.length} className="row-expansion-cell">
                                        <div className="row-expansion-component-wrapper">
                                            {renderRowSubComponent({ row })}
                                        </div>
                                    </td>
                                </tr>}
                            </React.Fragment>
                        )
                    })}
                </tbody>
            </Table>}
            {data.length <= 0 && !isLoading && <div className={ClassNames({ "no-data-with-header": showTable, "no-data-without-header": !showTable })}>
                {noDataMessage}
            </div>}
            {isLoading && <LoadingSpinner />}
            {paginationBottom && <div className="pagination-container" data-test-id='pagination-top-container'>
                <span className="page-count">{numberWithCommas(currentRowStart)} - {numberWithCommas(currentRowEnd)} of {numberWithCommas(totalRows)}</span>
                <ButtonGroup>
                    <Button variant="light" onClick={() => {
                        onPageUpdate()
                        previousPage()
                    }
                    } disabled={!canPreviousPage || currentRowStart === 0}>
                        <FontAwesomeIcon icon="angle-left" />
                    </Button>
                    <Button variant="light" onClick={() => {
                        onPageUpdate()
                        nextPage()
                    }} disabled={!canNextPage || currentRowStart === 0}>
                        <FontAwesomeIcon icon="angle-right" />
                    </Button>
                </ButtonGroup>
            </div>}
        </div>
    )
};


GNServerTable.defaultProps = {
    paginationBottom: true,
    settings: {
        pageSize: 20
    },
    onPageUpdate: () => {},
    setIsTableLoading: () => {}
};

GNServerTable.propTypes = {
    // className: optional css class name
    className: PropTypes.string,
    // columns: Must have id, accessor, Header options https://react-table.tanstack.com/docs/api/useTable
    columns: PropTypes.array.isRequired,
    // fetchData: Function for grabbing data to fill the table, will be called initially
    fetchData: PropTypes.func.isRequired,
    // noDataMessage: A component with text to display when there is an empty table
    noDataMessage: PropTypes.func.isRequired,
    // paginationBottom: default the pagination buttons will be at the bottom and sticky. Otherwise you can set to false to have the pagination on the top right.
    paginationBottom: PropTypes.bool,
    // refreshRowSubComponent: default to false, option to refresh render of sub component in table row
    refreshRowSubComponent: PropTypes.bool,
    // renderRowSubcomponent: function to render sub component when row is expanded
    renderRowSubComponent: PropTypes.func,
    // settings: options to pass in for initialState for the table. pageSize, sortBy etc.
    settings: PropTypes.object,
    // filters: optional filters to pass in for watching changes in order to reset page to 0
    filters: PropTypes.array,
    // showTableHeaderWithNoData: optional boolean that determines whether or not we show the table header with a no data message or not (default is true)
    showTableHeaderWithNoData: PropTypes.bool,
    // onPageUpdate: Function for clearing selected programs when updating the table page
    onPageUpdate: PropTypes.func,
    // selectedIds: Array of selected IDs. Necessary to update background for selected rows
    selectedIds: PropTypes.array,
    setIsTableLoading: PropTypes.func,
    selectedCatalog: PropTypes.string
};

export default GNServerTable;