import React, { useEffect, useRef, useState } from 'react';
import "./FileUpload.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from 'react-bootstrap';
import GNNotification from '../gnNotification/GNNotification';
import { formatFileSize } from '../../../utils/BulkUploadUtils';
import PropTypes from 'prop-types';
import { gnidsSetProgramFiles } from '../../../actions/GNIDSProgramFilesActions';
import { useDispatch, useSelector } from 'react-redux';
import { gnidsSelProgramFiles } from '../../../reducers/GNIDSProgramFilesReducer';
import { PROGRAM_BULK_UPLOAD } from '../../../constants/BulkUpload';
import ClassNames from 'classnames';

const FileUpload = ({ acceptTypes, maxSize, maxTotalFiles, handleNext }) => {
    const [files, setFiles] = useState(useSelector(gnidsSelProgramFiles));
    // NOTIFICATIONS
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState('');
    const [disableButton, setDisableButton] = useState(false);
    const inputRef = useRef();

    const dispatch = useDispatch();

    useEffect(() => {
        if (files.length >= maxTotalFiles) {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        }
    }, [files, setDisableButton, maxTotalFiles]);

    const handleClick = () => {
        inputRef.current.click();
    }

    /**
     * fileArr is an array-like object (but not an array?) with a length value and one
     * or more file descriptor objects. If the user has already added attachments, then
     * drags in an array of attachments that would cause the total to exceed maxTotalFiles,
     * then we want to prevent the extra files from being passed into validateFile, as the
     * state object won't have been updated yet.
     **/
    const handleFiles = async (fileArr) => {
        const limit = Math.max(Math.min(fileArr.length, maxTotalFiles - files.length), 1);
        let newFiles = [...files];
        for (let i = 0; i < limit; i++) {
            const file = fileArr?.[i];
            if (validateFile(file)) {
                if (checkIfDifferentFile(file)) {
                    newFiles = [];
                }
                newFiles.push(file);
            }
        }
        setFiles(newFiles);
        dispatch(gnidsSetProgramFiles(newFiles));

        // null out the value so that duplicate attempts to upload a file are recognized
        inputRef.current.value = null;
    }

    const checkIfDifferentFile = (file) => {
        return files.filter((storedFile) => storedFile.name !== file.name).length;
    }

    /**
     * If maxSize is set (in bytes) the file should not exceed that size.
     * If acceptTypes is set (a string of comma-delimited accepted file extensions,
     * e.g. ".js, .png, .xml") then the file should have a matching extension.
     * If maxTotalFiles is set, don't allow the number of attachments to exceed the limit
     **/
    const validateFile = (file) => {
        if (!file) {
            return false;
        }
        if (files.filter((storedFile) => storedFile.name === file.name).length) {
            setNotificationMsg(`This file was already attached.`);
            setShowNotification(true);
            return false;
        }
        if ((maxSize > 0) && (file?.size > maxSize)) {
            setNotificationMsg(`File size exceeds ${formatFileSize(maxSize)}.`);
            setShowNotification(true);
            return false;
        }
        if (acceptTypes) {
            const extension = file?.name?.split('.').pop();
            if (acceptTypes.indexOf(extension) < 0) {
                setNotificationMsg(`We only allow CSV files.`);
                setShowNotification(true);
                return false;
            }
        }
        if (checkIfDifferentFile(file)) {
            return true;
        }
        if ((maxTotalFiles > 0) && (files?.length >= maxTotalFiles)) {
            setNotificationMsg(`Maximum number of attachments is ${maxTotalFiles}.`);
            setShowNotification(true);
            return false;
        }
        return true;
    }

    const removeFile = (name) => {
        const newFiles = files.filter((file) => file.name !== name);
        setFiles(newFiles);
        dispatch(gnidsSetProgramFiles(newFiles));
    }

    const preventBubbling = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <div className="file-upload-container" data-testid='file-upload'>
            <div
                className={ClassNames('file-drop-area', { 'file-upload-success': files?.length > 0 })}
                onDragEnter={preventBubbling}
                onDragOver={preventBubbling}
                onDrop={(e) => {
                    preventBubbling(e);
                    handleFiles(e.dataTransfer.files);
                }}
            >
                {files?.length > 0 &&
                    <div className="file-list">
                        <ul className="files">
                            {files.map((file) => (
                                <>
                                    <li key={file.name} onClick={() => removeFile(file.name)}>
                                        <span>
                                            {file.name}
                                        </span>
                                        <span className="file-size">({formatFileSize(file.size)})</span>
                                    </li>
                                </>
                            ))}
                        </ul>
                    </div>
                }
                {files?.length === 0 && <FontAwesomeIcon icon="upload" />}
                <div className="message">
                    {files?.length === 0 && PROGRAM_BULK_UPLOAD.FILE_UPLOAD_TEXT}
                    <span className={ClassNames('browse-button', { 'disabled': disableButton })} onClick={handleClick}>
                        {files?.length === 0 ? PROGRAM_BULK_UPLOAD.BROWSE_BUTTON_TEXT : PROGRAM_BULK_UPLOAD.REPLACE_FILE_TEXT}
                    </span>
                    <input
                        ref={inputRef}
                        type="file"
                        className="file-input"
                        onChange={(e) => handleFiles(e.target.files)}
                        accept={acceptTypes}
                    />
                </div>
                {files.length === 0 && <div className="limit-message">{PROGRAM_BULK_UPLOAD.FILE_UPLOAD_LIMIT_TEXT}</div>}
            </div>
            <Button variant="primary" disabled={files.length === 0} className="register-btn" data-testid='register-btn' onClick={handleNext}>Register</Button>
            <GNNotification
                className={'file-upload-notification'}
                handleShow={setShowNotification}
                message={notificationMsg}
                milliseconds={10000}
                show={showNotification}
                success={false}
            />
        </div>
    )
}

FileUpload.defaultProps = {
    acceptTypes: '',
    maxSize: 0,
    maxTotalFiles: 0,
    handleNext: () => { }
};

FileUpload.propTypes = {
    acceptTypes: PropTypes.string,
    maxSize: PropTypes.number,
    maxTotalFiles: PropTypes.number,
    handleNext: PropTypes.func
};

export default FileUpload;
