import React from 'react';
import PropTypes from 'prop-types';

import GNInput from "../common/gnInput/GNInput";
import GNDropdown from "../common/gnDropdown/GNDropdown";
import TypeaheadFilter from "../common/typeaheadFilter/TypeaheadFilter";
import {
    MOBIUS_LABELS,
    IMAGE_SIDE_MENU
} from '../../constants/Mobius';
import { getImageValidationSchema, MOBIUS_IMAGE_FIELDS } from '../../constants/MobiusImageValidation';
import { IMAGE_FILE_TYPES } from '../../utils/ImageUtils';
import { convertStringtoArray } from '../../utils/GeneralUtils';
import { IMAGE_LABELS } from '../../constants/Image';
import { MOBIUS_IMAGE_TITLE_MAX_LENGTH } from '../../constants/MobiusImageValidation';

export const ImageModalEditOverview = ({handleFormChange, values, validateForm, errors, vocabAllImageCategories}) => {
    return (<div className='overview-section' id={IMAGE_SIDE_MENU.OVERVIEW.id}>
        <div className='image-modal-tab-header'>
            {MOBIUS_LABELS.OVERVIEW}
        </div>
        <div className='image-modal-tab-body'>
            <div className='top-section'>
                <TypeaheadFilter
                    addLabelKey={IMAGE_LABELS.ADD_CATEGORY}
                    allowNew={true}
                    className="category-typeahead"
                    filterLabel={IMAGE_LABELS.CATEGORY}
                    handleChange={(items) => {
                        const formattedCategory = items.map(item => item?.label ? item.label : item);
                        handleFormChange(MOBIUS_IMAGE_FIELDS.CATEGORY, formattedCategory[0]);
                    }}
                    isRequired={getImageValidationSchema()?.fields[MOBIUS_IMAGE_FIELDS.CATEGORY]?.exclusiveTests?.required}
                    list={vocabAllImageCategories || []}
                    multiple={false}
                    placeholder={IMAGE_LABELS.CATEGORY}
                    selected={convertStringtoArray(values?.category)}
                    validationText={errors?.category?.valueOf}
                    validateTypeahead={() => validateForm(MOBIUS_IMAGE_FIELDS.CATEGORY)}
                />
                <div className='dimensions-section'>
                    <label className='filter-label'>{IMAGE_LABELS.DIMENSIONS}</label>
                    <div className='dimensions-fields'>
                        <GNInput
                            handleChange={(item) => handleFormChange(MOBIUS_IMAGE_FIELDS.DIMENSIONS_WIDTH, item)}
                            isRequired={getImageValidationSchema()?.fields[MOBIUS_IMAGE_FIELDS.DIMENSIONS]?.fields[MOBIUS_IMAGE_FIELDS.WIDTH]?.exclusiveTests?.required}
                            className='dimension-width'
                            isValueInvalid={errors?.dimensions?.width === IMAGE_LABELS.DIMENSIONS_INVALID}
                            placeholder={IMAGE_LABELS.DIMENSIONS_WIDTH}
                            validateInput={() => validateForm(MOBIUS_IMAGE_FIELDS.DIMENSIONS_WIDTH)}
                            validationText={errors?.dimensions?.width}
                            value={values?.dimensions?.width} />
                        <div className='dimensions-seperator'>
                            <span>X</span>
                        </div>
                        <GNInput
                            handleChange={(item) => handleFormChange(MOBIUS_IMAGE_FIELDS.DIMENSIONS_HEIGHT, item)}
                            isRequired={getImageValidationSchema()?.fields[MOBIUS_IMAGE_FIELDS.DIMENSIONS]?.fields[MOBIUS_IMAGE_FIELDS.HEIGHT]?.exclusiveTests?.required}
                            placeholder={IMAGE_LABELS.DIMENSIONS_HEIGHT}
                            isValueInvalid={errors?.dimensions?.height === IMAGE_LABELS.DIMENSIONS_INVALID}
                            className='dimension-height'
                            validateInput={() => validateForm(MOBIUS_IMAGE_FIELDS.DIMENSIONS_HEIGHT)}
                            validationText={errors?.dimensions?.height}
                            value={values?.dimensions?.height} />
                    </div>
                </div>
                <GNInput
                    handleChange={(item) => handleFormChange(MOBIUS_IMAGE_FIELDS.DIMENSIONS_ASPECT_RATIO, item)}
                    isRequired={getImageValidationSchema()?.fields[MOBIUS_IMAGE_FIELDS.DIMENSIONS]?.fields[MOBIUS_IMAGE_FIELDS.ASPECT_RATIO]?.exclusiveTests?.required}
                    label={IMAGE_LABELS.ASPECT_RATIO}
                    className='aspect-ratio'
                    placeholder={IMAGE_LABELS.ASPECT_RATIO}
                    isValueInvalid={errors?.dimensions?.aspectRatio === IMAGE_LABELS.ASPECT_RATIO_INVALID}
                    validateInput={() => validateForm(MOBIUS_IMAGE_FIELDS.DIMENSIONS_ASPECT_RATIO)}
                    validationText={errors?.dimensions?.aspectRatio}
                    value={values?.dimensions?.aspectRatio} />
            </div>
            <div className='filetype-section'>
                <GNDropdown
                    handleChange={(item) => handleFormChange(MOBIUS_IMAGE_FIELDS.IMAGE_TYPE, item)}
                    isRequired={getImageValidationSchema()?.fields[MOBIUS_IMAGE_FIELDS.IMAGE_TYPE]?.exclusiveTests?.required}
                    isAlphabeticallySorted
                    className='image-type'
                    label={IMAGE_LABELS.IMAGE_FILE_TYPE}
                    options={IMAGE_FILE_TYPES}
                    validateInput={() => validateForm(MOBIUS_IMAGE_FIELDS.IMAGE_TYPE)}
                    value={values?.fileType} />
            </div>
            <div className='image-title-section'>
                <GNInput
                    handleChange={(item) => handleFormChange(MOBIUS_IMAGE_FIELDS.TITLE, item)}
                    isRequired={getImageValidationSchema()?.fields[MOBIUS_IMAGE_FIELDS.TITLE]?.exclusiveTests?.required}
                    label={IMAGE_LABELS.TITLE}
                    className='image-title'
                    placeholder={IMAGE_LABELS.TITLE}
                    textAreaMax={MOBIUS_IMAGE_TITLE_MAX_LENGTH}
                    type="textarea"
                    validateInput={() => validateForm(MOBIUS_IMAGE_FIELDS.TITLE)}
                    validationText={errors?.title?.valueOf}
                    value={values?.title} />
            </div>
            <div className='image-url-section'>
                <GNInput
                    handleChange={(item) => handleFormChange(MOBIUS_IMAGE_FIELDS.URL, item)}
                    isRequired={getImageValidationSchema()?.fields[MOBIUS_IMAGE_FIELDS.URL]?.exclusiveTests?.required}
                    isValueInvalid={errors?.url === IMAGE_LABELS.URL_INVALID}
                    label={IMAGE_LABELS.IMAGE_URL}
                    className='image-url'
                    placeholder={IMAGE_LABELS.IMAGE_URL}
                    validateInput={() => validateForm(MOBIUS_IMAGE_FIELDS.URL)}
                    validationText={errors?.url}
                    value={values?.url} />
            </div>
        </div>
    </div>)
}

ImageModalEditOverview.propTypes = {
    handleFormChange: PropTypes.func,
    values: PropTypes.object,
    errors: PropTypes.object,
    validateForm: PropTypes.func,
    vocabAllImageCategories: PropTypes.array
};

export default ImageModalEditOverview;