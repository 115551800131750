import React from 'react';
import PropTypes from 'prop-types';

import {
    IMAGE_SIDE_MENU
} from '../../constants/Mobius';
import { getImageValidationSchema, MOBIUS_IMAGE_FIELDS } from '../../constants/MobiusImageValidation';
import GNDropdown from "../common/gnDropdown/GNDropdown";
import GNInput from "../common/gnInput/GNInput";
import { IMAGE_LABELS } from '../../constants/Image';

export const ImageModalEditDescription = ({handleFormChange, values, validateForm, errors, allLanguages}) => {
    return (<div className='description-section' id={IMAGE_SIDE_MENU.DESCRIPTION.id}>
        <div className='image-modal-tab-header'>
            {IMAGE_LABELS.DESCRIPTION}
        </div>
        <div className='image-modal-tab-body'>
            <div className='image-description-language'>
                <GNDropdown
                    label={IMAGE_LABELS.DESCRIPTION_LANGUAGE}
                    options={allLanguages}
                    className='image-description-language'
                    value={values?.description?.language}
                    isAlphabeticallySorted
                    handleChange={(item) => {
                        handleFormChange(MOBIUS_IMAGE_FIELDS.DESCRIPTION_LANGUAGE, item)
                    }}
                    isRequired={getImageValidationSchema()?.fields?.[IMAGE_LABELS.DESCRIPTION_LANGUAGE]?.exclusiveTests?.required}
                    validateInput={validateForm(MOBIUS_IMAGE_FIELDS.DESCRIPTION)}
                />
            </div>
            <div className='description-message'>
                {IMAGE_LABELS.DESCRIPTION}
            </div>
            <div className="description-field-wrapper">
                <GNInput
                    handleChange={(item) => handleFormChange(MOBIUS_IMAGE_FIELDS.DESCRIPTION_VALUE, item)}
                    isRequired={getImageValidationSchema()?.fields?.[MOBIUS_IMAGE_FIELDS.DESCRIPTION_VALUE]?.exclusiveTests?.required}
                    placeholder="Description"
                    className='image-description-value'
                    textAreaMax={500}
                    textAreaRows={5}
                    type="textarea"
                    validationText={errors?.description?.value?.valueOf}
                    validateInput={() => validateForm(MOBIUS_IMAGE_FIELDS.DESCRIPTION)}
                    value={values?.description?.value}
                />
            </div>
        </div>
    </div>)
}

ImageModalEditDescription.propTypes = {
    handleFormChange: PropTypes.func,
    values: PropTypes.object,
    errors: PropTypes.object,
    validateForm: PropTypes.func,
    allLanguages: PropTypes.array
};

export default ImageModalEditDescription;