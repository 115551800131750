import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IMAGE_LABELS } from "../../constants/Image";

const ImageModalEditAdditionalDetailsBooleanField = ({handleFormChange, label, tooltip, value, field}) => {
    return (<div className='additional-details-boolean-field'>
        <div className='field-label-container'>
            <div className='field-label'>{label}</div>
            <OverlayTrigger overlay={<Tooltip>{tooltip}</Tooltip>} placement="right">
                <FontAwesomeIcon icon='circle-info' />
            </OverlayTrigger>
        </div>
        <div className="boolean-divider"/>
        <div className='radio-group'>
            <div className='yes-section'>
                <Form.Check
                    custom
                    inline
                    id={`custom-radio-${label}`}
                    checked={value}
                    type='radio'
                    value={value}
                    onClick={() => {
                        handleFormChange(field, !value)
                    }}
                />
                <div className='boolean-label'>{IMAGE_LABELS.YES}</div>
            </div>
            <div className='no-section'>
                <Form.Check
                    custom
                    inline
                    id={`custom-radio-${label}`}
                    checked={!value}
                    type='radio'
                    value={!value}
                    onClick={() => {
                        handleFormChange(field, !value)
                    }}
                />
                <div className='boolean-label'>{IMAGE_LABELS.NO}</div>
            </div>
        </div>
    </div>)
}


ImageModalEditAdditionalDetailsBooleanField.propTypes = {
    handleFormChange: PropTypes.func,
    label: PropTypes.string,
    tooltip: PropTypes.string,
    field: PropTypes.string,
    value: PropTypes.bool
};

export default ImageModalEditAdditionalDetailsBooleanField;