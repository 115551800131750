import React from 'react';
import PropTypes from 'prop-types';
import MobiusStatusIcon from '../mobiusStatusIcon/MobiusStatusIcon';
import './MobiusSeriesStatus.scss';

export const MobiusSeriesStatus = ({publishStatus, episodesCount, episodesPublishingStatus}) => {
    return (
        <>
            <div style={{whiteSpace: 'nowrap'}}>
                <MobiusStatusIcon status={publishStatus} circle={true} />
                <hr className='separator'/>
                <div className="series-status-icon-container">
                    <>
                        {episodesPublishingStatus && episodesPublishingStatus.map((publishingStatus, ind) => {
                            return <MobiusStatusIcon key={ind} status={publishingStatus} circle={true} episodeStatusIcon={ind && true}/>
                        }
                        )}</>

                    {episodesCount !== undefined ? <span className='episode-count'>{`+${episodesCount} Ep`}</span> : 'Loading...'}
                </div>

            </div>
        </>
    );
}



MobiusSeriesStatus.propTypes = {
    publishStatus: PropTypes.string,
    episodesCount: PropTypes.number,
    episodesPublishingStatus: PropTypes.array
};

export default MobiusSeriesStatus;