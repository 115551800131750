import React, { useState, useEffect } from 'react';
import "./ScrollSpyMenu.scss";
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import { MOBIUS_STATUS, PROGRAM_SIDE_MENU } from '../../../constants/Mobius';
import MobiusStatusIcon from '../../mobius/mobiusStatusIcon/MobiusStatusIcon';

// The area the ScrollSpyMenu should target with should be grouped by <div id=""> and the matching ids given in menuItemIds
const ScrollSpyMenu = ({ disabled, handleItemClick, menuItemIds, menuLabel, menuRatios, targetContainerId }) => {
    const [activeItem, setActiveItem] = useState(menuItemIds[0]?.id || null);

    useEffect(() => {
        const targetContainer = document.getElementById(targetContainerId);
        if (targetContainer) {
            targetContainer.addEventListener("scroll", () => {
                const observerOptions = {
                    root: targetContainer,
                    triggerOnce: true,
                    threshold: 0 // Won't consider a section passed until every pixel is visible
                }
                const observer = new IntersectionObserver(entries => {
                    const found = entries.find((e, i) => e.intersectionRatio > menuRatios[i]);
                    if (found) {
                        const id = found.target.getAttribute('id');
                        setActiveItem(id);
                    }
                }, observerOptions);

                // Track all sections that have an `id` applied
                targetContainer.querySelectorAll('div[id]').forEach((section) => {
                    observer.observe(section);
                });
            });
        }
    }, [targetContainerId, menuRatios]);

    return (
        <div className="scroll-spy-menu">
            {menuLabel && <div className="menu-label">{menuLabel}</div>}
            {menuItemIds.map((item, idx) => (
                <div className={ClassNames("menu-item", { 'active': (item.id === activeItem) && !disabled, 'publish-exception': (item.id === PROGRAM_SIDE_MENU.PUBLISH_EXCEPTION.id) })} key={idx}>
                    <a href={`#${item.id}`} {...(handleItemClick && {onClick: handleItemClick})}>
                        {item.id === PROGRAM_SIDE_MENU.PUBLISH_EXCEPTION.id && <MobiusStatusIcon status={MOBIUS_STATUS.PUBLISH_EXCEPTION} circle={true} />}
                        {item.label}
                    </a>
                </div>
            ))}
        </div>
    );
}

ScrollSpyMenu.propTypes = {
    disabled: PropTypes.bool,
    handleItemClick: PropTypes.func,
    menuItemIds: PropTypes.array.isRequired,
    menuLabel: PropTypes.string,
    menuRatios: PropTypes.array,
    targetContainerId: PropTypes.string.isRequired
};

export default ScrollSpyMenu;