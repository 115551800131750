import React from 'react';
import find from 'lodash.find';
import {
    TAB_NAMES,
    PROGRAM_COLORS,
    PROGRAM_SUBTYPES,
    PROGRAM_GNPROGTYPES,
    PROGRAM_SEASON_EPISODE_COLUMN_HEADERS
} from '../constants/Program';
import { MOBIUS_INITIAL_DESCRIPTIONS } from '../constants/MobiusProgramValidation';
import moment from 'moment';
import isEmpty from 'lodash.isempty';
import { API_ERROR_MESSAGES, MOBIUS_NOTIFICATIONS } from '../constants/Mobius';

export const getProgramTabs = (program) => {
    // Always show the "Overview" tab, since there are many subtabs and there is always data here
    const tabs = [TAB_NAMES.OVERVIEW];
    if (program?.seasons?.length > 0 || program?.allSeasons?.length > 0) {
        tabs.push(TAB_NAMES.SEASONS_AND_EPISODES);
    }
    if (program?.cast?.length > 0 || program?.crew?.length > 0) {
        tabs.push(TAB_NAMES.CAST_AND_CREW);
    }
    if (program?.images?.length > 0) {
        tabs.push(TAB_NAMES.IMAGES);
    }
    // Always push "Other Versions" since we need to call a separate API
    tabs.push(TAB_NAMES.OTHER_VERSIONS);
    if (program?.relationships?.length > 0) {
        tabs.push(TAB_NAMES.RELATED_PROGRAMS);
    }
    if (program?.episode_info?.alternate_episodes?.length > 0) {
        tabs.push(TAB_NAMES.ALTERNATE_EPISODES);
    }
    // Always push "Video Descriptors", need to show teaser whether or not there's data
    tabs.push(TAB_NAMES.VIDEO_DESCRIPTORS);
    return tabs;
};

export const getOverviewSubMenuTabs = (program) => {
    // Always show "Summary" tab, there's usually data
    const tabs = [TAB_NAMES.SUMMARY];
    if (program?.sports_info?.teams?.length > 0) {
        tabs.push(TAB_NAMES.TEAMS);
    }
    // Always push "Titles" and "Descriptions", since we want to show if there's missing data
    tabs.push(TAB_NAMES.TITLES);
    tabs.push(TAB_NAMES.DESCRIPTIONS);
    if (program?.releases?.length > 0) {
        tabs.push(TAB_NAMES.RELEASES);
    }
    if (program?.ratings?.length > 0) {
        tabs.push(TAB_NAMES.RATINGS);
    }
    if (program?.awards?.length > 0) {
        tabs.push(TAB_NAMES.AWARDS);
    }
    return tabs;
};

export const getProgramTitle = (program, parentTitleOnly = false, textOnly = false) => {
    if (program?.titles?.length > 0) {
        const mainTitle = find(program.titles, { type: 'full', sub_type: 'Main', size: 120 })?.text || program.titles[0]?.text || '';
        if (program.gn_progtype === PROGRAM_GNPROGTYPES.EPISODE && !parentTitleOnly && textOnly && program.episode_info?.title) {
            return program.episode_info.title;
        } else if (program.gn_progtype === PROGRAM_GNPROGTYPES.EPISODE && !parentTitleOnly && program.episode_info?.title) {
            return <span>{mainTitle}<i className="fas fa-angle-right gnview-breadcrumbs-separator" />{program.episode_info.title}</span>;
        } else if ((program.gn_progtype === PROGRAM_GNPROGTYPES.SPORTS || program.gn_progtype === PROGRAM_GNPROGTYPES.SPORTS_SUMMARY) && !parentTitleOnly && textOnly && program.sports_info?.title) {
            return program.sports_info.title;
        } else if ((program.gn_progtype === PROGRAM_GNPROGTYPES.SPORTS || program.gn_progtype === PROGRAM_GNPROGTYPES.SPORTS_SUMMARY) && program.sports_info?.title) {
            return <span>{mainTitle}<i className="fas fa-angle-right gnview-breadcrumbs-separator" />{program.sports_info.title}</span>;
        } else {
            return mainTitle;
        }
    }
    return '';
};

export const getProgramTitleAltText = (program) => {
    if (program?.titles?.length > 0) {
        const mainTitle = find(program.titles, { type: 'full', sub_type: 'Main', size: 120 })?.text || program.titles[0]?.text || '';
        if (program.gn_progtype === PROGRAM_GNPROGTYPES.EPISODE && program.episode_info?.title) {
            return program.episode_info.title;
        } else if ((program.gn_progtype === PROGRAM_GNPROGTYPES.SPORTS || program.gn_progtype === PROGRAM_GNPROGTYPES.SPORTS_SUMMARY) && program.sports_info?.title) {
            return program.sports_info.title;
        } else {
            return mainTitle;
        }
    }
    return '';
};

export const getProgramDescription = (program) => {
    if (program?.descriptions?.length > 0) {
        return find(program.descriptions, { size: 250 })?.text || program.descriptions[0]?.text || '';
    }
    return '';
};

export const containsUnexpiredImages = program => program?.images?.filter(img => !img.expired_date)?.length > 0 || program?.parent_season_images?.filter(img => !img.expired_date)?.length > 0 || program?.parent_program_images?.filter(img => !img.expired_date)?.length > 0;

export const getUnexpiredImagesProp = program => {
    if (program?.images?.filter(img => !img.expired_date)?.length > 0) {
        return 'images';
    } else if (program?.parent_season_images?.filter(img => !img.expired_date)?.length > 0) {
        return 'parent_season_images';
    } else if (program?.parent_program_images?.filter(img => !img.expired_date)?.length > 0) {
        return 'parent_program_images';
    } else {
        return '';
    }
};

export const getProgramImageHorizontal = (program) => {
    if (containsUnexpiredImages(program) && program.gn_progtype) {
        const imageType = getUnexpiredImagesProp(program);
        const unexpiredImageProp = `unexpired_${imageType}`;
        program[unexpiredImageProp] = program[imageType]?.filter(img => !img.expired_date);
        const defaultImage = program[unexpiredImageProp]?.[0]?.image;
        if (program.gn_progtype === PROGRAM_GNPROGTYPES.MOVIE) {
            // VOD Art
            return find(program[unexpiredImageProp], { ratio: '2:1', category: 'VOD Art' })?.image
                || find(program[unexpiredImageProp], { ratio: '16:9', category: 'VOD Art' })?.image
                || find(program[unexpiredImageProp], { ratio: '4:3', category: 'VOD Art' })?.image
                || find(program[unexpiredImageProp], { ratio: '9:5', category: 'VOD Art' })?.image
                // Poster Art
                || find(program[unexpiredImageProp], { ratio: '2:1', category: 'Poster Art' })?.image
                || find(program[unexpiredImageProp], { ratio: '16:9', category: 'Poster Art' })?.image
                || find(program[unexpiredImageProp], { ratio: '4:3', category: 'Poster Art' })?.image
                || find(program[unexpiredImageProp], { ratio: '9:5', category: 'Poster Art' })?.image
                // No Category
                || find(program[unexpiredImageProp], { ratio: '2:1' })?.image
                || find(program[unexpiredImageProp], { ratio: '16:9' })?.image
                || find(program[unexpiredImageProp], { ratio: '4:3' })?.image
                || find(program[unexpiredImageProp], { ratio: '9:5' })?.image
                || defaultImage
                || '';
        } else if (program.gn_progtype === PROGRAM_GNPROGTYPES.SERIES) {
            // Banner-L1
            return find(program[unexpiredImageProp], { ratio: '2:1', category: 'Banner-L1' })?.image
                || find(program[unexpiredImageProp], { ratio: '16:9', category: 'Banner-L1' })?.image
                || find(program[unexpiredImageProp], { ratio: '4:3', category: 'Banner-L1' })?.image
                || find(program[unexpiredImageProp], { ratio: '9:5', category: 'Banner-L1' })?.image
                // Iconic
                || find(program[unexpiredImageProp], { ratio: '2:1', category: 'Iconic' })?.image
                || find(program[unexpiredImageProp], { ratio: '16:9', category: 'Iconic' })?.image
                || find(program[unexpiredImageProp], { ratio: '4:3', category: 'Iconic' })?.image
                || find(program[unexpiredImageProp], { ratio: '9:5', category: 'Iconic' })?.image
                // No category
                || find(program[unexpiredImageProp], { ratio: '2:1' })?.image
                || find(program[unexpiredImageProp], { ratio: '16:9' })?.image
                || find(program[unexpiredImageProp], { ratio: '4:3' })?.image
                || find(program[unexpiredImageProp], { ratio: '9:5' })?.image
                || defaultImage
                || '';
        } else {
            return find(program[unexpiredImageProp], { ratio: '2:1' })?.image
                || find(program[unexpiredImageProp], { ratio: '16:9' })?.image
                || find(program[unexpiredImageProp], { ratio: '4:3' })?.image
                || find(program[unexpiredImageProp], { ratio: '9:5' })?.image
                || defaultImage
                || '';
        }
    }
    return '';
};

export const getProgramImageVertical = (program) => {
    if (containsUnexpiredImages(program) && program.gn_progtype) {
        const imageType = getUnexpiredImagesProp(program);
        const unexpiredImageProp = `unexpired_${imageType}`;
        program[unexpiredImageProp] = program[imageType]?.filter(img => !img.expired_date);
        const defaultImage = program[unexpiredImageProp][0]?.image;
        if (program.gn_progtype === PROGRAM_GNPROGTYPES.MOVIE) {
            // VOD Art
            return find(program[unexpiredImageProp], { ratio: '2:3', category: 'VOD Art'})?.image
                || find(program[unexpiredImageProp], { ratio: '3:4', category: 'VOD Art'})?.image
                || find(program[unexpiredImageProp], { ratio: '2:3', category: 'Poster Art' })?.image
                || find(program[unexpiredImageProp], { ratio: '3:4', category: 'Poster Art' })?.image
                || find(program[unexpiredImageProp], { ratio: '2:3' })?.image
                || find(program[unexpiredImageProp], { ratio: '3:4' })?.image
                || defaultImage
                || '';
        } else if (program.gn_progtype === PROGRAM_GNPROGTYPES.SERIES) {
            return find(program[unexpiredImageProp], { ratio: '2:3', category: 'Banner-L1' })?.image
                || find(program[unexpiredImageProp], { ratio: '3:4', category: 'Banner-L1' })?.image
                || find(program[unexpiredImageProp], { ratio: '2:3', category: 'Iconic' })?.image
                || find(program[unexpiredImageProp], { ratio: '3:4', category: 'Iconic' })?.image
                || find(program[unexpiredImageProp], { ratio: '2:3' })?.image
                || find(program[unexpiredImageProp], { ratio: '3:4' })?.image
                || defaultImage
                || '';
        } else {
            return find(program[unexpiredImageProp], { ratio: '2:3' })?.image
                || find(program[unexpiredImageProp], { ratio: '3:4' })?.image
                || defaultImage
                || '';
        }
    }
    return '';
};

export const getProgramImageByRatio = (program, ratio) => {
    if (containsUnexpiredImages(program) && program.gn_progtype) {
        const imageType = getUnexpiredImagesProp(program);
        const unexpiredImageProp = `unexpired_${imageType}`;
        program[unexpiredImageProp] = program[imageType]?.filter(img => !img.expired_date);
        const defaultImage = program[unexpiredImageProp][0]?.image;
        if (program.gn_progtype === PROGRAM_GNPROGTYPES.MOVIE) {
            return find(program[unexpiredImageProp], { ratio, category: 'VOD Art'})?.image || find(program[unexpiredImageProp], { ratio, category: 'Poster Art' })?.image || find(program[unexpiredImageProp], { ratio })?.image || defaultImage || '';
        } else if (program.gn_progtype === PROGRAM_GNPROGTYPES.SERIES) {
            return find(program[unexpiredImageProp], { ratio, category: 'Banner-L1' })?.image || find(program[unexpiredImageProp], { ratio, category: 'Iconic' })?.image || find(program[unexpiredImageProp], { ratio })?.image || defaultImage || '';
        } else {
            return find(program[unexpiredImageProp], { ratio })?.image || defaultImage || '';
        }
    }
    return '';
};

export const getProgramRating = (program) => {
    if (!isEmpty(program) && program?.ratings?.length > 0 && program.subtype) {
        const match = {};

        if (program.gn_progtype === PROGRAM_GNPROGTYPES.MOVIE) {
            match.ratings_body = 'Motion Picture Association of America';
        } else if (program.gn_progtype === PROGRAM_GNPROGTYPES.SERIES || program.gn_progtype === PROGRAM_GNPROGTYPES.TRIMMED_SERIES) {
            match.ratings_body = 'USA Parental Rating';
        }
        const rating = find(program?.ratings, match)?.code?.toUpperCase();
        return rating || program.ratings[0]?.code || 'None';
    }
    return '';
};

export const getProgramSeasonAndEpisodeHeaders = (program, hasLink = false) => {
    if (program?.gn_progtype) {
        switch (program.gn_progtype) {
        case PROGRAM_GNPROGTYPES.SPORTS_SUMMARY:
        case PROGRAM_GNPROGTYPES.SPORTS:
            return hasLink ? PROGRAM_SEASON_EPISODE_COLUMN_HEADERS.SPORTS_EVENT_WITH_LINK : PROGRAM_SEASON_EPISODE_COLUMN_HEADERS.SPORTS_EVENT_NO_LINK;
        default:
            // Other program types may have season information, we are checking if there is season information first, then grabbing headers
            return hasLink ? PROGRAM_SEASON_EPISODE_COLUMN_HEADERS.SERIES_WITH_LINK : PROGRAM_SEASON_EPISODE_COLUMN_HEADERS.SERIES_NO_LINK;
        }
    }
    return [];
}

export const getProgramColor = (programType, gnview = true) => {
    return gnview ? PROGRAM_COLORS?.gnview[programType] : PROGRAM_COLORS?.gnIds[programType];
};

export const getReleaseYear = (program) => {
    if (program?.release_year) {
        return Number(program.release_year);
    } else if (program?.original_air_date &&
      moment(program.original_air_date, 'YYYY-MM-DD', true).isValid()) {
        return moment(program.original_air_date).year();
    } else {
        return null
    }
};

export const getTeamInfo = teamsArr => teamsArr?.length > 0 ? teamsArr.map(teamObj => ({venue: teamObj.is_home ? 'Home' : 'Away', ...teamObj})) : [];

export const checkProgramExists = (list, tmsID) => {
    return list.some(program => program.tmsid === tmsID);
}

// Used to format the program details response to look like the typeahead program response
export const formatProgramObject = (program) => {
    return {
        tmsid: program.result.tmsid,
        tms_id: program.result.tms_id,
        title: getProgramTitle(program.result),
        gn_progtype: program.result.gn_progtype,
        type: program.result.type,
        subtype: program.result.subtype,
        duration: program.result.duration,
        origin_countries: program.result.countries_of_origin,
        primary_image: { image: getProgramImageVertical(program.result)},
        top_cast: program.result.cast.map((cast) => cast.name),
        release_year: getReleaseYear(program.result),
        images: program.result.images,
        allEpisodes: program.result?.allEpisodes
    };
}

export const isProgramAnEpisode = (program) => {
    if (program?.subtype) {
        switch (program.subtype) {
        case PROGRAM_SUBTYPES.EPISODE:
        case PROGRAM_SUBTYPES.SPORT_RELATED_EPISODE:
        case PROGRAM_SUBTYPES.TEAM_EVENT:
            return true;
        default:
            return false;
        }
    }
    return false;
}

/**
 * used to generate program descriptions array for all lengths
 * whether or not user has inputted a description for a specific length.
 * @param {Object[]} originalDescriptions - descriptions field - Array of objects
 * @returns {Object[]}
 */
export const generateDescriptions = (originalDescriptions) => {
    if (originalDescriptions?.length !== MOBIUS_INITIAL_DESCRIPTIONS?.length) {
        const lengthSet = new Set(originalDescriptions.map((d) => d.length));
        const language = originalDescriptions.length > 0 ? originalDescriptions[0].language : '';
        const generatedDescriptions = [
            ...originalDescriptions,
            ...MOBIUS_INITIAL_DESCRIPTIONS.filter((d) => !lengthSet.has(d.length))
        ].sort((a, b) => b.length - a.length);

        if (language.length > 0) {
            generatedDescriptions.forEach((description) => {
                description.language = language;
            });
        }

        return generatedDescriptions;
    }
    return originalDescriptions.sort((a, b) => b.length - a.length);
};

export const generateErrorMessage = (errorMessage) => {
    let newErrorMessage = MOBIUS_NOTIFICATIONS.PROGRAM_ERROR_SAVED
    if (errorMessage?.includes(API_ERROR_MESSAGES.API_IMAGE_NOT_FOUND)) {
        newErrorMessage = MOBIUS_NOTIFICATIONS.IMAGE_ERROR_NOT_FOUND
    } else if (errorMessage?.includes(API_ERROR_MESSAGES.API_IMAGE_ALREADY_MAPPED)) {
        newErrorMessage = MOBIUS_NOTIFICATIONS.IMAGE_ERROR_ALREADY_MAPPED
    }
    return newErrorMessage;
}

export const findTitleByPresentationGnID = (presentationsArray, episodesArray, idToFind) => {
    const foundPresentation = presentationsArray?.find(obj => obj.presentationGnID === idToFind);
    const foundEpisode = episodesArray?.find(obj => obj.gnID === idToFind);
    return (foundPresentation || foundEpisode) ? (foundPresentation?.title || foundPresentation?.showTitle || constructEpisodeTitle(presentationsArray, foundEpisode)) : null;
};

export const constructEpisodeTitle = (presentationsArray, foundEpisode) => {
    const foundSeries = presentationsArray.find(obj => obj?.presentationGnID === foundEpisode?.showPresentationGnID)
    if (!foundSeries) {
        return null;
    }
    return (
        <div className='episode-title-container'>
            <div className='episode-show-title'>{foundSeries?.title || foundSeries.showTitle}</div>
            <div className='season-and-episode-title'>{`S${foundEpisode?.seasonNumber}E${foundEpisode?.episodeNumber}: ${foundEpisode?.title?.value}`}</div>
        </div>
    )
}

export const findTitleByCatalogGnID = (arr, idToFind) => {
    const foundObject = arr?.find(obj => obj.value === idToFind);
    return foundObject ? foundObject.name : null;
};

// Looks through the errors and compares it to a premade list of possible errors. Returns a condensed list of the actual present errors with a count of how many of each one.
export const findMatchingObjects = (errorObject, errorList) => {
    const matchingObjects = [];
    if (errorObject && errorList) {
        errorList.forEach((currentError) => {
            const matchingRadio = errorObject[currentError.value]
            if (matchingRadio) {
                matchingObjects.push({...currentError, numOfErrors: errorObject[currentError.value].length});
            }
        });
    }
    return matchingObjects;
}

/**
* Maps program type and subtype to the corresponding search terms.
* @param {string} programType - The program type (e.g., 'movie', 'show', 'episode').
* @param {string} subType - The program subtype (e.g., 'featureFilm', 'shortFilm').
* @returns {Object} The mapped programType and subType.
*/
export const mapProgramTypeAndSubTypeToGnviewFormat = (programType, subType) => {
    const programMapping = {
        'movie': {
            'featureFilm': { programType: 'Feature Film', subType: 'Feature Film' },
            'shortFilm': { programType: 'Short Film', subType: 'Short Film' },
            'trailer': { programType: 'Short Film', subType: 'Trailer' },
            'tvMovie': { programType: 'TV Movie', subType: 'TV Movie' }
        },
        'show': {
            'compilation': { programType: 'Special', subType: 'Compilation' },
            'derivative': { programType: 'Series', subType: 'Preview' },
            'highlights': { programType: 'Series', subType: 'Preview' },
            'miniseries': { programType: 'Miniseries', subType: 'Miniseries' },
            'musicVideo': { programType: 'Music Video', subType: 'Music Video' },
            'paidProgram': { programType: 'Paid Programming', subType: 'Paid Program' },
            'series': { programType: 'Series', subType: 'Series' },
            'special': { programType: 'Special', subType: 'All Subtypes' }
        },
        'episode': {
            'episode': { programType: 'Series', subType: 'Episode' }
        }
    };
    // Check if the program type exists in the mapping
    if (programMapping[programType] && programMapping[programType][subType]) {
        return programMapping[programType][subType];
    } else {
        console.error(`Mapping not found for ${programType}/${subType}`);
        return null;
    }
}

export const transformEpisodeForPublish = (episdoeToTransform) => {
    const transformedEpisodeObj = {"gnID": episdoeToTransform.gnID, "title": episdoeToTransform.title.value, "type": episdoeToTransform.type, "subType": episdoeToTransform.subType, "episodeNumber": episdoeToTransform.episodeNumber, "publishingStatus": episdoeToTransform.publishingStatus, "seasonNumber": episdoeToTransform.seasonNumber, "showPresentationGnID": episdoeToTransform.showPresentationGnID, "seasonGnID": episdoeToTransform.seasonGnID}
    return transformedEpisodeObj;
}