import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './TablePopover.scss';
import { Overlay } from 'react-bootstrap';
import ClassNames from 'classnames';
import { handleEventPropagation } from '../../../utils/GeneralUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { gvauthSelMobiusSourceID } from '../../../reducers/GNVAuthReducer';
import { getGNIDTablePopoverByGnId } from '../../../reducers/GNIDSTableReducer';
import GNConfirmationModal from '../gnConfirmationModal/GNConfirmationModal';
import { TABLE_POPOVER_BUTTONS, MOBIUS_BUTTON_TEXT, MOBIUS_CONFIRMATIONS, MOBIUS_NOTIFICATIONS, MOBIUS_PROGRAM_TYPES_VALUES } from '../../../constants/Mobius';
import { gnidsPopoverModalToggle, gnidsPopoverModalToggleAll, mobiusDeleteItemByGNId } from '../../../actions/MobiusActions';

const TablePopover = ({
    handleModal,
    handleNotification,
    program,
    gnID,
    category,
    setRegisterPresentationMode,
    setRegisterVersionMode,
    showRegisterOptions = true
}) => {
    const dispatch = useDispatch();
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const sourceId = useSelector(gvauthSelMobiusSourceID);
    const showPopoverDropdown = useSelector(getGNIDTablePopoverByGnId(gnID));
    const target = useRef(null);

    const getMessagesByCategory = () => {
        switch (category) {
        case MOBIUS_PROGRAM_TYPES_VALUES.EPISODE:
        case MOBIUS_PROGRAM_TYPES_VALUES.PROGRAM:
            return ({
                deleteSuccessNotificationMessage: MOBIUS_NOTIFICATIONS.PROGRAM_SUCCESS_DELETE,
                deleteFailureNotificationMessage: MOBIUS_NOTIFICATIONS.PROGRAM_ERROR_DELETE,
                deleteConfirmMessage: MOBIUS_CONFIRMATIONS.DELETE_PROGRAM_CONFIRMATION_MESSAGE,
                deleteConfirmTitle: MOBIUS_CONFIRMATIONS.DELETE_PROGRAM_CONFIRMATION_TITLE
            })
        case MOBIUS_PROGRAM_TYPES_VALUES.CATALOG:
            return ({
                deleteSuccessNotificationMessage: MOBIUS_NOTIFICATIONS.CATALOG_SUCCESS_DELETE,
                deleteFailureNotificationMessage: MOBIUS_NOTIFICATIONS.CATALOG_ERROR_DELETE,
                deleteConfirmMessage: MOBIUS_CONFIRMATIONS.DELETE_CATALOG_CONFIRMATION_MESSAGE,
                deleteConfirmTitle: MOBIUS_CONFIRMATIONS.DELETE_CATALOG_CONFIRMATION_TITLE
            })
        case MOBIUS_PROGRAM_TYPES_VALUES.IMAGE:
            return ({
                deleteSuccessNotificationMessage: MOBIUS_NOTIFICATIONS.IMAGE_SUCCESS_DELETE,
                deleteFailureNotificationMessage: MOBIUS_NOTIFICATIONS.IMAGE_ERROR_DELETE,
                deleteConfirmMessage: MOBIUS_CONFIRMATIONS.DELETE_IMAGE_CONFIRMATION_MESSAGE,
                deleteConfirmTitle: MOBIUS_CONFIRMATIONS.DELETE_IMAGE_CONFIRMATION_TITLE
            })
        default:
            return ({
                deleteSuccessNotificationMessage: MOBIUS_NOTIFICATIONS.PROGRAM_SUCCESS_DELETE,
                deleteFailureNotificationMessage: MOBIUS_NOTIFICATIONS.PROGRAM_ERROR_DELETE,
                deleteConfirmMessage: MOBIUS_CONFIRMATIONS.DELETE_PROGRAM_CONFIRMATION_MESSAGE,
                deleteConfirmTitle: MOBIUS_CONFIRMATIONS.DELETE_PROGRAM_CONFIRMATION_TITLE
            })
        }
    }
    const {deleteSuccessNotificationMessage, deleteFailureNotificationMessage, deleteConfirmMessage, deleteConfirmTitle} = getMessagesByCategory()

    const handleDeleteBtn = () => {
        setIsModalLoading(true);
        const {type} = program
        if (program && sourceId && gnID) {
            dispatch(mobiusDeleteItemByGNId(gnID, sourceId, type))
                .then(() => {
                    setShowConfirmationModal(false);
                    setIsModalLoading(false);
                    handleModal(program, true, null, null, false, true);
                    handleNotification(true, deleteSuccessNotificationMessage, true);
                })
                .catch(() => {
                    setShowConfirmationModal(false);
                    setIsModalLoading(false);
                    handleNotification(true, deleteFailureNotificationMessage, false);
                });
        }
    };

    const popoverDropdown = (
        <div className='table-popover-dropdown'>
            {showRegisterOptions && <div className="register-buttons">
                <div className='menu-item' onClick={(e) => {
                    handleEventPropagation(e);
                    setRegisterVersionMode(true);
                    handleModal(program, false, null, null, true, null);
                }}>
                    <FontAwesomeIcon icon="plus-circle" className="table-popover-plus" />
                    {TABLE_POPOVER_BUTTONS.REGISTER_VERSION}
                </div>
                <div className='menu-item' onClick={(e) => {
                    handleEventPropagation(e);
                    setRegisterPresentationMode(true);
                    handleModal(program, false, null, null, true, null);
                }}>
                    <FontAwesomeIcon icon="plus-circle" className="table-popover-plus" />
                    {TABLE_POPOVER_BUTTONS.REGISTER_PRESENTATION}
                </div>
                <hr className='menu-line' />
            </div>}
            <div className='menu-item' onClick={(e) => {
                handleEventPropagation(e);
                setShowConfirmationModal(true);
            }}>
                <FontAwesomeIcon icon="trash-alt" className="table-popover-trash" />
                {TABLE_POPOVER_BUTTONS.DELETE}
            </div>
        </div>
    );

    return (
        <div className={ClassNames('table-popover-container', {
            'popover-expanded': showPopoverDropdown
        })} onClick={(e) => {
            dispatch(gnidsPopoverModalToggleAll())
            dispatch(gnidsPopoverModalToggle(gnID, !showPopoverDropdown))
            handleEventPropagation(e);
        }} ref={target}>
            <FontAwesomeIcon icon="ellipsis-v" className="Table-popover-ellipsis" data-testid='Table-popover-ellipsis'/>
            <Overlay transition={false} rootClose={true} show={showPopoverDropdown} onHide={() => {
                dispatch(gnidsPopoverModalToggle(gnID, !showPopoverDropdown))
            }} placement='bottom' target={target.current}>{popoverDropdown}</Overlay>
            {showConfirmationModal && (
                <GNConfirmationModal
                    cancelButtonHandler={() => setShowConfirmationModal(false)}
                    cancelButtonText={MOBIUS_BUTTON_TEXT.CANCEL.toUpperCase()}
                    isLoading={isModalLoading}
                    message={deleteConfirmMessage}
                    show={showConfirmationModal}
                    submitButtonHandler={handleDeleteBtn}
                    submitButtonText={MOBIUS_BUTTON_TEXT.DELETE.toUpperCase()}
                    title={deleteConfirmTitle}
                />
            )}
        </div>
    );
};

TablePopover.propTypes = {
    handleModal: PropTypes.func.isRequired,
    handleNotification: PropTypes.func.isRequired,
    program: PropTypes.object.isRequired,
    category: PropTypes.string,
    gnID: PropTypes.string.isRequired,
    setRegisterPresentationMode: PropTypes.func,
    setRegisterVersionMode: PropTypes.func,
    showRegisterOptions: PropTypes.bool
};

export default TablePopover;