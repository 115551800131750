import {
    SET_GNIDS_IMAGE,
    CLEAR_GNIDS_IMAGE_MODAL_DATA,
    SHOW_IMAGE_MODAL
} from '../actions/ActionTypes';

export const IMAGE_MODAL_STORE_NAME = 'image';


/**
 * Initial State
 */
export const initialState = {
    image: null,
    show: false
};

/**
 * Reducer
 */
export const GNIDSImageModalReducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_GNIDS_IMAGE:
        return {
            ...state,
            image: action.payload
        };
    case SHOW_IMAGE_MODAL:
        return {
            ...state,
            show: action.payload
        }
    case CLEAR_GNIDS_IMAGE_MODAL_DATA:
        return initialState;
    default:
        return state;
    }
};


/**
 * Selectors
 */
export const gnidsImage = (state) => state[IMAGE_MODAL_STORE_NAME]?.image;
export const isImageModalOpen = (state) => state[IMAGE_MODAL_STORE_NAME]?.show

/**
 * Default export
 */
export default GNIDSImageModalReducer;